import { Optional } from "./common";
import { ErrorCoreOutDated } from "./error";

const DOMAIN = "com.myassets";

export enum VersionedType {
  Action = "Action",
  Contact = "Contact",
  ContactLocation = "ContactLocation",
  Group = "Group", //will be removed
  GroupInfo = "GroupInfo",
  Art = "Art",
  ArtSummary = "ArtSummary",
  Belonging = "Belonging",
  BelongingSummary = "BelongingSummary",
  CashAndBankingSummary = "CashAndBankingSummary",
  Cryptocurrency = "Cryptocurrency",
  CryptocurrencySummary = "CryptocurrencySummary",
  Insurance = "Insurance",
  InsuranceSummary = "InsuranceSummary",
  OtherInvestment = "OtherInvestment",
  OtherInvestmentSummary = "OtherInvestmentSummary",
  Property = "Property",
  PropertySummary = "PropertySummary",
  Portfolio = "Portfolio",
  TraditionalInvestmentSummary = "TraditionalInvestmentSummary",
  Wine = "Wine",
  WinePurchase = "WinePurchase",
  WineSummary = "WineSummary",
  Institution = "Institution",
  Cash = "Cash",
  CreditCard = "CreditCard",
  CurrentAccount = "CurrentAccount",
  SavingAccount = "SavingAccount",
  Loan = "Loan",
  Mortgage = "Mortgage",
}
export type VersionedTypeString<
  Type extends VersionedType = VersionedType,
  V extends number = number
> = `${typeof DOMAIN}/${Type}/v${V}`;

export const ActionTypeVersion: VersionedTypeString<VersionedType.Action, 2> =
  "com.myassets/Action/v2";
export const ContactTypeVersion: VersionedTypeString<VersionedType.Contact, 2> =
  "com.myassets/Contact/v2";
export const ContactLocationTypeVersion: VersionedTypeString<
  VersionedType.ContactLocation,
  2
> = "com.myassets/ContactLocation/v2";
export const GroupTypeVersion: VersionedTypeString<VersionedType.Group, 2> =
  "com.myassets/Group/v2";
export const GroupInfoTypeVersion: VersionedTypeString<
  VersionedType.GroupInfo,
  2
> = "com.myassets/GroupInfo/v2";

export const ArtTypeVersion: VersionedTypeString<VersionedType.Art, 2> =
  "com.myassets/Art/v2";
export const ArtSummaryTypeVersion: VersionedTypeString<
  VersionedType.ArtSummary,
  3
> = "com.myassets/ArtSummary/v3";

export const BelongingTypeVersion: VersionedTypeString<
  VersionedType.Belonging,
  2
> = "com.myassets/Belonging/v2";
export const BelongingSummaryTypeVersion: VersionedTypeString<
  VersionedType.BelongingSummary,
  3
> = "com.myassets/BelongingSummary/v3";

export const CashAndBankingSummaryTypeVersion: VersionedTypeString<
  VersionedType.CashAndBankingSummary,
  3
> = "com.myassets/CashAndBankingSummary/v3";

export const CryptocurrencyTypeVersion: VersionedTypeString<
  VersionedType.Cryptocurrency,
  2
> = "com.myassets/Cryptocurrency/v2";
export const CryptocurrencySummaryTypeVersion: VersionedTypeString<
  VersionedType.CryptocurrencySummary,
  2
> = "com.myassets/CryptocurrencySummary/v2";

export const InsuranceTypeVersion: VersionedTypeString<
  VersionedType.Insurance,
  2
> = "com.myassets/Insurance/v2";
export const InsuranceSummaryTypeVersion: VersionedTypeString<
  VersionedType.InsuranceSummary,
  3
> = "com.myassets/InsuranceSummary/v3";

export const OtherInvestmentTypeVersion: VersionedTypeString<
  VersionedType.OtherInvestment,
  2
> = "com.myassets/OtherInvestment/v2";
export const OtherInvestmentSummaryTypeVersion: VersionedTypeString<
  VersionedType.OtherInvestmentSummary,
  3
> = "com.myassets/OtherInvestmentSummary/v3";

export const PropertyTypeVersion: VersionedTypeString<
  VersionedType.Property,
  2
> = "com.myassets/Property/v2";
export const PropertySummaryTypeVersion: VersionedTypeString<
  VersionedType.PropertySummary,
  3
> = "com.myassets/PropertySummary/v3";

export const PortfolioTypeVersion: VersionedTypeString<
  VersionedType.Portfolio,
  2
> = "com.myassets/Portfolio/v2";
export const TraditionalInvestmentSummaryTypeVersion: VersionedTypeString<
  VersionedType.TraditionalInvestmentSummary,
  2
> = "com.myassets/TraditionalInvestmentSummary/v2";

export const WineTypeVersion: VersionedTypeString<VersionedType.Wine, 2> =
  "com.myassets/Wine/v2";
export const WinePurchaseTypeVersion: VersionedTypeString<
  VersionedType.WinePurchase,
  2
> = "com.myassets/WinePurchase/v2";
export const WineSummaryTypeVersion: VersionedTypeString<
  VersionedType.WineSummary,
  3
> = "com.myassets/WineSummary/v3";

export const InstitutionTypeVersion: VersionedTypeString<
  VersionedType.Institution,
  2
> = "com.myassets/Institution/v2";

export const CashTypeVersion: VersionedTypeString<VersionedType.Cash, 2> =
  "com.myassets/Cash/v2";
export const CreditCardTypeVersion: VersionedTypeString<
  VersionedType.CreditCard,
  2
> = "com.myassets/CreditCard/v2";
export const CurrentAccountTypeVersion: VersionedTypeString<
  VersionedType.CurrentAccount,
  2
> = "com.myassets/CurrentAccount/v2";
export const SavingAccountTypeVersion: VersionedTypeString<
  VersionedType.SavingAccount,
  2
> = "com.myassets/SavingAccount/v2";
export const LoanTypeVersion: VersionedTypeString<VersionedType.Loan, 2> =
  "com.myassets/Loan/v2";
export const MortgageTypeVersion: VersionedTypeString<
  VersionedType.Mortgage,
  2
> = "com.myassets/Mortgage/v2";

export function destructVersionedType(input: string): {
  domain: string;
  type: VersionedType;
  version: number;
} {
  const parts = input.split("/");
  if (parts.length !== 3) {
    throw new Error(`Invalid versioned type: "${input}"`);
  }
  const [domain, type, versionString] = parts;
  if (domain !== DOMAIN) {
    throw new Error(`Invalid domain: "${domain}"`);
  }
  if (!Object.values(VersionedType).includes(type as VersionedType)) {
    throw new Error(`Invalid type: "${type}"`);
  }
  const version = parseInt(versionString.slice(1));
  if (isNaN(version)) {
    throw new Error(`Invalid version: "${versionString}"`);
  }
  return { domain, type: type as VersionedType, version };
}

export type TypedObject<Type extends VersionedType, V extends number> = {
  "@type": VersionedTypeString<Type, V>;
};

export enum TypeResult {
  DataOutDated,
  UpToDate,
  CoreOutDated,
}
export function validateTypeUpToDate<
  Type extends VersionedType,
  V extends number,
  VersionedTypeObject extends TypedObject<Type, V>
>(
  input: VersionedTypeObject,
  expectedType: VersionedTypeString<Type, V>,
  errorOnCoreOutDated: boolean
): TypeResult {
  const { type, version } = destructVersionedType(input["@type"]);
  const expected = destructVersionedType(expectedType);
  if (type !== expected.type) {
    throw new Error(`Inconsistent type: "${type}"`);
  }
  if (version < expected.version) return TypeResult.DataOutDated;
  else if (version == expected.version) return TypeResult.UpToDate;
  else if (errorOnCoreOutDated) {
    throw ErrorCoreOutDated(type, version, expected.version);
  } else return TypeResult.CoreOutDated;
}

export function assureSummaryVersion<
  Type extends VersionedType,
  V extends number,
  T extends TypedObject<Type, V>
>(
  data: Optional<T>,
  assureFunc: (input: T, errorOnCoreOutDated?: boolean) => TypeResult,
  defaultFunc: () => T,
  errorOnCoreOutDated?: boolean
) {
  if (data === undefined) {
    return defaultFunc();
  } else {
    const result = assureFunc(data, errorOnCoreOutDated);
    if (result === TypeResult.DataOutDated) {
      return defaultFunc();
    } else {
      return data;
    }
  }
}
