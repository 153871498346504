import { m } from 'framer-motion'

import { cn } from '@/utils/classnames'
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import { PlusIcon } from '@/components/icon'

interface CreateAssetButtonProps {
  icon?: JSX.Element
  label?: string
  className?: string
  disabled?: boolean
  onClick?: () => void
  id?: string
}

export default function CreateAssetButton({ icon, label, disabled, className, onClick, id }: CreateAssetButtonProps) {
  return (
    <m.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.3 }}>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            id={id}
            className={cn(
              'h-8 w-8 rounded-full bg-red-700 hover:bg-red-900 disabled:bg-grey md:h-10 md:w-10',
              className
            )}
            onClick={onClick}
            disabled={disabled}
            aria-label={'Create asset'}
            data-testid={'create-asset-button'}
          >
            {icon ?? <PlusIcon className={'h-4 w-4 text-white md:h-6 md:w-6'} />}
          </Button>
        </TooltipTrigger>
        {label && (
          <TooltipContent side={'bottom'} sideOffset={8}>
            {label}
          </TooltipContent>
        )}
      </Tooltip>
    </m.div>
  )
}
