import * as React from 'react'

interface MaskState {
  isMasked: boolean
  toggleMask: () => void
}

const MaskContext = React.createContext<MaskState>({ isMasked: false, toggleMask: () => {} })

function getInitialState() {
  if (typeof window === 'undefined') return false
  const value = sessionStorage.getItem('isMasked')
  return value ? JSON.parse(value) : false
}

export function MaskProvider({ children }: React.PropsWithChildren) {
  const [isMasked, setIsMasked] = React.useState<boolean>(getInitialState)

  const toggleMask = React.useCallback(() => {
    setIsMasked((prev) => {
      const nextState = !prev
      sessionStorage.setItem('isMasked', JSON.stringify(nextState))
      return nextState
    })
  }, [])

  const value = React.useMemo(() => ({ isMasked, toggleMask }), [isMasked, toggleMask])

  return <MaskContext.Provider value={value}>{children}</MaskContext.Provider>
}

export function useMask() {
  return React.useContext(MaskContext)
}
