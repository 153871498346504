import {
  CashAndBankingSummary,
  CategoriesAssociatedLiability,
} from "./cashAndBankingSummary";
import { ArtSummary } from "./artSummary";
import { BelongingSummary, SubtypeItem } from "./belongingSummary";
import {
  Amount,
  AssetType,
  Categories,
  Currency,
  FinanceTypes,
  Optional,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { InsuranceSummary } from "./insuranceSummary";
import { OtherInvestmentSummary } from "./otherInvestmentSummary";
import { PropertySummary } from "./propertySummary";
import { WineSummary } from "./wineSummary";
import { calculatePercentage, subDecimal } from "../utils";
import Decimal from "decimal.js";
import { SummaryPermissions } from "./summaryManager";
import { PermissionCategory } from "../refPaths";

interface BalanceSheet {
  assets: Amount;
  liabilities: Amount;
  netValue: Amount;
}

type WithAssetType<T> = T & { assetType: AssetType };
type ValueDistribution<Label> = AssetsAndLiabilities<ValueWithLabel<Label>>;
interface AssetsAndLiabilities<Data> {
  assets: Data[];
  liabilities: Data[];
}

interface ValueWithLabel<T> {
  label: T;
  value: Amount;
  percentage: number;
}

// export interface GlobalDashboard {
//   //#NOTE we can't access stock price and coin price here, the final calculation should be done outside
//   // valueDistribution: ValueDistribution<Categories>;
//   // myFinances: BalanceSheet & ValueDistribution<FinanceTypes>;
//   myFinancesData: {
//     cashAndBanking?: OmitKeys<
//       CashAndBankingSummary.GlobalDashboardData,
//       "categoryLiabilities"
//     >;
//     traditionalInvestment?: TraditionalInvestmentSummary.GlobalDashboardData;
//     otherInvestment?: OtherInvestmentSummary.GlobalDashboardData;
//     cryptocurrency?: CryptocurrencySummary.GlobalDashboardData;
//     insurance?: InsuranceSummary.GlobalDashboardData;
//   };
//   myProperties: BalanceSheet;
//   //#NOTE wine, art and subtypeItems of collectables are mixed and displayed together
//   // - we can't really count on the name to locate art and wineAndSprits
//   myCollectables: BalanceSheet & {
//     subtypeItems: WithAssetType<SubtypeItem>[];
//   };
//   myBelongings: BalanceSheet & { distribution: ValueDistribution<string> };
// }
export type GlobalDashboard = BalanceSheet & {
  //#NOTE we can't access stock price and coin price here, the final calculation should be done outside
  // valueDistribution: ValueDistribution<Categories>;
  // myFinances: BalanceSheet & ValueDistribution<FinanceTypes>;
  valueDistribution: ValueDistribution<Categories>;
  myFinances: BalanceSheet & {
    distribution: ValueDistribution<FinanceTypes>;
  };
  myProperties: BalanceSheet;
  //#NOTE wine, art and subtypeItems of collectables are mixed and displayed together
  // - we can't really count on the name to locate art and wineAndSprits
  myCollectables: BalanceSheet & {
    subtypeItems: WithAssetType<SubtypeItem>[];
  };
  myBelongings: BalanceSheet & { distribution: ValueDistribution<string> };
};
export namespace GlobalDashboard {
  export function defaultValue(currency: Currency): GlobalDashboard {
    return {
      assets: Amount.zero(currency),
      liabilities: Amount.zero(currency),
      netValue: Amount.zero(currency),
      valueDistribution: {
        assets: [],
        liabilities: [],
      },
      myFinances: {
        assets: Amount.zero(currency),
        liabilities: Amount.zero(currency),
        netValue: Amount.zero(currency),
        distribution: {
          //by AssetType
          assets: [],
          liabilities: [],
        },
      },
      myProperties: {
        assets: Amount.zero(currency),
        liabilities: Amount.zero(currency), // Associated
        netValue: Amount.zero(currency),
      },
      myCollectables: {
        assets: Amount.zero(currency),
        liabilities: Amount.zero(currency), // Associated
        netValue: Amount.zero(currency),
        subtypeItems: [],
      },
      myBelongings: {
        assets: Amount.zero(currency),
        liabilities: Amount.zero(currency), // Associated
        netValue: Amount.zero(currency),
        distribution: {
          // by subtype
          assets: [],
          liabilities: [],
        },
      },
    };
  }

  //#NOTE this function will recalculate assets, liabilities, netValue and distribution of input
  export function calculateSumData(
    input: GlobalDashboard,
    permissions: SummaryPermissions
  ) {
    const assets = Amount.zero(input.assets.currency);
    const liabilities = Amount.zero(input.assets.currency);
    const valueDistribution: ValueDistribution<Categories> = {
      assets: [],
      liabilities: [],
    };

    assets.value = new Decimal(input.myFinances.assets.value)
      .add(input.myProperties.assets.value)
      .add(input.myCollectables.assets.value)
      .add(input.myBelongings.assets.value)
      .toNumber();
    liabilities.value = input.myFinances.liabilities.value;

    if (
      permissions[PermissionCategory.Finance].read ||
      permissions[PermissionCategory.Insurance].read
    ) {
      valueDistribution.assets.push({
        label: Categories.MyFinances,
        value: input.myFinances.assets,
        percentage: calculatePercentage(
          input.myFinances.assets.value,
          assets.value
        ),
      });
      valueDistribution.liabilities.push({
        label: Categories.MyFinances,
        value: input.myFinances.liabilities,
        percentage: calculatePercentage(
          input.myFinances.liabilities.value,
          liabilities.value
        ),
      });
    }
    if (permissions[PermissionCategory.Property].read) {
      valueDistribution.assets.push({
        label: Categories.MyProperties,
        value: input.myProperties.assets,
        percentage: calculatePercentage(
          input.myProperties.assets.value,
          assets.value
        ),
      });
    }
    if (permissions[PermissionCategory.OtherCollectables].read) {
      valueDistribution.assets.push({
        label: Categories.MyCollectables,
        value: input.myCollectables.assets,
        percentage: calculatePercentage(
          input.myCollectables.assets.value,
          assets.value
        ),
      });
    }
    if (permissions[PermissionCategory.Belonging].read) {
      valueDistribution.assets.push({
        label: Categories.MyBelongings,
        value: input.myBelongings.assets,
        percentage: calculatePercentage(
          input.myBelongings.assets.value,
          assets.value
        ),
      });
    }
    input.assets = assets;
    input.liabilities = liabilities;
    input.netValue.currency = assets.currency;
    input.netValue.value = subDecimal(assets.value, liabilities.value);
    input.valueDistribution = valueDistribution;
  }

  export async function fromSummaries(
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    cashAndBanking: Optional<CashAndBankingSummary>,
    traditionalInvestment: Optional<Amount>, //calculated outside
    otherInvestment: Optional<OtherInvestmentSummary>,
    cryptocurrency: Optional<Amount>, //calculated outside
    insurance: Optional<InsuranceSummary>,
    property: Optional<PropertySummary>,
    art: Optional<ArtSummary>,
    wine: Optional<WineSummary>,
    otherCollectable: Optional<BelongingSummary>,
    belonging: Optional<BelongingSummary>,
    associatedLiability: Optional<CategoriesAssociatedLiability>,
    permissions: SummaryPermissions
  ) {
    const currency = exchangeRate.targetCurrency;
    const ignoreAssetTypes: AssetType[] = [];
    if (art === undefined) ignoreAssetTypes.push(AssetType.Art);
    if (wine === undefined) ignoreAssetTypes.push(AssetType.WineAndSpirits);
    if (otherCollectable === undefined)
      ignoreAssetTypes.push(AssetType.OtherCollectables);

    const result = defaultValue(currency);

    // MyFinances
    const insuranceData = insurance
      ? InsuranceSummary.toDisplay(insurance, exchangeRate)
      : undefined;
    result.myFinances.assets.value = insuranceData?.assets.value || 0;

    const cashAndBankingData = cashAndBanking
      ? CashAndBankingSummary.toDisplay(cashAndBanking, exchangeRate)
      : undefined;
    result.myFinances.liabilities.value =
      cashAndBankingData?.liabilities.value || 0;
    if (permissions[PermissionCategory.Finance].read) {
      if (
        cashAndBankingData === undefined ||
        traditionalInvestment === undefined ||
        otherInvestment === undefined ||
        cryptocurrency === undefined
      )
        throw new Error("Missing finance data");

      const otherInvestmentData = OtherInvestmentSummary.toDisplay(
        otherInvestment,
        exchangeRate
      );

      result.myFinances.assets.value = new Decimal(
        result.myFinances.assets.value
      )
        .add(cashAndBankingData.assets.value)
        .add(traditionalInvestment.value)
        .add(otherInvestmentData.assets.value)
        .add(cryptocurrency.value)
        .toNumber();

      result.myFinances.distribution.assets = [
        {
          label: FinanceTypes.CashAndBanking,
          value: cashAndBankingData.assets,
          percentage: calculatePercentage(
            cashAndBankingData.assets.value,
            result.myFinances.assets.value
          ),
        },
        {
          label: FinanceTypes.TraditionalInvestments,
          value: {
            currency,
            value: traditionalInvestment.value,
          },
          percentage: calculatePercentage(
            traditionalInvestment.value,
            result.myFinances.assets.value
          ),
        },
        {
          label: FinanceTypes.OtherInvestments,
          value: otherInvestmentData.assets,
          percentage: calculatePercentage(
            otherInvestmentData.assets.value,
            result.myFinances.assets.value
          ),
        },
        {
          label: FinanceTypes.Cryptocurrencies,
          value: {
            currency,
            value: cryptocurrency.value,
          },
          percentage: calculatePercentage(
            cryptocurrency.value,
            result.myFinances.assets.value
          ),
        },
      ];
      result.myFinances.distribution.liabilities = [
        {
          label: FinanceTypes.CashAndBanking,
          value: cashAndBankingData.liabilities,
          percentage: 100,
        },
        {
          label: FinanceTypes.TraditionalInvestments,
          value: {
            currency,
            value: 0,
          },
          percentage: 0,
        },
        {
          label: FinanceTypes.OtherInvestments,
          value: {
            currency,
            value: 0,
          },
          percentage: 0,
        },
        {
          label: FinanceTypes.Cryptocurrencies,
          value: {
            currency,
            value: 0,
          },
          percentage: 0,
        },
      ];
    }
    result.myFinances.netValue.value = subDecimal(
      result.myFinances.assets.value,
      result.myFinances.liabilities.value
    );

    if (
      permissions[PermissionCategory.Insurance].read &&
      insuranceData !== undefined
    ) {
      result.myFinances.distribution.assets.push({
        label: FinanceTypes.Insurance,
        value: insuranceData.assets,
        percentage: calculatePercentage(
          insuranceData.assets.value,
          result.myFinances.assets.value
        ),
      });
      result.myFinances.distribution.liabilities.push({
        label: FinanceTypes.Insurance,
        value: {
          currency,
          value: 0,
        },
        percentage: 0,
      });
    }

    if (
      permissions[PermissionCategory.Property].read &&
      property !== undefined
    ) {
      const propertyData = PropertySummary.toDisplay(property, exchangeRate);
      result.myProperties.assets.value = propertyData.assets.value;
      result.myProperties.liabilities.value =
        associatedLiability?.MyProperties.value || 0;
      result.myProperties.netValue.value = result.myProperties.assets.value;
    }

    let collectableAssetsValue = new Decimal(0);
    const collectableSubtypeItems: WithAssetType<SubtypeItem>[] = [];
    if (permissions[PermissionCategory.Art].read && art !== undefined) {
      const artData = ArtSummary.toDisplay(art, exchangeRate);
      if (artData.subtypeItems.length > 0) {
        const { assetNumber, itemNumber } = artData.subtypeItems.reduce(
          (acc, v) => {
            return {
              assetNumber: acc.assetNumber + v.assetNumber,
              itemNumber: acc.itemNumber + v.itemNumber,
            };
          },
          { assetNumber: 0, itemNumber: 0 }
        );

        collectableAssetsValue = collectableAssetsValue.add(
          artData.assets.value
        );
        collectableSubtypeItems.push({
          assetType: AssetType.Art,
          label: AssetType.Art,
          value: artData.assets,
          percentage: 100,
          assetNumber,
          itemNumber,
        });
      }
    }
    if (permissions[PermissionCategory.Wine].read && wine !== undefined) {
      const wineData = WineSummary.toDisplay(wine, exchangeRate);
      if (wineData.subtypeItems.length > 0) {
        const { assetNumber, itemNumber } = wineData.subtypeItems.reduce(
          (acc, v) => {
            return {
              assetNumber: acc.assetNumber + v.assetNumber,
              itemNumber: acc.itemNumber + v.itemNumber,
            };
          },
          { assetNumber: 0, itemNumber: 0 }
        );

        collectableAssetsValue = collectableAssetsValue.add(
          wineData.assets.value
        );
        collectableSubtypeItems.push({
          assetType: AssetType.WineAndSpirits,
          label: AssetType.WineAndSpirits,
          value: wineData.assets,
          percentage: 100,
          assetNumber,
          itemNumber,
        });
      }
    }

    if (
      permissions[PermissionCategory.OtherCollectables].read &&
      otherCollectable !== undefined
    ) {
      const otherCollectableData = BelongingSummary.toDisplay(
        otherCollectable,
        exchangeRate
      );

      collectableAssetsValue = collectableAssetsValue.add(
        otherCollectableData.assets.value
      );
      collectableSubtypeItems.push(
        ...otherCollectableData.subtypeItems.map((v) => ({
          assetType: AssetType.OtherCollectables,
          label: v.label,
          value: v.value,
          percentage: 100,
          assetNumber: v.assetNumber,
          itemNumber: v.itemNumber,
        }))
      );
    }
    collectableSubtypeItems.map((v) => {
      v.percentage = calculatePercentage(v.value.value, collectableAssetsValue);
    });
    collectableSubtypeItems.sort((a, b) => b.value.value - a.value.value);
    result.myCollectables.assets.value = collectableAssetsValue.toNumber();
    result.myCollectables.liabilities.value =
      associatedLiability?.MyCollectables.value || 0;
    result.myCollectables.netValue.value = result.myCollectables.assets.value;
    result.myCollectables.subtypeItems = collectableSubtypeItems;

    if (
      permissions[PermissionCategory.Belonging].read &&
      belonging !== undefined
    ) {
      const belongingData = BelongingSummary.toDisplay(belonging, exchangeRate);
      result.myBelongings.assets.value = belongingData.assets.value;
      result.myBelongings.liabilities.value =
        associatedLiability?.MyBelongings.value || 0;
      result.myBelongings.netValue.value = result.myBelongings.assets.value;
      result.myBelongings.distribution.assets = belongingData.subtypeItems.map(
        (v) => ({
          label: v.label,
          value: v.value,
          percentage: calculatePercentage(
            v.value.value,
            result.myBelongings.assets.value
          ),
        })
      );
    }

    calculateSumData(result, permissions);
    return result;
  }
}
