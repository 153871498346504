import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2272%22%20height%3D%2272%22%3E%3Cpath%20d%3D%22M0%200%20C0.66%200.33%201.32%200.66%202%201%20C-1.465%209.91%20-1.465%209.91%20-5%2019%20C0.61%2019.33%206.22%2019.66%2012%2020%20C10.04945522%2023.90108957%207.87853904%2026.39658825%204.8359375%2029.39453125%20C4.32503571%2029.90497482%203.81413391%2030.4154184%203.28775024%2030.94132996%20C2.20575408%2032.02031057%201.12133401%2033.09686524%200.03466797%2034.17114258%20C-1.60368276%2035.79141795%20-3.23406937%2037.41936932%20-4.86328125%2039.04882812%20C-11.59162611%2045.75466715%20-18.34650667%2052.34530313%20-26%2058%20C-25.29807915%2053.06678166%20-23.36469225%2048.74025318%20-21.4375%2044.1875%20C-21.10814453%2043.39923828%20-20.77878906%2042.61097656%20-20.43945312%2041.79882812%20C-19.63041094%2039.86416204%20-18.81576148%2037.93184247%20-18%2036%20C-23.28%2035.67%20-28.56%2035.34%20-34%2035%20C-31.99230184%2030.98460368%20-29.84077609%2028.81666858%20-26.5625%2025.9375%20C-17.35098927%2017.65936147%20-8.70930789%208.79918009%200%200%20Z%20M-14.28515625%2021.15234375%20C-15.65994141%2022.53099609%20-15.65994141%2022.53099609%20-17.0625%2023.9375%20C-17.98160156%2024.85402344%20-18.90070312%2025.77054688%20-19.84765625%2026.71484375%20C-22.11427273%2028.59812547%20-22.11427273%2028.59812547%20-22%2030%20C-18.04%2030%20-14.08%2030%20-10%2030%20C-10.99%2032.97%20-11.98%2035.94%20-13%2039%20C-8.10565787%2034.90473413%20-3.29279666%2030.73642915%201%2026%20C1%2025.67%201%2025.34%201%2025%20C-3.62%2025%20-8.24%2025%20-13%2025%20C-12.34%2023.02%20-11.68%2021.04%20-11%2019%20C-12.40187453%2018.88572727%20-12.40187453%2018.88572727%20-14.28515625%2021.15234375%20Z%20%22%20fill%3D%22%23FEFEFE%22%20transform%3D%22translate(47%2C7)%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ValuationIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg width={size} height={size} viewBox={'0 0 72 72'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
      <path
        d={
          'M0 0 C0.66 0.33 1.32 0.66 2 1 C-1.465 9.91 -1.465 9.91 -5 19 C0.61 19.33 6.22 19.66 12 20 C10.04945522 23.90108957 7.87853904 26.39658825 4.8359375 29.39453125 C4.32503571 29.90497482 3.81413391 30.4154184 3.28775024 30.94132996 C2.20575408 32.02031057 1.12133401 33.09686524 0.03466797 34.17114258 C-1.60368276 35.79141795 -3.23406937 37.41936932 -4.86328125 39.04882812 C-11.59162611 45.75466715 -18.34650667 52.34530313 -26 58 C-25.29807915 53.06678166 -23.36469225 48.74025318 -21.4375 44.1875 C-21.10814453 43.39923828 -20.77878906 42.61097656 -20.43945312 41.79882812 C-19.63041094 39.86416204 -18.81576148 37.93184247 -18 36 C-23.28 35.67 -28.56 35.34 -34 35 C-31.99230184 30.98460368 -29.84077609 28.81666858 -26.5625 25.9375 C-17.35098927 17.65936147 -8.70930789 8.79918009 0 0 Z M-14.28515625 21.15234375 C-15.65994141 22.53099609 -15.65994141 22.53099609 -17.0625 23.9375 C-17.98160156 24.85402344 -18.90070312 25.77054688 -19.84765625 26.71484375 C-22.11427273 28.59812547 -22.11427273 28.59812547 -22 30 C-18.04 30 -14.08 30 -10 30 C-10.99 32.97 -11.98 35.94 -13 39 C-8.10565787 34.90473413 -3.29279666 30.73642915 1 26 C1 25.67 1 25.34 1 25 C-3.62 25 -8.24 25 -13 25 C-12.34 23.02 -11.68 21.04 -11 19 C-12.40187453 18.88572727 -12.40187453 18.88572727 -14.28515625 21.15234375 Z'
        }
        fill={'currentColor'}
        transform={'translate(47,7)'}
      />
    </svg>
  )
}
