import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIzNXB4IiB2aWV3Qm94PSIwIDAgMzUgMzUiIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGRlc2M+Q3JlYXRlZCB3aXRoIEx1bmFjeTwvZGVzYz4KICA8ZGVmcz4KICAgIDxwYXRoIGQ9Ik0wIDEyTDE3LjUgMEwzNSAxMkwzNSAzNUwwIDM1TDAgMTJaTTE2LjUgMTJMOSAxMkw5IDguMjUzNjFMMTYuNSAzLjExMDc1TDE2LjUgMTJaTTE4LjUgMTJMMjYgMTJMMjYgOC4yNTM2MUwxOC41IDMuMTEwNzVMMTguNSAxMlpNMy41MzY1MiAxMkw3IDEyTDcgOS42MjUwNEwzLjUzNjUyIDEyWk0zMS40NjM1IDEyTDI4IDEyTDI4IDkuNjI1MDRMMzEuNDYzNSAxMlpNNyAxNEwyIDE0TDIgMjVMNyAyNUw3IDE0Wk05IDMzTDkgMTRMMTYuNSAxNEwxNi41IDMzTDkgMzNaTTE4LjUgMTRMMTguNSAzM0wyNiAzM0wyNiAxNEwxOC41IDE0Wk0yOCAyNUwyOCAxNEwzMyAxNEwzMyAyNUwyOCAyNVoiIGlkPSJwYXRoXzEiIC8+CiAgICA8cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIGlkPSJhcnRib2FyZF8xIiAvPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwXzEiPgogICAgICA8dXNlIHhsaW5rOmhyZWY9IiNhcnRib2FyZF8xIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIC8+CiAgICA8L2NsaXBQYXRoPgogICAgPGNsaXBQYXRoIGlkPSJtYXNrXzEiPgogICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoXzEiIC8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBpZD0iaWNvbi9yb29tL2NvbnNlcnZhdG9yeSIgY2xpcC1wYXRoPSJ1cmwoI2NsaXBfMSkiPgogICAgPHBhdGggZD0iTTAgMTJMMTcuNSAwTDM1IDEyTDM1IDM1TDAgMzVMMCAxMlpNMTYuNSAxMkw5IDEyTDkgOC4yNTM2MUwxNi41IDMuMTEwNzVMMTYuNSAxMlpNMTguNSAxMkwyNiAxMkwyNiA4LjI1MzYxTDE4LjUgMy4xMTA3NUwxOC41IDEyWk0zLjUzNjUyIDEyTDcgMTJMNyA5LjYyNTA0TDMuNTM2NTIgMTJaTTMxLjQ2MzUgMTJMMjggMTJMMjggOS42MjUwNEwzMS40NjM1IDEyWk03IDE0TDIgMTRMMiAyNUw3IDI1TDcgMTRaTTkgMzNMOSAxNEwxNi41IDE0TDE2LjUgMzNMOSAzM1pNMTguNSAxNEwxOC41IDMzTDI2IDMzTDI2IDE0TDE4LjUgMTRaTTI4IDI1TDI4IDE0TDMzIDE0TDMzIDI1TDI4IDI1WiIgaWQ9Imljb24iIGZpbGw9IiM0QUEwQTgiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSJub25lIiAvPgogIDwvZz4KPC9zdmc+)
 */
export default function ConservatoryIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 35 35'}
      version={'1.1'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <defs>
        <path
          d={
            'M0 12L17.5 0L35 12L35 35L0 35L0 12ZM16.5 12L9 12L9 8.25361L16.5 3.11075L16.5 12ZM18.5 12L26 12L26 8.25361L18.5 3.11075L18.5 12ZM3.53652 12L7 12L7 9.62504L3.53652 12ZM31.4635 12L28 12L28 9.62504L31.4635 12ZM7 14L2 14L2 25L7 25L7 14ZM9 33L9 14L16.5 14L16.5 33L9 33ZM18.5 14L18.5 33L26 33L26 14L18.5 14ZM28 25L28 14L33 14L33 25L28 25Z'
          }
          id={'path_1'}
        />
        <rect width={'35'} height={'35'} id={'artboard_1'} />
        <clipPath id={'clip_1'}>
          <use xlinkHref={'#artboard_1'} clipRule={'evenodd'} />
        </clipPath>
        <clipPath id={'mask_1'}>
          <use xlinkHref={'#path_1'} />
        </clipPath>
      </defs>
      <g id={'icon/room/conservatory'} clip-path={'url(#clip_1)'}>
        <path
          d={
            'M0 12L17.5 0L35 12L35 35L0 35L0 12ZM16.5 12L9 12L9 8.25361L16.5 3.11075L16.5 12ZM18.5 12L26 12L26 8.25361L18.5 3.11075L18.5 12ZM3.53652 12L7 12L7 9.62504L3.53652 12ZM31.4635 12L28 12L28 9.62504L31.4635 12ZM7 14L2 14L2 25L7 25L7 14ZM9 33L9 14L16.5 14L16.5 33L9 33ZM18.5 14L18.5 33L26 33L26 14L18.5 14ZM28 25L28 14L33 14L33 25L28 25Z'
          }
          id={'icon'}
          fill={'#0F889B'}
          fillRule={'evenodd'}
          stroke={'none'}
        />
      </g>
    </svg>
  )
}
