import { Amount, Optional, PeriodWithNumber } from "../common";
import {
  IsAmount,
  IsEnum,
  IsLongString,
  IsOptional,
  IsString,
  NotEncrypted,
  ValidateNestedWithType,
} from "../../decorators";

export enum RentalAgreementType {
  FixedTerm = "Fixed Term",
  Periodic = "Periodic",
}

export const rentalAgreementTypeValues = Object.values(RentalAgreementType);

export class RentalDetail {
  @NotEncrypted
  @IsEnum(RentalAgreementType, { message: "Invalid rental agreement type" })
  agreementType!: RentalAgreementType;

  @NotEncrypted
  @IsAmount({ min: 0, message: "Monthly rental cannot be negative" })
  monthlyRental!: Amount;

  @NotEncrypted
  @IsAmount({ min: 0, message: "Security deposit cannot be negative" })
  securityDeposit!: Amount;

  @NotEncrypted
  @ValidateNestedWithType(PeriodWithNumber)
  term!: PeriodWithNumber;

  @NotEncrypted
  @IsOptional()
  @IsString()
  landlordId?: string; // from addr_book

  @NotEncrypted
  @IsString()
  @IsOptional()
  otherFees?: string;

  @IsLongString()
  @IsOptional()
  notes?: string;
}
export namespace RentalDetail {
  export function optionalEqual(
    a: Optional<RentalDetail>,
    b: Optional<RentalDetail>
  ) {
    if (a && b) {
      return (
        a.agreementType === b.agreementType &&
        Amount.equal(a.monthlyRental, b.monthlyRental) &&
        Amount.equal(a.securityDeposit, b.securityDeposit) &&
        a.term.num === b.term.num &&
        a.term.period === b.term.period &&
        a.landlordId === b.landlordId &&
        a.otherFees === b.otherFees &&
        a.notes === b.notes
      );
    } else {
      return a === b;
    }
  }
}
