import getSymbolFromCurrency from 'currency-symbol-map'
import { endOfDay, getYear, startOfDay } from 'date-fns'
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { sanitize } from 'isomorphic-dompurify'

import { Amount, Currency, DateFormat, NumberFormat } from 'core/remodel/types/common'
import { Contact } from 'core/remodel/types/contact'
import i18n from '@/utils/i18n'

export function getNestedValue(object: Record<string, any>, field: string): any {
  return field.split('.').reduce((acc, val) => (acc && acc[val] ? acc[val] : undefined), object)
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes: number | string, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(+bytes) / Math.log(k))

  return `${parseFloat((+bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function round10(value: number, digits: number = 2): number {
  try {
    const exp = Math.pow(10, digits)
    return Math.round(value * exp) / exp
  } catch (error: any) {
    return NaN
  }
}

/**
 * 1. `#,###.#` -> `{ digitGroupSeparator: ",", decimalCharacter: "." }`
 * 2. `#.###,#` -> `{ digitGroupSeparator: ".", decimalCharacter: "," }`
 * 3. `# ###.#` -> `{ digitGroupSeparator: " ", decimalCharacter: "." }`
 * 4. `#'###.#` -> `{ digitGroupSeparator: "'", decimalCharacter: "." }`
 */
export function resolveNumberFormat(format: NumberFormat = NumberFormat.Comma) {
  const matched = format.match(/[,.\s']/g)
  return { digitGroupSeparator: matched?.[0], decimalCharacter: matched?.[1] }
}

export interface NumberFormatConfig {
  digits?: number
  isNegativeSign?: boolean
  isShortening?: boolean
}

export function formatNumber(
  number: number | undefined,
  format: NumberFormat | undefined,
  config?: NumberFormatConfig
) {
  try {
    if (number === undefined) throw new Error('Invalid number')
    if (isNaN(number)) throw new Error('Invalid number')
    const { digitGroupSeparator = ',', decimalCharacter = '.' } = resolveNumberFormat(format)
    // https://wesbos.com/destructuring-default-values
    const { isNegativeSign = false, digits = 2, isShortening = true } = config ?? {}
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
    const intlConfig = { minimumFractionDigits: digits, maximumFractionDigits: digits }
    const formatter = new Intl.NumberFormat('en', intlConfig)
    // https://stackoverflow.com/a/44475397
    const charReplaceMap: Record<string, string> = { ',': digitGroupSeparator, '.': decimalCharacter }
    const isNegative = number < 0
    const absNumber = Math.abs(number)
    const shorteningPair = [
      { value: 1e12, symbol: 'TN' },
      { value: 1e9, symbol: 'BN' }
    ]
    const matchedConfig = shorteningPair.find((item) => absNumber >= item.value)
    const formatted =
      matchedConfig && isShortening
        ? `${round10(absNumber / matchedConfig.value, 2)} ${matchedConfig.symbol}`
        : formatter.format(absNumber).replace(/[,.]/g, (char) => charReplaceMap[char])

    return isNegative ? (isNegativeSign ? `-${formatted}` : `(${formatted})`) : formatted
  } catch {
    return '-'
  }
}

export function formatSymbol(number: number, currency: Currency | '%', digits = 0) {
  if (currency === '%') {
    const formatter = new Intl.NumberFormat(navigator.language || 'en-US', {
      style: 'percent',
      maximumFractionDigits: digits
    })
    return formatter.format(number / 100)
  }
  const absNumber = Math.abs(number)
  const shorteningPair = [
    { value: 1e12, symbol: 'TN' },
    { value: 1e9, symbol: 'BN' }
  ]
  const matchedConfig = shorteningPair.find((item) => absNumber >= item.value)
  const isNegative = number < 0
  const locale = navigator.language || 'en-US'
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: matchedConfig ? 2 : digits
  })

  const formatParts = (num: number) => {
    const parts = formatter.formatToParts(num)
    const currencyIndex = parts.findIndex((part) => part.type === 'currency')
    const numericParts = parts
      .filter((part) => ['integer', 'decimal', 'fraction', 'group'].includes(part.type))
      .map((part) => part.value)
      .join('')
    return { numericParts, isCurrencyPrefix: currencyIndex === 0 }
  }
  if (matchedConfig) {
    const scaledNumber = absNumber / matchedConfig.value
    const { numericParts, isCurrencyPrefix } = formatParts(scaledNumber)
    const numberWithSuffix = `${numericParts} ${matchedConfig.symbol}`
    const formattedNumber = isNegative ? `(${numberWithSuffix})` : numberWithSuffix
    const currencySymbol = getSymbolFromCurrency(currency)
    return isCurrencyPrefix ? `${currencySymbol} ${formattedNumber}` : `${formattedNumber} ${currencySymbol}`
  }
  const { numericParts, isCurrencyPrefix } = formatParts(absNumber)
  const formattedNumber = isNegative ? `(${numericParts})` : numericParts
  const currencySymbol = getSymbolFromCurrency(currency)
  return isCurrencyPrefix ? `${currencySymbol} ${formattedNumber}` : `${formattedNumber} ${currencySymbol}`
}

export function formatNumberShort(number: number) {
  try {
    const formatter = new Intl.NumberFormat('en', {
      notation: 'compact',
      compactDisplay: 'short',
      maximumFractionDigits: number > 1e9 ? 2 : 0
    })
    const isNegative = number < 0
    const formatted = formatter.format(Math.abs(number))
    return isNegative ? `(${formatted})` : formatted
  } catch {
    return '-'
  }
}

export const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export function formatDate(
  date: number | Date | undefined,
  token: string | DateFormat | undefined = DateFormat.MMDDYYYY,
  timeZone: string = defaultTimeZone
) {
  try {
    if (date === undefined) throw new Error('Invalid date')
    if (isNaN(new Date(date).getTime())) throw new Error('Invalid date')
    const isSameYear = getYear(new Date(date)) === getYear(new Date())
    const hasMonthAbbr = token.includes('MMM')
    const handleYearToken = isSameYear && hasMonthAbbr ? token.replace(/yyyy, |, yyyy/, '') : token
    return formatInTimeZone(date, timeZone, handleYearToken)
  } catch {
    return '-'
  }
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function getFullName(contact: Contact) {
  const { firstName, lastName } = contact
  return `${firstName ?? ''} ${lastName ?? ''}`.trim()
}

export function getAddress(contact: Contact) {
  const { addressLine1, addressLine2, town, state, zipCode, country, address: googleAddress } = contact
  const contactAddress = [addressLine1, addressLine2, town, state, zipCode, country].filter(Boolean).join(', ')
  return googleAddress || contactAddress || '-'
}

export function formatLink(link?: string): string {
  if (!link) return '-'
  const website = /^http:\/\/|https:\/\//.test(link) ? link : `https://${link}`
  return sanitize(website)
}

export function makeOptions(values: string[], parse: (key: string) => string) {
  return values.map((value) => ({ label: i18n?.t(parse(value), { defaultValue: value }) ?? value, value }))
}

export type SortAlphabeticallyOptions<T> = {
  data: T[]
  selector: (data: T) => string
  locale: string
}

export function sortAlphabetically<T>({ data, selector, locale }: SortAlphabeticallyOptions<T>) {
  return data.sort((a, b) => {
    const strA = selector(a)
    const strB = selector(b)
    return strA.localeCompare(strB, locale, { sensitivity: 'base' })
  })
}

export function startOfDayInTz(date: Date, timeZone: string) {
  const zonedTime = utcToZonedTime(date, timeZone)
  const startOfDayInTimezone = startOfDay(zonedTime)
  return zonedTimeToUtc(startOfDayInTimezone, timeZone)
}

export function endOfDayInTz(date: Date, timeZone: string) {
  const zonedTime = utcToZonedTime(date, timeZone)
  const endOfDayInTimezone = endOfDay(zonedTime)
  return zonedTimeToUtc(endOfDayInTimezone, timeZone)
}
