import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%20-104%20512%20512%22%3E%3Cpath%20d%3D%22M365%203.016c44.39%2010.3%2061.371%2036.257%2082.66%2071.984H365zM105.578%2078c8.692%200%2015%204.195%2015%2014%200%208.27-6.691%2014.977-14.957%2015H15c-8.285%200-15%206.719-15%2015%200%208.285%206.715%2015%2015%2015h135c8.363%200%2015.059%206.71%2015.059%2015%200%208.285-6.715%2015-15%2015H15c-8.285%200-15%206.715-15%2015s6.715%2015%2015%2015h33v45c0%208.285%206.715%2015%2015%2015h30.152c5.375%2026.477%2028.77%2046%2056.348%2046s50.973-19.523%2056.348-46h152.304c5.375%2026.477%2028.77%2046%2056.348%2046s50.973-19.523%2056.348-46H497c8.285%200%2015-6.715%2015-15v-90c0-44.012-46.422-46.934-46.465-47H350c-8.285%200-15-6.715-15-15V0H63c-8.285%200-15%206.715-15%2015v33H30c-8.285%200-15%206.715-15%2015s6.715%2015%2015%2015zm328.367%20148.055c10.739%2010.738%2010.739%2028.156%200%2038.894C416.672%20282.223%20387%20269.934%20387%20245.5c0-24.43%2029.672-36.719%2046.945-19.445zm-265%200c10.739%2010.738%2010.739%2028.156%200%2038.894C151.672%20282.223%20122%20269.934%20122%20245.5c0-24.43%2029.672-36.719%2046.945-19.445zm0%200%22%2F%3E%3C%2Fsvg%3E)
 */
export default function DeliveringIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 -104 512 512'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M365 3.016c44.39 10.3 61.371 36.257 82.66 71.984H365zM105.578 78c8.692 0 15 4.195 15 14 0 8.27-6.691 14.977-14.957 15H15c-8.285 0-15 6.719-15 15 0 8.285 6.715 15 15 15h135c8.363 0 15.059 6.71 15.059 15 0 8.285-6.715 15-15 15H15c-8.285 0-15 6.715-15 15s6.715 15 15 15h33v45c0 8.285 6.715 15 15 15h30.152c5.375 26.477 28.77 46 56.348 46s50.973-19.523 56.348-46h152.304c5.375 26.477 28.77 46 56.348 46s50.973-19.523 56.348-46H497c8.285 0 15-6.715 15-15v-90c0-44.012-46.422-46.934-46.465-47H350c-8.285 0-15-6.715-15-15V0H63c-8.285 0-15 6.715-15 15v33H30c-8.285 0-15 6.715-15 15s6.715 15 15 15zm328.367 148.055c10.739 10.738 10.739 28.156 0 38.894C416.672 282.223 387 269.934 387 245.5c0-24.43 29.672-36.719 46.945-19.445zm-265 0c10.739 10.738 10.739 28.156 0 38.894C151.672 282.223 122 269.934 122 245.5c0-24.43 29.672-36.719 46.945-19.445zm0 0'
        }
      />
    </svg>
  )
}
