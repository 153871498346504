import React, { ElementType, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { cn } from '@/utils/classnames'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/base/Tooltip'

type AllowedElementType = 'p' | 'div' | 'span' | 'label'

type ElementTypeMap = {
  p: HTMLParagraphElement
  div: HTMLDivElement
  span: HTMLSpanElement
  label: HTMLLabelElement
}

interface TruncatedTextProps<T extends AllowedElementType = 'p'> {
  as?: T
  children: React.ReactNode
  className?: string
  tip?: string
}

const TooltipDisplay = ({ content }: { content: React.ReactNode }) => (
  <TooltipContent
    className={'pointer-events-none w-fit overflow-hidden rounded border border-white bg-background-main p-2'}
  >
    <span className={'whitespace-nowrap text-white'}>{content}</span>
  </TooltipContent>
)

const TruncatedText = <T extends AllowedElementType = 'p'>({ as, children, className, tip }: TruncatedTextProps<T>) => {
  const Component = (as || 'p') as ElementType
  const textRef = useRef<ElementTypeMap[T]>(null)
  const [isTruncated, setIsTruncated] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!textRef.current) return

    const observer = new ResizeObserver(() => {
      const element = textRef.current
      if (element) {
        const { scrollWidth, clientWidth } = element
        setIsTruncated(scrollWidth > clientWidth)
      }
    })

    observer.observe(textRef.current)
    return () => observer.disconnect()
  }, [])

  const tooltipContent = React.useMemo(() => {
    if (!(tip || (isTruncated && isOpen))) return null
    return createPortal(<TooltipDisplay content={tip || children} />, document.body)
  }, [isTruncated, isOpen, tip, children])

  return (
    <Tooltip open={isOpen} onOpenChange={setIsOpen}>
      <TooltipTrigger asChild>
        <Component
          ref={textRef as React.Ref<ElementTypeMap[T]>}
          className={cn('truncate', isTruncated && 'cursor-default', className)}
        >
          {children}
        </Component>
      </TooltipTrigger>
      {tooltipContent}
    </Tooltip>
  )
}

export default TruncatedText
