import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20%20width%3D%2224%22%20%20height%3D%2224%22%20%20viewBox%3D%220%200%2060%2060%22%20%20fill%3D%22%23ddd%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20%20%20%20d%3D%22M19.0387%2055C17.8879%2055%2016.9271%2054.6146%2016.1562%2053.8438C15.3854%2053.0729%2015%2052.1121%2015%2050.9613V9.03875C15%207.88792%2015.3854%206.92708%2016.1562%206.15625C16.9271%205.38542%2017.8879%205%2019.0387%205H40.9613C42.1121%205%2043.0729%205.38542%2043.8438%206.15625C44.6146%206.92708%2045%207.88792%2045%209.03875V50.9613C45%2052.1121%2044.6146%2053.0729%2043.8438%2053.8438C43.0729%2054.6146%2042.1121%2055%2040.9613%2055H19.0387ZM17.5%2048.75V50.9613C17.5%2051.3462%2017.6602%2051.699%2017.9806%2052.0194C18.301%2052.3398%2018.6537%2052.5%2019.0387%2052.5H40.9613C41.3463%2052.5%2041.699%2052.3398%2042.0194%2052.0194C42.3398%2051.699%2042.5%2051.3462%2042.5%2050.9613V48.75H17.5ZM17.5%2046.25H42.5V13.75H17.5V46.25ZM17.5%2011.25H42.5V9.03875C42.5%208.65375%2042.3398%208.30104%2042.0194%207.98063C41.699%207.66021%2041.3463%207.5%2040.9613%207.5H19.0387C18.6537%207.5%2018.301%207.66021%2017.9806%207.98063C17.6602%208.30104%2017.5%208.65375%2017.5%209.03875V11.25Z%22%20%20%2F%3E%3C%2Fsvg%3E)
 */
export default function PhoneIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 60 60'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M19.0387 55C17.8879 55 16.9271 54.6146 16.1562 53.8438C15.3854 53.0729 15 52.1121 15 50.9613V9.03875C15 7.88792 15.3854 6.92708 16.1562 6.15625C16.9271 5.38542 17.8879 5 19.0387 5H40.9613C42.1121 5 43.0729 5.38542 43.8438 6.15625C44.6146 6.92708 45 7.88792 45 9.03875V50.9613C45 52.1121 44.6146 53.0729 43.8438 53.8438C43.0729 54.6146 42.1121 55 40.9613 55H19.0387ZM17.5 48.75V50.9613C17.5 51.3462 17.6602 51.699 17.9806 52.0194C18.301 52.3398 18.6537 52.5 19.0387 52.5H40.9613C41.3463 52.5 41.699 52.3398 42.0194 52.0194C42.3398 51.699 42.5 51.3462 42.5 50.9613V48.75H17.5ZM17.5 46.25H42.5V13.75H17.5V46.25ZM17.5 11.25H42.5V9.03875C42.5 8.65375 42.3398 8.30104 42.0194 7.98063C41.699 7.66021 41.3463 7.5 40.9613 7.5H19.0387C18.6537 7.5 18.301 7.66021 17.9806 7.98063C17.6602 8.30104 17.5 8.65375 17.5 9.03875V11.25Z'
        }
      />
    </svg>
  )
}
