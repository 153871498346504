import {
  Account,
  AccountSource,
  AccountState,
  AccountStateWriter,
  AccountTransaction,
  Command,
  Event,
} from "../types/cashAndBanking";
import {
  AggregateRoot,
  Repo,
  Sequence,
  buildUpdateGroupCommand,
  getSeqDocPath,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import {
  Amount,
  AssetType,
  Attachment,
  Currency,
  Optional,
  TargetCurrencyExchangeRateDataMap,
  WithId,
} from "../types/common";
import { EncryptionFieldKey } from "../encryption/utils";
import {
  AllowedDecimalPlaces,
  UpdateObject,
  addDecimal,
  calculateOwnedValue,
  mulAmount,
  mulDecimal,
} from "../utils";
import {
  AssociatedLiabilityAsset,
  CashAndBankingSummary,
  CashAndBankingSummaryAggregate,
  SupportLiabilityType,
} from "../types/cashAndBankingSummary";
import { ExchangeRate } from "./exchangeRate";
import { FullRefs, Refs, getAssetsByIds } from "../refs";
import { GroupUpdater } from "./groups";
import { Institution } from "../types/cashAndBanking/institution";
import { DataPoisoned, InvalidInput } from "../types/error";
import { EncryptionManager } from "./encryption";
import { SavingAccount } from "../types/cashAndBanking/savingAccount";
import {
  RoleToAsset,
  RelationSearchKeyword,
  fromRelationsOfAsset,
  isRole,
  toKeywordWithId,
  AssetsOfProperty,
} from "../types/relations";
import { Cash, CASH_INSTITUTION } from "../types/cashAndBanking/cash";
import { DbSharedFields } from "../types/database";
import { SummaryManager } from "../types/summaryManager";
import Decimal from "decimal.js";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";
import { SummaryLoader } from "../types/summaryLoader";
import { Mortgage } from "../types/cashAndBanking/mortgage";
import { Loan } from "../types/cashAndBanking/loan";
import { CreditCard } from "../types/cashAndBanking/creditCard";
import { CurrentAccount } from "../types/cashAndBanking/currentAccount";
import { PlaidToken } from "../types/finance";
import { getPlaidInstitutionTokenPath } from "../refPaths";
import { createHash } from "crypto";

export class CashAndBankingRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }

  async getSyncedSummary(currency?: Currency) {
    const summary = (
      await this.summaryManager.get(AssetType.CashAndBanking).syncAndGetData()
    ).summary;
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return CashAndBankingSummary.toDisplay(summary, exRate);
  }

  async getSyncedAssociatedLiabilities() {
    const liabilityRelations = await CoreFirestore.getDocsFromCollection(
      this.refs.currentRefs.Relations,
      CoreFirestore.where(
        "keyword",
        "array-contains",
        RelationSearchKeyword.LiabilityAllocated
      )
    ).then(getQueriedData);
    const result: {
      [assetType in SupportLiabilityType]: {
        [assetId: string]: string[];
      };
    } = {
      [AssetType.Art]: {},
      [AssetType.Property]: {},
      [AssetType.WineAndSpirits]: {},
      [AssetType.Belonging]: {},
      [AssetType.OtherCollectables]: {},
      [AssetType.OtherInvestment]: {},
    };
    liabilityRelations.forEach((v) => {
      const { id: accountId, relatedTargets } = fromRelationsOfAsset(v);
      Object.values(relatedTargets).forEach((target) => {
        if (!isRole(target, RoleToAsset.AssociatedAsset)) return;
        if (!target.assetType) throw new DataPoisoned("AssetType is missing");
        if (!result[target.assetType][target.targetId])
          result[target.assetType][target.targetId] = [];
        result[target.assetType][target.targetId].push(accountId);
      });
    });
    return result;
  }

  async getAssetTypeLiabilities(
    assetTypes: SupportLiabilityType[],
    currency?: Currency
  ): Promise<{
    [assetType in SupportLiabilityType]?: Amount;
  }> {
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );

    const filteredLiabilityAssets =
      await getAssociatedLiabilityAssetsFromAccounts(
        this.refs.currentRefs
      ).then((liabilityAssets) =>
        liabilityAssets.filter((v) => assetTypes.includes(v.assetType))
      );
    const liabilityAssetsMap = await getLiabilityAssetsMap(
      this.refs.currentRefs,
      filteredLiabilityAssets
    );

    const result: {
      [assetType in SupportLiabilityType]?: Amount;
    } = {};
    const displayCurrency = exRate.targetCurrency;
    assetTypes.forEach((assetType) => {
      result[assetType] = {
        currency: displayCurrency,
        value: 0,
      };
    });

    Object.values(liabilityAssetsMap).forEach((liabilityAssets) => {
      liabilityAssets.forEach((liabilityAsset) => {
        if (!assetTypes.includes(liabilityAsset.assetType)) return;
        const currentAmount: Amount = {
          currency: displayCurrency,
          value: mulAmount(
            liabilityAsset.liability.value,
            exRate.rates[liabilityAsset.liability.currency].rate
          ),
        };
        result[liabilityAsset.assetType]!.value = new Decimal(
          currentAmount.value
        )
          .toDecimalPlaces(AllowedDecimalPlaces)
          .add(result[liabilityAsset.assetType]!.value)
          .toNumber();
      });
    });
    return result;
  }

  async getAssetLiabilities(
    assetTypes: SupportLiabilityType[],
    currency?: Currency
  ): Promise<{
    [assetType in SupportLiabilityType]?: {
      [assetId: string]: Amount;
    };
  }> {
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return getAssetLiabilities(this.refs.currentRefs, exRate, assetTypes);
  }

  async getAllInstitution(): Promise<Institution[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetCollectionRef<Institution>(
        AssetType.BankOrInstitution
      );
    const institutions = (
      await CoreFirestore.getDocsFromCollection(
        collectionRef,
        CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
      ).then(getQueriedData)
    ).map((v) => Institution.convertDate(v));
    return institutions.sort(
      (a, b) => b.updateAt.getTime() - a.updateAt.getTime()
    );
  }

  async getInstitutionsByIds(ids: string[]): Promise<Institution[]> {
    const result = await getAssetsByIds<Institution>(
      this.refs.currentRefs,
      AssetType.BankOrInstitution,
      ids
    );
    return result.map((v) => Institution.convertDate(v));
  }

  async getInstitution(name: string): Promise<Optional<Institution>> {
    return tryGetInstitution(this.refs.currentRefs, name).then((i) => {
      if (i) return Institution.convertDate(i);
      else return i;
    });
  }

  async getAccountById<T extends Account, U extends Account.Type>(
    id: string,
    subtype: U
  ): Promise<T> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      id
    );
    const account = await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => Account.decryptAndConvertDate(v, this.Encryption.current));
    if (account.subtype !== subtype) {
      throw new Error("subtype mismatch");
    }
    //#NOTE saving / current account does not compute value
    if (!account.value) account.value = <any>{};
    return account as T;
  }

  async getAccountsByIds(ids: string[]): Promise<Account[]> {
    const result = await getAssetsByIds<Account.Encrypted>(
      this.refs.currentRefs,
      AssetType.CashAndBanking,
      ids
    );
    return Promise.all(
      result.map((v) =>
        Account.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async hasDuplicatePlaidAccount(institution: string, mask: string | null, name: string, currency: string | null): Promise<boolean> {
    const collectionRef = this.refs.currentRefs.getAssetCollectionRef<Account.Encrypted>(AssetType.CashAndBanking)
    const queryId = getQueryIdHash(institution, mask, name, currency)

    const result = await CoreFirestore.getDocsFromCollection(collectionRef,
      CoreFirestore.where("extSourceQueryId", "==", queryId),
      CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId),
      CoreFirestore.where("extSource", "==", AccountSource.Plaid)
    ).then(getQueriedData)
    return result.length > 0
  }

  async getAllTransactions(accountId: string): Promise<AccountTransaction[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetTransactionCollectionRef<AccountTransaction.Encrypted>(
        AssetType.CashAndBanking,
        accountId
      );
    return Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.orderBy("date", "desc")
        ).then(getQueriedData)
      ).map((v) =>
        AccountTransaction.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async addAccount(req: Account.Create, plaidDocId?: string) {
    const newDocRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      req.id
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        newDocRef.id
      );

      const account = Account.fromCreateChecked(
        req,
        this.refs.currentRefs.userId
      );
      const institutionWithSuffix = plaidDocId
        ? `${account.institution}|${plaidDocId}`
        : account.institution;
      let maybeInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        institutionWithSuffix
      );

      const encrypted = await Account.encrypt(account, this.Encryption.current);
      Account.validateEncryptedObj(encrypted, true);
      if (!maybeInstitution) {
        const newId = CoreFirestore.genAssetId();
        maybeInstitution = Institution.newValue(
          newId,
          institutionWithSuffix,
          this.refs.currentRefs.userId,
          plaidDocId
        );
      }
      const state: AccountState = {
        account: <any>{},
        institution: { [account.institution]: maybeInstitution },
        transactions: {},
      };

      const ar = Account.newAggregateRoot(state);
      ar.handle(Command.createAsset(this.refs.selfRefs.userId, encrypted));
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateAccount(req: WithId<Account.Update>) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      req.id
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Account.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentUndecrypted);

      const currentData = await Account.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const currentInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");

      const state: AccountState = {
        account: currentUndecrypted,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        docRef.id
      );

      const {
        updates,
        metadata: { addedToGroup, removedFromGroup },
      } = Account.intoUpdate(currentData, req);
      Account.encryptedKeysArray;

      const maybeNewInstitutionName: Optional<string> = (<any>updates)
        .institution;
      if (maybeNewInstitutionName) {
        const maybeNewInstitution = await tryGetInstitution(
          this.refs.currentRefs,
          maybeNewInstitutionName
        );
        state.institution[maybeNewInstitutionName] =
          maybeNewInstitution ||
          Institution.newValue(
            CoreFirestore.genAssetId(),
            maybeNewInstitutionName,
            this.refs.currentRefs.userId
          );
      }

      const encryptedUpdate: UpdateObject<Account.UpdateEncrypted> =
        Account.removeEncryptedFields(updates);
      let shouldEncrypt = false;
      const encryptedFieldsInUpdate = Account.encryptedKeysArray.reduce(
        (obj, key) => {
          const encryptField = (<any>updates)[key];
          if (encryptField) {
            obj[key] = encryptField;
            shouldEncrypt = true;
          } else if (encryptField === null) {
            shouldEncrypt = true;
          } else if ((<any>currentData)[key]) {
            obj[key] = (<any>currentData)[key];
          }
          return obj;
        },
        {} as any
      ) as Account.EncryptedPart;

      if (shouldEncrypt) {
        const encrypted = await Account.encryptPartial(
          encryptedFieldsInUpdate,
          this.Encryption.current
        );
        encryptedUpdate[EncryptionFieldKey] = encrypted[EncryptionFieldKey];
      }
      if (updates.attachments && updates.attachments !== null) {
        encryptedUpdate.attachments = await Attachment.encryptArray(
          updates.attachments,
          this.Encryption.current
        );
      }

      Account.validateEncryptedObj(encryptedUpdate);
      const ar = Account.newAggregateRoot(state);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup
        )
      );
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteAccount(id: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      id
    );
    let currentInstitution: Institution | undefined;
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);

      let institutionWithSuffix = currentData.institution;
      switch (currentData.subtype) {
        case Account.Type.Cash:
          institutionWithSuffix = CASH_INSTITUTION;
          break;
        case Account.Type.CreditCardAccount:
          const creditAccount = currentData as CreditCard.Encrypted;
          institutionWithSuffix = creditAccount.extSourceId ? `${currentData.institution}|${creditAccount.extSourceId}` : currentData.institution;
          break;
        case Account.Type.CurrentAccount:
          const currentAccount = currentData as CurrentAccount.Encrypted;
          institutionWithSuffix = currentAccount.extSourceId ? `${currentData.institution}|${currentAccount.extSourceId}` : currentData.institution;
          break;
        case Account.Type.SavingAccount:
          const savingAccount = currentData as SavingAccount.Encrypted;
          institutionWithSuffix = savingAccount.extSourceId ? `${currentData.institution}|${savingAccount.extSourceId}` : currentData.institution;
          break;
        case Account.Type.LoanAccount:
          const loanAccount = currentData as Loan.Encrypted;
          institutionWithSuffix = loanAccount.extSourceId ? `${currentData.institution}|${loanAccount.extSourceId}` : currentData.institution;
          break;
        case Account.Type.MortgageAccount:
          const mortgageAccount = currentData as Mortgage.Encrypted;
          institutionWithSuffix = mortgageAccount.extSourceId ? `${currentData.institution}|${mortgageAccount.extSourceId}` : currentData.institution;
          break;
      }

      currentInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        institutionWithSuffix
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        docRef.id
      );
      const ar = Account.newAggregateRoot(state);
      ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });


    // If this institution is Plaid linked and has no account left, disconnect Plaid link.  
    if (currentInstitution && currentInstitution.plaidDocId && Object.keys(currentInstitution.accounts).length === 0) {
      // Delete institution      
      await CoreFirestore.deleteDoc(this.refs.currentRefs.getAssetDocRef<Institution>(AssetType.BankOrInstitution, currentInstitution.id))

      await this.markPlaidPendingDeletion(currentInstitution.plaidDocId!)
    }
  }

  async closeAccount(id: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);
      const currentInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        docRef.id
      );
      const ar = Account.newAggregateRoot(state);
      ar.handle(Command.closeAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async addTransaction(newAccountTx: AccountTransaction.Create) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      newAccountTx.accountId
    );
    const txDocRef =
      this.refs.currentRefs.getAssetTransactionDocRef<AccountTransaction.Encrypted>(
        AssetType.CashAndBanking,
        newAccountTx.accountId,
        newAccountTx.id
      );
    const newTx: AccountTransaction = {
      ...newAccountTx,
      ownerId: this.refs.currentRefs.userId,
      createAt: <any>CoreFirestore.serverTimestamp(),
    };

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(txDocRef).then(checkDuplicated);
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);
      const currentInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        docRef.id
      );

      const encrypted = await AccountTransaction.encrypt(
        newTx,
        this.Encryption.current
      );
      AccountTransaction.validate(encrypted, true);
      const ar = Account.newAggregateRoot(state);
      ar.handle(
        Command.addTransaction(
          this.refs.selfRefs.userId,
          encrypted.accountId,
          encrypted.id,
          encrypted
        )
      );
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateTransaction(
    accountId: string,
    req: WithId<AccountTransaction.Update>
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      accountId
    );
    const txDocRef =
      this.refs.currentRefs.getAssetTransactionDocRef<AccountTransaction.Encrypted>(
        AssetType.CashAndBanking,
        accountId,
        req.id
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);
      const undecryptedTx = await transaction
        .get(txDocRef)
        .then(checkAndGetData);
      const currentTx = await AccountTransaction.decryptAndConvertDate(
        undecryptedTx,
        this.Encryption.current
      );

      const currentInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {
          [req.id]: undecryptedTx,
        },
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        docRef.id
      );

      const { notes, ...updates } = AccountTransaction.intoUpdate(
        currentTx,
        req
      );
      const encryptedUpdate: UpdateObject<Account.UpdateEncrypted> = <any>(
        updates
      );
      if (notes) {
        const encrypted = await AccountTransaction.encryptPartial(
          { notes },
          this.Encryption.current
        );
        encryptedUpdate[EncryptionFieldKey] = encrypted[EncryptionFieldKey];
      }
      AccountTransaction.validate(encryptedUpdate);

      const ar = Account.newAggregateRoot(state);
      ar.handle(
        Command.updateTransaction(
          this.refs.selfRefs.userId,
          currentTx.accountId,
          currentTx.id,
          encryptedUpdate
        )
      );
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateBalance(
    accountId: string,
    newBalances: AccountTransaction.UpdateBalance[]
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      accountId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);
      let isMultiCurrency = false;
      if (newBalances.length == 0) {
        throw new InvalidInput("newBalances is empty");
      } else if (
        (<SavingAccount.Encrypted>currentData).accountType !==
        Account.AccountType.MultiCurrency
      ) {
        if (newBalances.length > 1)
          throw new InvalidInput(
            "newBalances has more than 1 item in non multi-currency account"
          );
        else if (newBalances[0].subAccountId)
          delete newBalances[0].subAccountId;
      } else {
        newBalances.forEach((newBalance) => {
          if (!newBalance.subAccountId)
            throw new InvalidInput(
              "subAccountId should not be empty in multi-currency account"
            );
        });
        isMultiCurrency = true;
      }

      const currentInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        docRef.id
      );

      const ar = Account.newAggregateRoot(state);

      for (const newBalance of newBalances) {
        let currentValue =
          currentData.value ||
          (<SavingAccount.Encrypted>currentData).subAccounts[0].balance;
        if (isMultiCurrency) {
          const subAccount = (<SavingAccount.Encrypted>(
            currentData
          )).subAccounts.find((v) => v.id === newBalance.subAccountId);
          if (!subAccount) throw new DataPoisoned("subAccount not found");
          currentValue = subAccount.balance;
        }

        if (mulDecimal(newBalance.value, currentValue.value) < 0)
          throw new InvalidInput(
            "The sign of newBalance value and (sub)account value should be the same"
          );
        if (newBalance.value === currentValue.value) return;
        const newTx = AccountTransaction.updateBalanceCreation(
          currentData.subtype,
          currentValue,
          accountId,
          this.refs.currentRefs.userId,
          newBalance
        );

        const encryptedTx = await AccountTransaction.encrypt(
          newTx,
          this.Encryption.current
        );
        AccountTransaction.validate(encryptedTx);
        ar.handle(
          Command.addTransaction(
            this.refs.selfRefs.userId,
            encryptedTx.accountId,
            encryptedTx.id,
            encryptedTx
          )
        );
        const events = ar.applyAllChanges();
        await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
        //commit
        repo.manualCommit(ar, events);
      }
    });
  }

  async deleteTransaction(accountId: string, txId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      accountId
    );
    const txDocRef =
      this.refs.currentRefs.getAssetTransactionDocRef<AccountTransaction.Encrypted>(
        AssetType.CashAndBanking,
        accountId,
        txId
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);
      const undecryptedTx = await transaction
        .get(txDocRef)
        .then(checkAndGetData);
      const currentTx = await AccountTransaction.decryptAndConvertDate(
        undecryptedTx,
        this.Encryption.current
      );

      const currentInstitution = await tryGetInstitution(
        this.refs.currentRefs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {
          [txId]: currentTx,
        },
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        docRef.id
      );

      const ar = Account.newAggregateRoot(state);
      ar.handle(
        Command.deleteTransaction(
          this.refs.selfRefs.userId,
          currentTx.accountId,
          currentTx.id
        )
      );
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs.currentRefs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  // Perhaps there is a better location for this?
  // Should this be done in cloud function instead?
  async markPlaidPendingDeletion(docId: string) {
    const uid = this.refs.currentRefs.userId;
    if (!uid) throw new Error("User id not found");

    const path = getPlaidInstitutionTokenPath(uid, docId);
    const ref = CoreFirestore.doc<PlaidToken>(path);
    const doc = await CoreFirestore.getDoc(ref).then(checkAndGetData);
    CoreFirestore.updateDoc(ref, {
      status: { ...doc.status, isConnected: false, pendingDeletion: true },
    });
  }
}

export namespace CashAndBankingRepo {
  export async function removeGroup(
    refs: FullRefs,
    id: string,
    groupId: string
  ) {
    const docRef = refs.currentRefs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      id
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Account.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentUndecrypted);

      const currentInstitution = await tryGetInstitution(
        refs.currentRefs,
        currentUndecrypted.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");

      const state: AccountState = {
        account: currentUndecrypted,
        institution: { [currentUndecrypted.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await newRepo(refs.currentRefs, transaction, docRef.id);
      const ar = Account.newAggregateRoot(state);
      const currentGroupIds = ar.state().account.groupIds;
      const arToCommit = buildUpdateGroupCommand(
        ar,
        Command.updateAsset,
        refs.selfRefs.userId,
        currentGroupIds,
        groupId,
        false
      );

      const groupUpdater = new GroupUpdater(refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      //commit
      if (arToCommit) {
        const events = arToCommit.applyAllChanges();
        repo.manualCommit(arToCommit, events);
      }

      // delete from group
      groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<Cash.Encrypted>(
      AssetType.CashAndBanking,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Account.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentUndecrypted);

      const currentInstitution = await tryGetInstitution(
        refs,
        currentUndecrypted.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");

      const state: AccountState = {
        account: currentUndecrypted,
        institution: { [currentUndecrypted.institution]: currentInstitution },
        transactions: {},
      };
      const encryptedUpdate: UpdateObject<Cash.Encrypted> = {};
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Shareholder: {
            if (!currentUndecrypted.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder =
              currentUndecrypted.ownership.shareholder.filter(
                (s) => s.contactId !== contactId
              );
            if (
              updateShareholder.length ===
              currentUndecrypted.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            encryptedUpdate.ownership = {
              myOwnership: currentUndecrypted.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!currentUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = currentUndecrypted.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateBeneficiary.length === currentUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            encryptedUpdate.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const repo = await newRepo(refs, transaction, id);
      const ar = Account.newAggregateRoot(state);
      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  //#NOTE the id decides the transaction collection to write
  export async function newRepo(
    refs: Refs,
    transaction: Transaction,
    accountId?: string
  ): Promise<Repo<AccountState<Account.Encrypted>, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.CashAndBanking,
      new AccountStateWriter(
        refs.getAssetCollectionRef(AssetType.CashAndBanking),
        accountId
          ? refs.getAssetTransactionCollectionRef(
            AssetType.CashAndBanking,
            accountId
          )
          : undefined,
        refs.getAssetCollectionRef(AssetType.BankOrInstitution),
        refs.Relations
      )
    );
  }

  export async function newArAndUpdateGroup(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string,
    groupId: string,
    isAdd: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Cash.Encrypted>(
      AssetType.CashAndBanking,
      assetId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = Account.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) Account.handleOutDated(currentData);
    const currentInstitution = await tryGetInstitution(
      refs,
      currentData.institution
    );
    if (!currentInstitution) throw new DataPoisoned("Institution not found");
    const state: AccountState = {
      account: currentData,
      institution: { [currentData.institution]: currentInstitution },
      transactions: {},
    };
    const ar = Account.newAggregateRoot(state);
    const currentGroupIds = ar.state().account.groupIds;
    return buildUpdateGroupCommand(
      ar,
      Command.updateAsset,
      executerId,
      currentGroupIds,
      groupId,
      isAdd
    );
  }

  export async function newArAndUpdateAllocatedLiability(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string
  ) {
    const liabilityRelation = await CoreFirestore.getDocsFromCollection(
      refs.Relations,
      CoreFirestore.where(
        "keyword",
        "array-contains",
        toKeywordWithId(RelationSearchKeyword.LiabilityAllocated, assetId)
      )
    ).then(getQueriedData);

    const aggregates = [];
    for (const relation of liabilityRelation) {
      if (!isRole(relation[assetId], RoleToAsset.AssociatedAsset)) continue;
      const docRef = refs.getAssetDocRef<Loan.Encrypted | Mortgage.Encrypted>(
        AssetType.CashAndBanking,
        relation.id
      );
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);
      const currentInstitution = await tryGetInstitution(
        refs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };
      const ar = Account.newAggregateRoot(state);
      const currentUndecrypted = ar.state().account;

      const encryptedUpdate: UpdateObject<Loan.Encrypted | Mortgage.Encrypted> =
        {};
      if ((<Mortgage.Encrypted>currentUndecrypted).linkToPropertyId) {
        (<Mortgage.Encrypted>encryptedUpdate).linkToPropertyId = <any>null;
      } else if ((<Loan.Encrypted>currentUndecrypted).allocations) {
        // #HACK For wine: assetId is {userId}_{wineId} in relation, but only {wineId} in allocations
        let targetAssetId = assetId;
        if (relation[assetId].assetType === AssetType.WineAndSpirits) {
          targetAssetId = assetId.split("_")[1];
        }
        const updateAllocations = (<Loan.Encrypted>(
          currentUndecrypted
        )).allocations.filter((a) => a.assetId !== targetAssetId);
        if (
          updateAllocations.length ===
          (<Loan.Encrypted>currentUndecrypted).allocations.length
        )
          throw new DataPoisoned("assetId not found");
        (<Loan.Encrypted>encryptedUpdate).allocations = updateAllocations;
      }

      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      aggregates.push(ar);
    }

    return aggregates;
  }
}

export class CashAndBankingAdminRepo {
  protected readonly executerId: string;
  protected readonly refs: Refs;
  protected readonly summary: SummaryLoader<
    CashAndBankingSummary,
    CashAndBankingSummaryAggregate
  >;

  constructor(executerId: string, refs: Refs) {
    if (!CoreFirestore.isAdmin()) {
      throw new Error("This repo is for admin only");
    }
    this.executerId = executerId;
    this.refs = refs;
    this.summary = new SummaryLoader(
      refs,
      AssetType.CashAndBanking,
      true,
      CoreFirestore.doc(
        getSeqDocPath(refs.userId, AssetType.CashAndBanking)
      ) as DocumentReference<Sequence>,
      {
        ref: refs.CashAndBankingSummary,
        aggregateConstructor: CashAndBankingSummaryAggregate,
        newAggregateRoot: (transaction: Transaction) => {
          return CashAndBankingSummary.newAggregateRoot(
            transaction,
            refs.CashAndBankingSummary
          );
        },
      }
    );
  }

  async syncSummary() {
    return (await this.summary.syncAndGetData()).summary;
  }

  async updateBalance(
    plaidAccountId: string,
    newBalance: Amount,
    plaidDocId?: string
  ) {
    if (!CoreFirestore.isAdmin()) {
      throw new Error("This repo is for admin only");
    }
    const accountId = (await getPlaidAccountByExtId(this.refs, plaidAccountId))
      ?.id;
    const docRef = this.refs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      accountId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);

      const institutionWithSuffix = plaidDocId
        ? `${currentData.institution}|${plaidDocId}`
        : currentData.institution;
      const currentInstitution = await tryGetInstitution(
        this.refs,
        institutionWithSuffix
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs,
        transaction,
        docRef.id
      );

      const ar = Account.newAggregateRoot(state);

      const command: Command.OverwriteValue = {
        executerId: this.executerId,
        kind: Command.Kind.OverwriteValue,
        primaryValue: newBalance,
      };

      ar.handle(command);

      const events = ar.applyAllChanges();
      // commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteAccount(accountId: string) {
    const docRef = this.refs.getAssetDocRef<Account.Encrypted>(
      AssetType.CashAndBanking,
      accountId
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Account.assureVersion(currentData);
      if (result === TypeResult.DataOutDated)
        Account.handleOutDated(currentData);
      const currentInstitution = await tryGetInstitution(
        this.refs,
        currentData.institution
      );
      if (!currentInstitution) throw new DataPoisoned("Institution not found");
      const state: AccountState = {
        account: currentData,
        institution: { [currentData.institution]: currentInstitution },
        transactions: {},
      };

      const repo = await CashAndBankingRepo.newRepo(
        this.refs,
        transaction,
        docRef.id
      );
      const ar = Account.newAggregateRoot(state);
      ar.handle(Command.deleteAsset(this.executerId));
      const events = ar.applyAllChanges();
      await handleRelatedAggregates(this.refs, transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

export function getQueryIdHash(institution: string, mask: string | null, name: string, currency: string | null) {
  return createHash('md5').update(`${institution}|${name}|${mask}|${currency}`).digest('hex')
}

//#NOTE read before this function and set/update after this function
async function handleRelatedAggregates(
  refs: Refs,
  transaction: Transaction,
  ar: AggregateRoot<AccountState, Command, Event>
) {
  const { account } = ar.state();
  const data = ar.relatedUpdates() as Account.RelatedUpdates;
  let groupUpdater: Optional<GroupUpdater> = undefined;
  if (data.addedGroupIds || data.removedGroupIds) {
    groupUpdater = new GroupUpdater(
      refs,
      (data.addedGroupIds || []).concat(data.removedGroupIds || [])
    );
    await groupUpdater.read(transaction);
  }
  // * writes
  if (data.addedGroupIds && groupUpdater) {
    for (const groupId of data.addedGroupIds) {
      groupUpdater.addOneItemToGroup(
        transaction,
        groupId,
        account.id,
        AssetType.CashAndBanking,
        account.subtype
      );
    }
  }
  if (data.removedGroupIds && groupUpdater) {
    for (const groupId of data.removedGroupIds) {
      groupUpdater.deleteOneItemFromGroup(transaction, groupId, account.id);
    }
  }
}

async function getPlaidAccountByExtId(refs: Refs, plaidAccountId: string) {
  const assetCollection = refs.getAssetCollectionRef<Account.Encrypted>(
    AssetType.CashAndBanking
  );
  return CoreFirestore.getDocsFromCollection(
    assetCollection,
    CoreFirestore.where("extId", "==", plaidAccountId)
  ).then((snapshot) => (snapshot.empty ? undefined : snapshot.docs[0].data()));
}

async function tryGetInstitution(
  refs: Refs,
  name: string
): Promise<Optional<Institution>> {
  const Docs = await CoreFirestore.getDocsFromCollection(
    refs.getAssetCollectionRef<Institution>(AssetType.BankOrInstitution),
    CoreFirestore.where(
      "queryKey",
      "==",
      Institution.buildQueryKey(refs.userId, name)
    )
  );

  const result = Docs.docs.map((d) => d.data());
  if (result.length > 0) {
    const data = result[0];
    const checkResult = Institution.assureVersion(data);
    if (checkResult === TypeResult.DataOutDated) Institution.handleOutDated();
    return data;
  } else {
    return undefined;
  }
}

export async function getAssetLiabilities(
  refs: Refs,
  exRate: TargetCurrencyExchangeRateDataMap,
  assetTypes: SupportLiabilityType[],
  unsoldAssets?: AssetsOfProperty[]
) {
  const result: {
    [assetType in SupportLiabilityType]?: {
      [assetId: string]: Amount;
    };
  } = {};
  assetTypes.forEach((assetType) => (result[assetType] = {}));
  const displayCurrency = exRate.targetCurrency;

  const filteredLiabilityAssets =
    await getAssociatedLiabilityAssetsFromAccounts(refs).then((assets) =>
      assets.filter((asset) => assetTypes.includes(asset.assetType))
    );
  const liabilityAssetsMap = await getLiabilityAssetsMap(
    refs,
    filteredLiabilityAssets,
    { type: "keepAll" }
  );

  Object.values(liabilityAssetsMap).forEach((assets) => {
    assets.forEach((asset) => {
      if (
        !assetTypes.includes(asset.assetType) ||
        (unsoldAssets && !unsoldAssets.some((a) => a.assetId === asset.assetId))
      ) {
        return;
      }
      const currentValue = asset.liability.value;
      const toDisplayExRate = exRate.rates[asset.liability.currency].rate;
      const value = new Decimal(currentValue)
        .mul(toDisplayExRate)
        .toDecimalPlaces(AllowedDecimalPlaces)
        .toNumber();
      if (!result[asset.assetType]![asset.assetId])
        result[asset.assetType]![asset.assetId] = {
          currency: displayCurrency,
          value: 0,
        };
      result[asset.assetType]![asset.assetId].value = addDecimal(
        result[asset.assetType]![asset.assetId].value,
        value
      );
    });
  });
  return result;
}

export async function getAssociatedLiabilityAssetsFromAccounts(
  refs: Refs
): Promise<AssociatedLiabilityAsset[]> {
  const assetCollection = refs.getAssetCollectionRef<Account.Encrypted>(
    AssetType.CashAndBanking
  );
  const snapshot = await CoreFirestore.getDocsFromCollection(
    assetCollection,
    CoreFirestore.where("subtype", "in", [
      Account.Type.LoanAccount,
      Account.Type.MortgageAccount,
    ]),
    CoreFirestore.where("ownerId", "==", refs.userId)
  );
  return snapshot.docs
    .map((d) => {
      const account = d.data();
      switch (account.subtype) {
        case Account.Type.LoanAccount:
          return account.allocations.map((v) => {
            const liability = calculateOwnedValue(account.value, v.percent);
            liability.value = -liability.value;
            return {
              accountId: account.id,
              assetId: v.assetId,
              liability,
              assetType: v.assetType,
            };
          }) as AssociatedLiabilityAsset[];
        case Account.Type.MortgageAccount:
          if (account.linkToPropertyId) {
            const liability = { ...account.value };
            liability.value = -liability.value;
            return [
              {
                accountId: account.id,
                assetId: account.linkToPropertyId,
                liability,
                assetType: AssetType.Property,
              } as AssociatedLiabilityAsset,
            ];
          }
      }
      return [];
    })
    .flat();
}

export async function getLiabilityAssetsMap(
  refs: Refs,
  liabilityAssets: AssociatedLiabilityAsset[],
  processDataHint?: //default action is to remove sold, archived or closed assets by fetching relations
    | {
      type: "keepAll";
    }
    | {
      type: "keepAssetTypes";
      data: SupportLiabilityType[];
    }
) {
  const liabilityAssetsMap = liabilityAssets.reduce((acc, asset) => {
    if (!acc[asset.assetId]) acc[asset.assetId] = [];
    acc[asset.assetId].push(asset);
    return acc;
  }, {} as Record<string, AssociatedLiabilityAsset[]>);
  const assetIds: string[] = [];
  switch (processDataHint?.type) {
    case "keepAll":
      return liabilityAssetsMap;
    case "keepAssetTypes":
      const assetTypes = processDataHint.data;
      Object.values(liabilityAssetsMap).forEach((assets) => {
        if (assets.length == 0) return;
        if (assetTypes.includes(assets[0].assetType)) {
          if (assets[0].assetType != AssetType.WineAndSpirits)
            assetIds.push(assets[0].assetId);
        } else {
          delete liabilityAssetsMap[assets[0].assetId];
        }
      });
      break;
    default:
      //#NOTE find assets that need to be excluded from relations, they could be sold, archived or closed
      Object.values(liabilityAssetsMap)
        .filter(
          (assets) =>
            assets.length > 0 &&
            //#NOTE wine cannot be sold, don't need to check from relation
            assets[0].assetType !== AssetType.WineAndSpirits
        )
        .forEach((assets) => {
          assetIds.push(assets[0].assetId);
        });
  }

  if (assetIds.length > 0) {
    const relations = await CoreFirestore.getDocsByIdsPure(
      refs.Relations,
      //#NOTE wineId can not be used query relations and wine will not be sold, archived or closed, so filter out wine here
      assetIds,
      {
        ignoreNotFound: true,
      }
    );
    relations.forEach((relation) => {
      if (relation.closed) {
        delete liabilityAssetsMap[relation.id];
      }
    });
  }
  return liabilityAssetsMap;
}
