import {
  Amount,
  AssetType,
  AssetV2,
  Beneficiary,
  Deletable,
  MultiCurrencyAmount,
  Optional,
  Owner,
  Ownership,
  PathsOfAmountField,
  PathsOfDateField,
  Attachment,
  ChangeTypeOfKeys,
  Currency,
  SupportActivityType,
} from "./common";
import {
  EncryptedType,
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  RequireEncryptionFields,
  fullObjectDecryption,
  fullObjectEncryption,
  fullObjectEncryptionNotStrict,
  removeEncryptionFields,
  IVSaltFieldKey,
  IVSaltFieldDefaultValue,
  EncryptionField,
} from "../encryption/utils";
import {
  OmitKeys,
  UpdateObject,
  applyUpdateToObject,
  mulAmount,
  validateValueInEnum,
  AllowedDecimalPlaces,
  addDecimal,
  calculatePercentage,
  mulDecimal,
  subDecimal,
  validateStringNotEmpty,
  calculateOwnedValue,
  deepCopy,
} from "../utils";
import {
  AggregateRoot,
  Domain,
  IAggregate,
  IAggregateStateWriter,
  RepoAndAggregates,
  SupportActionAggregate,
  setObjectDeleted,
  stateIsDeleted,
} from "./aggregate";
import { ErrorDataOutDated, DataPoisoned, InvalidInput } from "./error";
import { RelationsOfAsset, buildWineRelation } from "./relations";
import {
  EventBase,
  SharedEvent,
  ActionEvent,
  EventWithTime,
  preSealEvent,
  TagPair,
  SummaryData,
} from "./event";
import { ActionCommand, CommandBase } from "./command";
import Decimal from "decimal.js";
import { ExchangeRate } from "../database/exchangeRate";
import { TastingNote } from "./actions/tastingNote";
import { Encrypted, Encryption } from "../database/encryption";
import {
  Activity,
  ActivityKind,
  EventToActivitiesFunction,
  activitySortKeyMap,
  validateActMapFuncKnowEventKind,
} from "./activities";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
  WithFieldValue,
} from "../../coreFirebase";
import {
  VersionedType,
  VersionedTypeString,
  WinePurchaseTypeVersion,
  WineTypeVersion,
  validateTypeUpToDate,
} from "./typeVersion";
import { addLocationDataToDetail, genActivityLogs } from "./activityUtils";
import { CollectableAcquisition } from "./common/acquisition";
import { LocationInfo } from "./relations/locationInfo";
import { SummaryTag } from "./summary";

export interface WineCatalogueMinInfo {
  wineId: string;
  name: string;
  subtype: WineType | "-";

  producer: string;
  vintage: number; // year

  catalogueImage?: string;

  country?: string;
  masterVarietal?: string;
  variety?: string;
  labeledVariety: string;

  drinkWindow?: string;
  proRating?: number;

  region?: string;
  subRegion?: string;
  appellation?: string;

  // NOTE managed by triggers in `functions`, do not modify this field in other places
  indexedAt?: Date;
  indexVersion?: number;
}
export type WineCatalogue = Omit<WineCatalogueMinInfo, "labeledVariety"> & {
  vineyard?: string;
  designation?: string;
};

export namespace WineCatalogueMinInfo {
  export function getVarietal<
    T extends Pick<WineCatalogueMinInfo, "variety" | "masterVarietal">
  >(wine: T): string {
    if (
      wine.masterVarietal &&
      wine.masterVarietal.length !== 0 &&
      wine.masterVarietal != "-"
    ) {
      return wine.masterVarietal;
    } else if (
      wine.variety &&
      wine.variety.length !== 0 &&
      wine.variety != "-"
    ) {
      return wine.variety;
    } else {
      return "-";
    }
  }
  export function getOrigin<T extends Pick<WineCatalogueMinInfo, "country">>(
    wine: T
  ): string {
    if (wine.country && wine.country.length !== 0 && wine.country != "-") {
      return wine.country;
    } else {
      return "-";
    }
  }

  export type Update = UpdateObject<WineCatalogueMinInfo>;

  //#TODO need check
  export function validate(data: Update, isCreate: boolean = false) {
    if (
      (isCreate || data.subtype) &&
      data.subtype! != "-" &&
      !validateValueInEnum(data.subtype!, WineType)
    ) {
      throw new InvalidInput("invalid subtype");
    }
    if ((isCreate || data.vintage) && data.vintage!.toString().length !== 4) {
      throw new InvalidInput("vintage must be 4 digits");
    }
    if ((isCreate || data.producer) && !validateStringNotEmpty(data.producer)) {
      throw new InvalidInput("brand is required");
    }
    //optional fields
  }

  export function equal(
    a: WineCatalogueMinInfo,
    b: WineCatalogueMinInfo
  ): boolean {
    return (
      a.name === b.name &&
      a.subtype === b.subtype &&
      a.producer === b.producer &&
      a.vintage === b.vintage &&
      a.catalogueImage === b.catalogueImage &&
      a.country === b.country &&
      a.masterVarietal === b.masterVarietal &&
      a.variety === b.variety &&
      a.region === b.region &&
      a.subRegion === b.subRegion &&
      a.appellation === b.appellation
    );
  }
}

type WineBase = OmitKeys<AssetV2 & WineCatalogueMinInfo, "value">;
export interface Wine extends WineBase {
  "@type": VersionedTypeString<VersionedType.Wine, 2>;
  assetType: AssetType.WineAndSpirits;
  purchases: WinePurchase.Min[];
  value: MultiCurrencyAmount;
  personalRefNo?: string;
  isDeleting?: boolean;
}
export namespace Wine {
  export function assureVersion(
    input: Wine | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(input, WineTypeVersion, errorOnCoreOutDated);
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Wine);
  }

  export const datePaths: readonly PathsOfDateField<Wine>[] = [
    "createAt",
    "updateAt",
  ] as const;
  export function convertDate(input: Wine.Encrypted): Wine.Encrypted {
    const result = { ...input };
    CoreFirestore.convertDateFieldsFromFirestore(result, datePaths);
    return result;
  }

  export type EncryptedKeys = Exclude<AssetV2.EncryptedKeys, "name">;
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Wine, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<Wine, EncryptedKeys>;
  export const encryptedKey: keyof EncryptedPart = "notes";

  export function checkAndBuildWineId(userId: string, id: string) {
    if (isNaN(Number(id))) {
      if (!id.startsWith(`${userId}_`))
        throw new InvalidInput("Invalid wineDocId");
      return id;
    } else return buildWineId(userId, id);
  }
  export function buildWineId(userId: string, wineId: string) {
    return `${userId}_${wineId}`;
  }
  export function tryDestructDocId(wineDocId: string): Optional<{
    userId: string;
    wineId: string;
  }> {
    const parts = wineDocId.split("_");
    if (parts.length != 2) {
      return undefined;
    }
    return {
      userId: parts[0],
      wineId: parts[1],
    };
  }

  export function calculateValuePurchaseFromNetWorth<
    T extends Pick<Wine, "purchases">
  >(wine: T): MultiCurrencyAmount {
    return wine.purchases.reduce((acc, purchase) => {
      if (acc[purchase.netWorth.currency]) {
        acc[purchase.netWorth.currency] = addDecimal(
          acc[purchase.netWorth.currency]!,
          purchase.netWorth.value
        );
      } else {
        acc[purchase.netWorth.currency] = purchase.netWorth.value;
      }
      return acc;
    }, {} as MultiCurrencyAmount);
  }

  export function calculateBaseValueFromBottleAndValue<
    T extends { purchases: U[] },
    U extends Pick<WinePurchase, "id" | "bottleCount" | "valuePerBottle">
  >(
    wine: T,
    exchangeRate: ExchangeRate
  ): {
    value: Amount;
    purchaseNetWorth: {
      [purchaseId: string]: Amount;
    };
  } {
    exchangeRate.checkInitialized();
    const currency = exchangeRate.BaseCurrency as Currency;
    let wineValue = new Decimal(0);
    const purchaseNetWorth = Object.fromEntries(
      wine.purchases.map((v) => {
        const netWorth = new Decimal(v.valuePerBottle.value)
          .mul(v.bottleCount.bottles)
          .mul(
            exchangeRate.getToBaseExchangeRate(v.valuePerBottle.currency).rate
          )
          .toDecimalPlaces(AllowedDecimalPlaces);
        wineValue = wineValue.add(netWorth);
        return [v.id, { currency, value: netWorth.toNumber() }];
      })
    );
    return {
      value: {
        currency,
        value: wineValue.toNumber(),
      },
      purchaseNetWorth,
    };
  }

  export async function encrypt(
    input: Wine,
    encryption: Encryption
  ): Promise<Encrypted> {
    const { attachments, ...rest } = (await fullObjectEncryptionNotStrict(
      input,
      [encryptedKey],
      encryption
    )) as OmitKeys<Encrypted, "attachments"> & {
      attachments?: Attachment[];
    };
    const result = rest as Encrypted;
    if (attachments) {
      result.attachments = await Attachment.encryptArray(
        attachments,
        encryption
      );
    }
    return result;
  }
  export async function decrypt(
    data: Encrypted,
    encryption: Encryption
  ): Promise<Wine> {
    const EncryptedPart: EncryptedPart = await encryption.decryptAndStringify(
      data[EncryptionFieldKey]["data"],
      encryption.convertBase64ToIVSalt(data[EncryptionFieldKey][IVSaltFieldKey])
    );
    const { attachments, ...rest } = data;
    const result: Wine = {
      ...removeEncryptionFields(rest),
      ...EncryptedPart,
      attachments: await Attachment.decryptArray(attachments, encryption),
    };
    return result;
  }

  export function defaultStateValue(
    ownerId: string,
    wineDocId: string
  ): Encrypted {
    return {
      "@type": WineTypeVersion,
      assetType: AssetType.WineAndSpirits,
      subtype: "-",
      id: wineDocId,
      wineId: "",
      ownerId,
      version: 0,
      name: "",
      createAt: <any>CoreFirestore.serverTimestamp(),
      updateAt: <any>CoreFirestore.serverTimestamp(),
      value: {},
      producer: "-",
      vintage: 0,
      labeledVariety: "-",
      purchases: [],
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue,
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
  }

  export type Update = Pick<Wine, "attachments" | "mainImage">;

  export function updateCatalogueInfo(
    wine: Encrypted,
    update: WineCatalogueMinInfo
  ) {
    if (WineCatalogueMinInfo.equal(wine, update)) return wine;
    return {
      ...wine,
      ...update,
      labeledVariety: WineCatalogueMinInfo.getVarietal(update),
    };
  }

  export interface RelatedAggregates {
    cashAndBanking?: RepoAndAggregates<any, any, any>;
  }
}

export interface BottleCount {
  pendings: number;
  bottles: number; // not Consumed, including pendings
  consumed: number;
}
export namespace BottleCount {
  export function equal(a: BottleCount, b: BottleCount): boolean {
    return (
      a.pendings === b.pendings &&
      a.bottles === b.bottles &&
      a.consumed === b.consumed
    );
  }
}

export interface WinePurchase {
  "@type": VersionedTypeString<VersionedType.WinePurchase, 2>;
  id: string;
  wineId: string;
  ownerId: string;
  purchaseDate: Date;
  deliveryDate: Date;
  bottleSize: BottleSize;
  pricingMethod: WinePricingMethod;
  price: Amount;
  totalCost: Amount;
  valuePerBottle: Amount;
  netWorth: Amount; //calculated with info above only consider not consumed bottles
  acquisition?: CollectableAcquisition;

  ownership?: Ownership;
  beneficiary?: Beneficiary;

  bottleCount: BottleCount;

  bottles: OneBottleOfWine[];
  createAt: Date;
  updateAt: Date;
  // NOTE managed by triggers in `functions`, do not modify this field in other places
  indexedAt?: Date;
  indexVersion?: number;
}
export namespace WinePurchase {
  export function assureVersion(
    input: WinePurchase | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      WinePurchaseTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.WinePurchase);
  }

  export const datePaths: readonly PathsOfDateField<WinePurchase>[] = [
    "purchaseDate",
    "deliveryDate",
    "createAt",
    "updateAt",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<WinePurchase>[] = [
    "netWorth",
    "price",
    "totalCost",
    "valuePerBottle",
  ] as const;
  export function convertDate(input: Encrypted): Encrypted {
    const result = { ...input };
    CoreFirestore.convertDateFieldsFromFirestore(result, datePaths);
    result.bottles.map((v) => {
      if (v.removal)
        CoreFirestore.convertDateFieldsFromFirestore(
          v.removal,
          Removal.datePaths
        );
    });
    return result;
  }

  export type CreateFields = OmitKeys<
    WinePurchase,
    | "@type"
    | "ownerId"
    | "createAt"
    | "updateAt"
    | SystemGeneratedFields
    | "bottles"
  > & {
    bottles: ChangeTypeOfKeys<
      OneBottleOfWine,
      "status",
      Extract<WineStatus, "Pending" | "Delivered">
    >[];
  };

  export type Encrypted = RequireEncryptionFields<
    WinePurchase,
    { bottles: OneBottleOfWine.Encrypted[] }
  >;

  export type SystemGeneratedFields = "netWorth" | "bottleCount";
  export type EncryptedCreate = OmitKeys<Encrypted, SystemGeneratedFields>;
  export type EncryptedUpdate = OmitKeys<
    Encrypted,
    "@type" | "ownerId" | "createAt"
  >;

  export function fromCreate(
    from: CreateFields,
    ownerId: string
  ): WinePurchase {
    const { netWorth, bottleCount } = generateNetWorthAndBottleCount(
      from.bottles,
      from.valuePerBottle
    );
    const purchase: WithFieldValue<WinePurchase> = {
      ...from,
      ownerId,
      netWorth,
      bottleCount,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      "@type": WinePurchaseTypeVersion,
    };
    return purchase as WinePurchase;
  }

  export function generateNetWorthAndBottleCount(
    bottles: OneBottleOfWine.Comparable[],
    valuePerBottle: Amount
  ): Pick<WinePurchase, "netWorth" | "bottleCount"> {
    const result: Pick<WinePurchase, SystemGeneratedFields> = {
      netWorth: {
        currency: valuePerBottle.currency,
        value: 0,
      },
      bottleCount: {
        pendings: 0,
        bottles: bottles.length,
        consumed: 0,
      },
    };
    bottles.forEach((bottle) => {
      if (bottle.status === WineStatus.Consumed) {
        result.bottleCount.consumed++;
        result.bottleCount.bottles--;
      } else if (bottle.status === WineStatus.Pending) {
        result.bottleCount.pendings++;
      }
    });
    result.netWorth.value = mulDecimal(
      valuePerBottle.value,
      result.bottleCount.bottles
    );

    return result;
  }

  export async function encrypt(
    rawData: WinePurchase,
    encryption: Encryption
  ): Promise<Encrypted> {
    const { bottles, ...rest } = rawData;
    return {
      ...rest,
      bottles: await Promise.all(
        bottles.map((v) => OneBottleOfWine.encrypt(v, encryption))
      ),
    };
  }
  export async function decrypt(
    data: Encrypted,
    encryption: Encryption
  ): Promise<WinePurchase> {
    const { bottles, ...rest } = data;
    return {
      ...rest,
      bottles: await Promise.all(
        bottles.map((v) => OneBottleOfWine.decrypt(v, encryption))
      ),
    };
  }

  //#TODO need check
  export function validateEncryptedObj(
    data: UpdateObject<
      OmitKeys<Encrypted, "bottles"> & { bottles: OneBottleOfWine.Comparable[] }
    >,
    isCreate: boolean = false
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    if (isCreate) {
      if (!data.purchaseDate)
        throw new InvalidInput("PurchaseDate is required");
      if (!data.deliveryDate)
        throw new InvalidInput("DeliveryDate is required");
    }
    if (
      (isCreate || data.bottleSize) &&
      !validateValueInEnum(data.bottleSize!, BottleSize)
    ) {
      throw new InvalidInput("BottleSize is not valid");
    }
    if (
      (isCreate || data.pricingMethod) &&
      !validateValueInEnum(data.pricingMethod!, WinePricingMethod)
    ) {
      throw new InvalidInput("PricingMethod is not valid");
    }
    if ((isCreate || data.price) && data.price!.value < 0) {
      throw new InvalidInput("Price must be positive");
    }
    if ((isCreate || data.valuePerBottle) && data.valuePerBottle!.value < 0) {
      throw new InvalidInput("ValuePerBottle can't be negative");
    }
    if ((isCreate || data.netWorth) && data.netWorth!.value < 0) {
      throw new InvalidInput("NetWorth can't be negative");
    }
    if (
      (isCreate || data.bottleCount) &&
      (data.bottleCount!.bottles < 0 ||
        data.bottleCount!.consumed < 0 ||
        data.bottleCount!.pendings < 0)
    ) {
      throw new InvalidInput("BottleCount can't be negative");
    }
    if (isCreate || data.bottles) {
      if (data.bottles!.length === 0) {
        throw new InvalidInput("Bottles can't be empty");
      }
      //#TODO if isCreate, bottles[number].status = WineStatus.Pending | WineStatus.Delivered, else bottles can also be WineStatus.InMyCellar
      data.bottles!.forEach((bottle) => {
        OneBottleOfWine.validate(bottle, isCreate);
      });
    }
    // optional fields
    if (data.acquisition) {
      CollectableAcquisition.validateEncryptedObj(data.acquisition);
    }
    if (data.ownership) {
      Ownership.validate(data.ownership);
    }
    if (data.beneficiary) {
      Owner.validate(0, data.beneficiary);
    }
  }

  export function validateUpdateInCommand(data: UpdateInCommand) {
    validateEncryptedObj(data);
    if (data.addBottles) {
      data.addBottles.forEach((bottle) => {
        OneBottleOfWine.validate(bottle, true);
      });
    }
    if (data.location) {
      LocationInfo.validateEncryptedObj(data.location);
    }
  }

  export type Min = Pick<
    WinePurchase,
    | "id"
    | "bottleSize"
    | "valuePerBottle"
    | "bottleCount"
    | "netWorth"
    | "totalCost"
    | "pricingMethod"
  > & { myOwnership?: number }; // FIXME: may need migration or remove it.
  export function toMin<
    T extends Pick<
      WinePurchase,
      | "id"
      | "bottleSize"
      | "valuePerBottle"
      | "price"
      | "totalCost"
      | "bottleCount"
      | "netWorth"
      | "acquisition"
      | "pricingMethod"
      | "ownership"
    >
  >(from: T): Min {
    return {
      id: from.id,
      bottleSize: from.bottleSize,
      valuePerBottle: from.valuePerBottle,
      totalCost: from.totalCost,
      bottleCount: from.bottleCount,
      netWorth: from.netWorth,
      pricingMethod: from.pricingMethod,
      myOwnership: from.ownership?.myOwnership,
    };
  }

  // export type Update = OmitKeys<UpdateObject<Encrypted>, "bottles"> & {
  //   //#HACK we assume that new bottles will be in the same location, like they are batch added
  //   newBottles?: Encrypted["bottles"];
  //   bottleUpdates?: Encrypted["bottles"];
  // };

  type Update = Pick<
    Encrypted,
    | "bottleSize"
    | "price"
    | "totalCost"
    | "pricingMethod"
    | "valuePerBottle"
    | "acquisition"
    | "deliveryDate"
    | "purchaseDate"
    | "ownership"
    | "beneficiary"
  > & {
    status: Exclude<WineStatus, "Consumed">;
  };
  export type UpdateInCommand = UpdateObject<
    Update & { addBottles: OneBottleOfWine.Encrypted[] } & Pick<
        OneBottleOfWine.Encrypted,
        "location"
      >
  >;
  export type UpdateInReq = UpdateObject<
    Update & { addBottles: number } & Pick<OneBottleOfWine, "location">
  >;
}

export interface OneBottleOfWine {
  bottleId: string;
  status: WineStatus;
  location: LocationInfo;
  removal?: Removal;
}
export namespace OneBottleOfWine {
  export type Encrypted = RequireEncryptionFields<
    OneBottleOfWine,
    EncryptedPart
  >;
  export type EncryptedPart = {
    location: LocationInfo.Encrypted;
    removal?: Removal.Encrypted;
  };
  export type EncryptedPartKeys = "location" | "removal";
  export type Comparable = OmitKeys<OneBottleOfWine, EncryptedPartKeys>;

  export function validate(
    data: UpdateObject<OneBottleOfWine.Encrypted>,
    isCreate: boolean
  ) {
    if (
      isCreate &&
      ![WineStatus.Pending, WineStatus.Delivered].includes(data.status!)
    ) {
      throw new InvalidInput("Create status must be Pending or Delivered");
    }
    if (!validateValueInEnum(data.status!, WineStatus)) {
      throw new InvalidInput("Invalid status");
    }
    if (isCreate && !data.location) {
      throw new InvalidInput("Location is required");
    }
    if (data.location) {
      LocationInfo.validateEncryptedObj(data.location);
    }
    // optional fields
    if (data.removal) {
      Removal.validate(data.removal);
    }
  }

  export async function encrypt(
    rawData: OneBottleOfWine,
    encryption: Encryption
  ): Promise<Encrypted> {
    const { removal, ...bottleRest } = rawData;
    (<OneBottleOfWine.Encrypted>bottleRest).location =
      await LocationInfo.encrypt(bottleRest.location, encryption);
    if (removal)
      (<OneBottleOfWine.Encrypted>bottleRest).removal = await Removal.encrypt(
        removal,
        encryption
      );
    return <OneBottleOfWine.Encrypted>bottleRest;
  }

  export async function decrypt(
    data: Encrypted,
    encryption: Encryption
  ): Promise<OneBottleOfWine> {
    const { removal, ...bottleRest } = data;
    if (removal)
      (<OneBottleOfWine>bottleRest).removal = await Removal.decrypt(
        removal,
        encryption
      );
    (<OneBottleOfWine>bottleRest).location = await LocationInfo.decrypt(
      bottleRest.location,
      encryption
    );
    return bottleRest;
  }

  export function equal(a: OneBottleOfWine, b: OneBottleOfWine): boolean {
    return (
      a.bottleId === b.bottleId &&
      a.status === b.status &&
      LocationInfo.equal(a.location, b.location) &&
      Removal.optionalEqual(a.removal, b.removal)
    );
  }
}

export interface Removal {
  date: Date;
  reason: RemovalReason;
  //   @Encrypted
  notes: string;
}
export namespace Removal {
  export const datePaths: readonly PathsOfDateField<Removal>[] = [
    "date",
  ] as const;
  export type EncryptedKeys = "notes";
  export type Encrypted = EncryptedType<Removal, EncryptedKeys>;
  export type EncryptedPart = Pick<Removal, EncryptedKeys>;
  export const encryptedKey: keyof EncryptedPart = "notes";

  export type Validate = OmitKeys<Removal, EncryptedKeys>;
  export function validate<T extends Validate>(data: T) {
    if (!validateValueInEnum(data.reason, RemovalReason)) {
      throw new InvalidInput("Invalid removal reason");
    }
  }

  export async function encrypt(
    rawData: Removal,
    encryption: Encryption
  ): Promise<Removal.Encrypted> {
    return fullObjectEncryption(rawData, [encryptedKey], encryption);
  }
  export const decrypt = fullObjectDecryption<Removal, EncryptedKeys>;
  export function optionalEqual(a: Optional<Removal>, b: Optional<Removal>) {
    if (a && b) {
      return a.date.getTime() === b.date.getTime() && a.reason === b.reason;
    } else {
      return a === b;
    }
  }
}

export enum VintageRange {
  WineVintageRange1 = "Before 1970",
  WineVintageRange2 = "1970-1979",
  WineVintageRange3 = "1980-1989",
  WineVintageRange4 = "1990-1999",
  WineVintageRange5 = "2000-2009",
  WineVintageRange6 = "After 2010",
}
export function vintageToRange(vintage: number) {
  if (isNaN(vintage)) throw new InvalidInput("vintage isNaN");
  if (vintage < 1970) return VintageRange.WineVintageRange1;
  if (vintage < 1980) return VintageRange.WineVintageRange2;
  if (vintage < 1990) return VintageRange.WineVintageRange3;
  if (vintage < 2000) return VintageRange.WineVintageRange4;
  if (vintage < 2010) return VintageRange.WineVintageRange5;
  return VintageRange.WineVintageRange6;
}

export interface WineAggregateState {
  wine: Wine.Encrypted;
  purchases: { [id: string]: Deletable<Optional<WinePurchase.Encrypted>> };
}
export namespace WineAggregateState {
  export function convertDate(input: WineAggregateState): WineAggregateState {
    return {
      wine: Wine.convertDate(input.wine),
      purchases: Object.fromEntries(
        Object.entries(input.purchases).map(([id, purchase]) => [
          id,
          purchase ? WinePurchase.convertDate(purchase) : undefined,
        ])
      ),
    };
  }

  export function newAggregateRoot(
    state: WineAggregateState,
    relatedReads?: RelatedReads
  ) {
    return new AggregateRoot(new WineAggregate(state, relatedReads));
  }

  export type SupportActions = TastingNote;
  export type SupportActionsEncrypted = TastingNote.Encrypted;
  export type SupportActionsUpdate = TastingNote.Update;
  export type RelatedReads = {
    actions: { [id: string]: SupportActionsEncrypted };
  };
  export type RelatedUpdates = {
    purchaseIdsToRemove?: string[];
    setActions?: SupportActionsEncrypted[];
    removedActionIds?: string[];
    shouldUpdateWine: boolean;
  };
}

export namespace Command {
  export enum CustomKind {
    AddPurchase = "AddPurchase",
    UpdatePurchase = "UpdatePurchase",
    DeletePurchase = "DeletePurchase",
    // RelocateAsset = "RelocateAsset",
    UpdateCatalogue = "UpdateCatalogue",
    RelocateBottle = "RelocateBottle",
    RemoveBottle = "RemoveBottle",
    setWineDeleting = "setWineDeleting",
    DeleteWine = "DeleteWine",
  }
  export type Kind = ActionCommand.Kind | CustomKind;
  export const Kind = {
    ...ActionCommand.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends CommandBase {
    kind: Kind;
  }

  export interface AddPurchase extends BaseExtended {
    kind: CustomKind.AddPurchase;
    catalogueInfo: WineCatalogueMinInfo;
    purchase: WinePurchase.EncryptedCreate;
    personalRefNo?: string;
    attachments?: Attachment.Encrypted[];
    mainImage?: string;
  }
  export function addPurchase(
    executerId: string,
    catalogueInfo: WineCatalogueMinInfo,
    purchase: WinePurchase.EncryptedCreate,
    personalRefNo: Optional<string>,
    attachments: Optional<Attachment.Encrypted[]>,
    mainImage: Optional<string>
  ): AddPurchase {
    const cmd: AddPurchase = {
      kind: CustomKind.AddPurchase,
      executerId,
      catalogueInfo,
      purchase,
      attachments,
    };
    if (attachments && attachments.length > 0) {
      attachments.forEach(({ key }, idx) => {
        if (
          attachments.findIndex((attachment) => attachment.key === key) != idx
        )
          throw new InvalidInput("Duplicate attachment key");
      });
      cmd.attachments = attachments;
    }
    if (mainImage) {
      cmd.mainImage = mainImage;
    }
    if (personalRefNo) {
      cmd.personalRefNo = personalRefNo;
    }
    return cmd;
  }

  export interface UpdatePurchase extends BaseExtended {
    kind: CustomKind.UpdatePurchase;
    id: string;
    update: WinePurchase.UpdateInCommand;
    wine?: Wine; // required if addBottle or location update
    personalRefNo?: Deletable<string>;
    attachments?: Deletable<Attachment.Encrypted[]>;
    mainImage?: Deletable<string>;
  }
  export function updatePurchase(
    executerId: string,
    id: string,
    update: WinePurchase.UpdateInCommand,
    wine: Optional<Wine>,
    personalRefNo: Optional<Deletable<string>>,
    attachments: Optional<Deletable<Attachment.Encrypted[]>>,
    mainImage: Optional<Deletable<string>>
  ): UpdatePurchase {
    const command: UpdatePurchase = {
      kind: CustomKind.UpdatePurchase,
      executerId,
      id,
      update,
      wine,
      personalRefNo,
      attachments,
      mainImage,
    };
    return command;
  }

  export interface DeletePurchase extends BaseExtended {
    kind: CustomKind.DeletePurchase;
    ids: string[];
    wine: Wine.Encrypted;
  }
  export function deletePurchase(
    executerId: string,
    ids: string[],
    wine: Wine.Encrypted
  ): DeletePurchase {
    return {
      kind: CustomKind.DeletePurchase,
      executerId,
      ids,
      wine,
    };
  }

  // export interface RelocateAsset extends BaseExtended {
  //   kind: CustomKind.RelocateAsset;
  //   purchaseId: string;
  //   bottleIds: string[];
  //   fromLocationId: string;
  //   toLocation: LocationInfo.Encrypted;
  // }
  // export function relocateAsset(
  //   executerId: string,
  //   purchaseId: string,
  //   bottleIds: string[],
  //   fromLocationId: string,
  //   toLocation: LocationInfo.Encrypted
  // ): RelocateAsset {
  //   return {
  //     kind: <any>CustomKind.RelocateAsset,
  //     executerId,
  //     purchaseId,
  //     bottleIds,
  //     fromLocationId,
  //     toLocation,
  //   };
  // }

  export interface UpdateCatalogue extends BaseExtended {
    kind: CustomKind.UpdateCatalogue;
    update: WineCatalogueMinInfo.Update;
  }
  export function updateCatalogue(
    executerId: string,
    update: WineCatalogueMinInfo.Update
  ): UpdateCatalogue {
    return {
      kind: CustomKind.UpdateCatalogue,
      executerId,
      update,
    };
  }

  export interface RelocateBottle extends BaseExtended {
    kind: CustomKind.RelocateBottle;
    purchaseId: string;
    ids: string[];
    wine: Wine.Encrypted;
    fromLocationId?: string;
    location: LocationInfo.Encrypted;
  }
  export function relocateBottle(
    executerId: string,
    purchaseId: string,
    ids: string[],
    wine: Wine.Encrypted,
    location: LocationInfo.Encrypted,
    fromLocationId?: string
  ): RelocateBottle {
    const command: RelocateBottle = {
      kind: CustomKind.RelocateBottle,
      executerId,
      purchaseId,
      ids,
      wine,
      location,
    };
    if (fromLocationId) {
      command.fromLocationId = fromLocationId;
    }
    return command;
  }

  export interface RemoveBottle extends BaseExtended {
    kind: CustomKind.RemoveBottle;
    purchaseId: string;
    ids: string[];
    removal: Removal.Encrypted;
    wine: Wine;
  }
  export function removeBottle(
    executerId: string,
    purchaseId: string,
    ids: string[],
    removal: Removal.Encrypted,
    wine: Wine
  ): RemoveBottle {
    return {
      kind: CustomKind.RemoveBottle,
      executerId,
      purchaseId,
      ids,
      removal,
      wine,
    };
  }

  export interface SetWineDeleting extends BaseExtended {
    kind: CustomKind.setWineDeleting;
  }
  export function setWineDeleting(executerId: string): SetWineDeleting {
    return {
      kind: CustomKind.setWineDeleting,
      executerId,
    };
  }
  export interface DeleteWine extends BaseExtended {
    kind: CustomKind.DeleteWine;
  }
  export function deleteWine(executerId: string): DeleteWine {
    return {
      kind: CustomKind.DeleteWine,
      executerId,
    };
  }

  export interface AddAction
    extends ActionCommand.AddAction<WineAggregateState.SupportActionsEncrypted> {}
  export interface UpdateAction
    extends ActionCommand.UpdateAction<WineAggregateState.SupportActionsUpdate> {}
  export interface DeleteAction extends ActionCommand.DeleteAction {}
}
export type Command =
  | Command.AddPurchase
  | Command.UpdatePurchase
  | Command.DeletePurchase
  // | Command.RelocateAsset
  | Command.UpdateCatalogue
  | Command.RelocateBottle
  | Command.RemoveBottle
  | Command.SetWineDeleting
  | Command.DeleteWine
  | Command.AddAction
  | Command.UpdateAction
  | Command.DeleteAction;
export namespace Event {
  enum CustomKind {
    PurchaseAdded = "PurchaseAdded",
    PurchaseUpdated = "PurchaseUpdated",
    PurchaseDeleted = "PurchaseDeleted",
    CatalogueInfoUpdated = "CatalogueInfoUpdated",
    BottleLocationUpdated = "BottleLocationUpdated",
    BottleRemoved = "BottleRemoved",
    WineDeleting = "WineDeleting",
    WineDeleted = "WineDeleted",
  }
  export type Kind =
    | CustomKind
    | ActionEvent.Kind
    | Extract<
        SharedEvent.Kind,
        | "ShareholderUpdated"
        | "BeneficiaryUpdated"
        | "ValueUpdated"
        | "ImageAdded"
        | "MainImageSet"
      >;
  export const Kind = {
    ...SharedEvent.Kind,
    ...CustomKind,
    ...ActionEvent.Kind,
  };
  interface BaseExtended extends EventBase {
    kind: Kind;
  }

  export interface PurchaseAdded extends BaseExtended {
    kind: CustomKind.PurchaseAdded;
    catalogueInfo: WineCatalogueMinInfo;
    data: WinePurchase.Encrypted;
    personalRefNo?: string;
    attachments?: Attachment.Encrypted[];
    mainImage?: string;
  }
  export interface PurchaseUpdated extends BaseExtended {
    kind: CustomKind.PurchaseUpdated;
    id: string;
    update: WinePurchase.UpdateInCommand;
    personalRefNo?: Deletable<string>;
    attachments?: Deletable<Attachment.Encrypted[]>;
    mainImage?: Deletable<string>;
    // if addBottles is provided in update
    logInfo?: {
      vintage: number;
      wineName: string;
    };
  }
  export interface PurchaseDeleted extends BaseExtended {
    kind: CustomKind.PurchaseDeleted;
    ids: string[];
    logInfo: {
      vintage: number;
      wineName: string;
      purchases: {
        [id: string]: {
          bottles: number;
          netWorth: Amount;
        };
      };
    };
  }
  export interface CatalogueInfoUpdated extends BaseExtended {
    kind: CustomKind.CatalogueInfoUpdated;
    update: WineCatalogueMinInfo.Update;
  }
  export interface BottleLocationUpdated extends BaseExtended {
    kind: CustomKind.BottleLocationUpdated;
    purchaseId: string;
    updates: {
      ids: string[];
      previous?: LocationInfo.Encrypted;
      current: Deletable<LocationInfo.Encrypted>;
    }[];
    logInfo: {
      vintage: number;
      wineName: string;
    };
  }
  export interface BottleRemoved extends BaseExtended {
    kind: CustomKind.BottleRemoved;
    purchaseId: string;
    ids: string[];
    removal: Removal.Encrypted;
    logInfo: {
      vintage: number;
      wineName: string;
      valuePerBottle: Amount;
    };
  }
  export interface WineDeleting extends BaseExtended {
    kind: CustomKind.WineDeleting;
  }
  //#NOTE this should kill wine and purchases
  export interface WineDeleted extends BaseExtended {
    kind: CustomKind.WineDeleted;
  }
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {
    purchaseId: string;
    logInfo: {
      purchaseDate: Date;
    };
  }
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {
    purchaseId: string;
    logInfo: {
      purchaseDate: Date;
    };
  }

  type ValueUpdateData = Partial<
    Pick<WinePurchase.Encrypted, "valuePerBottle" | "netWorth">
  >;
  export interface ValueUpdated extends BaseExtended {
    kind: SharedEvent.Kind.ValueUpdated;
    purchaseId: string;
    previous?: ValueUpdateData;
    current: ValueUpdateData;
  }
  //#TODO need to check if this is needed
  export interface ImageAdded extends SharedEvent.ImageAdded {}
  export interface MainImageSet extends SharedEvent.MainImageSet {}

  export interface ActionAdded
    extends ActionEvent.ActionAdded<WineAggregateState.SupportActionsEncrypted> {}
  export interface ActionUpdated
    extends ActionEvent.ActionUpdated<WineAggregateState.SupportActionsUpdate> {}
  export interface ActionDeleted extends ActionEvent.ActionDeleted {}

  export const toActivities: EventToActivitiesFunction<Event> = (
    event: Event,
    assetType: SupportActivityType,
    assetId: string,
    ownerId: string,
    time: Date
  ) => {
    const baseFields: OmitKeys<Activity.Base, "activityKind"> = {
      assetId,
      ownerId,
      sortKey: 0,
      executerId: event.executerId,
      time,
    };
    validateActMapFuncKnowEventKind(assetType, [event]);
    // NOTE For wine, only the three events below can be handled by `getActivityLogs`
    const needProcessEventKinds: SharedEvent.Kind[] = [
      Event.Kind.ShareholderUpdated,
      Event.Kind.BeneficiaryUpdated,
      Event.Kind.MainImageSet,
    ];

    let activities: Encrypted<Activity>[] = [];
    switch (event.kind) {
      case Event.Kind.PurchaseAdded: {
        const activity: Encrypted<Activity> = {
          ...baseFields,
          activityKind: ActivityKind.NewBottlesBought,
          detail: {
            bottles: event.data.bottles.length,
            vintage: event.catalogueInfo.vintage,
            wineName: event.catalogueInfo.name,
            value: {
              currency: event.data.valuePerBottle.currency,
              value: mulAmount(
                event.data.valuePerBottle.value,
                event.data.bottles.length
              ),
            },
          },
        };
        activities = [
          activity,
          {
            ...baseFields,
            activityKind: ActivityKind.VintageCreated,
            detail: {
              vintage: event.catalogueInfo.vintage,
              wineName: event.catalogueInfo.name,
            },
          },
        ];
        break;
      }
      case Event.Kind.PurchaseUpdated: {
        if (event.update.addBottles) {
          if (!event.update.valuePerBottle || !event.logInfo)
            throw new DataPoisoned("valuePerBottle and logInfo missing");
          const activity: Encrypted<Activity> = {
            ...baseFields,
            activityKind: ActivityKind.NewBottlesBought,
            detail: {
              bottles: event.update.addBottles.length,
              vintage: event.logInfo.vintage,
              wineName: event.logInfo.wineName,
              value: {
                currency: event.update.valuePerBottle.currency,
                value: mulAmount(
                  event.update.valuePerBottle.value,
                  event.update.addBottles.length
                ),
              },
            },
          };
          activities = [activity];
        }
        break;
      }
      case Event.Kind.PurchaseDeleted: {
        event.ids.forEach((purchaseId) => {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.RemoveBottles,
            detail: {
              bottles: event.logInfo.purchases[purchaseId].bottles,
              vintage: event.logInfo.vintage,
              wineName: event.logInfo.wineName,
              value: event.logInfo.purchases[purchaseId].netWorth,
            },
          });
        });
        break;
      }
      case Event.Kind.BottleRemoved: {
        const valuePerBottle = event.logInfo.valuePerBottle;
        const activity: Encrypted<Activity> = {
          ...baseFields,
          activityKind: ActivityKind.RemoveBottles,
          detail: {
            bottles: event.ids.length,
            vintage: event.logInfo.vintage,
            wineName: event.logInfo.wineName,
            removalReason: event.removal.reason,
            value: {
              currency: valuePerBottle.currency,
              value: mulAmount(valuePerBottle.value, event.ids.length),
            },
          },
        };
        activities = [activity];
        break;
      }
      case Event.Kind.BottleLocationUpdated: {
        for (const update of event.updates) {
          if (update.current) {
            // NOTE it is possible that only room or position is updated
            const roomId = update.current.roomId || update.previous?.roomId;
            const locationType =
              update.current.locationType || update.previous?.locationType;
            const locationId =
              update.current.locationId || update.previous?.locationId;
            const position =
              update.current.position || update.previous?.position;
            if (!locationType || !locationId) {
              throw new Error("locationType or locationId is missing");
            }

            const activity: Encrypted<Activity> = {
              ...baseFields,
              activityKind: ActivityKind.BottleLocationCreated,
              detail: {
                bottles: update.ids.length,
                locationType,
                locationId,
                vintage: event.logInfo.vintage,
                wineName: event.logInfo.wineName,
              },
            };
            addLocationDataToDetail(
              activity.detail!,
              roomId
                ? {
                    roomId,
                    position,
                  }
                : undefined
            );
            activities.push(activity);
          } else if (update.previous && update.current === null) {
            if (!event.logInfo) throw new DataPoisoned("logInfo missing");
            const activity: Encrypted<Activity> = {
              ...baseFields,
              activityKind: ActivityKind.BottleLocationRemoved,
              detail: {
                bottles: update.ids.length,
                locationType: update.previous.locationType,
                locationId: update.previous.locationId,
                vintage: event.logInfo.vintage,
                wineName: event.logInfo.wineName,
              },
            };
            addLocationDataToDetail(
              activity.detail!,
              update.previous.roomId
                ? {
                    roomId: update.previous.roomId,
                    position: update.previous.position,
                  }
                : undefined
            );
            activities.push(activity);
          }
        }
        break;
      }
      case Event.Kind.ValueUpdated: {
        if (event.previous && event.current) {
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.ValueUpdated,
              detail: {
                previous: event.previous.netWorth,
                current: event.current.netWorth,
              },
            },
          ];
        }
        break;
      }
      default:
        return genActivityLogs(
          needProcessEventKinds,
          event,
          assetType,
          assetId,
          ownerId,
          time
        );
    }
    return activities.map((activity) => ({
      ...activity,
      sortKey: activitySortKeyMap[activity.activityKind!],
    }));
  };
}

export type Event =
  | Event.PurchaseAdded
  | Event.PurchaseUpdated
  | Event.PurchaseDeleted
  | Event.CatalogueInfoUpdated
  | Event.BottleLocationUpdated
  | Event.BottleRemoved
  | Event.WineDeleting
  | Event.WineDeleted
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.ValueUpdated
  | Event.ImageAdded
  | Event.MainImageSet
  | Event.ActionAdded
  | Event.ActionUpdated
  | Event.ActionDeleted;

export function toTagPair<
  T extends Pick<
    WineCatalogueMinInfo & WinePurchase,
    "subtype" | "bottleSize" | "vintage" | "country" | "labeledVariety"
  >
>(current: UpdateObject<T>, previous?: T): TagPair[] {
  const tagPairs: TagPair[] = [];
  // wine
  if (current.subtype) {
    tagPairs.push({ key: SummaryTag.Wine.Subtype, val: current.subtype });
  } else if (previous && current.subtype !== null) {
    tagPairs.push({ key: SummaryTag.Wine.Subtype, val: previous.subtype });
  }
  if (current.vintage) {
    tagPairs.push({
      key: SummaryTag.Wine.Vintage,
      val: vintageToRange(current.vintage),
    });
  } else if (previous?.vintage && current.vintage !== null) {
    tagPairs.push({
      key: SummaryTag.Wine.Vintage,
      val: vintageToRange(previous.vintage),
    });
  }
  if (current.country) {
    tagPairs.push({
      key: SummaryTag.Wine.Origin,
      val: WineCatalogueMinInfo.getOrigin({ country: current.country }),
    });
  } else if (previous?.country && current.country !== null) {
    tagPairs.push({
      key: SummaryTag.Wine.Origin,
      val: WineCatalogueMinInfo.getOrigin({ country: previous.country }),
    });
  }
  if (current.labeledVariety) {
    tagPairs.push({
      key: SummaryTag.Wine.Varietal,
      val: current.labeledVariety,
    });
  } else if (previous && current.labeledVariety !== null) {
    tagPairs.push({
      key: SummaryTag.Wine.Varietal,
      val: previous.labeledVariety,
    });
  }

  // purchase
  if (current.bottleSize) {
    tagPairs.push({
      key: SummaryTag.Wine.BottleSize,
      val: BottleSizeToString(current.bottleSize),
    });
  } else if (previous?.bottleSize && current.bottleSize !== null) {
    tagPairs.push({
      key: SummaryTag.Wine.BottleSize,
      val: BottleSizeToString(previous.bottleSize),
    });
  }
  return tagPairs;
}

// for delete, update catalogue
export function groupSummaryDataByBottleSize(
  summaryDataMap: { [label: string]: SummaryData },
  purchase: WinePurchase.Min,
  prevWine: Wine.Encrypted,
  currWine?: UpdateObject<WineCatalogueMinInfo>
) {
  // only summaryTag `bottleSize` may be different between purchases, so we group summaryData by `bottleSize`
  const target = summaryDataMap[purchase.bottleSize];
  const prevOwnedValue = calculateOwnedValue(
    purchase.netWorth,
    purchase.myOwnership
  );
  if (target) {
    target.prevOwnedValue[purchase.netWorth.currency] =
      (target.prevOwnedValue[purchase.netWorth.currency] || 0) +
      prevOwnedValue.value;
    target.prevAssetNumber += 1;
    target.prevItemNumber += purchase.bottleCount.bottles;
    if (currWine) {
      target.currOwnedValue[purchase.netWorth.currency] =
        (target.currOwnedValue[purchase.netWorth.currency] || 0) +
        prevOwnedValue.value;
      target.currAssetNumber += 1;
      target.currItemNumber += purchase.bottleCount.bottles;
    }
  } else {
    summaryDataMap[purchase.bottleSize] = {
      prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
      currOwnedValue: currWine
        ? MultiCurrencyAmount.fromAmounts(prevOwnedValue)
        : {},
      prevAssetNumber: 1,
      currAssetNumber: currWine ? 1 : 0,
      prevItemNumber: purchase.bottleCount.bottles,
      currItemNumber: currWine ? purchase.bottleCount.bottles : 0,
      prevTags: toTagPair({ ...prevWine, ...purchase }),
      currTags: currWine
        ? toTagPair(currWine, { ...prevWine, ...purchase })
        : [],
    };
  }
}

class WineAggregate implements IAggregate<WineAggregateState, Command, Event> {
  state: WineAggregateState;
  kind: string;
  relatedReads?: WineAggregateState.RelatedReads;
  relatedUpdates: WineAggregateState.RelatedUpdates = {
    shouldUpdateWine: false,
  };

  constructor(
    state: WineAggregateState,
    relatedReads?: WineAggregateState.RelatedReads
  ) {
    this.state = state;
    this.kind = Domain.WineAndSpirits;
    if (relatedReads) this.relatedReads = relatedReads;
  }

  id(): string {
    return this.state.wine.id;
  }

  version(): number {
    return this.state.wine.version;
  }

  incrementVersion(): void {
    this.state.wine.version++;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.AddPurchase:
        return this.handleAddPurchase(command).map(preSealEvent);
      case Command.Kind.UpdatePurchase:
        return this.handleUpdatePurchase(command).map(preSealEvent);
      case Command.Kind.DeletePurchase:
        return this.handleDeletePurchase(command).map(preSealEvent);
      // case Command.Kind.RelocateAsset:
      //   return this.handleRelocateAsset(command);
      case Command.Kind.UpdateCatalogue:
        return this.handleUpdateCatalogue(command).map(preSealEvent);
      case Command.Kind.RelocateBottle:
        return this.handleRelocateBottle(command).map(preSealEvent);
      case Command.Kind.RemoveBottle:
        return this.handleRemoveBottle(command).map(preSealEvent);
      case Command.Kind.setWineDeleting:
        return this.handleSetWineDeleting(command).map(preSealEvent);
      case Command.Kind.DeleteWine:
        return this.handleDeleteWine(command).map(preSealEvent);

      case Command.Kind.AddAction:
        return SupportActionAggregate.handleAdd<
          WineAggregateState,
          WineAggregateState.SupportActionsEncrypted
        >(this, command);
      case Command.Kind.UpdateAction:
        return SupportActionAggregate.handleUpdate<
          WineAggregateState,
          WineAggregateState.SupportActionsUpdate
        >(this, command);
      case Command.Kind.DeleteAction:
        return SupportActionAggregate.handleDelete(this, command);
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.PurchaseAdded:
        this.applyPurchaseAdded(event, time);
        break;
      case Event.Kind.PurchaseUpdated:
        this.applyPurchaseUpdated(event);
        break;
      case Event.Kind.PurchaseDeleted: {
        const removingId = event.ids.map((id) => {
          const idx = this.state.wine.purchases.findIndex((v) => v.id === id);
          if (idx !== -1) this.state.wine.purchases.splice(idx, 1);
          this.relatedUpdates.shouldUpdateWine = true;
          return id;
        });
        if (this.relatedUpdates.purchaseIdsToRemove) {
          this.relatedUpdates.purchaseIdsToRemove.push(...removingId);
        } else this.relatedUpdates.purchaseIdsToRemove = removingId;

        this.state.wine.value = Wine.calculateValuePurchaseFromNetWorth(
          this.state.wine
        );
        this.relatedUpdates.shouldUpdateWine = true;
        break;
      }
      case Event.Kind.CatalogueInfoUpdated:
        this.state.wine = applyUpdateToObject<WineCatalogueMinInfo>(
          this.state.wine,
          event.update
        ) as Wine.Encrypted;
        this.relatedUpdates.shouldUpdateWine = true;
        break;
      case Event.Kind.BottleLocationUpdated:
        this.applyBottleLocationUpdated(event);
        break;
      case Event.Kind.BottleRemoved: {
        const purchase = this.state.purchases[event.purchaseId];
        if (!purchase) throw new Error("Purchase not found in state");
        event.ids.forEach((id) => {
          const bottle = purchase.bottles.find((v) => v.bottleId === id);
          if (!bottle) {
            throw new InvalidInput("Bottle id not found");
          }
          if (bottle.status == WineStatus.Consumed)
            throw new InvalidInput("Bottle already consumed");
          if (bottle.status == WineStatus.Pending) {
            purchase.bottleCount.pendings--;
          }
          purchase.bottleCount.bottles--;
          purchase.bottleCount.consumed++;
          bottle.status = WineStatus.Consumed;
          bottle.removal = event.removal;
        });
        this.relatedUpdates.shouldUpdateWine = true;
        break;
      }
      case Event.Kind.WineDeleting:
        this.state.wine.isDeleting = true;
        this.relatedUpdates.shouldUpdateWine = true;
        break;
      case Event.Kind.WineDeleted:
        this.state.wine = setObjectDeleted(this.state.wine);
        break;
      case Event.Kind.ValueUpdated: {
        const purchase = this.state.purchases[event.purchaseId];
        if (!purchase) throw new Error("Purchase not found in state");
        if (event.current.valuePerBottle)
          purchase.valuePerBottle = event.current.valuePerBottle;
        if (event.current.netWorth) purchase.netWorth = event.current.netWorth;
        const purchaseIdx = this.state.wine.purchases.findIndex(
          (v) => v.id === purchase.id
        );
        if (purchaseIdx === -1) throw new DataPoisoned("Purchase not found");
        this.state.wine.purchases[purchaseIdx] = WinePurchase.toMin(purchase);
        this.state.wine.value = Wine.calculateValuePurchaseFromNetWorth(
          this.state.wine
        );
        this.relatedUpdates.shouldUpdateWine = true;
        break;
      }

      case Event.Kind.ActionAdded:
        SupportActionAggregate.applyAdded<
          WineAggregateState,
          WineAggregateState.SupportActionsEncrypted
        >(this, event);
        break;
      case Event.Kind.ActionUpdated:
        SupportActionAggregate.applyUpdated<
          WineAggregateState,
          WineAggregateState.SupportActionsUpdate
        >(this, event);
        break;
      case Event.Kind.ActionDeleted:
        SupportActionAggregate.applyDeleted(this, event);
        break;
    }
    return this;
  }

  private getCheckedPurchase(id: string): WinePurchase.Encrypted {
    const currentPurchase = this.state.purchases[id];
    if (!currentPurchase) {
      throw new InvalidInput(
        "Purchase not found in state, need to get it on ar creation"
      );
    }
    return currentPurchase;
  }
  private handleAddPurchase({
    executerId,
    catalogueInfo,
    purchase,
    personalRefNo,
    attachments,
    mainImage,
  }: Command.AddPurchase): Event[] {
    WineCatalogueMinInfo.validate(catalogueInfo);
    WinePurchase.validateEncryptedObj(purchase, true);

    const currTags = toTagPair({ ...catalogueInfo, ...purchase });

    const { netWorth, bottleCount } =
      WinePurchase.generateNetWorthAndBottleCount(
        purchase.bottles,
        purchase.valuePerBottle
      );
    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.PurchaseAdded,
        catalogueInfo,
        data: { ...purchase, bottleCount, netWorth },
        personalRefNo,
        attachments,
        mainImage,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: {},
            prevAssetNumber: 0,
            currAssetNumber: 1,
            prevItemNumber: 0,
            currItemNumber: bottleCount.bottles,
            currTags: deepCopy(currTags),
          },
        ],
      },
      {
        executerId,
        purchaseId: purchase.id,
        kind: Event.Kind.BottleLocationUpdated,
        updates: [
          {
            ids: purchase.bottles.map((bottle) => bottle.bottleId),
            current: purchase.bottles[0].location,
          },
        ],
        logInfo: {
          vintage: catalogueInfo.vintage,
          wineName: catalogueInfo.name,
        },
      },
      {
        executerId,
        kind: Event.Kind.ValueUpdated,
        purchaseId: purchase.id,
        current: {
          valuePerBottle: purchase.valuePerBottle,
          netWorth,
        },
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: MultiCurrencyAmount.fromAmounts(
              calculateOwnedValue(netWorth, purchase.ownership?.myOwnership)
            ),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: bottleCount.bottles,
            currItemNumber: bottleCount.bottles,
            prevTags: deepCopy(currTags),
            currTags: deepCopy(currTags),
          },
        ],
      },
    ];

    if (purchase.ownership) {
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        purchaseId: purchase.id,
        current: purchase.ownership,
        logInfo: {
          purchaseDate: purchase.purchaseDate,
        },
      };
      events.push(shareholderUpdated);
    }
    if (purchase.beneficiary) {
      events.push({
        executerId,
        purchaseId: purchase.id,
        kind: Event.Kind.BeneficiaryUpdated,
        current: purchase.beneficiary,
        logInfo: {
          purchaseDate: purchase.purchaseDate,
        },
      });
    }
    if (attachments) {
      events.push(
        ...SharedEvent.attachmentEventOnCreate(
          executerId,
          attachments,
          mainImage
        )
      );
    }
    return events;
  }
  private handleUpdatePurchase({
    executerId,
    id,
    update,
    wine,
    personalRefNo,
    attachments,
    mainImage,
  }: Command.UpdatePurchase): Event[] {
    WinePurchase.validateUpdateInCommand(update);
    const events: Event[] = [];
    const currentPurchase = this.getCheckedPurchase(id);
    // used to trace the change after each event
    let prevTags = toTagPair({ ...this.state.wine, ...currentPurchase });
    let prevOwnedValue = calculateOwnedValue(
      currentPurchase.netWorth,
      currentPurchase.ownership?.myOwnership
    );

    const updateEvent: Event.PurchaseUpdated = {
      executerId,
      kind: Event.Kind.PurchaseUpdated,
      id,
      update,
    };
    if (update.addBottles) {
      if (!wine) throw new InvalidInput("Wine is required when adding bottles");
      updateEvent.logInfo = {
        vintage: wine.vintage,
        wineName: wine.name,
      };
    }

    let bottlesNumber = currentPurchase.bottleCount.bottles;
    if (update.addBottles) bottlesNumber += update.addBottles.length;
    const currOwnedValue = calculateOwnedValue(
      {
        currency: currentPurchase.valuePerBottle.currency,
        value: mulDecimal(currentPurchase.valuePerBottle.value, bottlesNumber),
      },
      update.ownership?.myOwnership || currentPurchase.ownership?.myOwnership
    );
    const isTagUpdated = update.bottleSize;
    if (
      !Amount.equal(prevOwnedValue, currOwnedValue) ||
      update.addBottles ||
      isTagUpdated
    ) {
      const currTags = toTagPair(update, {
        ...this.state.wine,
        ...currentPurchase,
      });
      updateEvent.summaryData = [
        {
          prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
          currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
          prevAssetNumber: 1,
          currAssetNumber: 1,
          prevItemNumber: currentPurchase.bottleCount.bottles,
          currItemNumber:
            currentPurchase.bottleCount.bottles +
            (update.addBottles?.length || 0),
          prevTags: deepCopy(prevTags),
          currTags: deepCopy(currTags),
        },
      ];
      prevOwnedValue = { ...currOwnedValue };
      if (isTagUpdated) prevTags = deepCopy(currTags);
    }
    if (personalRefNo) {
      updateEvent.personalRefNo = personalRefNo;
    }
    if (attachments !== undefined) {
      updateEvent.attachments = attachments;
    }
    if (mainImage !== undefined) {
      updateEvent.mainImage = mainImage;
      if (
        mainImage !== null &&
        mainImage !== "" &&
        mainImage !== this.state.wine.mainImage
      ) {
        events.push({
          executerId,
          kind: Event.Kind.MainImageSet,
          previous: this.state.wine.mainImage,
          current: mainImage,
        });
      }
    }
    events.push(updateEvent);

    if (update.price) {
      if (update.price.value < 0)
        throw new InvalidInput("Price must be positive");
    }
    if (update.valuePerBottle || update.addBottles) {
      const valueUpdated: Event.ValueUpdated = <Event.ValueUpdated>{
        executerId,
        kind: Event.Kind.ValueUpdated,
        purchaseId: id,
        previous: {},
        current: {},
      };
      let valuePerBottle = currentPurchase.valuePerBottle;
      if (update.valuePerBottle) {
        if (
          !Amount.equal(currentPurchase.valuePerBottle, update.valuePerBottle)
        ) {
          valuePerBottle = update.valuePerBottle;
          valueUpdated.previous!.valuePerBottle =
            currentPurchase.valuePerBottle;
          valueUpdated.current!.valuePerBottle = update.valuePerBottle;
        }
      } else {
        update.valuePerBottle = valuePerBottle;
      }
      let bottlesNumber = currentPurchase.bottleCount.bottles;
      if (update.addBottles) bottlesNumber += update.addBottles.length;
      valueUpdated.previous!.netWorth = currentPurchase.netWorth;
      valueUpdated.current!.netWorth = {
        currency: valuePerBottle.currency,
        value: mulDecimal(valuePerBottle.value, bottlesNumber),
      };

      const currOwnedValue = calculateOwnedValue(
        valueUpdated.current!.netWorth,
        update.ownership?.myOwnership || currentPurchase.ownership?.myOwnership
      );
      valueUpdated.summaryData = [
        {
          prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
          currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
          prevAssetNumber: 1,
          currAssetNumber: 1,
          prevItemNumber: bottlesNumber,
          currItemNumber: bottlesNumber,
          prevTags: deepCopy(prevTags),
          currTags: deepCopy(prevTags),
        },
      ];
      events.push(valueUpdated);
    }
    if (update.ownership) {
      Ownership.validate(update.ownership);
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        purchaseId: id,
        previous: currentPurchase.ownership,
        current: update.ownership,
        logInfo: {
          purchaseDate: currentPurchase.purchaseDate,
        },
      };
      events.push(shareholderUpdated);
    }
    if (update.beneficiary) {
      Owner.validate(0, update.beneficiary);
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        purchaseId: id,
        previous: currentPurchase.beneficiary,
        current: update.beneficiary,
        logInfo: {
          purchaseDate: currentPurchase.purchaseDate,
        },
      });
    }
    if (update.location) {
      if (currentPurchase.bottles.length === 0)
        throw new DataPoisoned("No bottles in purchase");
      const firstLocation = currentPurchase.bottles.find((bottle) => {
        return bottle.status !== WineStatus.Consumed;
      })?.location;
      if (firstLocation) {
        currentPurchase.bottles.forEach((bottle) => {
          if (
            bottle.status != WineStatus.Consumed &&
            bottle.location.locationId !== firstLocation!.locationId
          )
            throw new InvalidInput(
              "All not-consumed bottles must be in the same location"
            );
        });
      }
      if (!wine)
        throw new InvalidInput("Wine is required when updating location");
      events.push({
        executerId,
        kind: Event.Kind.BottleLocationUpdated,
        purchaseId: id,
        updates: [
          {
            ids: currentPurchase.bottles
              .filter((v) => v.status !== WineStatus.Consumed)
              .map((bottle) => bottle.bottleId),
            previous: firstLocation,
            current: update.location,
          },
        ],
        logInfo: {
          vintage: wine?.vintage,
          wineName: wine?.name,
        },
      });
    }
    return events;
  }

  private handleDeletePurchase({
    executerId,
    ids,
    wine,
  }: Command.DeletePurchase): Event[] {
    // if (!this.state.wine.isDeleting)
    //   throw new InvalidInput(
    //     "Purchase can only be deleted when wine is deleting"
    //   );
    const processedIds = ids.filter((id) =>
      this.state.wine.purchases.some((v) => v.id === id)
    );
    const summaryDataMap: { [label: string]: SummaryData } = {};
    const purchases = processedIds.reduce((acc, id) => {
      const purchase = wine.purchases.find((v) => v.id === id)!;
      acc[id] = {
        bottles: purchase.bottleCount.bottles,
        netWorth: purchase.netWorth,
      };

      groupSummaryDataByBottleSize(summaryDataMap, purchase, wine);

      return acc;
    }, {} as { [id: string]: { bottles: number; netWorth: Amount } });
    const summaryData = Object.values(summaryDataMap);
    return [
      {
        executerId,
        kind: Event.Kind.PurchaseDeleted,
        ids: processedIds,
        logInfo: {
          vintage: wine.vintage,
          wineName: wine.name,
          purchases,
        },
        summaryData: summaryData.length > 0 ? summaryData : undefined,
      },
    ];
  }
  private handleUpdateCatalogue({
    executerId,
    update,
  }: Command.UpdateCatalogue): Event[] {
    WineCatalogueMinInfo.validate(update);
    const summaryDataMap: { [label: string]: SummaryData } = {};
    if (
      update.subtype ||
      update.vintage ||
      update.country ||
      update.labeledVariety
    ) {
      Object.values(this.state.wine.purchases).forEach((purchase) => {
        groupSummaryDataByBottleSize(
          summaryDataMap,
          purchase,
          this.state.wine,
          update
        );
      });
    }
    const summaryData = Object.values(summaryDataMap);
    return [
      {
        executerId,
        kind: Event.Kind.CatalogueInfoUpdated,
        update,
        summaryData: summaryData.length > 0 ? summaryData : undefined,
      },
    ];
  }
  // private handleRelocateAsset({
  //   purchaseId,
  //   bottleIds,
  //   executerId,
  //   toLocation,
  // }: Command.RelocateAsset): Event[] {
  //   const currentPurchase = this.getCheckedPurchase(purchaseId);
  //   if (currentPurchase.bottles.length === 0)
  //     throw new InvalidInput("No bottles in purchase");

  //   const event: Event.BottleLocationUpdated = {
  //     executerId,
  //     kind: Event.Kind.BottleLocationUpdated,
  //     purchaseId,
  //     updates: currentPurchase.bottles
  //       .filter(
  //         (b) =>
  //           bottleIds.includes(b.bottleId) && b.status != WineStatus.Consumed
  //       )
  //       .map((b) => {
  //         return {
  //           ids: [b.bottleId],
  //           previous: b.location,
  //           current: {
  //             ...toLocation,
  //             [EncryptionFieldKey]: b.location[EncryptionFieldKey],
  //           },
  //         };
  //       }),
  //   };
  //   if (event.updates.length > 0) return [event];
  //   else return [];
  // }
  private handleRelocateBottle({
    executerId,
    purchaseId,
    ids,
    wine,
    location,
    fromLocationId,
  }: Command.RelocateBottle): Event[] {
    LocationInfo.validateEncryptedObj(location);
    const currentPurchase = this.getCheckedPurchase(purchaseId);
    const updates: Event.BottleLocationUpdated["updates"] = [];
    ids.forEach((id) => {
      const currentBottle = currentPurchase.bottles.find(
        (v) => v.bottleId === id
      );
      if (!currentBottle) {
        throw new InvalidInput("Bottle id not found");
      }

      if (
        fromLocationId &&
        fromLocationId !== currentBottle.location.locationId
      )
        return;
      if (LocationInfo.equal(location, currentBottle.location)) return;

      const maybeSameUpdateIdx = updates.findIndex(
        (v) =>
          LocationInfo.optionalEqual(v.previous, currentBottle.location) &&
          LocationInfo.equal(v.current!, location)
      );
      if (maybeSameUpdateIdx !== -1) {
        updates[maybeSameUpdateIdx].ids.push(id);
      } else {
        updates.push({
          ids: [id],
          previous: currentBottle.location,
          current: location,
        });
      }
    });

    if (updates.length == 0) {
      throw new InvalidInput("No bottle location updated");
    }
    return [
      {
        executerId,
        kind: Event.Kind.BottleLocationUpdated,
        purchaseId,
        updates,
        logInfo: {
          vintage: wine.vintage,
          wineName: wine.name,
        },
      },
    ];
  }
  private handleRemoveBottle({
    executerId,
    removal,
    purchaseId,
    ids,
    wine,
  }: Command.RemoveBottle): Event[] {
    Removal.validate(removal);
    const currentPurchase = this.getCheckedPurchase(purchaseId);
    const maybeCurrentBottle = currentPurchase.bottles.find((v) =>
      ids.includes(v.bottleId)
    );
    if (maybeCurrentBottle == undefined)
      throw new InvalidInput("Bottle id not found");

    const purchase = wine.purchases.find((v) => v.id === purchaseId);
    if (!purchase) throw new InvalidInput("Purchase id not found");

    const tags = toTagPair({ ...this.state.wine, ...currentPurchase });
    const prevOwnedValue = calculateOwnedValue(
      currentPurchase.netWorth,
      currentPurchase.ownership?.myOwnership
    );
    const updatedNetWorth: Amount = {
      currency: currentPurchase.netWorth.currency,
      value: subDecimal(
        currentPurchase.netWorth.value,
        mulDecimal(ids.length, currentPurchase.valuePerBottle.value)
      ),
    };
    const currOwnedValue = calculateOwnedValue(
      updatedNetWorth,
      currentPurchase.ownership?.myOwnership
    );
    return [
      {
        executerId,
        kind: Event.Kind.BottleRemoved,
        purchaseId,
        ids,
        removal,
        logInfo: {
          vintage: wine.vintage,
          wineName: wine.name,
          valuePerBottle: purchase.valuePerBottle,
        },
        summaryData: [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
            currOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: currentPurchase.bottleCount.bottles,
            currItemNumber: currentPurchase.bottleCount.bottles - ids.length,
            prevTags: deepCopy(tags),
            currTags: deepCopy(tags),
          },
        ],
      },
      {
        executerId,
        kind: Event.Kind.ValueUpdated,
        purchaseId,
        previous: {
          netWorth: currentPurchase.netWorth,
        },
        current: {
          netWorth: updatedNetWorth,
        },
        summaryData: [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
            currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: currentPurchase.bottleCount.bottles - ids.length,
            currItemNumber: currentPurchase.bottleCount.bottles - ids.length,
            prevTags: deepCopy(tags),
            currTags: deepCopy(tags),
          },
        ],
      },
      //#HACK This event will keep the location info on the removed bottles to ensure that we can still obtain the
      // - location info when adding bottles during a purchase update.
      // - However, remove the location from the relation so that we can calculate the correct number of bottles.
      {
        executerId,
        kind: Event.Kind.BottleLocationUpdated,
        purchaseId,
        updates: [
          {
            ids,
            previous: maybeCurrentBottle.location,
            current: null,
          },
        ],
        logInfo: {
          vintage: wine.vintage,
          wineName: wine.name,
        },
      },
    ];
  }
  private handleSetWineDeleting({
    executerId,
  }: Command.SetWineDeleting): Event[] {
    if (this.state.wine.isDeleting)
      throw new InvalidInput("Wine is already deleting");
    return [
      {
        executerId,
        kind: Event.Kind.WineDeleting,
      },
    ];
  }
  private handleDeleteWine(command: Command.DeleteWine): Event[] {
    // const events: Event[] = [];
    // if (!this.state.wine.isDeleting) {
    //   events.push({
    //     executerId: command.executerId,
    //     kind: Event.Kind.WineDeleting,
    //   });
    // }
    // for (let i = 0; i < this.state.wine.purchases.length; i++) {
    //   if (i >= 5) break;
    //   events.push({
    //     executerId: command.executerId,
    //     kind: Event.Kind.PurchaseDeleted,
    //     ids: [this.state.wine.purchases[i].id],
    //   });
    // }
    // return events;
    const summaryDataMap: { [label: string]: SummaryData } = {};
    Object.values(this.state.wine.purchases).forEach((purchase) => {
      groupSummaryDataByBottleSize(summaryDataMap, purchase, this.state.wine);
    });
    const summaryData = Object.values(summaryDataMap);
    return [
      {
        executerId: command.executerId,
        kind: Event.Kind.WineDeleted,
        summaryData: summaryData.length > 0 ? summaryData : undefined,
      },
    ];
  }

  private applyPurchaseAdded(event: Event.PurchaseAdded, time: Date): void {
    this.state.purchases[event.data.id] = event.data;

    if (!WineCatalogueMinInfo.equal(this.state.wine, event.catalogueInfo)) {
      this.state.wine = Wine.updateCatalogueInfo(
        this.state.wine,
        event.catalogueInfo
      );
      this.state.wine.updateAt = time;
    }
    this.state.wine.purchases.push(
      WinePurchase.toMin(this.state.purchases[event.data.id]!)
    );
    if (event.personalRefNo) {
      this.state.wine.personalRefNo = event.personalRefNo;
    }
    if (event.attachments) {
      this.state.wine.attachments = event.attachments;
    }
    if (event.mainImage) {
      this.state.wine.mainImage = event.mainImage;
    }
    this.state.wine.value = Wine.calculateValuePurchaseFromNetWorth(
      this.state.wine
    );
    this.relatedUpdates.shouldUpdateWine = true;
  }

  private applyPurchaseUpdated({
    id,
    update,
    personalRefNo,
    attachments,
    mainImage,
  }: Event.PurchaseUpdated): void {
    const currentPurchase = this.getCheckedPurchase(id);
    const { status, location, addBottles, valuePerBottle: _, ...rest } = update;
    applyUpdateToObject(currentPurchase, rest);

    if (currentPurchase.bottles.length == 0)
      throw new DataPoisoned("No bottles in purchase");
    if (status || location) {
      currentPurchase.bottles.forEach((bottle) => {
        if (bottle.status == WineStatus.Consumed) return;
        if (status) bottle.status = status;
        if (location) bottle.location = location;
      });
    }
    if (addBottles) {
      currentPurchase.bottles = currentPurchase.bottles.concat(addBottles);
    }

    const { netWorth, bottleCount } =
      WinePurchase.generateNetWorthAndBottleCount(
        currentPurchase.bottles,
        currentPurchase.valuePerBottle
      );
    currentPurchase.netWorth = netWorth;
    currentPurchase.bottleCount = bottleCount;

    const purchaseIdx = this.state.wine.purchases.findIndex(
      (v) => v.id === currentPurchase.id
    );
    if (purchaseIdx === -1) throw new DataPoisoned("Purchase not found");
    this.state.wine.purchases[purchaseIdx] =
      WinePurchase.toMin(currentPurchase);

    if (personalRefNo !== undefined) {
      if (personalRefNo === null) {
        delete this.state.wine.personalRefNo;
      } else {
        this.state.wine.personalRefNo = personalRefNo;
      }
    }
    if (attachments !== undefined) {
      if (attachments === null) {
        delete this.state.wine.attachments;
      } else {
        this.state.wine.attachments = attachments;
      }
    }
    if (mainImage !== undefined) {
      if (mainImage === null) {
        delete this.state.wine.mainImage;
      } else {
        this.state.wine.mainImage = mainImage;
      }
    }
    this.state.wine.value = Wine.calculateValuePurchaseFromNetWorth(
      this.state.wine
    );
    this.relatedUpdates.shouldUpdateWine = true;
  }

  private applyBottleLocationUpdated(event: Event.BottleLocationUpdated): void {
    const currentPurchase = this.getCheckedPurchase(event.purchaseId);
    event.updates.forEach((update) => {
      update.ids.forEach((id) => {
        const bottle = currentPurchase.bottles.find((v) => v.bottleId === id);
        if (!bottle) throw new InvalidInput("Bottle id not found");
        //#HACK removed bottles will still memorize the location
        if (update.current) bottle.location = update.current;
      });
    });
  }
}

export enum WineType {
  Beer = "Beer",
  Blue = "Blue",
  BlueSparkling = "BlueSparkling",
  Cider = "Cider",
  CiderSparkling = "CiderSparkling",
  CiderSweetDessert = "CiderSweetDessert",
  FruitVegetableWine = "FruitVegetableWine",
  NonAlcoholic = "NonAlcoholic",
  Orange = "Orange",
  OrangeSparkling = "OrangeSparkling",
  Red = "Red",
  RedFortified = "RedFortified",
  RedSparkling = "RedSparkling",
  RedSweetDessert = "RedSweetDessert",
  Rose = "Rose",
  RoseFortified = "RoseFortified",
  RoseSparkling = "RoseSparkling",
  RoseSweetDessert = "RoseSweetDessert",
  Sake = "Sake",
  Spirits = "Spirits",
  White = "White",
  WhiteFortified = "WhiteFortified",
  WhiteOffDry = "WhiteOffDry",
  WhiteSparkling = "WhiteSparkling",
  WhiteSweetDessert = "WhiteSweetDessert",
}

export const wineTypeValues = Object.values(WineType);

export enum BottleSize {
  "750ml" = "750ml",
  "375ml" = "375ml",
  "1_5L" = "1_5L",
  "3_0L" = "3_0L",
  "50ml" = "50ml",
  "100ml" = "100ml",
  "187ml" = "187ml",
  "200ml" = "200ml",
  "250ml" = "250ml",
  "275ml" = "275ml",
  "300ml" = "300ml",
  "330ml" = "330ml",
  "350ml" = "350ml",
  "500ml" = "500ml",
  "550ml" = "550ml",
  "620ml" = "620ml",
  "650ml" = "650ml",
  "700ml" = "700ml",
  "720ml" = "720ml",
  "1_0L" = "1_0L",
  "1_125L" = "1_125L",
  "1_4L" = "1_4L",
  "1_75L" = "1_75L",
  "1_88L" = "1_88L",
  "2_0L" = "2_0L",
  "2_25L" = "2_25L",
  "2_5L" = "2_5L",
  "3_78L" = "3_78L",
  "4_0L" = "4_0L",
  "4_5L" = "4_5L",
  "5_0L" = "5_0L",
  "6_0L" = "6_0L",
  "9_0L" = "9_0L",
  "12_0L" = "12_0L",
  "15_0L" = "15_0L",
  "16_0L" = "16_0L",
  "18_0L" = "18_0L",
  "27_0L" = "27_0L",
  "30_0L" = "30_0L",
  "6oz" = "6oz",
  "12oz" = "12oz",
  "16oz" = "16oz",
}

const BottleSizeString: Map<BottleSize, string> = (() => {
  const result = new Map();
  Object.values(BottleSize).forEach((v) => {
    result.set(v, v.replaceAll("_", "."));
  });
  return result;
})();

export function BottleSizeToString(size: BottleSize | string): string {
  return BottleSizeString.get(size as BottleSize) ?? size;
}

export const BottleSizeMl = {
  [BottleSize["750ml"]]: 750,
  [BottleSize["375ml"]]: 375,
  [BottleSize["1_5L"]]: 1500,
  [BottleSize["3_0L"]]: 3000,
  [BottleSize["50ml"]]: 50,
  [BottleSize["100ml"]]: 100,
  [BottleSize["187ml"]]: 187,
  [BottleSize["200ml"]]: 200,
  [BottleSize["250ml"]]: 250,
  [BottleSize["275ml"]]: 275,
  [BottleSize["300ml"]]: 300,
  [BottleSize["330ml"]]: 330,
  [BottleSize["350ml"]]: 350,
  [BottleSize["500ml"]]: 500,
  [BottleSize["550ml"]]: 550,
  [BottleSize["620ml"]]: 620,
  [BottleSize["650ml"]]: 650,
  [BottleSize["700ml"]]: 700,
  [BottleSize["720ml"]]: 720,
  [BottleSize["1_0L"]]: 1000,
  [BottleSize["1_125L"]]: 1125,
  [BottleSize["1_4L"]]: 1400,
  [BottleSize["1_75L"]]: 1750,
  [BottleSize["1_88L"]]: 1880,
  [BottleSize["2_0L"]]: 2000,
  [BottleSize["2_25L"]]: 2250,
  [BottleSize["2_5L"]]: 2500,
  [BottleSize["3_78L"]]: 3780,
  [BottleSize["4_0L"]]: 4000,
  [BottleSize["4_5L"]]: 4500,
  [BottleSize["5_0L"]]: 5000,
  [BottleSize["6_0L"]]: 6000,
  [BottleSize["9_0L"]]: 9000,
  [BottleSize["12_0L"]]: 12000,
  [BottleSize["15_0L"]]: 15000,
  [BottleSize["16_0L"]]: 16000,
  [BottleSize["18_0L"]]: 18000,
  [BottleSize["27_0L"]]: 27000,
  [BottleSize["30_0L"]]: 30000,
  [BottleSize["6oz"]]: 177.441,
  [BottleSize["12oz"]]: 354.882,
  [BottleSize["16oz"]]: 473.176,
};

export enum WinePricingMethod {
  Lot = "Lot",
  Bottle = "Bottle",
}

export const winePricingMethodValues = Object.values(WinePricingMethod);

export enum WineStatus {
  Pending = "Pending",
  Delivered = "Delivered",
  InMyCellar = "InMyCellar",
  Consumed = "Consumed",
}

export const wineStatusValues = Object.values(WineStatus);

export enum RemovalReason {
  BroughtToTasting = "RemovalReasonBroughtToTasting",
  ConsumedByFamilyMember = "RemovalReasonConsumedByFamilyMember",
  DeleteAndExclude = "RemovalReasonDeleteAndExclude",
  Donated = "RemovalReasonDonated",
  DrankFromFriendsCellar = "RemovalReasonDrankFromFriendsCellar",
  DrankFromMyCellar = "RemovalReasonDrankFromYourCellar",
  DroppedOrBroke = "RemovalReasonDroppedOrBroke",
  GaveAwayAsGift = "RemovalReasonGaveAwayAsGift",
  HadTaste = "RemovalReasonHadTaste",
  MissingPresumedDrunk = "RemovalReasonMissingPresumedDrunk",
  RestaurantPurchase = "RemovalReasonRestaurantPurchase",
  SoldOrTraded = "RemovalReasonSoldOrTraded",
  SpoiledButNotReturned = "RemovalReasonSpoiledButNotReturned",
  SpoiledAndReturned = "RemovalReasonSpoiledAndReturned",
  UsedForCooking = "RemovalReasonUsedForCooking",
}

export enum WineReportFilter {
  IndividualWine = "IndividualWine",
  BottleSize = "BottleSize",
  Colour = "Colour", //subtype
  Country = "Country",
  MasterVariety = "MasterVariety",
  Producer = "Producer",
  Vintage = "Vintage",
  Type = "Type",
}

export type WineForReport = OmitKeys<
  WineCatalogueMinInfo,
  "catalogueImage" | "variety" | "masterVarietal"
> & {
  labeledVariety: string;
  value: Amount;
  id: string;
  purchases: {
    [purchaseId: string]: OmitKeys<WinePurchase.Min, "valuePerBottle">;
  };
};
export namespace WineForReport {
  export function fromWine(
    from: Wine.Encrypted,
    value: Amount,
    exRate: ExchangeRate
  ): WineForReport {
    return {
      id: from.id,
      wineId: from.wineId,
      value,
      purchases: Object.fromEntries(
        from.purchases.map((v) => {
          const { valuePerBottle, ...rest } = v;
          rest.netWorth.value = new Decimal(rest.netWorth.value)
            .mul(
              exRate.getToBaseExchangeRate(rest.netWorth.currency as Currency)
                .rate
            )
            .toDecimalPlaces(AllowedDecimalPlaces)
            .toNumber();
          rest.netWorth.currency = exRate.BaseCurrency! as Currency;
          rest.totalCost.value = new Decimal(rest.totalCost.value)
            .mul(
              exRate.getToBaseExchangeRate(rest.totalCost.currency as Currency)
                .rate
            )
            .toDecimalPlaces(AllowedDecimalPlaces)
            .toNumber();
          rest.totalCost.currency = exRate.BaseCurrency! as Currency;
          return [v.id, rest];
        })
      ),
      //catalogue
      name: from.name,
      subtype: from.subtype,

      producer: from.producer,
      vintage: from.vintage,

      country: from.country,
      labeledVariety: from.labeledVariety,
    };
  }
}

interface PurchaseRef {
  wineDocId: string;
  purchaseId: string;
}

export interface WineReport {
  groupBy: WineReportFilter[];
  data: WineReportNode[];
}

export interface WineReportNode {
  label: string;
  bottles: number;
  value: Amount;
  price: Amount;
  percentage: number;
  refs: PurchaseRef[]; //data above is calculated from these refs
  children: WineReportNode[];
}

export class WineReportBuilder {
  wines: { [wineId: string]: WineForReport } = {};
  builderTree: WineReportNode;
  currentReportTree: WineReport;
  baseCurrency: Currency;
  layeredRefs: WineReportNode[][] = [];
  currentLayer = -1;
  totalBottles: number;

  constructor(wines: WineForReport[], baseCurrency: Currency) {
    this.baseCurrency = baseCurrency;
    this.totalBottles = 0;
    wines.forEach((w) => {
      this.wines[w.id] = w;
      this.totalBottles = addDecimal(
        Object.values(w.purchases).reduce((sum, v) => {
          return sum + v.bottleCount.bottles;
        }, 0),
        this.totalBottles
      );
    });

    const refs: PurchaseRef[] = [];
    wines.forEach((w) => {
      Object.values(w.purchases).forEach((p) => {
        refs.push({ wineDocId: w.id, purchaseId: p.id });
      });
    });

    this.builderTree = this.summarize(refs);
    this.currentReportTree = {
      groupBy: [],
      data: [],
    };
  }

  groupBy(filter: WineReportFilter) {
    if (this.currentReportTree.groupBy.indexOf(filter) == -1) {
      if (this.currentLayer == -1) {
        this.summarize(this.builderTree.refs, filter, this.builderTree);
        this.layeredRefs.push(this.builderTree.children);
        this.currentReportTree.data = this.builderTree.children;
      } else {
        const currentLayerRefs = this.layeredRefs[this.currentLayer];
        const nextLayerRefs: WineReportNode[] = [];
        currentLayerRefs.forEach((node) => {
          this.summarize(node.refs, filter, node);
          node.children.forEach((n) => nextLayerRefs.push(n));
        });
        this.layeredRefs.push(nextLayerRefs);
      }
      this.currentReportTree.groupBy.push(filter);
      this.currentLayer++;
    }
    return this.currentReportTree;
  }

  removeLatestFilter() {
    if (this.currentLayer == -1) return this.currentReportTree;
    if (this.currentLayer == 0) {
      this.builderTree.children = [];
      this.currentReportTree.data = [];
    } else {
      const previousLayerRefs = this.layeredRefs[this.currentLayer - 1];
      previousLayerRefs.forEach((node, idx, arr) => {
        arr[idx].children = [];
      });
    }

    this.currentReportTree.groupBy.pop();
    this.layeredRefs.pop();
    this.currentLayer--;
    return this.currentReportTree;
  }

  groupByMultiFilter(...filters: WineReportFilter[]) {
    filters.forEach((f) => {
      this.groupBy(f);
    });
    return this.currentReportTree;
  }

  private summarize(
    refs: PurchaseRef[],
    filter?: WineReportFilter,
    currentNode?: WineReportNode
  ): WineReportNode {
    const result: WineReportNode = currentNode
      ? currentNode
      : {
          label: "root",
          bottles: 0,
          value: {
            currency: this.baseCurrency,
            value: 0,
          },
          price: {
            currency: this.baseCurrency,
            value: 0,
          },
          percentage: 100,
          refs,
          children: [],
        };

    const newChildren: { [idx: string]: WineReportNode } = {};
    refs.forEach((v) => {
      const wine = this.wines[v.wineDocId];
      const purchase = wine.purchases[v.purchaseId];

      if (filter) {
        let nodeKey;
        let label = "";
        switch (filter) {
          case WineReportFilter.IndividualWine:
            nodeKey = encodeURI(v.wineDocId);
            label = wine.name;
            break;
          case WineReportFilter.BottleSize:
            nodeKey = encodeURI(purchase.bottleSize);
            label = BottleSizeToString(purchase.bottleSize);
            break;
          case WineReportFilter.Type:
          case WineReportFilter.Colour:
            nodeKey = encodeURI(wine.subtype);
            label = wine.subtype;
            break;
          case WineReportFilter.Country: {
            const country = wine.country ?? "-";
            nodeKey = encodeURI(country);
            label = country;
            break;
          }
          case WineReportFilter.MasterVariety: {
            const variety = wine.labeledVariety!;
            nodeKey = encodeURI(variety);
            label = variety;
            break;
          }
          case WineReportFilter.Producer:
            nodeKey = encodeURI(wine.producer);
            label = wine.producer;
            break;
          case WineReportFilter.Vintage: {
            const vintage = wine.vintage.toString();
            nodeKey = encodeURI(vintage);
            label = vintage;
            break;
          }
        }
        if (newChildren[nodeKey]) {
          const node = newChildren[nodeKey];
          node.bottles += purchase.bottleCount.bottles;
          node.value.value = new Decimal(purchase.netWorth.value)
            .add(node.value.value)
            .toNumber();
          node.price.value = addDecimal(
            node.price.value,
            purchase.totalCost.value
          );
          node.refs.push(v);
        } else {
          const node = {
            label,
            bottles: purchase.bottleCount.bottles,
            value: { ...purchase.netWorth },
            price: { ...purchase.totalCost },
            percentage: 0, //calculate later
            refs: [v],
            children: [],
          };
          newChildren[nodeKey] = node;
        }
      } else {
        result.bottles += purchase.bottleCount.bottles;
        result.value.value = new Decimal(purchase.netWorth.value)
          .add(result.value.value)
          .toNumber();
        result.price.value = addDecimal(
          result.price.value,
          purchase.totalCost.value
        );
      }
    });

    result.children = Object.values(newChildren)
      .map((node) => {
        node.bottles;
        node.percentage = calculatePercentage(node.bottles, this.totalBottles);
        return node;
      })
      .sort((a, b) => {
        if (a.percentage == b.percentage) {
          return a.label.localeCompare(b.label);
        }
        return b.percentage - a.percentage;
      });
    return result;
  }
}

export class WineStateWriter
  implements IAggregateStateWriter<WineAggregateState, Command, Event>
{
  transaction!: Transaction;
  wineCollectionRef: CollectionReference<Wine.Encrypted>;
  purchasesCollectionRef: CollectionReference<WinePurchase.Encrypted>;
  relationCollectionRef: CollectionReference<RelationsOfAsset>;

  constructor(
    wineRef: CollectionReference<Wine.Encrypted>,
    purchasesCollectionRef: CollectionReference<WinePurchase.Encrypted>,
    relationRef: CollectionReference<RelationsOfAsset>
  ) {
    this.wineCollectionRef = wineRef;
    this.purchasesCollectionRef = purchasesCollectionRef;
    this.relationCollectionRef = relationRef;
  }

  setStateTx(transaction: Transaction, aggregate: WineAggregate): void {
    const wineDocRef = CoreFirestore.docFromCollection(
      this.wineCollectionRef,
      aggregate.state.wine.id
    );
    if (stateIsDeleted(aggregate.state.wine)) {
      this.deleteStateTx(transaction, aggregate, wineDocRef);
    } else {
      if (aggregate.relatedUpdates.shouldUpdateWine)
        transaction.set(wineDocRef, aggregate.state.wine);
      Object.entries(aggregate.state.purchases).forEach(([id, purchase]) => {
        if (purchase) {
          transaction.set(
            CoreFirestore.docFromCollection(this.purchasesCollectionRef, id),
            purchase
          );
          transaction.set(
            CoreFirestore.docFromCollection(this.relationCollectionRef, id),
            buildWineRelation(purchase)
          );
        }
      });
    }
    if (aggregate.relatedUpdates.purchaseIdsToRemove) {
      aggregate.relatedUpdates.purchaseIdsToRemove.forEach((id) => {
        transaction.delete(
          CoreFirestore.docFromCollection(this.purchasesCollectionRef, id)
        );
        transaction.delete(
          CoreFirestore.docFromCollection(this.relationCollectionRef, id)
        );
      });
    }
    aggregate.relatedUpdates = {
      shouldUpdateWine: false,
    };
  }

  deleteStateTx(
    transaction: Transaction,
    aggregate: WineAggregate,
    wineDocRef: DocumentReference<Wine.Encrypted>
  ): void {
    transaction.delete(wineDocRef);
    aggregate.relatedUpdates.purchaseIdsToRemove?.forEach((id) => {
      transaction.delete(
        CoreFirestore.docFromCollection(this.purchasesCollectionRef, id)
      );
      transaction.delete(
        CoreFirestore.docFromCollection(this.relationCollectionRef, id)
      );
    });
  }
}
