import * as React from 'react'
import Head from 'next/head'

import { useAuthStore } from '@/store/authStore'

export interface StaticImageProps extends React.ComponentPropsWithoutRef<'img'> {
  fill?: boolean
}

export function StaticImage({
  src,
  draggable = false,
  decoding = 'async',
  sizes,
  srcSet,
  fill = false,
  style,
  ...props
}: StaticImageProps) {
  return (
    <>
      <ImagePreload srcSet={srcSet} sizes={sizes} src={src} fetchPriority={'high'} />
      <ImageElement
        fetchPriority={'high'}
        loading={'eager'}
        decoding={decoding}
        srcSet={srcSet}
        sizes={sizes}
        src={src}
        draggable={draggable}
        style={getImageStyle(fill, style)}
        {...props}
      />
    </>
  )
}

export interface ImageProps extends React.ComponentPropsWithoutRef<'img'> {
  base64?: string
  placeholder?: string
  fill?: boolean
}

export function Image({
  src,
  base64,
  draggable = false,
  decoding = 'async',
  placeholder,
  sizes,
  srcSet,
  fill = false,
  style,
  ...props
}: ImageProps) {
  const decryptURL = useDecryptURL(src, base64)
  const isImageLoaded = decryptURL !== null

  return (
    <>
      {placeholder && <ImagePreload srcSet={srcSet} sizes={sizes} src={placeholder} fetchPriority={'high'} />}
      <ImageElement
        fetchPriority={isImageLoaded ? 'auto' : 'high'}
        loading={isImageLoaded ? 'lazy' : 'eager'}
        decoding={decoding}
        srcSet={isImageLoaded ? undefined : srcSet}
        sizes={isImageLoaded ? undefined : sizes}
        src={isImageLoaded ? decryptURL : placeholder}
        draggable={draggable}
        style={getImageStyle(fill, style)}
        {...props}
      />
    </>
  )
}

interface ImageElementProps extends React.ComponentPropsWithoutRef<'img'> {}

function ImageElement({ fetchPriority, loading, decoding, srcSet, sizes, src, ...props }: ImageElementProps) {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
    <img
      fetchPriority={fetchPriority}
      loading={loading}
      decoding={decoding}
      srcSet={srcSet}
      sizes={sizes}
      src={src}
      {...props}
    />
  )
}

interface ImagePreloadProps {
  src?: string
  srcSet?: string
  sizes?: string
  fetchPriority?: 'auto' | 'high' | 'low'
}

function ImagePreload({ src, srcSet, sizes, fetchPriority }: ImagePreloadProps) {
  const props = {
    imageSrcSet: srcSet,
    imageSizes: sizes,
    fetchPriority
  }

  return (
    <Head>
      <link
        key={'__static-img-' + src + srcSet + sizes}
        rel={'preload'}
        as={'image'}
        href={srcSet ? undefined : src}
        {...props}
      />
    </Head>
  )
}

function getImageStyle(fill?: boolean, style?: React.CSSProperties) {
  const fillStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
  return Object.assign(fill ? fillStyle : {}, style)
}

function useDecryptURL(src?: string, base64?: string) {
  const [decryptedURL, setDecryptedURL] = React.useState<string | null>(null)

  React.useEffect(() => {
    const decrypt = async () => {
      try {
        if (!src) throw new Error('missing src!')
        if (!base64) throw new Error('missing base64!')
        const { database } = useAuthStore.getState()
        if (!database) throw new Error('database is not available!')
        const resp = await fetch(src)
        const encrypted = await resp.arrayBuffer()
        const iv = database.Encryption.current.convertBase64ToIVSalt(base64)
        const decrypted = await database.Encryption.current.decryptBytes(encrypted, iv)
        const blob = new Blob([decrypted], { type: 'image/jpeg' })
        setDecryptedURL((prev) => {
          if (prev) URL.revokeObjectURL(prev)
          return URL.createObjectURL(blob)
        })
      } catch (err) {
        if (err instanceof Error) {
          const message = err.message
          console.log('failed to decrypt image:', message)
        }
      }
    }

    decrypt()
  }, [src, base64])

  return decryptedURL
}
