import { AssetType } from 'core/remodel/types/enums'

export const placeholderImageMap = {
  /* Finance */
  [AssetType.CashAndBanking]: '/images/finances/finance.png',
  [AssetType.TraditionalInvestments]: '/images/finances/finance.png',
  [AssetType.OtherInvestment]: '/images/finances/finance.png',
  [AssetType.Cryptocurrency]: '/images/finances/coin.svg',
  [AssetType.Insurance]: '/images/finances/finance.png',
  /* Property */
  [AssetType.Property]: '/images/properties/property-40w.png',
  /* Collectable */
  [AssetType.Art]: '/images/collectables/art-40w.png',
  [AssetType.WineAndSpirits]: '/images/collectables/wine-40w.png',
  [AssetType.OtherCollectables]: '/images/collectables/other-40w.png',
  [AssetType.Belonging]: '/images/collectables/belonging-40w.png',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '/images/finances/finance.png',
  [AssetType.WinePurchases]: '/images/collectables/wine-40w.png'
}

export const placeholderImageSrcSetMap = {
  /* Finance */
  [AssetType.CashAndBanking]: undefined,
  [AssetType.TraditionalInvestments]: undefined,
  [AssetType.OtherInvestment]: undefined,
  [AssetType.Cryptocurrency]: undefined,
  [AssetType.Insurance]: undefined,
  /* Property */
  [AssetType.Property]: `
    /images/properties/property-40w.png 40w,
    /images/properties/property-98px.png 98w,
    /images/properties/property-128px.png 128w,
    /images/properties/property-190w.png 190w,
    /images/properties/property-200w.png 200w
  `,
  /* Collectable */
  [AssetType.Art]: `
    /images/collectables/art-40w.png 40w,
    /images/collectables/art-98px.png 98w,
    /images/collectables/art-128px.png 128w,
    /images/collectables/art-190w.png 190w,
    /images/collectables/art-200w.png 200w
  `,
  [AssetType.WineAndSpirits]: `
    /images/collectables/wine-40w.png 40w,
    /images/collectables/wine-98px.png 98w,
    /images/collectables/wine-128px.png 128w,
    /images/collectables/wine-190w.png 190w,
    /images/collectables/wine-200w.png 200w
  `,
  [AssetType.OtherCollectables]: `
    /images/collectables/other-40w.png 40w,
    /images/collectables/other-98px.png 98w,
    /images/collectables/other-128px.png 128w,
    /images/collectables/other-190w.png 190w,
    /images/collectables/other-200w.png 200w
  `,
  [AssetType.Belonging]: `
    /images/collectables/belonging-40w.png 40w,
    /images/collectables/belonging-98px.png 98w,
    /images/collectables/belonging-128px.png 128w,
    /images/collectables/belonging-190w.png 190w,
    /images/collectables/belonging-200w.png 200w
  `,
  /* Will not be used */
  [AssetType.BankOrInstitution]: undefined,
  [AssetType.WinePurchases]: undefined
}

export const placeholderImageSizes = '(max-width: 400px) 98px, (max-width: 600px) 128px, 190px'

export const infoPathnameMap = {
  /* Finance */
  [AssetType.CashAndBanking]: '/finances/accounts/info/',
  [AssetType.TraditionalInvestments]: '/finances/tradit-invest/info/',
  [AssetType.OtherInvestment]: '/finances/other-invest/info/',
  [AssetType.Cryptocurrency]: '/finances/crypto/',
  [AssetType.Insurance]: '/finances/insurance/info/',
  /* Property */
  [AssetType.Property]: '/properties/summary/info/',
  /* Collectable */
  [AssetType.Art]: '/collectables/art/info/',
  [AssetType.WineAndSpirits]: '/collectables/wine/info/',
  [AssetType.OtherCollectables]: '/collectables/other/info/',
  [AssetType.Belonging]: '/belongings/summary/info/',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '/finances/summary/',
  [AssetType.WinePurchases]: '/collectables/wine/'
}

export const listPathnameMap = {
  /* Finance */
  [AssetType.CashAndBanking]: '/finances/accounts/',
  [AssetType.TraditionalInvestments]: '/finances/tradit-invest/',
  [AssetType.OtherInvestment]: '/finances/other-invest/',
  [AssetType.Cryptocurrency]: '/finances/crypto/',
  [AssetType.Insurance]: '/finances/insurance/',
  /* Property */
  [AssetType.Property]: '/properties/summary/list/',
  /* Collectable */
  [AssetType.Art]: '/collectables/art/list/',
  [AssetType.WineAndSpirits]: '/collectables/wine/list/',
  [AssetType.OtherCollectables]: '/collectables/other/list/',
  [AssetType.Belonging]: '/belongings/summary/list/',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '/finances/summary/',
  [AssetType.WinePurchases]: '/collectables/wine/'
}

export const subtypeOptionMap = {
  /* Finance */
  [AssetType.CashAndBanking]: 'finances:AccountType.',
  [AssetType.TraditionalInvestments]: '',
  [AssetType.OtherInvestment]: 'finances:InvestmentTypeOptions.',
  [AssetType.Cryptocurrency]: '',
  [AssetType.Insurance]: 'finances:InsuranceSubType.',
  /* Property */
  [AssetType.Property]: 'properties:PropertyTypeOptions.',
  /* Collectable */
  [AssetType.Art]: 'collectables:ArtTypeOptions.',
  [AssetType.WineAndSpirits]: 'collectables:WineTypeOptions.',
  [AssetType.OtherCollectables]: 'collectables:OtherCollectableTypeOptions.',
  [AssetType.Belonging]: 'collectables:BelongingTypeOptions.',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '',
  [AssetType.WinePurchases]: ''
}
