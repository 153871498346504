import { Amount, PathsOfDateField } from "../common";
import { OmitKeys, addDecimal } from "../../utils";
import { Account, AccountSource, Event } from "../cashAndBanking";
import { ErrorDataOutDated, DataPoisoned } from "../error";
import { CoreFirestore, WithFieldValue } from "../../../coreFirebase";
import {
  InstitutionTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "../typeVersion";

//#NOTE ask the global summary thing to sync and get valuation/units and updateAt
export interface Institution {
  "@type": VersionedTypeString<VersionedType.Institution, 2>;
  id: string;
  name: string;
  ownerId: string;
  extSource?: AccountSource;
  queryKey: string;

  accounts: {
    [id: string]: AccountMin;
  };

  createAt: Date;
  updateAt: Date;
  plaidDocId?: string;
}
export interface AccountMin {
  name: string;
  id: string;
  subtype: Account.Type;
  subAccounts: SubAccount[];
  closed: boolean;
  ownedPercentage: number;
  updateAt: Date;
  extId?: string;
  extSource?: AccountSource;
}

export interface SubAccount {
  //#NOTE if the account doesn't have subAccount layer it'll be the same as account id
  id: string;
  balance: Amount;
  isDefault?: boolean;
}
namespace SubAccount {
  export type Update = OmitKeys<SubAccount, "id">;
}

export namespace Institution {
  export function assureVersion(
    input: Institution,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      InstitutionTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Institution);
  }

  export const datePaths: readonly PathsOfDateField<Institution>[] = [
    "createAt",
    "updateAt",
  ] as const;
  export function convertDate(input: Institution): Institution {
    CoreFirestore.convertDateFieldsFromFirestore(input, datePaths);
    Object.values(input.accounts).map((account) => {
      CoreFirestore.convertDateFieldsFromFirestore(account, ["updateAt"]);
    });
    return input;
  }

  export function newValue(
    id: string,
    name: string,
    ownerId: string,
    plaidDocId?: string
  ): Institution {
    const data: WithFieldValue<Institution> = {
      "@type": InstitutionTypeVersion,
      name,
      id,
      ownerId,

      queryKey: buildQueryKey(ownerId, name),

      accounts: {},

      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      plaidDocId,
    };
    return data as Institution;
  }

  export function buildQueryKey(userId: string, name: string): string {
    return `${userId}/${name}`;
  }

  export function updateValue(
    account: AccountMin,
    updates: Event.SubAccountUpdate[]
  ) {
    updates.forEach(({ id, value }) => {
      const subAccount = account.subAccounts.find((s) => s.id === id);
      if (!subAccount) {
        throw new DataPoisoned("Sub account not found");
      }
      subAccount.balance.value = addDecimal(
        subAccount.balance.value,
        value.value
      );
    });
  }
}
