import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2035%2034%22%3E%3Cpath%20d%3D%22M34.38.968c-.27-.626-.736-.94-1.402-.94H2.588c-.665%200-1.132.314-1.4.94-.27.66-.159%201.222.332%201.688l11.705%2011.888v11.718c0%20.419.15.78.451%201.086l6.078%206.172c.285.305.641.459%201.069.459.19%200%20.387-.04.593-.121.617-.274.926-.748.926-1.423V14.544L34.047%202.656c.491-.466.602-1.028.333-1.688Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FilterIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 35 34'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M34.38.968c-.27-.626-.736-.94-1.402-.94H2.588c-.665 0-1.132.314-1.4.94-.27.66-.159 1.222.332 1.688l11.705 11.888v11.718c0 .419.15.78.451 1.086l6.078 6.172c.285.305.641.459 1.069.459.19 0 .387-.04.593-.121.617-.274.926-.748.926-1.423V14.544L34.047 2.656c.491-.466.602-1.028.333-1.688Z'
        }
      />
    </svg>
  )
}
