import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20viewBox%3D%22-4%20-3%2020%2022%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M.168.182A.545.545%200%200%201%20.98.181l6.851%207.38A.647.647%200%200%201%208%208c0%20.165-.06.323-.169.44L.98%2015.819A.552.552%200%200%201%20.574%2016a.552.552%200%200%201-.406-.182.656.656%200%200%201%200-.877L6.614%208%20.17%201.06A.656.656%200%200%201%20.169.182Zm6%200A.545.545%200%200%201%206.98.181l6.851%207.38A.647.647%200%200%201%2014%208c0%20.165-.06.323-.169.44L6.98%2015.819a.552.552%200%200%201-.406.181.552.552%200%200%201-.406-.182.656.656%200%200%201%200-.877L12.614%208%206.17%201.06a.656.656%200%200%201-.001-.878Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function OneWayArrowsIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'-4 -3 20 22'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M.168.182A.545.545 0 0 1 .98.181l6.851 7.38A.647.647 0 0 1 8 8c0 .165-.06.323-.169.44L.98 15.819A.552.552 0 0 1 .574 16a.552.552 0 0 1-.406-.182.656.656 0 0 1 0-.877L6.614 8 .17 1.06A.656.656 0 0 1 .169.182Zm6 0A.545.545 0 0 1 6.98.181l6.851 7.38A.647.647 0 0 1 14 8c0 .165-.06.323-.169.44L6.98 15.819a.552.552 0 0 1-.406.181.552.552 0 0 1-.406-.182.656.656 0 0 1 0-.877L12.614 8 6.17 1.06a.656.656 0 0 1-.001-.878Z'
        }
      />
    </svg>
  )
}
