import { Loader2Icon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { Button, Modal, Progress } from '@/components/base'

interface ConfirmProps {
  title: string
  onCancel: () => void
  cancelLabel: string
  onConfirm: () => void
  confirmLabel: string
  content?: string
  disabled?: boolean
  percentage?: number
  progressContent?: string
  additionalContent?: JSX.Element
}

export default function Confirm({
  title,
  content,
  onCancel,
  cancelLabel,
  onConfirm,
  confirmLabel,
  disabled = false,
  percentage,
  progressContent,
  additionalContent
}: ConfirmProps) {
  const { t } = useTranslation()

  return (
    <Modal className={'max-w-md'} onBackdropClick={onCancel}>
      <Modal.Header>
        <label className={'text-sm font-medium uppercase text-primary'}>{title}</label>
        <Modal.CloseButton className={'text-primary'} onClose={onCancel} />
      </Modal.Header>
      <Modal.Content>
        {!isNaN(percentage!) ? (
          <div className={'flex h-52 flex-col items-center justify-center gap-3 p-4'}>
            <p>{progressContent ?? t('DeletingAssets')}</p>
            <Progress value={percentage} />
            <p>{`${percentage} %`}</p>
          </div>
        ) : (
          <div className={'space-y-4'}>
            {content && (
              <div className={'flex min-h-[50px] flex-col justify-center gap-4'}>
                <span className={'max-w-[416px] break-words text-sm text-gray-600'}>{content}</span>
              </div>
            )}

            {additionalContent}

            <fieldset className={'flex justify-end gap-2'} disabled={disabled}>
              <Button
                className={'min-w-[130px]'}
                variant={'outline'}
                size={'md'}
                onClick={onCancel}
                aria-label={'cancel button'}
                data-testid={'cancel-button'}
              >
                {cancelLabel ?? t('Cancel')}
              </Button>
              <Button
                className={'group relative min-w-[130px]'}
                variant={'solid'}
                size={'md'}
                onClick={onConfirm}
                aria-label={'confirm button'}
                data-testid={'confirm-button'}
              >
                {additionalContent ? (
                  <span>{confirmLabel}</span>
                ) : (
                  <>
                    <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                    <span className={'group-disabled:opacity-0'}>{confirmLabel ?? t('Create')}</span>
                  </>
                )}
              </Button>
            </fieldset>
          </div>
        )}
      </Modal.Content>
    </Modal>
  )
}

interface ConfirmSkeletonProps {
  title: string
  onCancel: () => void
}

export function ConfirmSkeleton({ title, onCancel }: ConfirmSkeletonProps) {
  return (
    <Modal className={'max-w-md'} onBackdropClick={onCancel}>
      <Modal.Header>
        <label className={'text-sm font-medium uppercase text-primary'}>{title}</label>
        <Modal.CloseButton className={'text-primary'} onClose={onCancel} />
      </Modal.Header>
      <Modal.Content className={'gap-4'}>
        <div className={'space-y-2'}>
          <div className={'h-4 w-1/3 animate-pulse rounded bg-grey/20'} />
          <div className={'h-4 animate-pulse rounded bg-grey/20'} />
          <div className={'h-4 w-2/3 animate-pulse rounded bg-grey/20'} />
        </div>
        <div className={'flex justify-end gap-2'}>
          <Button className={'h-9 w-[130px]'} variant={'outline'} size={'md'} disabled={true}>
            <div className={'h-2 w-full animate-pulse rounded bg-grey/40'} />
          </Button>
          <Button className={'h-9 w-[130px]'} variant={'solid'} size={'md'} disabled={true}>
            <div className={'h-2 w-full animate-pulse rounded bg-grey/40'} />
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}
