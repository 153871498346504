import {
  Amount,
  AssetV2,
  Beneficiary,
  PathsOfDateField,
  Owner,
  Ownership,
  compareGroupUpdate,
  Optional,
  AssetType,
  LocationType,
  Attachment,
  AssetV2WithCollectableAttributes,
  PathsOfAmountField,
  SupportActivityType,
  MultiCurrencyAmount,
} from "./common";
import {
  EncryptedType,
  EncryptionField,
  EncryptionFieldKey,
  RequireEncryptionFields,
  removeEncryptionFields,
  EncryptionFieldDefaultValue,
  fullObjectEncryption,
  doRemoveEncryptedFields,
  IVSaltFieldKey,
  IVSaltFieldDefaultValue,
} from "../encryption/utils";
import {
  AggregateBase,
  AggregateRoot,
  RepoAndAggregates,
  SupportActionAggregate,
  SupportSellAggregate,
  SupportValuationAggregate,
  handleAddInsurance,
  handleRemoveInsurance,
  setObjectDeleted,
} from "./aggregate";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  applyUpdateToObject,
  buildObjectUpdate,
  validateValueInEnum,
  optionalDateEqual,
  validateStringNotEmpty,
  deepCopy,
  calculateOwnedValue,
} from "../utils";
import { ErrorDataOutDated, InvalidInput } from "./error";
import {
  ActionEvent,
  EventWithTime,
  preSealEvent,
  SellEvent,
  SharedEvent,
  TagPair,
  ValuationEvent,
} from "./event";
import { Valuation } from "./actions/valuation";
import {
  ActionCommand,
  SellCommand,
  SharedCommand,
  ValuationCommand,
} from "./command";
import { Offer } from "./actions/offer";
import { Consignment } from "./actions/consignment";
import { Exhibition } from "./actions/exhibition";
import { Literature } from "./actions/literature";
import { SoldInfo } from "./actions/soldInfo";
import { Encryption } from "../database/encryption";
import {
  EventToActivitiesFunction,
  validateActMapFuncKnowEventKind,
} from "./activities";
import { CoreFirestore, WithFieldValue } from "../../coreFirebase";
import {
  ArtTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";
import { genActivityLogs } from "./activityUtils";
import { CollectableAcquisition } from "./common/acquisition";
import { LocationInfo } from "./relations/locationInfo";
import { ExcludeStatus, SummaryUtils, SummaryTag } from "./summary";

export enum ArtType {
  Architecture = "Architecture",
  BasRelief = "BasRelief",
  Books = "Books",
  Bust = "Bust",
  Calligraphy = "Calligraphy",
  Ceramics = "Ceramics",
  Collage = "Collage",
  Crafts = "Crafts",
  DecorativeArt = "DecorativeArt",
  Design = "Design",
  DigitalArts = "DigitalArts",
  Drawing = "Drawing",
  Etching = "Etching",
  Ethnographic = "Ethnographic",
  Filmmaking = "Filmmaking",
  Fresco = "Fresco",
  Glass = "Glass",
  Graffiti = "Graffiti",
  HangingScroll = "HangingScroll",
  Installation = "Installation",
  JewelryArt = "JewelryArt",
  JunkArt = "JunkArt",
  Metalwork = "Metalwork",
  MixedMedia = "MixedMedia",
  Mosaic = "Mosaic",
  Mural = "Mural",
  Other = "Other",
  Painting = "Painting",
  Performance = "Performance",
  Photography = "Photography",
  PosterArt = "PosterArt",
  Print = "Print",
  Printmaking = "Printmaking",
  RockArt = "RockArt",
  RugsAndCarpets = "RugsAndCarpets",
  SandArt = "SandArt",
  ScreenPrinting = "ScreenPrinting",
  Sculptures = "Sculptures",
  Statue = "Statue",
  TapestryArt = "TapestryArt",
  Textiles = "Textiles",
  Vase = "Vase",
  VideoArt = "VideoArt",
  WorkOnPaper = "WorkOnPaper",
}

export const artTypeValues = Object.values(ArtType);

export enum ArtStyle {
  "AbstractExpressionism" = "AbstractExpressionism",
  "AbstractImpressionism" = "AbstractImpressionism",
  "AcademicArt" = "AcademicArt",
  "Aestheticism" = "Aestheticism",
  "AmericanImpressionism" = "AmericanImpressionism",
  "AmericanModernism" = "AmericanModernism",
  "AmericanRealism" = "AmericanRealism",
  "AmericanRenaissance" = "AmericanRenaissance",
  "AmsterdamImpressionism" = "AmsterdamImpressionism",
  "AntwerpSchool" = "AntwerpSchool",
  "ArtDeco" = "ArtDeco",
  "ArtNouveau" = "ArtNouveau",
  "ArtePovera" = "ArtePovera",
  "ArtsAndCraftsMovement" = "ArtsAndCraftsMovement",
  "AshcanSchool" = "AshcanSchool",
  "Barbizonschool" = "Barbizonschool",
  "Baroque" = "Baroque",
  "BauhausStyle" = "BauhausStyle",
  "Biomorphism" = "Biomorphism",
  "BloomsburyGroup" = "BloomsburyGroup",
  "BologneseSchool" = "BologneseSchool",
  "BronzeAge" = "BronzeAge",
  "Caravaggisti" = "Caravaggisti",
  "Classicism" = "Classicism",
  "ClevelandSchool" = "ClevelandSchool",
  "ColorField" = "ColorField",
  "ConceptualArt" = "ConceptualArt",
  "Constructivism" = "Constructivism",
  "ContemporaryArt" = "ContemporaryArt",
  "Cubism" = "Cubism",
  "Dada" = "Dada",
  "DeStijlNeoplasticism" = "DeStijlNeoplasticism",
  "Deconstructivism" = "Deconstructivism",
  "DerBlaueReiter" = "DerBlaueReiter",
  "DieBrucke" = "DieBrucke",
  "DigitalArt" = "DigitalArt",
  "Dovetail" = "Dovetail",
  "DutchAndFlemishRenaissancePainting" = "DutchAndFlemishRenaissancePainting",
  "DutchGoldenAge" = "DutchGoldenAge",
  "EarlyChristianArtAndArchitecture" = "EarlyChristianArtAndArchitecture",
  "EarlyNetherlandishPainting" = "EarlyNetherlandishPainting",
  "EarlyRenaissance" = "EarlyRenaissance",
  "Expressionism" = "Expressionism",
  "Fauvism" = "Fauvism",
  "FigurationLibre" = "FigurationLibre",
  "FlorentinePainting" = "FlorentinePainting",
  "Fluxus" = "Fluxus",
  "FolkArt" = "FolkArt",
  "FrenchRenaissance" = "FrenchRenaissance",
  "Futurism" = "Futurism",
  "GeometricAbstraction" = "GeometricAbstraction",
  "GermanExpressionismCinema" = "GermanExpressionismCinema",
  "GermanRenaissance" = "GermanRenaissance",
  "GoodDesign" = "GoodDesign",
  "GothicArt" = "GothicArt",
  "Graffiti" = "Graffiti",
  "GroupOfSeven" = "GroupOfSeven",
  "GrupoMontparnasse" = "GrupoMontparnasse",
  "Hagenbund" = "Hagenbund",
  "HagueSchool" = "HagueSchool",
  "Happening" = "Happening",
  "HardEdgePainting" = "HardEdgePainting",
  "HarlemRenaissance" = "HarlemRenaissance",
  "HeidelbergSchool" = "HeidelbergSchool",
  "HighRenaissance" = "HighRenaissance",
  "HudsonRiverSchool" = "HudsonRiverSchool",
  "Hyperrealism" = "Hyperrealism",
  "Impressionism" = "Impressionism",
  "InstallationArt" = "InstallationArt",
  "InternationalTypographicStyle" = "InternationalTypographicStyle",
  "ItalianRenaissance" = "ItalianRenaissance",
  "Japonisme" = "Japonisme",
  "JunkArt" = "JunkArt",
  "KineticArt" = "KineticArt",
  "LandArt" = "LandArt",
  "LesNabis" = "LesNabis",
  "Luminism" = "Luminism",
  "LyricalAbstraction" = "LyricalAbstraction",
  "MagicalRealism" = "MagicalRealism",
  "MailArt" = "MailArt",
  "Mannerism" = "Mannerism",
  "Massurrealism" = "Massurrealism",
  "Maximalism" = "Maximalism",
  "MetaphysicalArt" = "MetaphysicalArt",
  "Metarealism" = "Metarealism",
  "MilaneseSchool" = "MilaneseSchool",
  "Minimalism" = "Minimalism",
  "MissionSchool" = "MissionSchool",
  "ModernArt" = "ModernArt",
  "Modernism" = "Modernism",
  "NaiveArt" = "NaiveArt",
  "Naturalism" = "Naturalism",
  "NazareneMovement" = "NazareneMovement",
  "Neoclassicism" = "Neoclassicism",
  "NeoDada" = "NeoDada",
  "NeoExpressionism" = "NeoExpressionism",
  "NeoImpressionism" = "NeoImpressionism",
  "Neoism" = "Neoism",
  "NeoRomanticism" = "NeoRomanticism",
  "NewObjectivity" = "NewObjectivity",
  "NewRealism" = "NewRealism",
  "Nihonga" = "Nihonga",
  "NorthernRenaissance" = "NorthernRenaissance",
  "NorthwestSchool" = "NorthwestSchool",
  "NorwichSchool" = "NorwichSchool",
  "OpArt" = "OpArt",
  "OutsiderArt" = "OutsiderArt",
  "PaduanSchool" = "PaduanSchool",
  "PaintersEleven" = "PaintersEleven",
  "PennsylvaniaImpressionism" = "PennsylvaniaImpressionism",
  "PerformanceArt" = "PerformanceArt",
  "Photorealism" = "Photorealism",
  "PitturaMetafisica" = "PitturaMetafisica",
  "Pointillism" = "Pointillism",
  "PopArt" = "PopArt",
  "PostImpressionism" = "PostImpressionism",
  "Postminimalism" = "Postminimalism",
  "Postmodernism" = "Postmodernism",
  "PostPainterlyAbstraction" = "PostPainterlyAbstraction",
  "Precisionism" = "Precisionism",
  "PreRaphaeliteBrotherhood" = "PreRaphaeliteBrotherhood",
  "Primitivism" = "Primitivism",
  "ProcessArt" = "ProcessArt",
  "Proletkult" = "Proletkult",
  "PsychedelicArt" = "PsychedelicArt",
  "PublicArt" = "PublicArt",
  "Rayonism" = "Rayonism",
  "Realism" = "Realism",
  "Regionalism" = "Regionalism",
  "Remodernism" = "Remodernism",
  "Renaissance" = "Renaissance",
  "Rococo" = "Rococo",
  "RomanesqueArt" = "RomanesqueArt",
  "Romanticism" = "Romanticism",
  "RussianAvantGarde" = "RussianAvantGarde",
  "SchoolOfFerrara" = "SchoolOfFerrara",
  "SchoolOfFontainebleau" = "SchoolOfFontainebleau",
  "SchoolOfParis" = "SchoolOfParis",
  "SectionDOr" = "SectionDOr",
  "ShinHanga" = "ShinHanga",
  "SieneseSchool" = "SieneseSchool",
  "Situationism" = "Situationism",
  "SocialRealism" = "SocialRealism",
  "SocialistRealism" = "SocialistRealism",
  "SosakuHanga" = "SōsakuHanga",
  "SotsArt" = "SotsArt",
  "SovietNonconformistArt" = "SovietNonconformistArt",
  "SpanishEclecticism" = "SpanishEclecticism",
  "SpanishRenaissance" = "SpanishRenaissance",
  "StreetArt" = "StreetArt",
  "Stuckism" = "Stuckism",
  "SturmUndDrang" = "SturmUndDrang",
  "Suprematism" = "Suprematism",
  "Surrealism" = "Surrealism",
  "Symbolism" = "Symbolism",
  "Synchromism" = "Synchromism",
  "Synthetism" = "Synthetism",
  "Tachisme" = "Tachisme",
  "Tonalism" = "Tonalism",
  "Toyism" = "Toyism",
  "UkiyoE" = "UkiyoE",
  "UniversalFlowering" = "UniversalFlowering",
  "UtrechtCaravaggism" = "UtrechtCaravaggism",
  "VenetianPainting" = "VenetianPainting",
  "VideoArt" = "VideoArt",
  "ViennaSchoolOfFantasticRealism" = "ViennaSchoolOfFantasticRealism",
  "Vorticism" = "Vorticism",
  "WashingtonColorSchool" = "WashingtonColorSchool",
  "WoodlandsStyle" = "WoodlandsStyle",
  "YoungBritishArtists" = "YoungBritishArtists",
  "YoungPoland" = "YoungPoland",
}

export const artStyleValues = Object.values(ArtStyle);

export enum ArtMedium {
  "AcrylicPaint" = "AcrylicPaint",
  "AcrylicResin" = "AcrylicResin",
  "Adhesive" = "Adhesive",
  "Agate" = "Agate",
  "Alabaster" = "Alabaster",
  "Aluminium" = "Aluminium",
  "Amber" = "Amber",
  "Amethyst" = "Amethyst",
  "Andesite" = "Andesite",
  "Azurite" = "Azurite",
  "Bakelite" = "Bakelite",
  "Bark" = "Bark",
  "Basalt" = "Basalt",
  "Beech" = "Beech",
  "Beeswax" = "Beeswax",
  "Birch" = "Birch",
  "BiscuitPorcelain" = "BiscuitPorcelain",
  "Bone" = "Bone",
  "Book" = "Book",
  "Brass" = "Brass",
  "Brick" = "Brick",
  "Brocade" = "Brocade",
  "Bronze" = "Bronze",
  "Calotype" = "Calotype",
  "Canvas" = "Canvas",
  "Cardboard" = "Cardboard",
  "CarraraMarble" = "CarraraMarble",
  "CartridgePaper" = "CartridgePaper",
  "CastIron" = "CastIron",
  "Celluloid" = "Celluloid",
  "Ceramic" = "Ceramic",
  "Chalk" = "Chalk",
  "Charcoal" = "Charcoal",
  "Cherry" = "Cherry",
  "Chiffon" = "Chiffon",
  "Chintz" = "Chintz",
  "Chromium" = "Chromium",
  "Cinnabar" = "Cinnabar",
  "Clay" = "Clay",
  "Coal" = "Coal",
  "Cobalt" = "Cobalt",
  "Cochineal" = "Cochineal",
  "ColoredPencil" = "ColoredPencil",
  "Concrete" = "Concrete",
  "Conte" = "Conte",
  "Copper" = "Copper",
  "Coral" = "Coral",
  "Cord" = "Cord",
  "Cork" = "Cork",
  "Cotton" = "Cotton",
  "Crayon" = "Crayon",
  "Crystal" = "Crystal",
  "Damask" = "Damask",
  "Diamond" = "Diamond",
  "Diorite" = "Diorite",
  "Distemper" = "Distemper",
  "Drawing" = "Drawing",
  "Dye" = "Dye",
  "Ebony" = "Ebony",
  "Egg" = "Egg",
  "ElectricalCable" = "ElectricalCable",
  "Emerald" = "Emerald",
  "EnamelPaint" = "EnamelPaint",
  "EncausticPainting" = "EncausticPainting",
  "Engraving" = "Engraving",
  "Epoxy" = "Epoxy",
  "Etching" = "Etching",
  "Eucalyptus" = "Eucalyptus",
  "Faience" = "Faience",
  "Feather" = "Feather",
  "Feldspar" = "Feldspar",
  "Felt" = "Felt",
  "Fiberglass" = "Fiberglass",
  "Fir" = "Fir",
  "Flint" = "Flint",
  "Foil" = "Foil",
  "Formica" = "Formica",
  "Garnet" = "Garnet",
  "Gauze" = "Gauze",
  "Gemstone" = "Gemstone",
  "Gesso" = "Gesso",
  "Glass" = "Glass",
  "Gold" = "Gold",
  "GoldLeaf" = "GoldLeaf",
  "Gouache" = "Gouache",
  "Granite" = "Granite",
  "GraphPaper" = "GraphPaper",
  "Graphite" = "Graphite",
  "GumArabic" = "GumArabic",
  "Hair" = "Hair",
  "Hematite" = "Hematite",
  "HessianFabric" = "HessianFabric",
  "Hide" = "Hide",
  "Horsehair" = "Horsehair",
  "IndiaInk" = "IndiaInk",
  "IndustrialHemp" = "IndustrialHemp",
  "Ink" = "Ink",
  "Iron" = "Iron",
  "Ivory" = "Ivory",
  "Jade" = "Jade",
  "Jadeite" = "Jadeite",
  "Jasper" = "Jasper",
  "Jet" = "Jet",
  "Jute" = "Jute",
  "Lace" = "Lace",
  "Lacquer" = "Lacquer",
  "LaidPaper" = "LaidPaper",
  "LapisLazuli" = "LapisLazuli",
  "Latex" = "Latex",
  "Lead" = "Lead",
  "LeadGlass" = "LeadGlass",
  "Leaf" = "Leaf",
  "Leather" = "Leather",
  "Limestone" = "Limestone",
  "Linen" = "Linen",
  "Lustreware" = "Lustreware",
  "Mahogany" = "Mahogany",
  "Malachite" = "Malachite",
  "Manganese" = "Manganese",
  "Maple" = "Maple",
  "Marble" = "Marble",
  "Masonite" = "Masonite",
  "Mercury" = "Mercury",
  "Metal" = "Metal",
  "MetallicPaint" = "MetallicPaint",
  "MilkGlass" = "MilkGlass",
  "Mohair" = "Mohair",
  "MuranoGlass" = "MuranoGlass",
  "Muslin" = "Muslin",
  "Nacre" = "Nacre",
  "NaturalRubber" = "NaturalRubber",
  "Neon" = "Neon",
  "Nephrite" = "Nephrite",
  "Newsprint" = "Newsprint",
  "Nickel" = "Nickel",
  "Nylon" = "Nylon",
  "Oak" = "Oak",
  "Obsidian" = "Obsidian",
  "OilPaint" = "OilPaint",
  "OilPastel" = "OilPastel",
  "Olive" = "Olive",
  "Onyx" = "Onyx",
  "Paper" = "Paper",
  "PaperNegative" = "PaperNegative",
  "Papyrus" = "Papyrus",
  "ParianMarble" = "ParianMarble",
  "PavonazzoMarble" = "PavonazzoMarble",
  "Pearl" = "Pearl",
  "Pebble" = "Pebble",
  "Pen" = "Pen",
  "Pencil" = "Pencil",
  "Peridot" = "Peridot",
  "Pewter" = "Pewter",
  "Photograph" = "Photograph",
  "PhotographicFilm" = "PhotographicFilm",
  "PhotographicPaper" = "PhotographicPaper",
  "Pigment" = "Pigment",
  "Pine" = "Pine",
  "Pitch" = "Pitch",
  "Plaster" = "Plaster",
  "Plastic" = "Plastic",
  "Platinum" = "Platinum",
  "Plywood" = "Plywood",
  "Polycarbonate" = "Polycarbonate",
  "Polyester" = "Polyester",
  "Polyethylene" = "Polyethylene",
  "Polypropylene" = "Polypropylene",
  "Polystyrene" = "Polystyrene",
  "Polyurethane" = "Polyurethane",
  "Porcelain" = "Porcelain",
  "Pottery" = "Pottery",
  "Quartz" = "Quartz",
  "Quartzite" = "Quartzite",
  "Rattan" = "Rattan",
  "Rayon" = "Rayon",
  "Resin" = "Resin",
  "Rhinestone" = "Rhinestone",
  "Ribbon" = "Ribbon",
  "RicePaper" = "RicePaper",
  "Rock" = "Rock",
  "Root" = "Root",
  "Rope" = "Rope",
  "Sand" = "Sand",
  "Sandstone" = "Sandstone",
  "Sanguine" = "Sanguine",
  "Sapphire" = "Sapphire",
  "Satin" = "Satin",
  "Sawdust" = "Sawdust",
  "Schist" = "Schist",
  "Sculpture" = "Sculpture",
  "Seashell" = "Seashell",
  "Seed" = "Seed",
  "SheetMetal" = "SheetMetal",
  "Silicon" = "Silicon",
  "Silk" = "Silk",
  "Silver" = "Silver",
  "SilverBromide" = "SilverBromide",
  "SilverHalide" = "SilverHalide",
  "Skin" = "Skin",
  "Slate" = "Slate",
  "Soapstone" = "Soapstone",
  "SprayPainting" = "SprayPainting",
  "Spruce" = "Spruce",
  "StainedGlass" = "StainedGlass",
  "StainlessSteel" = "StainlessSteel",
  "Steel" = "Steel",
  "SterlingSilver" = "SterlingSilver",
  "Stoneware" = "Stoneware",
  "Stucco" = "Stucco",
  "Taffeta" = "Taffeta",
  "Teak" = "Teak",
  "Tempera" = "Tempera",
  "Terracotta" = "Terracotta",
  "Textile" = "Textile",
  "Tin" = "Tin",
  "TissuePaper" = "TissuePaper",
  "Titanium" = "Titanium",
  "Tooth" = "Tooth",
  "Topaz" = "Topaz",
  "Tortoiseshell" = "Tortoiseshell",
  "TracingPaper" = "TracingPaper",
  "Travertine" = "Travertine",
  "Tulipwood" = "Tulipwood",
  "Turquoise" = "Turquoise",
  "Tusk" = "Tusk",
  "Twill" = "Twill",
  "Varnish" = "Varnish",
  "Vellum" = "Vellum",
  "Velvet" = "Velvet",
  "VitreousEnamel" = "VitreousEnamel",
  "Walnut" = "Walnut",
  "WatercolorPainting" = "WatercolorPainting",
  "Wax" = "Wax",
  "Willow" = "Willow",
  "Wire" = "Wire",
  "Wood" = "Wood",
  "Wool" = "Wool",
  "WroughtIron" = "WroughtIron",
  "Yarn" = "Yarn",
  "Zinc" = "Zinc",
}

export const artMediumValues = Object.values(ArtMedium);

//#NOTE OtherCollectable will also use this interface
export interface Art extends AssetV2WithCollectableAttributes {
  "@type": VersionedTypeString<VersionedType.Art, 2>;
  assetType: AssetType.Art;
  subtype: ArtType | string;
  artStyle?: ArtStyle;

  //Art Title is `name`
  number: number;
  purchaseDate: Date;
  price: Amount;
  purchasePrice: Amount;
  totalCost: Amount;
  artist: string;
  //`artistId` is set if the artist is from the artist db.
  artistId?: string;
  personalRefNo?: string;

  acquisition?: CollectableAcquisition;
  location: LocationInfo;
  ownership?: Ownership;
  beneficiary?: Beneficiary;
}
export namespace Art {
  export function assureVersion(
    input: Art | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(input, ArtTypeVersion, errorOnCoreOutDated);
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Art);
  }

  export const datePaths: readonly PathsOfDateField<Art>[] = [
    "createAt",
    "updateAt",
    "purchaseDate",
    "dateExecuted",
    "creationCompletionYear",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<Art>[] = [
    "value",
    "price",
    "totalCost",
  ] as const;

  // for activity log (maybe can use decorator in the future)
  export const primaryDetailKeys: readonly (keyof Art)[] = [
    "artist",
    "name",
    "subtype",
    "artStyle",
    "medium",
    // locationType, locationId
    "purchaseDate",
    "number",
    "price",
    "purchasePrice",
    "value",
    "groupIds",
    "personalRefNo",
    "notes",
  ] as const;
  export const attributeKeys: readonly (keyof Art)[] =
    AssetV2WithCollectableAttributes.baseAttributeKeys;

  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Art> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Art,
    "@type" | "ownerId" | "version" | "createAt" | "updateAt" | "valueSourceId"
  >;

  export type Draft = {
    req: OmitKeys<CreateFields, "location">;
    ownerId: string;
    groupId: string;
  };
  export type DraftWithState = Draft & { state: Art };
  export type EncryptedDraftWithState = RequireEncryptionFields<
    EncryptedType<DraftWithState, "req">,
    {
      state: Encrypted;
    }
  >;

  export type EncryptedKeys = AssetV2WithCollectableAttributes.EncryptedKeys;
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Art, EncryptedKeys>,
    {
      location: LocationInfo.Encrypted;
      acquisition?: CollectableAcquisition.Encrypted;
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<Art, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    "notes",
    "attributeNotes",
  ];

  export function fromCreate(from: Art.CreateFields, ownerId: string): Art {
    const art: WithFieldValue<Art> = {
      ...from,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      "@type": ArtTypeVersion,
    };
    return art as Art;
  }

  // objects/arrays "location","ownership","beneficiary","acquisition","attachments","groups","value"
  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Art>[] = [
    "name",
    "subtype",
    "artist",
    "number",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Art>[] = [
    "artStyle",
    "mainImage",
    "personalRefNo",
    "measurementUnits",
    "height",
    "width",
    "depth",
    "weight",
    "edition",
    "provenance",
    "geography",
    "inscription",
    "description",
    "catalogueText",
    "dimensionsSummary",
    "attributeNotes",
    "medium",
    "artistId",
    "notes",
  ];
  export function intoUpdate(
    current: Art,
    update: Art
  ): {
    updates: UpdateObject<Art>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
      newImages?: string[];
      newMainImage?: string;
      removedImages?: string[];
      locationPrimaryDetailsUpdated?: boolean;
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      OptionalSimpleTypeUpdatableKeys
    );

    if (!LocationInfo.equal(current.location, update.location)) {
      if (!LocationInfo.primaryDetailEqual(current.location, update.location))
        metadata.locationPrimaryDetailsUpdated = true;
      baseUpdateFields.location = update.location;
    }
    if (!Ownership.optionalEqual(current.ownership, update.ownership))
      if (update.ownership) baseUpdateFields.ownership = update.ownership;
      else baseUpdateFields.ownership = null;
    if (!Owner.optionalEqual(current.beneficiary, update.beneficiary))
      if (update.beneficiary) baseUpdateFields.beneficiary = update.beneficiary;
      else baseUpdateFields.beneficiary = null;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;

    if (!Amount.equal(current.value, update.value)) {
      baseUpdateFields.value = update.value;
    }
    if (!Amount.equal(current.price, update.price)) {
      baseUpdateFields.price = update.price;
    }
    if (!Amount.equal(current.purchasePrice, update.purchasePrice)) {
      baseUpdateFields.purchasePrice = update.purchasePrice;
    }
    if (!Amount.equal(current.totalCost, update.totalCost)) {
      baseUpdateFields.totalCost = update.totalCost;
    }
    if (current.purchaseDate.getTime() !== update.purchaseDate.getTime()) {
      baseUpdateFields.purchaseDate = update.purchaseDate;
    }
    if (!optionalDateEqual(current.dateExecuted, update.dateExecuted)) {
      if (update.dateExecuted)
        baseUpdateFields.dateExecuted = update.dateExecuted;
      else baseUpdateFields.dateExecuted = null;
    }
    if (
      !optionalDateEqual(
        current.creationCompletionYear,
        update.creationCompletionYear
      )
    ) {
      if (update.creationCompletionYear) {
        baseUpdateFields.creationCompletionYear = update.creationCompletionYear;
      } else {
        baseUpdateFields.creationCompletionYear = null;
      }
    }
    if (
      !CollectableAcquisition.optionalEqual(
        current.acquisition,
        update.acquisition
      )
    ) {
      if (update.acquisition) baseUpdateFields.acquisition = update.acquisition;
      else baseUpdateFields.acquisition = null;
    }

    const { attachments, newImages, removedImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (removedImages.length > 0) metadata.removedImages = removedImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;
    if (baseUpdateFields.mainImage !== undefined)
      metadata.newMainImage = baseUpdateFields.mainImage;

    return { updates: baseUpdateFields, metadata };
  }

  export function removeEncryptedFields<T extends Art | UpdateObject<Art>>(
    data: T
  ): OmitKeys<T, EncryptedKeys | "location" | "acquisition" | "attachments"> {
    const result = doRemoveEncryptedFields(
      data,
      encryptedKeysArray
    ) as OmitKeys<
      T,
      EncryptedKeys | "location" | "acquisition" | "attachments"
    >;
    delete (<any>result).location;
    delete (<any>result).acquisition;
    delete (<any>result).attachments;
    return result;
  }
  export async function encrypt(
    input: Art,
    encryption: Encryption
  ): Promise<Encrypted> {
    const {
      attachments,
      acquisition,
      location,
      ...rest
    }: EncryptedType<Art, EncryptedKeys> = await fullObjectEncryption(
      input,
      encryptedKeysArray,
      encryption
    );
    const result = rest as Encrypted;
    if (attachments) {
      result.attachments = await Attachment.encryptArray(
        attachments,
        encryption
      );
    }
    if (acquisition) {
      result.acquisition = await CollectableAcquisition.encrypt(
        acquisition,
        encryption
      );
    }
    if (location) {
      result.location = await LocationInfo.encrypt(location, encryption);
    }
    return result;
  }
  export async function encryptUpdate(
    encryptedPart: Optional<EncryptedPart>,
    location: UpdateObject<Art>["location"],
    acquisition: UpdateObject<Art>["acquisition"],
    attachments: UpdateObject<Art>["attachments"],
    encryption: Encryption
  ): Promise<
    Partial<EncryptionField> &
      Pick<UpdateObject<Encrypted>, "location" | "acquisition" | "attachments">
  > {
    const result: Partial<EncryptionField> &
      Pick<
        UpdateObject<Encrypted>,
        "location" | "acquisition" | "attachments"
      > = {};
    if (encryptedPart) {
      const iv = encryption.generateNewIVSalt();
      result[EncryptionFieldKey] = {
        data: await encryption.encryptAndStringify(encryptedPart, iv),
        [IVSaltFieldKey]: encryption.convertIVSaltToBase64(iv),
      };
    }
    if (location) {
      result.location = await LocationInfo.encrypt(location, encryption);
    }
    if (acquisition !== undefined) {
      if (acquisition !== null)
        result.acquisition = await CollectableAcquisition.encrypt(
          acquisition,
          encryption
        );
      else result.acquisition = null;
    }
    if (attachments !== undefined) {
      if (attachments !== null)
        result.attachments = await Attachment.encryptArray(
          attachments,
          encryption
        );
      else result.attachments = null;
    }
    return result;
  }
  export async function decrypt(
    data: Encrypted,
    encryption: Encryption
  ): Promise<Art> {
    const EncryptedPart: EncryptedPart = await encryption.decryptAndStringify(
      data[EncryptionFieldKey]["data"],
      encryption.convertBase64ToIVSalt(data[EncryptionFieldKey][IVSaltFieldKey])
    );
    const { location, acquisition, attachments, ...rest } = data;
    const result: Art = {
      ...removeEncryptionFields(rest),
      ...EncryptedPart,
      location: await LocationInfo.decrypt(location, encryption),
      attachments: await Attachment.decryptArray(attachments, encryption),
    };
    if (acquisition) {
      result.acquisition = await CollectableAcquisition.decrypt(
        acquisition,
        encryption
      );
    }
    return result;
  }

  export async function encryptDraft(input: Draft, encryption: Encryption) {
    return await fullObjectEncryption(input, ["req"], encryption);
  }
  export async function decryptDraftWithStateAndConvertDate(
    data: EncryptedDraftWithState,
    encryption: Encryption,
    decryptState: boolean = false
  ) {
    const encryptedPart: Pick<Draft, "req"> =
      await encryption.decryptAndStringify(
        data[EncryptionFieldKey]["data"],
        encryption.convertBase64ToIVSalt(
          data[EncryptionFieldKey][IVSaltFieldKey]
        )
      );
    // NOTE: date after encryption will be string, convert back to Date
    for (const key of datePaths) {
      if (key in encryptedPart.req) {
        (encryptedPart.req[key as keyof Draft["req"]] as Date) = new Date(
          encryptedPart.req[key as keyof Draft["req"]] as string
        );
      }
    }
    const { state, ...rest } = data;
    const result = {
      ...removeEncryptionFields(rest),
      ...encryptedPart,
      state: decryptState
        ? await decryptAndConvertDate(state, encryption)
        : state,
    };
    return result;
  }

  export function newAggregateRoot(
    art: Art.Encrypted,
    relatedReads?: Art.RelatedReads
  ) {
    return new AggregateRoot(new ArtAggregate(art, relatedReads));
  }

  export function defaultValue(): Art {
    return {
      "@type": ArtTypeVersion,
      assetType: AssetType.Art,
      subtype: "",
      id: "",
      name: "",
      ownerId: "",
      version: 0,
      createAt: new Date(0),
      updateAt: new Date(0),
      purchaseDate: new Date(0),
      number: 1,
      price: Amount.defaultValue(),
      purchasePrice: Amount.defaultValue(),
      value: Amount.defaultValue(),
      totalCost: Amount.defaultValue(),
      artist: "",
      location: {
        locationId: "",
        locationType: LocationType.MyProperty,
      },
    };
  }

  export function defaultStateValue(): Encrypted {
    return {
      "@type": ArtTypeVersion,
      assetType: AssetType.Art,
      subtype: "",
      id: "",
      name: "",
      ownerId: "",
      version: 0,
      createAt: new Date(0),
      updateAt: new Date(0),
      purchaseDate: new Date(0),
      number: 1,
      price: Amount.defaultValue(),
      purchasePrice: Amount.defaultValue(),
      value: Amount.defaultValue(),
      totalCost: Amount.defaultValue(),
      artist: "",
      location: {
        locationId: "",
        locationType: LocationType.MyProperty,
        [EncryptionFieldKey]: {
          data: EncryptionFieldDefaultValue,
          [IVSaltFieldKey]: IVSaltFieldDefaultValue,
        },
      },
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue, //`{name:""}`
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
  }

  export function validateEncryptedPart(
    data: EncryptedPart & {
      location?: LocationInfo.EncryptedPart;
      acquisition?: CollectableAcquisition.EncryptedPart;
      attachments?: Attachment.EncryptedPart[];
    },
    isCreate: boolean = false
  ) {
    if (isCreate || data.location)
      LocationInfo.validateEncryptedPart(data.location!);
    if (data.acquisition)
      CollectableAcquisition.validateEncryptedPart(data.acquisition);
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    if (isCreate && !data.purchaseDate)
      throw new InvalidInput("purchaseDate is required");
    if (
      (isCreate || data.subtype) &&
      !validateValueInEnum(data.subtype!, ArtType)
    ) {
      throw new InvalidInput("Subtype is not ArtType");
    }
    if ((isCreate || data.price) && data.price!.value < 0) {
      throw new InvalidInput("Price must be positive");
    }
    if ((isCreate || data.value) && data.value!.value < 0) {
      throw new InvalidInput("Value can't be negative");
    }
    if ((isCreate || data.artist) && !validateStringNotEmpty(data.artist)) {
      throw new InvalidInput("Artist is required");
    }
    if ((isCreate || data.number) && data.number! <= 0) {
      throw new InvalidInput("Item Number must be positive");
    }
    if (isCreate && !data.location) {
      throw new InvalidInput("Location is required");
    }
    if (data.location) {
      LocationInfo.validateEncryptedObj(data.location);
    }
    //optional fields
    if (data.artStyle && !validateValueInEnum(data.artStyle!, ArtStyle)) {
      throw new InvalidInput("ArtStyle is not valid");
    }
    if (data.ownership) {
      Ownership.validate(data.ownership);
    }
    if (data.beneficiary) {
      Owner.validate(0, data.beneficiary);
    }
    if (data.height && data.height < 0) {
      throw new InvalidInput("Height can't be negative");
    }
    if (data.width && data.width < 0) {
      throw new InvalidInput("Width can't be negative");
    }
    if (data.depth && data.depth < 0) {
      throw new InvalidInput("Depth can't be negative");
    }
    if (data.weight && data.weight < 0) {
      throw new InvalidInput("Weight can't be negative");
    }
    if (data.acquisition) {
      CollectableAcquisition.validateEncryptedObj(data.acquisition);
    }
    if (data.attachments) {
      Attachment.validateEncryptedObj(data.attachments);
    }
  }

  export type SupportActions = Offer | Consignment | Exhibition | Literature;
  export type SupportActionsEncrypted =
    | Offer.Encrypted
    | Consignment.Encrypted
    | Exhibition.Encrypted
    | Literature.Encrypted;
  export type SupportActionsUpdate =
    | Offer.Update
    | Consignment.Update
    | Exhibition.Update
    | Literature.Update;
  export type WriteActionEncrypted =
    | SupportActionsEncrypted
    | Valuation.Encrypted
    | SoldInfo.Encrypted;

  export interface RelatedReads {
    actions: { [id: string]: WriteActionEncrypted };
  }
  export interface RelatedUpdates {
    addedGroupIds?: string[];
    removedGroupIds?: string[];
    addedInsuranceIds?: string[];
    removedInsuranceIds?: string[];
    setActions?: WriteActionEncrypted[];
    removedActionIds?: string[];
  }

  export interface RelatedAggregates {
    group?: RepoAndAggregates<any, any, any>;
    insurance?: RepoAndAggregates<any, any, any>;
    cashAndBanking?: RepoAndAggregates<any, any, any>;
  }
}

export namespace Command {
  export type Kind =
    | SharedCommand.Kind
    | ValuationCommand.Kind
    | SellCommand.Kind
    | ActionCommand.Kind;
  export const Kind = {
    ...SharedCommand.Kind,
    ...ValuationCommand.Kind,
    ...SellCommand.Kind,
    ...ActionCommand.Kind,
  };

  export interface CreateAsset
    extends SharedCommand.CreateAsset<Art.Encrypted> {}
  export const createAsset = SharedCommand.createAsset<Art.Encrypted>;
  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<UpdateObject<Art.Encrypted>> {}
  export const updateAsset = SharedCommand.updateAsset<
    UpdateObject<Art.Encrypted>
  >;
  export interface RelocateAsset extends SharedCommand.RelocateAsset {}
  export const relocateAsset = SharedCommand.relocateAsset;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;
  export interface AddInsurance extends SharedCommand.AddInsurance {}
  export const addInsurance = SharedCommand.addInsurance;
  export interface RemoveInsurance extends SharedCommand.RemoveInsurance {}
  export const removeInsurance = SharedCommand.removeInsurance;

  export interface AddValuation extends ValuationCommand.AddValuation {}
  export const addValuation = ValuationCommand.addValuation;
  export interface UpdateValuation extends ValuationCommand.UpdateValuation {}
  export const updateValuation = ValuationCommand.updateValuation;
  export interface DeleteValuation extends ValuationCommand.DeleteValuation {}
  export const deleteValuation = ValuationCommand.deleteValuation;

  export interface MarkAsSold extends SellCommand.MarkAsSold {}
  export const markAsSold = SellCommand.markAsSold;
  export interface UpdateSoldInfo extends SellCommand.UpdateSoldInfo {}
  export const updateSoldInfo = SellCommand.updateSoldInfo;
  export interface DeleteSoldInfo extends SellCommand.DeleteSoldInfo {}
  export const deleteSoldInfo = SellCommand.deleteSoldInfo;

  //Offer
  //Consignment
  //Exhibition
  //Literature
  export interface AddAction
    extends ActionCommand.AddAction<Art.SupportActionsEncrypted> {}
  export interface UpdateAction
    extends ActionCommand.UpdateAction<Art.SupportActionsUpdate> {}
  export interface DeleteAction extends ActionCommand.DeleteAction {}
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.RelocateAsset
  | Command.DeleteAsset
  | Command.AddInsurance
  | Command.RemoveInsurance
  | Command.AddValuation
  | Command.UpdateValuation
  | Command.DeleteValuation
  | Command.MarkAsSold
  | Command.UpdateSoldInfo
  | Command.DeleteSoldInfo
  | Command.AddAction
  | Command.UpdateAction
  | Command.DeleteAction;

export namespace Event {
  export type Kind =
    | SharedEvent.Kind
    | ValuationEvent.Kind
    | SellEvent.Kind
    | ActionEvent.Kind;
  export const Kind = {
    ...SharedEvent.Kind,
    ...ValuationEvent.Kind,
    ...SellEvent.Kind,
    ...ActionEvent.Kind,
  };

  export interface AssetCreated
    extends SharedEvent.AssetCreated<Art.Encrypted> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<Art.Encrypted>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}
  export interface LocationUpdated extends SharedEvent.LocationUpdated {}
  export interface ValueUpdated extends SharedEvent.ValueUpdated {}
  export interface InsuranceUpdated extends SharedEvent.InsuranceUpdated {}
  export interface ImageAdded extends SharedEvent.ImageAdded {}
  export interface ImageRemoved extends SharedEvent.ImageRemoved {}
  export interface MainImageSet extends SharedEvent.MainImageSet {}
  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}

  export interface ValuationAdded extends ValuationEvent.ValuationAdded {}
  export interface ValuationUpdated extends ValuationEvent.ValuationUpdated {}
  export interface ValuationDeleted extends ValuationEvent.ValuationDeleted {}

  export interface SoldInfoAdded extends SellEvent.SoldInfoAdded {}
  export interface SoldInfoUpdated extends SellEvent.SoldInfoUpdated {}
  export interface SoldInfoDeleted extends SellEvent.SoldInfoDeleted {}

  export interface ActionAdded
    extends ActionEvent.ActionAdded<Art.SupportActionsEncrypted> {}
  export interface ActionUpdated
    extends ActionEvent.ActionUpdated<Art.SupportActionsUpdate> {}
  export interface ActionDeleted extends ActionEvent.ActionDeleted {}

  export const toActivities: EventToActivitiesFunction<Event> = (
    event: Event,
    assetType: SupportActivityType,
    assetId: string,
    ownerId: string,
    time: Date
  ) => {
    validateActMapFuncKnowEventKind(assetType, [event]);
    const needProcessEventKinds: Event.Kind[] = [
      Event.Kind.AssetCreated,
      Event.Kind.AssetDeleted,
      Event.Kind.AssetUpdated,
      Event.Kind.ValueUpdated,
      Event.Kind.ShareholderUpdated,
      Event.Kind.BeneficiaryUpdated,
      Event.Kind.LocationUpdated,
      Event.Kind.ImageAdded,
      Event.Kind.ImageRemoved,
      Event.Kind.MainImageSet,
      Event.Kind.GroupsUpdated,
      // actions
      Event.Kind.SoldInfoAdded,
      Event.Kind.SoldInfoUpdated,
      Event.Kind.SoldInfoDeleted,
      Event.Kind.ActionAdded,
      Event.Kind.ActionUpdated,
      Event.Kind.ActionDeleted,
    ];
    return genActivityLogs(
      needProcessEventKinds,
      event,
      assetType,
      assetId,
      ownerId,
      time,
      Art.primaryDetailKeys,
      Art.attributeKeys
    );
  };
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.LocationUpdated
  | Event.ValueUpdated
  | Event.InsuranceUpdated
  | Event.ImageAdded
  | Event.ImageRemoved
  | Event.MainImageSet
  | Event.GroupsUpdated
  | Event.ValuationAdded
  | Event.ValuationUpdated
  | Event.ValuationDeleted
  | Event.SoldInfoAdded
  | Event.SoldInfoUpdated
  | Event.SoldInfoDeleted
  | Event.ActionAdded
  | Event.ActionUpdated
  | Event.ActionDeleted;

export function toTagPair<T extends Pick<Art, "subtype" | "artStyle">>(
  current: UpdateObject<T>,
  previous?: T
): TagPair[] {
  const tagPairs: TagPair[] = [];
  if (current.subtype) {
    tagPairs.push({ key: SummaryTag.Art.Subtype, val: current.subtype });
  } else if (previous && current.subtype !== null) {
    tagPairs.push({ key: SummaryTag.Art.Subtype, val: previous.subtype });
  }
  if (current.artStyle) {
    tagPairs.push({ key: SummaryTag.Art.ArtStyle, val: current.artStyle });
  } else if (previous?.artStyle && current.artStyle !== null) {
    tagPairs.push({ key: SummaryTag.Art.ArtStyle, val: previous.artStyle });
  } else {
    tagPairs.push({ key: SummaryTag.Art.ArtStyle, val: "-" });
  }
  return tagPairs;
}

class ArtAggregate extends AggregateBase<Art.Encrypted, Command, Event> {
  state: Art.Encrypted;
  kind: string;
  relatedReads?: Art.RelatedReads;
  relatedUpdates: Art.RelatedUpdates = {};

  constructor(state: Art.Encrypted, relatedReads?: Art.RelatedReads) {
    super();
    this.state = state;
    this.kind = state.assetType;
    if (relatedReads) this.relatedReads = relatedReads;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return this.handleCreateAsset(command).map(preSealEvent);
      case Command.Kind.UpdateAsset:
        return this.handleUpdateAsset(command).map(preSealEvent);
      case Command.Kind.RelocateAsset:
        return this.handleRelocateAsset(command).map(preSealEvent);
      case Command.Kind.DeleteAsset:
        return this.handleDeleteAsset(command).map(preSealEvent);
      case Command.Kind.AddInsurance:
        return handleAddInsurance(
          this.state.acquisition?.insuranceIds,
          command
        ).map(preSealEvent);
      case Command.Kind.RemoveInsurance:
        return handleRemoveInsurance(
          this.state.acquisition?.insuranceIds,
          command
        ).map(preSealEvent);
      case Command.Kind.AddValuation:
        return SupportValuationAggregate.handleAdd<Art.Encrypted>(
          this,
          command,
          {
            shouldUpdate: SummaryUtils.shouldUpdateSummary(this.state),
            detail: {
              myOwnership: this.state.ownership?.myOwnership,
              itemNumber: this.state.number,
              tags: toTagPair(this.state),
            },
          }
        );
      case Command.Kind.UpdateValuation:
        return SupportValuationAggregate.handleUpdate<Art.Encrypted>(
          this,
          command,
          {
            shouldUpdate: SummaryUtils.shouldUpdateSummary(this.state),
            detail: {
              myOwnership: this.state.ownership?.myOwnership,
              itemNumber: this.state.number,
              tags: toTagPair(this.state),
            },
          }
        );
      case Command.Kind.DeleteValuation:
        return SupportValuationAggregate.handleDelete<Art.Encrypted>(
          this,
          command,
          {
            shouldUpdate: SummaryUtils.shouldUpdateSummary(this.state),
            detail: {
              myOwnership: this.state.ownership?.myOwnership,
              itemNumber: this.state.number,
              tags: toTagPair(this.state),
            },
          }
        );
      case Command.Kind.MarkAsSold:
        return SupportSellAggregate.handleAdd<Art.Encrypted>(this, command, {
          shouldUpdate: SummaryUtils.shouldUpdateSummary(this.state),
          detail: {
            myOwnership: this.state.ownership?.myOwnership,
            itemNumber: this.state.number,
            tags: toTagPair(this.state),
          },
        });
      case Command.Kind.UpdateSoldInfo:
        return SupportSellAggregate.handleUpdate<Art.Encrypted>(this, command);
      case Command.Kind.DeleteSoldInfo:
        return SupportSellAggregate.handleDelete<Art.Encrypted>(this, command, {
          shouldUpdate: SummaryUtils.shouldUpdateSummary(
            this.state,
            ExcludeStatus.Sold
          ),
          detail: {
            myOwnership: this.state.ownership?.myOwnership,
            itemNumber: this.state.number,
            tags: toTagPair(this.state),
          },
        });

      case Command.Kind.AddAction:
        return SupportActionAggregate.handleAdd<
          Art.Encrypted,
          Art.SupportActionsEncrypted
        >(this, command);
      case Command.Kind.UpdateAction:
        return SupportActionAggregate.handleUpdate<
          Art.Encrypted,
          Art.SupportActionsUpdate
        >(this, command);
      case Command.Kind.DeleteAction:
        return SupportActionAggregate.handleDelete(this, command);
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state = event.asset;
        if (this.state.acquisition && this.state.acquisition.insuranceIds)
          this.relatedUpdates.addedInsuranceIds =
            this.state.acquisition.insuranceIds;
        break;
      case Event.Kind.AssetUpdated:
        applyUpdateToObject(
          this.state,
          // FIXME: remove `asset` after migration
          event.current ? event.current : event.asset
        );
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetDeleted:
        if (this.state.groupIds && this.state.groupIds.length > 0) {
          this.relatedUpdates.removedGroupIds = this.state.groupIds;
        }
        if (this.state.acquisition && this.state.acquisition.insuranceIds)
          this.relatedUpdates.removedInsuranceIds =
            this.state.acquisition.insuranceIds;
        this.state = setObjectDeleted(this.state);
        break;
      case Event.Kind.ValueUpdated:
        this.state.value = event.current;
        if (event.valuationId) this.state.valueSourceId = event.valuationId;
        else delete this.state.valueSourceId;
        this.state.updateAt = time;
        break;
      case Event.Kind.GroupsUpdated:
        if (event.addIds.length > 0)
          this.relatedUpdates.addedGroupIds = event.addIds;
        if (event.removedIds.length > 0)
          this.relatedUpdates.removedGroupIds = event.removedIds;
        break;
      case Event.Kind.LocationUpdated:
        this.state.location = event.current;
        this.state.updateAt = time;
        break;
      case Event.Kind.InsuranceUpdated:
        if (event.previous && event.previous.length > 0) {
          if (event.current) {
            const removedInsuranceIds: string[] = [];
            event.previous.forEach((id) => {
              if (event.current!.includes(id)) return;
              removedInsuranceIds.push(id);
            });
            if (removedInsuranceIds.length > 0) {
              this.relatedUpdates.removedInsuranceIds = removedInsuranceIds;
            }
          } else {
            this.relatedUpdates.removedInsuranceIds = event.previous;
          }
        } else if (event.current) {
          this.relatedUpdates.addedInsuranceIds = event.current;
        }
        if (this.state.acquisition) {
          this.state.acquisition.insuranceIds = event.current;
        } else {
          this.state.acquisition = {
            //#HACK nothing encrypted, will be replace by asset IV if encrypted filed is added
            ...CollectableAcquisition.defaultValueWithEncryptedField(""),
            insuranceIds: event.current,
          };
        }
        break;

      case Event.Kind.ValuationAdded:
        SupportValuationAggregate.applyAdded<Art.Encrypted>(this, event);
        break;
      case Event.Kind.ValuationUpdated:
        SupportValuationAggregate.applyUpdated<Art.Encrypted>(this, event);
        break;
      case Event.Kind.ValuationDeleted:
        SupportValuationAggregate.applyDeleted<Art.Encrypted>(this, event);
        break;
      case Event.Kind.SoldInfoAdded:
        SupportSellAggregate.applyAdded<Art.Encrypted>(this, event);
        break;
      case Event.Kind.SoldInfoUpdated:
        SupportSellAggregate.applyUpdated<Art.Encrypted>(this, event);
        break;
      case Event.Kind.SoldInfoDeleted:
        SupportSellAggregate.applyDeleted<Art.Encrypted>(this, event);
        break;

      case Event.Kind.ActionAdded:
        SupportActionAggregate.applyAdded<
          Art.Encrypted,
          Art.SupportActionsEncrypted
        >(this, event);
        break;
      case Event.Kind.ActionUpdated:
        SupportActionAggregate.applyUpdated<
          Art.Encrypted,
          Art.SupportActionsUpdate
        >(this, event);
        break;
      case Event.Kind.ActionDeleted:
        SupportActionAggregate.applyDeleted(this, event);
        break;
    }
    return this;
  }

  private handleCreateAsset({
    asset,
    executerId,
    valuation,
  }: Command.CreateAsset): Event[] {
    Art.validateEncryptedObj(asset);
    if (!valuation)
      throw new InvalidInput("Valuation is required when creating asset");
    asset.valueSourceId = valuation.id;
    const currTags: TagPair[] = toTagPair(asset);
    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.AssetCreated,
        asset,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: {},
            prevAssetNumber: 0,
            currAssetNumber: 1,
            prevItemNumber: 0,
            currItemNumber: asset.number,
            currTags: deepCopy(currTags),
          },
        ],
      },
      {
        executerId,
        kind: Event.Kind.LocationUpdated,
        current: asset.location,
      },
      {
        executerId,
        kind: Event.Kind.ValuationAdded,
        data: valuation,
      },
      {
        executerId,
        kind: Event.Kind.ValueUpdated,
        current: asset.value,
        valuationId: valuation.id,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: MultiCurrencyAmount.fromAmounts(
              calculateOwnedValue(asset.value, asset.ownership?.myOwnership)
            ),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: asset.number,
            currItemNumber: asset.number,
            prevTags: deepCopy(currTags),
            currTags: deepCopy(currTags),
          },
        ],
      },
    ];

    if (asset.groupIds && asset.groupIds.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: asset.groupIds,
        removedIds: [],
      });
    }
    if (asset.ownership) {
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        current: asset.ownership,
      };
      events.push(shareholderUpdated);
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        current: asset.beneficiary,
      });
    }
    if (asset.attachments) {
      events.push(
        ...SharedEvent.attachmentEventOnCreate(
          executerId,
          asset.attachments,
          asset.mainImage
        )
      );
    }
    return events;
  }

  private handleUpdateAsset({
    executerId,
    asset,
    addedToGroup,
    removedFromGroup,
    newImages,
    newMainImage,
    removedImages,
    locationPrimaryDetailsUpdated,
  }: Command.UpdateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    Art.validateEncryptedObj(asset);
    const events: Event[] = [];
    const shouldUpdateSummary = SummaryUtils.shouldUpdateSummary(this.state);
    // used to trace the change after each event
    let prevTags = toTagPair(this.state);
    let prevValue = this.state.value;
    let prevOwnedValue = calculateOwnedValue(
      this.state.value,
      this.state.ownership?.myOwnership
    );

    if (asset.value) {
      const valueUpdated: Event.ValueUpdated = {
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.value,
        current: asset.value,
      };
      if (shouldUpdateSummary) {
        const currOwnedValue = calculateOwnedValue(
          asset.value,
          this.state.ownership?.myOwnership
        );
        valueUpdated.summaryData = [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
            currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: this.state.number,
            currItemNumber: this.state.number,
            prevTags: deepCopy(prevTags),
            currTags: deepCopy(prevTags),
          },
        ];
        prevValue = { ...asset.value };
        prevOwnedValue = { ...currOwnedValue };
      }
      events.push(valueUpdated);
      delete asset.value;
    }
    const assetUpdatedEvent: Event.AssetUpdated = {
      executerId,
      kind: Event.Kind.AssetUpdated,
      // TODO: remove this
      asset,
      previous: deepCopy(this.state),
      current: asset,
    };

    const currOwnedValue = calculateOwnedValue(
      prevValue,
      asset.ownership?.myOwnership || this.state.ownership?.myOwnership
    );
    const isTagUpdated = asset.subtype || asset.artStyle;
    if (
      shouldUpdateSummary &&
      (!Amount.equal(prevOwnedValue, currOwnedValue) ||
        asset.number ||
        isTagUpdated)
    ) {
      const currTags = toTagPair(asset, this.state);
      assetUpdatedEvent.summaryData = [
        {
          prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
          currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
          prevAssetNumber: 1,
          currAssetNumber: 1,
          prevItemNumber: this.state.number,
          currItemNumber: asset.number || this.state.number,
          prevTags: deepCopy(prevTags),
          currTags: deepCopy(currTags),
        },
      ];
      if (isTagUpdated) prevTags = deepCopy(currTags);
    }
    if (locationPrimaryDetailsUpdated)
      assetUpdatedEvent.locationPrimaryDetailsUpdated = true;
    events.push(assetUpdatedEvent);

    if (addedToGroup || removedFromGroup) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: addedToGroup ?? [],
        removedIds: removedFromGroup ?? [],
      });
    }
    if (newImages) {
      events.push({
        executerId,
        kind: Event.Kind.ImageAdded,
        images: newImages,
      });
    }
    if (removedImages) {
      events.push({
        executerId,
        kind: Event.Kind.ImageRemoved,
        images: removedImages,
      });
    }
    if (newMainImage) {
      events.push({
        executerId,
        kind: Event.Kind.MainImageSet,
        previous: this.state.mainImage,
        current: newMainImage,
      });
    }
    if (
      asset.location &&
      asset.location.locationId !== this.state.location.locationId
    ) {
      const event: Event.LocationUpdated = {
        executerId,
        kind: Event.Kind.LocationUpdated,
        current: asset.location,
      };
      if (this.state.location) event.previous = this.state.location;
      events.push(event);
    }
    if (asset.acquisition) {
      const maybeEvent = SharedEvent.insuranceEventOnUpdate(
        executerId,
        this.state.acquisition?.insuranceIds,
        asset.acquisition.insuranceIds
      );
      if (maybeEvent) events.push(maybeEvent);
    }

    if (asset.ownership) {
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        previous: this.state.ownership,
        current: asset.ownership,
      };
      events.push(shareholderUpdated);
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        previous: this.state.beneficiary,
        current: asset.beneficiary,
      });
    }

    return events;
  }

  private handleRelocateAsset({
    executerId,
    fromLocationId,
    toLocation,
  }: Command.RelocateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    LocationInfo.validateEncryptedObj(toLocation);
    if (this.state.location.locationId == fromLocationId) {
      return [
        {
          executerId,
          kind: Event.Kind.LocationUpdated,
          previous: this.state.location,
          current: {
            ...toLocation,
            [EncryptionFieldKey]: this.state.location[EncryptionFieldKey],
          },
        },
      ];
    } else {
      return [];
    }
  }

  private handleDeleteAsset({ executerId }: Command.DeleteAsset): Event[] {
    AssetV2.checkDelete(this.state);
    const shouldUpdateSummary = SummaryUtils.shouldUpdateSummary(this.state);
    return [
      {
        executerId,
        kind: Event.Kind.AssetDeleted,
        summaryData: shouldUpdateSummary
          ? [
              {
                prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                  calculateOwnedValue(
                    this.state.value,
                    this.state.ownership?.myOwnership
                  )
                ),
                currOwnedValue: {},
                prevAssetNumber: 1,
                currAssetNumber: 0,
                prevItemNumber: this.state.number,
                currItemNumber: 0,
                prevTags: toTagPair(this.state),
                currTags: [],
              },
            ]
          : undefined,
      },
    ];
  }
}
