import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2012%2013%22%20fill%3D%22%23ddd%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.93%206.727a2.15%202.15%200%200%200-1.43-.75q0-1.461-1.02-2.48Q7.461%202.477%206%202.477c-.79%200-1.5.235-2.125.715a3.42%203.42%200%200%200-1.25%201.86%202.62%202.62%200%200%200-1.54.975A2.76%202.76%200%200%200%20.5%207.767c0%20.755.27%201.4.805%201.925.54.535%201.195.785%201.945.785h6q.936-.001%201.595-.655.654-.659.655-1.595%200-.863-.57-1.5M6.5%208.977h-1v-1h1zm0-2h-1v-3h1z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CloudAlertIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 12 13'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M10.93 6.727a2.15 2.15 0 0 0-1.43-.75q0-1.461-1.02-2.48Q7.461 2.477 6 2.477c-.79 0-1.5.235-2.125.715a3.42 3.42 0 0 0-1.25 1.86 2.62 2.62 0 0 0-1.54.975A2.76 2.76 0 0 0 .5 7.767c0 .755.27 1.4.805 1.925.54.535 1.195.785 1.945.785h6q.936-.001 1.595-.655.654-.659.655-1.595 0-.863-.57-1.5M6.5 8.977h-1v-1h1zm0-2h-1v-3h1z'
        }
      />
    </svg>
  )
}
