import { AccountData } from "./database/account";
import { ArtsRepo } from "./database/arts";
import { BelongingsRepo } from "./database/belongings";
import { CashAndBankingRepo } from "./database/cashAndBanking";
import { CryptocurrencyRepo } from "./database/cryptocurrencies";
import { Encryption, EncryptionManager } from "./database/encryption";
import { ExchangeRate, ExchangeRateSource } from "./database/exchangeRate";
import { GroupsRepo } from "./database/groups";
import { InsuranceRepo } from "./database/insurance";
import { OtherInvestmentRepo } from "./database/otherInvestments";
import { PropertiesRepo } from "./database/properties";
import { TraditionalInvestmentRepo } from "./database/traditionalInvestments";
import { WineAndSpiritsRepo } from "./database/wineAndSprits";
import { FullRefs, Refs } from "./refs";
import {
  Currency,
  SupportActivityType,
  type ActualAssetType,
  type SearchFilterResult,
  type SearchResult,
  BackupCode,
  AssetV2,
  PropertyAssets,
  MapiSearchWithValueObject,
  RawMapiSearchWithValueObject,
  Amount,
  BackupCodeEncrypted,
} from "./types/common";
import {
  DailyTimeTickerPrice,
  ExchangeRateRawResult,
  ExchangeRateResult,
  PlaidAccountResponse,
  StockSearchInfo,
  StockSearchResult,
} from "./types/postgres";

import {
  Auth,
  CoreFirestore,
  Transaction,
  checkAndGetData,
  getQueriedData,
} from "../coreFirebase";
import { ActivityManger } from "./database/activities";
import { Attachments } from "./database/attachments";
import { DelegatesData } from "./database/delegates";
import { ActivityKind } from "./types/activities";
import type { Account } from "./types/cashAndBanking";
import { Delegate } from "./types/delegates";
import { EncryptionLib } from "./types/encryptionLib";
import { AssetType, CustomizedType } from "./types/enums";
import { wineTypeOptions } from "./types/options";
import { SummaryManager } from "./types/summaryManager";
import { Profile } from "./types/user";
import {
  RemovalReason,
  Wine,
  WineCatalogue,
  type VintageRange,
} from "./types/wineAndSprits";
import { getBackupCodesPath, getPlaidInstitutionTokenPath } from "./refPaths";
import { DbSharedFields, Params } from "./types/database";
import {
  ExportHandler,
  ExportRowV1,
  ExportRowV2,
  newExportHandler,
  ProgressMetadata,
} from "./database/exportHandler";
import {
  PriceSourceWrapper,
  StockPrice,
  StockSymbolExchange,
} from "./database/priceSource";
import { GlobalDashboardReportExporter } from "./database/report";
import { ClientRepo } from "./types/aggregate";
import { ArtistBasic, ArtistExhibitions, ArtistFull } from "./types/artist";
import { ComparativeNetWorthReport } from "./types/reports";
import {
  newQuerierV1,
  newQuerierV2,
} from "./database/exportHandler/firestoreQuerier";
import type { AcquisitionType } from "./types/common/acquisition";
import type { Art, ArtStyle } from "./types/arts";
import type { Portfolio } from "./types/traditionalInvestments";
import type { OtherInvestment } from "./types/otherInvestments";
import type { Cryptocurrency } from "./types/cryptocurrencies";
import type { Insurance } from "./types/insurance";
import type { Property } from "./types/properties";
import type {
  Belonging,
  OtherCollectable,
  OtherCollectableType,
} from "./types/belongings";
import { PlaidToken, PlaidTokenStatus } from "./types/finance";
import Decimal from "decimal.js";

const CRYPTO_EOD_EXCHANGE = "CC";

export const DEFAULT_BATCH_SIZE = 500;
export type BatchUpdateContext<Model, State, Command, TEvent> = {
  model: new () => Model;
  repoFunc: (
    transaction: Transaction
  ) => Promise<ClientRepo<State, Command, TEvent>>;
  updateFunc: (
    input: any,
    repo: ClientRepo<State, Command, TEvent>
  ) => Promise<unknown>;
  amount: number;
  batchSize?: number;
  decorator?: (input: Model, index: number) => Model;
  onBatchResult?: (result: BatchResult<Model>) => boolean | void;
};
export type BatchResult<T> = {
  batchNumber: number;
  batchTotal: number;
  error: Error | undefined;
};

export class Database {
  readonly userId: string;
  protected readonly auth: Auth;
  protected readonly refs: FullRefs;
  protected readonly encryptionLib: EncryptionLib;
  protected readonly params: Params;

  readonly Account: AccountData;
  readonly Attachments: Attachments;
  readonly Encryption: EncryptionManager;
  readonly Delegates: DelegatesData;

  readonly group: GroupsRepo;

  readonly belonging: BelongingsRepo;
  readonly otherCollectable: BelongingsRepo;
  readonly wine: WineAndSpiritsRepo;
  readonly art: ArtsRepo;
  readonly property: PropertiesRepo;
  readonly insurance: InsuranceRepo;
  readonly cryptocurrency: CryptocurrencyRepo;
  readonly otherInvestment: OtherInvestmentRepo;
  readonly traditionalInvestment: TraditionalInvestmentRepo;
  readonly cashAndBanking: CashAndBankingRepo;

  //need initialize
  ExRate: ExchangeRate;
  readonly summaryManager: SummaryManager;

  constructor(
    userId: string,
    encryptionLib: EncryptionLib,
    params: Params,
    auth: Auth,
    rateSource?: ExchangeRateSource
  ) {
    this.userId = userId;
    this.auth = auth;
    const selfRefs = new Refs(userId);
    this.refs = { currentRefs: selfRefs, selfRefs };
    this.encryptionLib = encryptionLib;
    this.params = params;

    if (rateSource) this.ExRate = new ExchangeRate(rateSource);
    else this.ExRate = new ExchangeRate(this);
    this.summaryManager = new SummaryManager();

    const encryptionSelf = new Encryption(
      DEKExistsInFirestore(this.refs.currentRefs),
      encryptionLib
    );
    this.Encryption = {
      current: encryptionSelf,
      self: encryptionSelf,
    };

    const shared: DbSharedFields = {
      refs: this.refs,
      encryption: this.Encryption,
      exRate: this.ExRate,
      summaryManager: this.summaryManager,
    };

    this.Attachments = new Attachments(userId, this.refs, this.Encryption);

    this.Account = new AccountData(shared, userId, this.auth);

    this.group = new GroupsRepo(
      this.refs,
      this.Encryption,
      this.summaryManager
    );

    this.belonging = new BelongingsRepo(shared, AssetType.Belonging);
    this.otherCollectable = new BelongingsRepo(
      shared,
      AssetType.OtherCollectables
    );
    this.wine = new WineAndSpiritsRepo(shared);
    this.art = new ArtsRepo(shared);
    this.property = new PropertiesRepo(shared, this);
    this.insurance = new InsuranceRepo(shared);
    this.cryptocurrency = new CryptocurrencyRepo(shared, this);
    this.otherInvestment = new OtherInvestmentRepo(shared);
    this.traditionalInvestment = new TraditionalInvestmentRepo(shared, this);
    this.cashAndBanking = new CashAndBankingRepo(shared);
    this.Delegates = new DelegatesData(
      this.refs.selfRefs,
      this.params,
      this.auth
    );
  }

  genAssetId(): string {
    return CoreFirestore.genAssetId();
  }

  getWineDocumentId(wineCatalogueId: string): string {
    return Wine.buildWineId(this.refs.currentRefs.userId, wineCatalogueId);
  }

  async switchToDelegate(
    data: { id: string; permissions: Delegate.EncryptedDelegator } | null
  ): Promise<void> {
    if (data === null) {
      this.refs.currentRefs = this.refs.selfRefs;
      this.Encryption.current = this.Encryption.self;

      this.Account.setCurrentPreferences();
      const preferences = await this.Account.tryGetCurrentPreferences();
      if (preferences && preferences.baseCurrency) {
        await this.ExRate.getAndSetBaseExRate(preferences.baseCurrency);
      }
      this.summaryManager.setSummaryLoader(this.refs.currentRefs);
    } else {
      const id = data.id;
      this.refs.currentRefs = new Refs(id);
      this.Encryption.current = new Encryption(
        DEKExistsInFirestore(this.refs.currentRefs),
        this.encryptionLib
      );
      // TODO: update encryption object
      const userToken = await this.auth?.currentUser?.getIdToken();
      if (!userToken) throw new Error("User token not found");

      await this.Encryption.current.loadDEK(id, userToken);

      this.Account.setCurrentPreferences();
      const preferences = await this.Account.tryGetCurrentPreferences();
      if (preferences && preferences.baseCurrency) {
        await this.ExRate.getAndSetBaseExRate(preferences.baseCurrency);
      }
      this.summaryManager.setSummaryLoader(
        this.refs.currentRefs,
        data?.permissions
      );
    }
  }

  async getGlobalDashboard(inputCurrency?: Currency) {
    this.ExRate.checkInitialized();
    const currency = inputCurrency || this.ExRate.BaseCurrency!;
    const exchangeRates = await this.ExRate.getToTargetExchangeRates(currency);

    return this.summaryManager.getGlobalDashboard(
      this.refs.currentRefs,
      exchangeRates,
      new PriceSourceWrapper(this)
    );
  }

  getActivityManager(
    assetType: SupportActivityType,
    assetId?: string,
    activityKinds?: ActivityKind[],
    batchLimit: number = 10
  ) {
    return new ActivityManger(
      this.refs.currentRefs,
      this.Encryption.current,
      assetType,
      batchLimit,
      assetId,
      activityKinds
    );
  }

  async prepareData(): Promise<void> {
    const user = this.auth.currentUser ?? undefined;
    if (user) {
      const token = await user.getIdToken();
      await this.Encryption!.self.loadDEK(user.uid, token);
      // console.log("dek: ", this.Encryption!.self.dek)
    }
  }

  async resetSummaryLoader(): Promise<void> {
    this.summaryManager.resetSummaryLoader(this.refs.currentRefs);
  }

  unsubscribeSummaries() {
    this.summaryManager.unsubscribe();
  }

  unsubscribe() {
    this.unsubscribeSummaries();
    this.Account.unsubscribePreferences();
  }

  /**
   * - Disable user
   * - Cancel subscription if is subscribed
   * @see `cloudfunctions/src/closeAccount.ts` - `closeAccount`
   */
  async closeAccount() {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    await this.Account.startCloseAccount();

    const url = `${endpoint}/closeAccount`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw Error(resp.statusText);
  }

  async reorderMFA(primaryPhoneNumber: string) {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/reorderMFA`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ primaryPhoneNumber }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw Error(resp.statusText);
  }

  async sendUnenrollMultiFactorEmail() {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");

    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    // Notify user that MFA is disabled
    const url = `${endpoint}/sendDisableMFAEmail`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
  }

  /***** one schema *****/
  /**
   * Get OneSchema user token
   */
  async getOneSchemaToken(): Promise<string> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/getOSJwt`;
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw Error(resp.statusText);
    const data = await resp.json();

    const token: string = data.jwt;
    return token;
  }

  /***** plaid *****/
  /**
   * Get plaid link token
   * @see `cloudfunctions/src/plaid.ts` - `plaidLinkToken`
   */
  async getPlaidLinkToken(): Promise<string> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const uid = this.refs.currentRefs.userId;

    const url = `${endpoint}/plaidLinkToken`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ uid }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: string = data.link_token;
    return result;
  }

  /**
   * Get plaid update institution link token
   * @see `cloudfunctions/src/plaid.ts` - `plaidUpdateToken`
   */
  async getPlaidUpdateToken(docId: string): Promise<string> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");
    const uid = this.refs.currentRefs.userId;

    const url = `${endpoint}/plaidUpdateToken`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ uid, docId }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: string = data.link_token;
    return result;
  }

  /**
   * Get plaid accounts
   * @see `cloudfunctions/src/plaid.ts` - `plaidExchangeQuery`
   */
  async getPlaidAccounts(
    plaidPublicToken: string,
    docId?: string
  ): Promise<PlaidAccountResponse> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const uid = this.refs.currentRefs.userId;

    const url = `${endpoint}/plaidExchangeQuery`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ plaidPublicToken, uid, docId }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: PlaidAccountResponse = data;
    return result;
  }

  /**
   * Update plaid account balances
   * @see `cloudfunctions/src/plaid.ts` - `plaidExchangeQuery`
   */
  async updatePlaidAccountBalances(docId: string) {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const uid = this.refs.currentRefs.userId;

    const url = `${endpoint}/plaidUpdateBalances`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ uid, docId }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
  }

  // Perhaps there is a better location for this?
  async getPlaidTokenStatus(docId: string): Promise<PlaidTokenStatus> {
    const uid = this.refs.currentRefs.userId;
    if (!uid) throw new Error("User id not found");

    const path = getPlaidInstitutionTokenPath(uid, docId);
    const ref = CoreFirestore.doc<PlaidToken>(path);
    const doc = await CoreFirestore.getDoc(ref).then(checkAndGetData);

    return doc.status;
  }

  // Perhaps there is a better location for this?
  // Should this be done in cloud function instead?
  async disconnectPlaid(docId: string) {
    const uid = this.refs.currentRefs.userId;
    if (!uid) throw new Error("User id not found");

    const path = getPlaidInstitutionTokenPath(uid, docId);
    const ref = CoreFirestore.doc<PlaidToken>(path);
    const doc = await CoreFirestore.getDoc(ref).then(checkAndGetData);
    CoreFirestore.updateDoc(ref, {
      status: { ...doc.status, isConnected: false },
    });
  }

  /***** stripe *****/
  /**
   * get url to make initial subscription payment
   * @see `cloudfunctions/src/newStripe.ts` - `getStripeCheckoutSessionUrl`
   */
  async getStripeCheckoutSessionUrl(
    payload: Record<"returnUrl", string>
  ): Promise<Record<"url", string>> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/getStripeCheckoutSessionUrl`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(payload),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();
    return data;
  }

  /**
   * get url to manage existing subscription
   * @see `cloudfunctions/src/newStripe.ts` - `getStripeCheckoutSessionUrl`
   */
  async getStripePortalSessionUrl(
    payload: Record<"returnUrl", string>
  ): Promise<Record<"url", string>> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/getStripePortalSessionUrl`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(payload),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();
    return data;
  }

  /**
   * get stock infos by EOD
   */
  async getStockInfos(query: {
    keyword?: string;
    exchange?: string;
    code?: string;
    specificDate?: string;
    offset?: number;
    limit?: number;
  }) {
    const result = await this.getEODInfos(query);
    return result.filter((r) => r.exchange !== CRYPTO_EOD_EXCHANGE);
  }

  async getCryptoInfos(query: {
    keyword?: string;
    code?: string;
    specificDate?: string;
    offset?: number;
    limit?: number;
  }) {
    const result = await this.getEODInfos({
      ...query,
      exchange: CRYPTO_EOD_EXCHANGE,
    });
    return result.filter((r) => r.exchange === CRYPTO_EOD_EXCHANGE);
  }

  /**
   *
   * @param request from mapi server
   * @returns
   */
  private async getEODInfos(request: {
    keyword?: string;
    exchange?: string;
    code?: string;
    specificDate?: string;
    offset?: number;
    limit?: number;
  }) {
    if (
      request.keyword === undefined &&
      request.exchange === undefined &&
      request.code === undefined &&
      request.specificDate === undefined
    ) {
      return [];
    }
    // FIXME: Unable to get stock price by Date now
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    if (request.keyword !== undefined) {
      request.keyword = request.keyword.replaceAll(/[().]/g, " ");
    }
    const queryParams = buildQueryParams(request);
    const url = `${endpoint}/v0/stock/daily?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);

    // #NOTE: not support FOREX (exchanges between two currencies) for now
    const data = (await resp.json()).filter(
      (d: any) => d.exchange !== "FOREX" // "FOREX": Exchange rates
    ) as Record<
      | "code"
      | "exchange"
      | "date"
      | "closePrice"
      | "name"
      | "country"
      | "currency"
      | "isin",
      string | null
    >[];
    return data;
  }

  //#TODO: separate this into a different API
  async getStockPrice(stocks: StockSymbolExchange[]): Promise<StockPrice> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    async function getSingle(exchange: string, code: string) {
      if (!exchange || !code || exchange.length == 0 || code.length == 0)
        return [];
      const queryParams = buildQueryParams({
        exchange,
        code,
      });
      const url = `${endpoint}/v0/stock/daily?${queryParams}`;
      const resp = await fetch(url, config);
      if (resp.status !== 200) throw new Error(resp.statusText);

      const data = await resp.json();
      return data.map((v: any) => {
        return {
          currency: v.currency,
          value: new Decimal(v.closePrice).toNumber(),
        } as Amount;
      });
    }

    const results = await Promise.all(
      stocks.map((stock) => getSingle(stock.exchange, stock.symbol))
    );
    const stockPrice: StockPrice = {};
    stocks.forEach((stock, index) => {
      if (results[index].length === 0) {
        console.warn(
          `No stock price found for ${stock.exchange}:${stock.symbol}`
        );
      }

      if (!stockPrice[stock.exchange]) stockPrice[stock.exchange] = {};

      const price: Amount =
        results[index].length > 0
          ? results[index][0]
          : Amount.zero(this.ExRate.BaseCurrency!);

      stockPrice[stock.exchange][stock.symbol] = price;
    });
    return stockPrice;
  }

  async getCryptoPrice(coinCodes: string[]): Promise<Record<string, Amount>> {
    const result = await this.getStockPrice(
      coinCodes.map((c) => ({ symbol: c, exchange: CRYPTO_EOD_EXCHANGE }))
    );
    return result[CRYPTO_EOD_EXCHANGE] || {};
  }

  /**
   * Not Used now, until /v0/catalogue/stock/search fixed
   */
  async getStocksByKeyword(params: Record<"keyword", string>) {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/catalogue/stock/search?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);

    const data = await resp.json();
    return data as Record<"symbol" | "exchange", string>[];
  }

  //#TODO find where this belongs
  async getLatestTimeTickerPrices(
    pairs: StockSearchInfo[]
  ): Promise<StockSearchResult[]> {
    const result: StockSearchResult[] = [];

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];

      const snapshot =
        await CoreFirestore.getDocsFromCollection<DailyTimeTickerPrice>(
          this.refs.currentRefs.TimeTickerPrices,
          CoreFirestore.where("exchange", "==", pair.exchange),
          CoreFirestore.where("code", "==", pair.symbol),
          CoreFirestore.orderBy("date", "desc")
        );
      if (!snapshot.empty && snapshot.docs.length > 0) {
        const doc = snapshot.docs[0];
        const {
          name,
          code,
          exchange,
          currency,
          closePrice,
        }: DailyTimeTickerPrice = doc.data();
        const record: StockSearchResult = {
          nameWithCode: `${name} (${code})`,
          exchange: exchange,
          priceWithCurrency: `${currency} ${closePrice.toFixed(2)}`,
        };
        result.push(record);
      } else {
        throw new Error(
          "Attempt to search fireId which has no stock price record"
        );
      }
    }

    return result;
  }

  /**
   * Create backup codes
   * @see `cloudfunctions/src/backupCode.ts` - `createBackupCodes`
   */
  async createBackupCodes(): Promise<string[]> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/createBackupCodes`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();
    return data;
  }

  /**
   * Get backup codes
   */
  async getBackupCodes(): Promise<string[]> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const uid = this.refs.currentRefs.userId;

    const url = `${endpoint}/old`;
    const docPath = getBackupCodesPath(uid);
    const docRef = CoreFirestore.doc<BackupCodeEncrypted>(docPath);
    const docData = await CoreFirestore.getDoc(docRef).then(checkAndGetData);
    if (!docData.encrypted) throw new Error("Invalid backup code data");

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ uid, docPath }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data: BackupCode = await resp.json();

    return data.codes;
  }

  /***** base on antony's api *****/
  /**
   * Get stock prices with query
   * @see `api/bin/api/src/api/stock_search.rs` - `get_stock_info_by_search`
   */
  async getStockPricesWithQuery(keyword: string): Promise<StockSearchResult[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { keyword };
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/stock_search?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const infos = await resp.json();

    const result = this.getLatestTimeTickerPrices(infos);
    return result;
  }

  /***** base on antony's api *****/
  /**
   * Get stock prices with query
   * @see `api/bin/api/src/api/stock_search.rs` - `get_stock_info_by_search`
   */
  async getExchangeRate(
    primary?: Currency,
    secondary?: Currency
  ): Promise<ExchangeRateResult[]> {
    if (primary === undefined && secondary === undefined) {
      throw new Error(
        "Primary and secondary currency cannot both be undefined"
      );
    }

    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params: {
      baseCurrency?: Currency;
      quoteCurrency?: Currency;
    } = {};
    if (primary) params.baseCurrency = primary;
    if (secondary) params.quoteCurrency = secondary;
    const queryParams = buildQueryParams(params);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    //#NOTE try the new api
    let resp = await fetch(
      `${endpoint}/v0/exchange_rate/latest?${queryParams}`,
      config
    );
    let rates: ExchangeRateRawResult[];
    switch (resp.status) {
      case 200: {
        rates = (await resp.json()) as ExchangeRateRawResult[];
        //#NOTE if we have data, we can break, or we fallthrough to try old api
        if (rates.length > 0) {
          break;
        }
      }
      case 404:
      case 500: {
        resp = await fetch(
          `${endpoint}/v0/exchange_rate?${queryParams}`,
          config
        );
        if (resp.status !== 200) throw new Error(resp.statusText);
        rates = (await resp.json()) as ExchangeRateRawResult[];
        break;
      }
      default:
        throw new Error(resp.statusText);
    }

    return rates.map(({ baseCurrency, quoteCurrency, rate, date }) => ({
      baseCurrency,
      quoteCurrency,
      rate: parseFloat(rate),
      date: new Date(date),
    }));
  }

  async getRawAssetById<T extends AssetV2>(
    assetType: AssetType,
    id: string
  ): Promise<T> {
    const docRef = this.refs.currentRefs.getAssetDocRef(assetType, id);
    const data = await CoreFirestore.getDoc(docRef).then(checkAndGetData);

    return data as T;
  }

  async getAssetsByIds<T extends { id: string }>(
    assetType: AssetType,
    ids: string[]
  ): Promise<T[]> {
    switch (assetType) {
      case AssetType.CashAndBanking:
        return this.cashAndBanking.getAccountsByIds(ids) as any as Promise<T[]>;
      case AssetType.BankOrInstitution:
        return this.cashAndBanking.getInstitutionsByIds(ids) as any as Promise<
          T[]
        >;
      case AssetType.TraditionalInvestments:
        return this.traditionalInvestment.getPortfoliosByIds(
          ids
        ) as any as Promise<T[]>;
      case AssetType.OtherInvestment:
        return this.otherInvestment.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Cryptocurrency:
        return this.cryptocurrency.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Insurance:
        return this.insurance.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Property:
        return this.property.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Art:
        return this.art.getByIds(ids) as any as Promise<T[]>;
      case AssetType.WineAndSpirits:
        return this.wine.getWinesByIds(ids) as any as Promise<T[]>;
      case AssetType.WinePurchases:
        return this.wine.getWinePurchasesByIds(ids) as any as Promise<T[]>;
      case AssetType.OtherCollectables:
        return this.otherCollectable.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Belonging:
        return this.belonging.getByIds(ids) as any as Promise<T[]>;
    }
  }

  async getAssetsByResults(results: SearchResult[]) {
    // FIXME: There are some data with wrong assetType (Ex. Some otherCollectables are with assetType 'Belonging')
    const assets = await Promise.allSettled(
      results.map(async ({ fireId, category, subType }) => {
        switch (category as ActualAssetType) {
          case AssetType.CashAndBanking:
            return this.cashAndBanking.getAccountById(
              fireId,
              subType as Account.Type
            );
          case AssetType.TraditionalInvestments:
            return this.traditionalInvestment.getPortfolioById(fireId);
          case AssetType.OtherInvestment:
            return this.otherInvestment.getById(fireId);
          case AssetType.Cryptocurrency:
            return this.cryptocurrency.getById(fireId);
          case AssetType.Insurance:
            return this.insurance.getById(fireId);
          case AssetType.Property:
            return this.property.getById(fireId);
          case AssetType.Art:
            return this.art.getById(fireId);
          case AssetType.WineAndSpirits:
            return this.wine.getWineById(fireId);
          case AssetType.OtherCollectables:
            return this.otherCollectable.getById(fireId);
          case AssetType.Belonging:
            return this.belonging.getById(fireId);
        }
      })
    );
    const isFulfilled = <T>(
      input: PromiseSettledResult<T>
    ): input is PromiseFulfilledResult<T> => input.status === "fulfilled";

    return assets.filter(isFulfilled).map(({ value }) => value);
  }

  private async fetchCurrencyCount(queryParams: any, config: any) {
    const respCurrencyCount = await fetch(
      `${this.params.apiEndpoint}/v0/firebase_search/currency_counts`,
      {
        ...config,
        method: "POST",
        body: JSON.stringify(queryParams),
      }
    );
    if (respCurrencyCount.status !== 200)
      throw new Error(respCurrencyCount.statusText);
    return await respCurrencyCount.json();
  }

  private async fetchPropertyAssetsCurrencyCount(
    queryParams: any,
    config: any
  ) {
    const respCurrencyCount = await fetch(
      `${this.params.apiEndpoint}/v0/firebase_search/property_assets/currency_counts`,
      {
        ...config,
        method: "POST",
        body: JSON.stringify(queryParams),
      }
    );
    if (respCurrencyCount.status !== 200)
      throw new Error(respCurrencyCount.statusText);
    return await respCurrencyCount.json();
  }

  private prepareCurrencyParams(requiredCurrencies?: Currency[]) {
    if (!requiredCurrencies) return {};
    this.ExRate.checkInitialized();
    const exchangeRate = requiredCurrencies.map((v: Currency) => ({
      currency: v,
      rate: this.ExRate.getToBaseExchangeRate(v).rate.toString(),
    }));
    return { exchangeRate };
  }

  /**
   * Get assets with query
   * @see `api/bin/api/src/api/firebase_search.rs` - `get_firebase_filter_info`
   */
  async getFilterInfo(assetType: AssetType): Promise<SearchFilterResult> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const paramsNew = {
      userId: this.refs.currentRefs.userId,
      category: [assetType],
    };
    const { requiredCurrencies } = await this.fetchCurrencyCount(
      paramsNew,
      config
    );
    const currencyParams = this.prepareCurrencyParams(requiredCurrencies);
    const params = {
      userId: this.refs.currentRefs.userId,
      category: assetType,
    };
    const queryParams = { ...params, ...currencyParams };

    const url = `${endpoint}/v0/firebase_search/filter_info`;
    const resp = await fetch(url, {
      ...config,
      method: "POST",
      body: JSON.stringify(queryParams),
    });
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();
    const valMin = Math.floor(parseFloat(data.minValue ?? 0));
    const valMax = Math.ceil(parseFloat(data.maxValue ?? 9999999));
    const bottlePriceMin = Math.floor(parseFloat(data.minBottlePrice ?? 0));
    const bottlePriceMax = Math.ceil(
      parseFloat(data.maxBottlePrice ?? 9999999)
    );
    const result: SearchFilterResult = {
      name: data.name ?? [],
      subtype: data.subtype ?? [],
      value: {
        minimum: valMin,
        maximum: valMin === valMax ? valMax + 10 : valMax, // Handle one data case(same value) => add gap value 10
      },
      purchaseAt: {
        minimum: data.minPurchaseAt ? new Date(data.minPurchaseAt) : new Date(),
        maximum: data.maxPurchaseAt ? new Date(data.maxPurchaseAt) : new Date(),
      },
      locationId: data.locationId ?? [],
      masterVarietal: data.masterVarietal,
      artStyle: data.artStyle,
      country: data.country,
      variety: data.varietal,
      brand: data.brand,
      roomId: data.roomId,
      bottlePrice: {
        minimum: bottlePriceMin,
        maximum: bottlePriceMax,
      },
    };

    return result;
  }

  /**
   * Get assets with query
   * @see `api/bin/api/src/api/firebase_search.rs` - `get_firebase_ids_by_search`
   */
  async getAssets(query?: Record<MapiSearchParamsType, string | string[]>) {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { userId: this.refs.currentRefs.userId, ...query };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const { count: totalCount, requiredCurrencies } =
      await this.fetchCurrencyCount(params, config);
    //#NOTE if no data, return empty list to save a call
    if (totalCount === 0) {
      return { totalCount: 0, list: [] };
    }

    const searchEndpoint = requiredCurrencies
      ? "firebase_search/search_with_value"
      : "firebase_search";
    const searchUrl = `${endpoint}/v0/${searchEndpoint}`;
    const queryParams = {
      ...params,
      ...this.prepareCurrencyParams(requiredCurrencies),
    };

    const resp = await fetch(searchUrl, {
      ...config,
      method: "POST",
      body: JSON.stringify(queryParams),
    });
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data: SearchResult[] = await resp.json();

    return {
      totalCount: totalCount as number,
      list: await this.getAssetsByResults(data),
    };
  }

  /**
   * get count and required currencies
   */
  private async getCountAndCurrencies(
    params: Partial<MapiSearchParams>
  ): Promise<{ count: number; currencies: Currency[] }> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/v0/firebase_search/currency_counts`;
    const config: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(params),
    };

    const resp = await fetch(url, config);
    if (resp.status !== 200) throw Error(resp.statusText);
    const data = await resp.json();
    return { count: data.count, currencies: data.requiredCurrencies };
  }

  /**
   * build exchange rate
   */
  private buildExchangeRate(currencies: Currency[]) {
    this.ExRate.checkInitialized();
    return currencies.map((currency) => ({
      currency,
      rate: this.ExRate.getToBaseExchangeRate(currency).rate.toString(),
    }));
  }

  /**
   * get assets by asset type
   */
  async getAssetsByTypes<
    AssetTypes extends AssetType[],
    Assets = GetAssetsByTypes<AssetTypes>
  >(
    assetType: AssetTypes,
    params: Partial<MapiSearchParams>
  ): Promise<{ totalCount: number; list: Assets }> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const baseParams: Partial<MapiSearchParams> = {
      ...params,
      userId: this.refs.currentRefs.userId,
      category: assetType,
    };
    // 1. get count and currencies
    const { count, currencies } = await this.getCountAndCurrencies(baseParams);
    //#NOTE if no data, return empty list to save a call
    if (count === 0) {
      return { totalCount: 0, list: [] as Assets };
    }

    // 2. build exchange rate
    const exchangeRate = this.buildExchangeRate(currencies);

    // 3. get assets from mapi search
    const url = `${endpoint}/v0/firebase_search/search_with_value`;
    const config: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ ...baseParams, exchangeRate }),
    };

    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data: SearchResult[] = await resp.json();
    const assets = await this.getAssetsByResults(data);

    return {
      totalCount: count,
      list: assets as unknown as Assets,
    };
  }

  async getAssetsSubTypeGroup(
    assetType: AssetTypeWithSubtype
  ): Promise<SubtypeGroup> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const baseParams: Record<string, any> = {
      perLimit: 6, //  default 6 per subType
      userId: this.refs.currentRefs.userId,
      category: assetType,
    };

    const url = `${endpoint}/v0/firebase_search/group_snapshot`;
    const config: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(baseParams),
    };

    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data: GroupedSubTypeResult[] = await resp.json();
    const subTypeGroup = await this.subTypeResultToSubTypeGroup(
      data,
      assetType
    );

    return subTypeGroup;
  }
  /**
   * get assets by OtherCollectables labels
   */
  async getOtherCollectablesByLabels<
    Labels extends OtherCollectableType[],
    OtherCollectables = GetOtherCollectableByLabels<Labels>
  >(
    labels: Labels,
    params: Partial<MapiSearchParams>
  ): Promise<{ totalCount: number; list: OtherCollectables }> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const baseParams: Partial<MapiSearchParams> = {
      ...params,
      userId: this.refs.currentRefs.userId,
      category: [AssetType.OtherCollectables],
      subType: labels, // Use labels as subType filter
    };
    // 1. Get count and currencies
    const { count, currencies } = await this.getCountAndCurrencies(baseParams);
    //#NOTE if no data, return empty list to save a call
    if (count === 0) {
      return { totalCount: 0, list: [] as OtherCollectables };
    }

    // 2. Build exchange rate
    const exchangeRate = this.buildExchangeRate(currencies);

    // 3. Get assets from MAPI search
    const url = `${endpoint}/v0/firebase_search/search_with_value`;
    const config: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ ...baseParams, exchangeRate }),
    };

    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data: SearchResult[] = await resp.json();

    // 4. Fetch assets from results
    const assets = await this.getAssetsByResults(data);

    return {
      totalCount: count,
      list: assets as unknown as OtherCollectables,
    };
  }

  /**
   * get assets by property id from Mapi only
   */
  async getMapiAssetsByPropertyId(
    propertyId: string,
    params: Partial<MapiSearchParams>
  ): Promise<{
    totalCount: number;
    list: MapiSearchWithValueObject[];
  }> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const baseParams: Partial<MapiSearchParams> = {
      ...params,
      userId: this.refs.currentRefs.userId,
      locationId: [propertyId],
    };

    // 1. get count and currencies
    const { count, currencies } = await this.getCountAndCurrencies(baseParams);
    //#NOTE if no data, return empty list to save a call
    if (count === 0) {
      return { totalCount: 0, list: [] };
    }

    // 2. build exchange rate
    const exchangeRate = this.buildExchangeRate(currencies);

    // 3. get assets from mapi search
    const url = `${endpoint}/v0/firebase_search/search_with_value`;
    const config: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ ...baseParams, exchangeRate }),
    };

    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const result: RawMapiSearchWithValueObject[] = await resp.json();
    return {
      totalCount: count,
      list: result.map((item) => ({
        ...item,
        ownedValue:
          item.ownedValue && item.valueCurrency
            ? ({
                currency: item.valueCurrency,
                value: parseFloat(item.ownedValue),
              } as Amount)
            : undefined,
      })),
    };
  }

  /**
   * get assets by property id
   */
  async getAssetsByPropertyId<T extends { id: string }>(
    propertyId: string,
    params: Partial<MapiSearchParams>
  ): Promise<{ totalCount: number; list: T[] }> {
    const { totalCount, list } = await this.getMapiAssetsByPropertyId(
      propertyId,
      params
    );

    // 3. get assets from firebase
    const assets = await this.getAssetsByResults(list);
    return { totalCount, list: assets as unknown as T[] };
  }
  async getDocumentVaultFolders(query: {
    category?: string[];
    subType?: string[];
    offset?: number;
    limit?: number;
  }) {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const { category, subType } = query;
    const countBody = {
      category,
      subType,
      userId: this.refs.currentRefs.userId,
    };
    const { count: totalCount } = await this.fetchCurrencyCount(
      countBody,
      config
    );

    const url = `${endpoint}/v0/firebase_search/document_vault/search`;
    const searchBody = JSON.stringify({
      ...query,
      userId: this.refs.currentRefs.userId,
    });
    const response = await fetch(url, { ...config, body: searchBody });
    const list = (await response.json()) as {
      fireId: string;
      category: string;
      subType: string;
      name: string;
      attachmentCount: number;
    }[];

    return { list, totalCount };
  }

  async checkFilesExist(query: { category?: string[]; subType?: string[] }) {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ ...query, userId: this.refs.currentRefs.userId }),
    };

    const url = `${endpoint}/v0/firebase_search/document_vault/attachment_exists`;
    const response = await fetch(url, config);
    const data = await response.json();

    return data as boolean;
  }

  async getAssetsByGlobalSearch(keyword: string): Promise<SearchResult[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const params = {
      userId: this.refs.currentRefs.userId,
      keyword,
      limit: 10,
    };

    const searchUrl = `${endpoint}/v0/firebase_search/search_bar`;
    const response = await fetch(searchUrl, {
      ...config,
      method: "POST",
      body: JSON.stringify(params),
    });
    if (response.status !== 200) throw new Error(response.statusText);
    const searchResults: SearchResult[] = await response.json();

    return searchResults;
  }

  /**
   * Get wines catalogues
   * @see `api/bin/api/src/api/wines.rs` - `get_wines`
   */
  async getWinesCatalogues(
    query?: Record<string, any>
  ): Promise<WineCatalogue[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { ...query };
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/wines?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const checkSubtype = (subtype: string) => {
      const matchedOptions = wineTypeOptions.find(({ label, value }) =>
        [label, value].includes(subtype)
      );
      return matchedOptions?.value ?? "-";
    };

    const result: WineCatalogue[] = data.map((item: any) => ({
      wineId: item.id,
      name: item.name ?? "-",
      subtype: checkSubtype(item.type),
      producer: item.producer ?? "-",
      vintage: item.vintage ?? 1990,
      catalogueImage: item.image?.[0],
      country: item.country ?? undefined,
      masterVarietal: item.masterVarietal ?? undefined,
      variety: item.varietal ?? undefined,
      drinkWindow: [item.drinkWindow.start, item.drinkWindow.end]
        .map((value: string) => (value ?? "").slice(0, 4))
        .join("-"),
      vineyard: item.vineyard ?? undefined,
      region: item.region ?? undefined,
      subRegion: item.subregion ?? undefined,
      appellation: item.appellation ?? undefined,
      designation: item.designation ?? undefined,
      proRating: item.score ?? undefined,
    }));
    return result;
  }

  /**
   * Get wines catalogues counts
   * @see `api/bin/api/src/api/wines.rs` - `get_wines_count`
   */
  async getWinesCataloguesCounts(query?: Record<string, any>): Promise<number> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { ...query };
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/wines/counts?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const counts: number = data;
    return counts;
  }

  /***** Hubspot *****/
  /**
   * Send updated user profile event data to hubspot
   * @see `cloudfunctions/src/userEvent.ts` - `sendUserEvent`
   */
  async sendUserEvent(data: Profile): Promise<void> {
    const { name: displayName, email, photo: photoURL } = data;
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const currentUser = await this.auth?.currentUser;
    if (!currentUser) throw new Error("User not found");
    const userToken = await currentUser.getIdToken();
    if (!userToken) throw new Error("User token not found");
    const uid = currentUser.uid;

    const url = `${endpoint}/userEvent`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({
        eventType: "USER_UPDATE",
        event: {
          uid,
          email,
          emailVerified: true,
          disabled: false,
          displayName,
          phoneNumber: "", //We can not update phoneNumber currently
          photoURL,
        },
      }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    console.log(`Called sendUserEvent successfully`);
  }

  /***** Artist *****/
  /**
   * Get artists with query
   * @see `api/bin/api/src/api/art_search.rs` - `get_artists_by_keyword`
   */
  async getArtistsWithKeyword(keyword: string): Promise<ArtistBasic[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { keyword };
    const paramsEntries = Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => [key, val]) : [[key, value]]
      )
      .filter(([_key, value]) => value !== "");
    const queryParams = new URLSearchParams(paramsEntries).toString();

    const url = `${endpoint}/v0/art_search?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: ArtistBasic[] = data.map((item: any) => ({
      id: item.id.toString() || "-",
      name: `${item.name} ${item.surname || ""}`,
      surname: item.surname || "Unknown",
      sourceId: item.sourceId.toString() || "-",
      birthYear: (item.birthYear || "Unknown").toString(),
    }));
    return result;
  }

  async getArtistById(id: string): Promise<ArtistBasic> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { id };
    const paramsEntries = Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => [key, val]) : [[key, value]]
      )
      .filter(([_key, value]) => value !== "");
    const queryParams = new URLSearchParams(paramsEntries).toString();
    const url = `${endpoint}/v0/art_search/artist?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: ArtistBasic = {
      id: data.id.toString() || "-",
      sourceId: data.sourceId.toString() || "-",
      name: `${data.name} ${data.surname || ""}`,
      surname: data.surname || "Unknown",
      birthYear: (data.birthYear || "Unknown").toString(),
    };
    return result;
  }

  async getCategoryBrandCount(category: AssetType): Promise<number> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const params = {
      userId: this.refs.currentRefs.userId,
      category,
    };

    const searchUrl = `${endpoint}/v0/firebase_search/summary/brand_count`;
    const response = await fetch(searchUrl, {
      ...config,
      method: "POST",
      body: JSON.stringify(params),
    });
    if (response.status !== 200) throw new Error(response.statusText);
    return await response.json();
  }

  async getCategoryPurchaseLMCount(category: AssetType): Promise<number> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();
    const lastMonth = (month + 11) % 12;
    const lastYear = lastMonth === 11 ? year - 1 : year;

    const params = {
      userId: this.refs.currentRefs.userId,
      category,
      monthIntervalStart: new Date(lastYear, lastMonth),
      monthIntervalEnd: new Date(<any>new Date(year, month) - 1),
    };

    const searchUrl = `${endpoint}/v0/firebase_search/summary/purchase_last_month_count`;
    const response = await fetch(searchUrl, {
      ...config,
      method: "POST",
      body: JSON.stringify(params),
    });
    if (response.status !== 200) throw new Error(response.statusText);
    return await response.json();
  }

  async getLocationUnsoldRoomIds(locationId: string): Promise<string[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const params = { locationId };

    const searchUrl = `${endpoint}/v0/firebase_search/location_room_id`;
    const response = await fetch(searchUrl, {
      ...config,
      method: "POST",
      body: JSON.stringify(params),
    });
    if (response.status !== 200) throw new Error(response.statusText);
    return await response.json();
  }

  async getArtistBySourceId(id: string): Promise<ArtistFull> {
    if (!id || !RegExp(/^\d+$/).test(id)) {
      throw new Error("Invalid artist sourceId");
    }

    const method = `getArtistDetail/?artistId=${id}`;
    const resp = await this.callCloudFunction("GET", method, {});
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: ArtistFull = {
      id: data?.info?.id.toString(),
      name: `${data?.info?.name} ${data?.info?.surname || ""}`.trim(),
      surname: data?.info?.surname,
      birthYear: data?.info?.birth_year,
      birthLocation: data?.info?.birth_location,
      deathYear: data?.info?.death_year,
      nationalities: data?.info?.nationalities || [],
      attributes: data?.info?.attributes || [],
      media: data?.info?.media || [],
      movements: data?.info?.movements || [],
      periods: data?.info?.periods || [],
      livesAndWorks: data?.info?.lives_and_works || [],
      socialMediaLinks: data?.info?.social_media_links || [],
      updatedAt: data?.info?.updated_at,
      url: data?.info?.url,
      imageUrl: data?.info?.image_url || null,
      practiceSummary: data?.info?.practice_summary || "",
    };
    return result;
  }

  async getArtistExhibitions(
    offset: number,
    limit: number,
    filter: {
      start_date: string;
      end_date: string;
      artists?: number[];
      related_to_artists?: number[];
    }
  ): Promise<ArtistExhibitions> {
    const resp = await this.callCloudFunction("POST", "getArtistExhibitions", {
      offset,
      limit,
      filter,
    });
    return (await resp.json()) as ArtistExhibitions;
  }

  /***** CustomizedType *****/
  async addCustomizedType(type: CustomizedType, name: string): Promise<void> {
    const userDoc = this.refs.currentRefs.getCustomizedTypeRef(type);
    await CoreFirestore.setDocWithOption(
      userDoc,
      { [name]: null },
      { merge: true }
    );
    // `/ User / user_id / CustomizeType / Label ? `: { something: null .....}
    // `/ User / user_id / CustomizeType / Insurance ? ` { somethingElse: null ....}
  }

  async removeCustomizedType(
    type: CustomizedType,
    name: string
  ): Promise<void> {
    const userDoc = this.refs.currentRefs.getCustomizedTypeRef(type);
    await CoreFirestore.updateDoc(userDoc, {
      [name]: CoreFirestore.deleteField(),
    });
  }

  async listCustomizedType(typeName: CustomizedType): Promise<string[]> {
    const userDoc = await CoreFirestore.getDoc<CustomizedTypeObject>(
      this.refs.currentRefs.getCustomizedTypeRef(typeName)
    );
    let data: string[] = userDoc.exists() ? Object.keys(userDoc.data()!) : [];

    const globalDoc = await CoreFirestore.getDoc(
      CoreFirestore.docFromCollection(
        this.refs.currentRefs.GlobalSetting,
        typeName
      )
    );
    if (globalDoc.exists()) {
      data = data.concat(Object.keys(globalDoc.data()!));
    }
    return data;
  }

  /**
   *
   * @returns {Promise<ProgressMetadata<any>[]>} The export tasks, id can be used to resumed the task
   */
  async getUnfinishedExportTasks(): Promise<ProgressMetadata<any>[]> {
    const ref = this.refs.currentRefs.getExportMetadataCollection();
    return await CoreFirestore.getDocsFromCollection<ProgressMetadata<any>>(
      ref
    ).then(getQueriedData);
  }
  /**
   * get an export handler v1
   *
   * @param {AssetType} assetType - desired asset type to make export
   * @param {string} taskId - task id to track the export progress, leave undefined to create a new task
   * @returns {Promise<ExportHandler>} The export handler to get export data and manage progress
   */
  async getExportHandlerV1(
    assetType: AssetType,
    taskId?: string,
    batchSize?: number
  ): Promise<ExportHandler<ExportRowV1>> {
    const querier = await newQuerierV1(
      assetType,
      this.refs.currentRefs,
      this.Encryption.current,
      this.ExRate,
      new PriceSourceWrapper(this),
      taskId,
      batchSize
    );
    return newExportHandler(querier);
  }

  /**
   * get an export handler v2
   *
   * @param {AssetType} assetType - desired asset type to make export
   * @param {string} taskId - task id to track the export progress, leave undefined to create a new task
   * @returns {Promise<ExportHandler>} The export handler to get export data and manage progress
   */
  async getExportHandlerV2(
    assetType: AssetType,
    taskId?: string,
    batchSize?: number
  ): Promise<ExportHandler<ExportRowV2>> {
    const querier = await newQuerierV2(
      assetType,
      this.refs.currentRefs,
      this.Encryption.current,
      this.ExRate,
      new PriceSourceWrapper(this),
      taskId,
      batchSize
    );
    return newExportHandler(querier);
  }

  async getComparativeNetWorthReport(
    excludeAssetTypes: (
      | AssetType.TraditionalInvestments
      | AssetType.OtherInvestment
      | AssetType.Cryptocurrency
      | AssetType.Insurance
      | AssetType.WineAndSpirits
    )[] = []
  ): Promise<ComparativeNetWorthReport> {
    const exporter = new GlobalDashboardReportExporter(
      this,
      this.refs.currentRefs,
      this.ExRate,
      // FIXME: use real price
      new PriceSourceWrapper(this)
    );
    return exporter.computeComparativeNetWorthReport(excludeAssetTypes);
  }

  private async callCloudFunction(
    requestType: "GET" | "POST" = "POST",
    method: string,
    body: any
  ): Promise<Response> {
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config: RequestInit = {
      method: requestType,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
        "firebase-jwt": userToken,
      },
    };

    if (requestType === "POST") {
      config.body = JSON.stringify(body);
    }

    const url = `${this.params.cfEndpoint}/${method}`;
    const resp = await fetch(url, config);

    return resp;
  }

  async subTypeResultToSubTypeGroup(
    subTypeResults: GroupedSubTypeResult[],
    category: AssetTypeWithSubtype
  ): Promise<SubtypeGroup> {
    const subTypeGroup: SubtypeGroup = {};

    for (const subTypeResult of subTypeResults) {
      const { subType, firebaseIds, count } = subTypeResult;

      let snapshotAssets: AssetsWithSubtype = [];

      switch (category) {
        case AssetType.Art:
          snapshotAssets = (await Promise.all(
            firebaseIds.map((fireId) => this.art.getById(fireId))
          )) as Art[];
          break;
        case AssetType.WineAndSpirits:
          snapshotAssets = (await Promise.all(
            firebaseIds.map((fireId) => this.wine.getWineById(fireId))
          )) as Wine[];
          break;
        case AssetType.OtherCollectables:
          snapshotAssets = (await Promise.all(
            firebaseIds.map((fireId) => this.otherCollectable.getById(fireId))
          )) as OtherCollectable[];
          break;
        case AssetType.Property:
          snapshotAssets = (await Promise.all(
            firebaseIds.map((fireId) => this.property.getById(fireId))
          )) as Property[];
          break;
        case AssetType.Belonging:
          snapshotAssets = (await Promise.all(
            firebaseIds.map((fireId) => this.belonging.getById(fireId))
          )) as Belonging[];
          break;
        default:
          // Handle unexpected category (should not happen with proper TypeScript)
          snapshotAssets = [];
          break;
      }

      subTypeGroup[subType] = {
        count,
        snapshotAssets,
      };
    }

    return subTypeGroup;
  }

  /**
   * Asynchronously creates multiple records of a given asset in the database with default/decorated values.
   *
   * @template Model The type of the model to be created.
   * @template RawRecord The basic js object type used to create the default raw record.
   * @param {Object} opts - The options for creating the models.
   * @param {new () => Model} opts.model - The class of the model to be created.
   * @param {() => RawRecord} opts.default - A function that returns a default raw record.
   * @param {number} opts.amount - The number of models to create.
   * @param {number} [opts.batchSize] - The number of models to create in each batch.
   * @param {(input: Model, index: number) => void} [opts.decorator] - A function that decorates each model, with specific data
   * @param {(result: BatchResult<Model>) => boolean | void} [opts.onBatchResult] - A function called after each batch update has completed, containing the results and any errors. Default behaviour is to stop after any errors. This function should return true if you wish to continue after an error.
   */
  async bulkUpdateAssets<Model, State, Command, TEvent>(
    opts: BatchUpdateContext<Model, State, Command, TEvent>
  ): Promise<void> {
    const { model, amount, decorator, updateFunc, onBatchResult } = opts;
    const batchSize = opts.batchSize || DEFAULT_BATCH_SIZE;
    const errors: Error[] = [];

    const batchTotal = Math.ceil(amount / batchSize);

    for (let batchNumber = 1; batchNumber <= batchTotal; batchNumber++) {
      let error: Error | undefined = undefined;
      try {
        await CoreFirestore.runTransaction(async (transaction) => {
          const repo = await opts.repoFunc(transaction);
          // Batch
          const start = (batchNumber - 1) * batchSize;
          const end = Math.min(start + batchSize, amount);
          for (let index = start; index < end; index++) {
            const item = new model();
            if (decorator) {
              decorator(item, index);
            }

            await updateFunc(item, repo);
          }
        });
      } catch (e) {
        if (!onBatchResult) {
          throw e;
        } //Throw error, if no batch erorr handler specified
        error = e as Error;
      }

      if (onBatchResult) {
        const carryOn = onBatchResult({ batchNumber, batchTotal, error });
        if (error && !carryOn) {
          break;
        }
      }
    }
  }

  ___testGetRefs() {
    return this.refs;
  }

  async checkRemindMFA() {
    try {
      // Check if MFA is enabled
      const list = await this.Account.getMultiFactorList();
      if (list.length > 0) throw new Error("MFA enabled");

      // Check if user is first login
      const { remindMFA } = await this.Account.getPreferences();
      if (remindMFA) throw new Error("MFA skipped");

      return true;
    } catch {
      return false;
    }
  }
}

type CustomizedTypeObject = { [key: string]: null };

const DEKExistsInFirestore = (refs: Refs) => async () => {
  const dek = await CoreFirestore.getDoc(refs.Dek);
  return dek.exists();
};

export enum MapiSearchParamsType {
  keyword = "keyword",
  category = "category",
  subType = "subType",
  brand = "brand",
  valueCurrency = "valueCurrency",
  valueLowerBound = "valueLowerBound",
  valueUpperBound = "valueUpperBound",
  bottlePriceLowerBound = "bottlePriceLowerBound",
  bottlePriceUpperBound = "bottlePriceUpperBound",
  acquisitionType = "acquisitionType",
  purchaseDateLowerBound = "purchaseDateLowerBound",
  purchaseDateUpperBound = "purchaseDateUpperBound",
  locationId = "locationId",
  roomId = "roomId",
  vintage = "vintage",
  masterVarietal = "masterVarietal",
  artStyle = "artStyle",
  offset = "offset",
  limit = "limit",
  desc = "desc",
  exchangeRate = "exchangeRate",
}

export type SearchAssetResult<T> = {
  list: T[];
  totalCount: number;
};

function buildQueryParams(params: Record<string, any>) {
  return new URLSearchParams(
    Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => [key, val]) : [[key, value]]
      )
      .filter(([_key, value]) => value !== "")
  ).toString();
}

export type MapiSearchParams = {
  keyword: string;
  sort: string;
  desc: "true" | "false";
  limit: number;
  offset: number;
  exchangeRate: { currency: Currency; rate: string }[];
  userId: string;
  // art
  artStyle: ArtStyle;
  // wine
  vintage: VintageRange;
  bottlePriceLowerBound: string;
  bottlePriceUpperBound: string;
  masterVarietal: string;
  // asset
  category: AssetType[];
  subType: string[];
  brand: string[];
  acquisitionType: AcquisitionType;
  purchaseDateLowerBound: string;
  purchaseDateUpperBound: string;
  // location
  locationId: string[];
  roomId: string[];
  // value
  valueCurrency: Currency;
  valueLowerBound: string;
  valueUpperBound: string;
};

export type AssetsMap = {
  [AssetType.CashAndBanking]: Account.Type[];
  [AssetType.BankOrInstitution]: Account.Type[];
  [AssetType.TraditionalInvestments]: Portfolio[];
  [AssetType.OtherInvestment]: OtherInvestment[];
  [AssetType.Cryptocurrency]: Cryptocurrency[];
  [AssetType.Insurance]: Insurance[];
  [AssetType.Property]: Property[];
  [AssetType.Art]: Art[];
  [AssetType.WineAndSpirits]: Wine[];
  [AssetType.WinePurchases]: Wine[];
  [AssetType.OtherCollectables]: OtherCollectable[];
  [AssetType.Belonging]: Belonging[];
};

export type GetAssetsByTypes<T extends AssetType[]> = {
  [K in T[number]]: AssetsMap[K];
}[T[number]];

export type OtherCollectableMap = {
  [K in OtherCollectableType]: K[];
};

export type GetOtherCollectableByLabels<T extends OtherCollectableType[]> = {
  [K in T[number]]: OtherCollectableMap[K];
}[T[number]];

type GroupedSubTypeResult = {
  subType: string;
  count: number;
  firebaseIds: string[];
};

export type AssetTypeWithSubtype =
  | AssetType.Art
  | AssetType.WineAndSpirits
  | AssetType.OtherCollectables
  | AssetType.Property
  | AssetType.Belonging;

export type AssetsWithSubtype = AssetsMap[AssetTypeWithSubtype];

export type SubtypeGroup = {
  [key: string]: {
    count: number;
    snapshotAssets: AssetsWithSubtype;
  };
};
