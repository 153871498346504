import {
  Amount,
  AssetType,
  AssetV2,
  Attachment,
  Beneficiary,
  Optional,
  Owner,
  PathsOfAmountField,
  PathsOfDateField,
  compareGroupUpdate,
  Period,
  PeriodWithNumber,
  compareInsuredUpdate,
  MultiCurrencyAmount,
} from "./common";
import {
  EncryptedType,
  EncryptionField,
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  RequireEncryptionFields,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
  removeEncryptionFields,
  IVSaltFieldKey,
  IVSaltFieldDefaultValue,
} from "../encryption/utils";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  applyUpdateToObject,
  buildObjectUpdate,
  deepCopy,
  optionalDateEqual,
  removeItemsFromArray,
  validateStringNotEmpty,
  validateValueInEnum,
} from "../utils";
import {
  AggregateBase,
  AggregateRoot,
  RepoAndAggregates,
  setObjectDeleted,
} from "./aggregate";
import { ErrorDataOutDated, InvalidInput } from "./error";
import { EventBase, EventWithTime, preSealEvent, SharedEvent } from "./event";
import { CommandBase, SharedCommand } from "./command";
import { Encryption } from "../database/encryption";
import { CoreFirestore, WithFieldValue } from "../../coreFirebase";
import {
  InsuranceTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";

export interface Rider {
  // @Encrypted
  name: string;
  amount: Amount;
  effectiveDate: Date;
  riderEndDate: Date;
}
namespace Rider {
  export const datePaths: readonly PathsOfDateField<Rider>[] = [
    "riderEndDate",
    "effectiveDate",
  ] as const;
  export type EncryptedKeys = "name";
  export type Encrypted = EncryptedType<Rider, "name">;
  export type EncryptedPart = Pick<Rider, EncryptedKeys>;
  export async function encrypt(
    data: Rider,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(data, ["name"], encryption);
  }
  export const decrypt = fullObjectDecryption<Rider, "name">;
  export function optionalEqual(a: Optional<Rider>, b: Optional<Rider>) {
    if (a && b) {
      return a.name === b.name && Amount.equal(a.amount, b.amount);
    } else {
      return a === b;
    }
  }
  export function validateEncryptedPart(data: EncryptedPart) {
    if (!validateStringNotEmpty(data.name)) {
      throw new InvalidInput("Name cannot be empty");
    }
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    if (isCreate) {
      if (!data.effectiveDate)
        throw new InvalidInput("EffectiveDate is required");
      if (!data.riderEndDate)
        throw new InvalidInput("RiderEndDate is required");
    }
    if (isCreate || data.amount) {
      Amount.validate("amount", data.amount!);
      if (data.amount!.value < 0) {
        throw new InvalidInput("Amount cannot be negative");
      }
    }
  }
}

export enum InsuranceSubType {
  Life = "Life",
  General = "General",
}

export enum InsuranceStatus {
  InForce = "InForce",
  Lapsed = "Lapsed",
  Matured = "Matured",
  NTU = "NTU",
  PaidUp = "PaidUp",
  Pending = "Pending",
  Outstanding = "Outstanding",
  Surrendered = "Surrendered",
  Suspended = "Suspended",
  TransferredOut = "TransferredOut",
}

export enum InsuranceKind {
  Personal = "Personal",
  Company = "Company",
}

export enum GeneralInsuranceType {
  Car = "Car",
  Health = "Health",
  Home = "Home",
  MultiCover = "MultiCover",
  Other = "Other",
  Pet = "Pet",
  Travel = "Travel",
}

export enum LifeInsuranceType {
  TermLife = "TermLife",
  WholeOfLife = "WholeOfLife",
}

export interface Insured {
  targetId: string;
  targetType: InsuranceTarget;
}
export type InsuredAssetType =
  | AssetType.Property
  | AssetType.Art
  | AssetType.OtherCollectables
  | AssetType.Belonging;
export type InsuranceTarget = InsuredAssetType | "Person";
export const InsuranceTargetTypes: readonly InsuranceTarget[] = [
  AssetType.Property,
  AssetType.Art,
  AssetType.OtherCollectables,
  AssetType.Belonging,
  "Person",
];

export interface Insurance extends AssetV2 {
  "@type": VersionedTypeString<VersionedType.Insurance, 2>;
  assetType: AssetType.Insurance;
  subtype: InsuranceSubType;

  //policyName is `name`
  status: InsuranceStatus;
  insuranceCompany: string;
  coverageAmount: Amount;
  //surrenderValue is `value`
  premium: Amount;
  frequencyOfPayment: PeriodWithNumber;
  startDate?: Date; // Cover From / effective date
  endDate?: Date; // Cover To / Policy End Date
  insured?: Insured[]; // ref to person(Health/lifeInsure) or item (others)
  personalRefNo?: string;
  insuranceType: GeneralInsuranceType | LifeInsuranceType;

  //attribute
  //   @Encrypted
  policyNumber?: string;
  brokerId?: string; // addr
  specialistId?: string; // addr
  lastPremiumDate?: Date;

  beneficiary?: Beneficiary;
  rider?: Rider[];

  // general only
  region?: string; // only for health
  companyOrPersonal?: InsuranceKind; // only for health
  premiumToDate?: Date;
}
export namespace Insurance {
  export function assureVersion(
    input: Insurance | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      InsuranceTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Insurance);
  }

  export const datePaths: readonly PathsOfDateField<Insurance>[] = [
    "createAt",
    "updateAt",
    "lastPremiumDate",
    "premiumToDate",
    "startDate",
    "endDate",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<Insurance>[] = [
    "coverageAmount",
    "premium",
    "value",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Insurance> {
    const decrypted = await Insurance.decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    if (decrypted.rider)
      decrypted.rider.map((v) =>
        CoreFirestore.convertDateFieldsFromFirestore(v, Rider.datePaths)
      );
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Insurance,
    "@type" | "ownerId" | "version" | "createAt" | "updateAt" | "valueSourceId"
  >;

  export type EncryptedKeys = AssetV2.EncryptedKeys | "policyNumber";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Insurance, EncryptedKeys>,
    { rider?: Rider.Encrypted[]; attachments?: Attachment.Encrypted[] }
  >;
  export type EncryptedPart = Pick<Insurance, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    "notes",
    "policyNumber",
  ] as const;

  export function fromCreate(
    from: Insurance.CreateFields,
    ownerId: string
  ): Insurance {
    const insurance: WithFieldValue<Insurance> = {
      ...from,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      "@type": InsuranceTypeVersion,
    };
    return insurance as Insurance;
  }

  // NON-OPTIONAL "coverageAmount", "premium", "value", "frequencyOfPayment"
  // OPTIONAL "startDate","endDate", "groupIds", "premiumToDate", "lastPremiumDate", "beneficiary", "rider",
  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Insurance>[] = [
    "insuranceCompany",
    "insuranceType",
    "status",
    "name",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Insurance>[] =
    [
      "policyNumber",
      "personalRefNo",
      "region", // only for health
      "companyOrPersonal",
      "brokerId",
      "specialistId",
      "notes",
    ];
  export function intoUpdate(
    current: Insurance,
    update: Insurance
  ): {
    updates: UpdateObject<Insurance>;
    metadata: {
      addedToGroup?: string[];
      removedFromGroup?: string[];
      addedToInsured?: Insured[];
      removedFromInsured?: Insured[];
      shouldEncryptRider: boolean;
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      OptionalSimpleTypeUpdatableKeys
    );

    if (!Amount.equal(current.coverageAmount, update.coverageAmount)) {
      baseUpdateFields.coverageAmount = update.coverageAmount;
    }
    if (!Amount.equal(current.premium, update.premium)) {
      baseUpdateFields.premium = update.premium;
    }
    if (!Amount.equal(current.value, update.value)) {
      baseUpdateFields.value = update.value;
    }
    if (
      current.frequencyOfPayment.num != update.frequencyOfPayment.num ||
      current.frequencyOfPayment.period != update.frequencyOfPayment.period
    ) {
      baseUpdateFields.frequencyOfPayment = update.frequencyOfPayment;
    }

    if (!optionalDateEqual(current.startDate, update.startDate))
      if (update.startDate) baseUpdateFields.startDate = update.startDate;
      else baseUpdateFields.startDate = null;
    if (!optionalDateEqual(current.endDate, update.endDate))
      if (update.endDate) baseUpdateFields.endDate = update.endDate;
      else baseUpdateFields.endDate = null;
    if (!optionalDateEqual(current.premiumToDate, update.premiumToDate))
      if (update.premiumToDate)
        baseUpdateFields.premiumToDate = update.premiumToDate;
      else baseUpdateFields.premiumToDate = null;
    if (!optionalDateEqual(current.lastPremiumDate, update.lastPremiumDate))
      if (update.lastPremiumDate)
        baseUpdateFields.lastPremiumDate = update.lastPremiumDate;
      else baseUpdateFields.lastPremiumDate = null;
    if (!Owner.optionalEqual(current.beneficiary, update.beneficiary))
      if (update.beneficiary) baseUpdateFields.beneficiary = update.beneficiary;
      else baseUpdateFields.beneficiary = null;

    let isRiderUpdated = false;
    if (current.rider && update.rider) {
      if (current.rider.length == update.rider.length) {
        for (let i = 0; i < current.rider.length; i++) {
          if (!Rider.optionalEqual(current.rider[i], update.rider[i])) {
            isRiderUpdated = true;
            break;
          }
        }
      } else {
        isRiderUpdated = true;
      }
    } else {
      isRiderUpdated = current.rider !== update.rider;
    }

    metadata.shouldEncryptRider = isRiderUpdated;
    if (isRiderUpdated)
      if (update.rider) baseUpdateFields.rider = update.rider;
      else baseUpdateFields.rider = null;

    const { fieldUpdate: insuredUpdate, insuredChanges } = compareInsuredUpdate(
      current.insured,
      update.insured
    );
    if (insuredUpdate !== undefined) {
      baseUpdateFields.insured = insuredUpdate;
    }
    if (insuredChanges.addedToInsured)
      metadata.addedToInsured = insuredChanges.addedToInsured;
    if (insuredChanges.removedFromInsured)
      metadata.removedFromInsured = insuredChanges.removedFromInsured;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;
    const { attachments, newImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;

    return { updates: baseUpdateFields, metadata };
  }

  export function removeEncryptedFields<
    T extends Insurance | UpdateObject<Insurance>
  >(data: T): OmitKeys<T, EncryptedKeys | "rider" | "attachments"> {
    const result = doRemoveEncryptedFields(
      data,
      encryptedKeysArray
    ) as OmitKeys<T, EncryptedKeys | "rider" | "attachments">;
    delete (<any>result).rider;
    delete (<any>result).attachments;
    return result;
  }

  export async function encrypt(
    input: Insurance,
    encryption: Encryption
  ): Promise<Encrypted> {
    const {
      attachments,
      rider,
      ...rest
    }: EncryptedType<Insurance, EncryptedKeys> = await fullObjectEncryption(
      input,
      encryptedKeysArray,
      encryption
    );
    const result = rest as Encrypted;
    if (attachments) {
      result.attachments = await Attachment.encryptArray(
        attachments,
        encryption
      );
    }
    if (rider) {
      result.rider = await Promise.all(
        rider.map((v) => Rider.encrypt(v, encryption))
      );
    }
    return result;
  }

  export async function encryptUpdate(
    encryptedPart: Optional<EncryptedPart>,
    rider: UpdateObject<Insurance>["rider"],
    attachments: UpdateObject<Insurance>["attachments"],
    encryption: Encryption
  ): Promise<
    Partial<EncryptionField> &
      Pick<UpdateObject<Encrypted>, "rider" | "attachments">
  > {
    const result: Partial<EncryptionField> &
      Pick<UpdateObject<Encrypted>, "rider" | "attachments"> = {};
    if (encryptedPart) {
      const iv = encryption.generateNewIVSalt();
      result[EncryptionFieldKey] = {
        data: await encryption.encryptAndStringify(encryptedPart, iv),
        [IVSaltFieldKey]: encryption.convertIVSaltToBase64(iv),
      };
    }
    if (rider !== undefined) {
      if (rider !== null)
        result.rider = await Promise.all(
          rider.map((v) => Rider.encrypt(v, encryption))
        );
      else result.rider = null;
    }
    if (attachments !== undefined) {
      if (attachments !== null)
        result.attachments = await Attachment.encryptArray(
          attachments,
          encryption
        );
      else result.attachments = null;
    }
    return result;
  }
  export async function decrypt(
    data: Encrypted,
    encryption: Encryption
  ): Promise<Insurance> {
    const EncryptedPart: EncryptedPart = await encryption.decryptAndStringify(
      data[EncryptionFieldKey]["data"],
      encryption.convertBase64ToIVSalt(data[EncryptionFieldKey][IVSaltFieldKey])
    );
    const { rider, attachments, ...rest } = data;
    const result: Insurance = {
      ...removeEncryptionFields(rest),
      ...EncryptedPart,
      attachments: await Attachment.decryptArray(attachments, encryption),
    };
    if (rider)
      result.rider = await Promise.all(
        rider.map((v) => Rider.decrypt(v, encryption))
      );
    return result;
  }

  export function newAggregateRoot(state: Insurance.Encrypted) {
    return new AggregateRoot(new InsuranceAggregate(state));
  }

  export function defaultStateValue(): Encrypted {
    return {
      "@type": InsuranceTypeVersion,
      assetType: AssetType.Insurance,
      subtype: InsuranceSubType.Life,
      id: "",
      name: "",
      ownerId: "",
      version: 0,
      value: Amount.defaultValue(),
      createAt: new Date(0),
      updateAt: new Date(0),
      status: InsuranceStatus.InForce,
      insuranceCompany: "",
      coverageAmount: Amount.defaultValue(),
      premium: Amount.defaultValue(),
      frequencyOfPayment: { num: 1, period: Period.Year },
      insuranceType: GeneralInsuranceType.Other,
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue, //`{name:""}`
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
  }

  export function checkFormat(
    data: Pick<
      Insurance,
      "subtype" | "insuranceType" | "insured" | "region" | "value"
    >
  ) {
    if (data.insured) {
      switch (true) {
        case data.subtype == InsuranceSubType.Life:
          if (data.region) {
            throw new InvalidInput(
              "region should not be with insured person in LifeInsurance"
            );
          }
        // falls through
        case data.insuranceType == GeneralInsuranceType.Health:
          data.insured.forEach((item) => {
            if (item.targetType != "Person") {
              throw new InvalidInput(
                `${data.subtype} ${data.insuranceType} must insured person`
              );
            }
          });
          break;
        default:
          data.insured.forEach((item) => {
            if (item.targetType == "Person") {
              throw new InvalidInput(
                `${data.subtype} ${data.insuranceType} shouldn't insured person`
              );
            }
          });
          break;
      }
    } else if (data.region) {
      throw new InvalidInput(
        "region should be with insured person in GeneralInsurance"
      );
    }
    if (
      data.insuranceType != LifeInsuranceType.WholeOfLife &&
      data.value.value > 0
    ) {
      throw new InvalidInput(
        "surrenderValue should be with WholeOfLife in LifeInsurance"
      );
    }
  }

  //#NOTE validate encrypted keys have legal value
  export function validateEncryptedPart(
    data: EncryptedPart & {
      rider?: Rider.EncryptedPart[];
      attachments?: Attachment.EncryptedPart[];
    },
    _isCreate: boolean = false
  ) {
    if (data.policyNumber && !validateStringNotEmpty(data.policyNumber)) {
      throw new InvalidInput("policyNumber can't be empty");
    }
    // optional fields
    if (data.rider) {
      data.rider.forEach((rider) => Rider.validateEncryptedPart(rider));
    }
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }

  //#TODO need checks
  //#NOTE validate data after encrypted
  export function validateEncryptedObj(
    data: UpdateObject<OmitKeys<Encrypted, typeof EncryptionFieldKey>>,
    isCreate: boolean = false
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    //non optional fields, if isCreate, the field must be in the data
    if (isCreate && !validateValueInEnum(data.subtype!, InsuranceSubType)) {
      throw new InvalidInput("Invalid insurance subtype");
    }
    if (
      (isCreate || data.status) &&
      !validateValueInEnum(data.status!, InsuranceStatus)
    ) {
      throw new InvalidInput("Invalid insurance status");
    }
    if ((isCreate || data.coverageAmount) && data.coverageAmount!.value < 0) {
      throw new InvalidInput("CoverageAmount can't be negative");
    }
    if ((isCreate || data.premium) && data.premium!.value < 0) {
      throw new InvalidInput("Premium can't be negative");
    }
    if (
      (isCreate || data.frequencyOfPayment) &&
      data.frequencyOfPayment!.num <= 0
    ) {
      throw new InvalidInput("Frequency must be positive");
    }
    if (
      (isCreate || data.insuranceType) &&
      !validateValueInEnum(data.insuranceType!, GeneralInsuranceType) &&
      !validateValueInEnum(data.insuranceType!, LifeInsuranceType)
    ) {
      throw new InvalidInput("Invalid insurance type");
    }
    if (
      (isCreate || data.insuranceCompany) &&
      !validateStringNotEmpty(data.insuranceCompany)
    ) {
      throw new InvalidInput("InsuranceCompany can't be empty");
    }
    //optional fields
    if (data.rider) {
      data.rider.forEach((rider) =>
        Rider.validateEncryptedObj(rider, isCreate)
      );
    }
    if (data.value && data.value.value < 0) {
      // surrender value
      throw new InvalidInput("Value can't be negative");
    }
    if (data.attachments) {
      Attachment.validateEncryptedObj(data.attachments);
    }
    if (data.beneficiary) {
      Owner.validate(0, data.beneficiary);
    }
    if (data.insured) {
      data.insured.forEach((item) => {
        if (!InsuranceTargetTypes.includes(item.targetType)) {
          throw new InvalidInput("Invalid insured targetType");
        }
      });
    }
  }

  export type RelatedUpdates = {
    addedGroupIds?: string[];
    removedGroupIds?: string[];
    addAssetInsured?: Insured[];
    removeAssetInsured?: Insured[];
  };

  export interface RelatedAggregates {
    group?: RepoAndAggregates<any, any, any>;
  }
}

export namespace Command {
  enum CustomKind {
    AddInsured = "AddInsured",
    RemoveInsured = "RemoveInsured",
  }
  export type Kind = SharedCommand.Kind | CustomKind;
  export const Kind = {
    ...SharedCommand.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends CommandBase {
    kind: Kind;
  }

  export interface CreateAsset
    extends SharedCommand.CreateAsset<Insurance.Encrypted> {}
  export const createAsset = SharedCommand.createAsset<Insurance.Encrypted>;
  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<UpdateObject<Insurance.Encrypted>> {
    addedToInsured?: Insured[];
    removedFromInsured?: Insured[];
  }
  export function updateAsset(
    executerId: string,
    asset: UpdateObject<Insurance.Encrypted>,
    addedToGroup: Optional<string[]> = undefined,
    removedFromGroup: Optional<string[]> = undefined,
    newImages: Optional<string[]> = undefined,
    newMainImage: Optional<string> = undefined,
    removedImages: Optional<string[]> = undefined,
    locationPrimaryDetailsUpdated: Optional<boolean> = undefined,
    addedToInsured: Optional<Insured[]> = undefined,
    removedFromInsured: Optional<Insured[]> = undefined
  ): UpdateAsset {
    const command: UpdateAsset = {
      kind: Kind.UpdateAsset,
      executerId,
      asset,
    };
    if (addedToGroup) command.addedToGroup = addedToGroup;
    if (removedFromGroup) command.removedFromGroup = removedFromGroup;
    if (newImages) command.newImages = newImages;
    if (newMainImage) command.newMainImage = newMainImage;
    if (removedImages) command.removedImages = removedImages;
    if (locationPrimaryDetailsUpdated)
      command.locationPrimaryDetailsUpdated = locationPrimaryDetailsUpdated;
    if (addedToInsured) command.addedToInsured = addedToInsured;
    if (removedFromInsured) command.removedFromInsured = removedFromInsured;
    return command;
  }
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;

  export interface AddInsured extends BaseExtended {
    kind: CustomKind.AddInsured;
    insured: Insured[];
  }
  export function addInsured(
    executerId: string,
    insured: Insured[]
  ): AddInsured {
    return {
      kind: CustomKind.AddInsured,
      executerId,
      insured,
    };
  }
  export interface RemoveInsured extends BaseExtended {
    kind: CustomKind.RemoveInsured;
    insured: Insured[];
  }
  export function removeInsured(
    executerId: string,
    insured: Insured[]
  ): RemoveInsured {
    return {
      kind: CustomKind.RemoveInsured,
      executerId,
      insured,
    };
  }
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.DeleteAsset
  | Command.AddInsured
  | Command.RemoveInsured;

export namespace Event {
  enum CustomKind {
    InsuredAdded = "InsuredAdded",
    InsuredRemoved = "InsuredRemoved",
  }
  export type Kind = SharedEvent.Kind | CustomKind;
  export const Kind = {
    ...SharedEvent.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends EventBase {
    kind: Kind;
  }

  export interface AssetCreated
    extends SharedEvent.AssetCreated<Insurance.Encrypted> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<Insurance.Encrypted>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}
  export interface ValueUpdated extends SharedEvent.ValueUpdated {}
  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}

  export interface InsuredAdded extends BaseExtended {
    kind: CustomKind.InsuredAdded;
    insured: Insured[];
  }
  export interface InsuredRemoved extends BaseExtended {
    kind: CustomKind.InsuredRemoved;
    insured: Insured[];
  }
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.BeneficiaryUpdated
  | Event.ValueUpdated
  | Event.GroupsUpdated
  | Event.InsuredAdded
  | Event.InsuredRemoved;

class InsuranceAggregate extends AggregateBase<
  Insurance.Encrypted,
  Command,
  Event
> {
  state: Insurance.Encrypted;
  kind: string;
  relatedUpdates: Insurance.RelatedUpdates = {};

  constructor(state: Insurance.Encrypted) {
    super();
    this.state = state;
    this.kind = state.assetType;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return this.handleCreateAsset(command).map(preSealEvent);
      case Command.Kind.UpdateAsset:
        return this.handleUpdateAsset(command).map(preSealEvent);
      case Command.Kind.DeleteAsset:
        return this.handleDeleteAsset(command).map(preSealEvent);
      case Command.Kind.AddInsured:
        return this.handleAddInsured(command).map(preSealEvent);
      case Command.Kind.RemoveInsured:
        return this.handleRemoveInsured(command).map(preSealEvent);
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state = event.asset;
        if (this.state.insured) {
          this.relatedUpdates.addAssetInsured = this.state.insured;
        }
        break;
      case Event.Kind.AssetUpdated:
        const update = event.current ? event.current : event.asset;
        // update at insured added / removed
        delete update.insured;
        applyUpdateToObject(this.state, update);
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetDeleted:
        if (this.state.groupIds && this.state.groupIds.length > 0)
          this.relatedUpdates.removedGroupIds = this.state.groupIds;
        if (this.state.insured && this.state.insured.length > 0) {
          this.relatedUpdates.removeAssetInsured = this.state.insured;
        }
        this.state = setObjectDeleted(this.state);
        break;
      case Event.Kind.ValueUpdated:
        this.state.value = event.current;
        break;
      case Event.Kind.GroupsUpdated:
        if (event.addIds.length > 0)
          this.relatedUpdates.addedGroupIds = event.addIds;
        if (event.removedIds.length > 0)
          this.relatedUpdates.removedGroupIds = event.removedIds;
        break;
      case Event.Kind.InsuredAdded:
        if (this.state.insured) this.state.insured.push(...event.insured);
        else this.state.insured = event.insured;
        if (this.relatedUpdates.addAssetInsured)
          this.relatedUpdates.addAssetInsured.push(...event.insured);
        else this.relatedUpdates.addAssetInsured = event.insured;
        break;
      case Event.Kind.InsuredRemoved:
        if (this.state.insured) {
          removeItemsFromArray(
            this.state.insured,
            event.insured,
            (src, removing) => src.targetId === removing.targetId
          );
          if (this.relatedUpdates.removeAssetInsured)
            this.relatedUpdates.removeAssetInsured.push(...event.insured);
          else this.relatedUpdates.removeAssetInsured = event.insured;
        }
        break;
    }
    return this;
  }

  private handleCreateAsset({
    executerId,
    asset,
  }: Command.CreateAsset): Event[] {
    Insurance.validateEncryptedObj(asset, true);

    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.AssetCreated,
        asset,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: {},
            prevAssetNumber: 0,
            currAssetNumber: 1,
            prevItemNumber: 0,
            currItemNumber: 1,
            currTags: [],
          },
        ],
      },
    ];
    if (asset.insuranceType == LifeInsuranceType.WholeOfLife) {
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        current: asset.value,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: MultiCurrencyAmount.fromAmounts(asset.value),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: 1,
            currItemNumber: 1,
            prevTags: [],
            currTags: [],
          },
        ],
      });
    }

    if (asset.groupIds && asset.groupIds.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: asset.groupIds,
        removedIds: [],
      });
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        current: asset.beneficiary,
      });
    }
    return events;
  }
  private handleUpdateAsset({
    executerId,
    asset,
    addedToGroup,
    removedFromGroup,
    addedToInsured,
    removedFromInsured,
  }: Command.UpdateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    Insurance.validateEncryptedObj(asset);
    const events: Event[] = [];
    if (asset.value && asset.value.value > 0) {
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.value.value > 0 ? this.state.value : undefined,
        current: asset.value,
        summaryData: [
          {
            prevOwnedValue:
              this.state.value.value > 0
                ? MultiCurrencyAmount.fromAmounts(this.state.value)
                : {},
            currOwnedValue: MultiCurrencyAmount.fromAmounts(asset.value),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: 1,
            currItemNumber: 1,
            prevTags: [],
            currTags: [],
          },
        ],
      });
      delete asset.value;
    }
    events.push({
      executerId,
      kind: Event.Kind.AssetUpdated,
      asset,
      previous: deepCopy(this.state),
      current: asset,
    });

    if (addedToGroup || removedFromGroup) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: addedToGroup ?? [],
        removedIds: removedFromGroup ?? [],
      });
    }

    if (addedToInsured && addedToInsured.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.InsuredAdded,
        insured: addedToInsured,
      });
    }
    if (removedFromInsured && removedFromInsured.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.InsuredRemoved,
        insured: removedFromInsured,
      });
    }

    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        previous: this.state.beneficiary,
        current: asset.beneficiary,
      });
    }
    return events;
  }
  private handleDeleteAsset({ executerId }: Command.DeleteAsset): Event[] {
    AssetV2.checkDelete(this.state);
    return [
      {
        executerId,
        kind: Event.Kind.AssetDeleted,
        summaryData: [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(this.state.value),
            currOwnedValue: {},
            prevAssetNumber: 1,
            currAssetNumber: 0,
            prevItemNumber: 1,
            currItemNumber: 0,
            prevTags: [],
            currTags: [],
          },
        ],
      },
    ];
  }
  private handleAddInsured({
    executerId,
    insured,
  }: Command.AddInsured): Event[] {
    if (insured.length === 0) throw new InvalidInput("No insured to add");
    if (this.state.insured) {
      insured = insured.filter(
        (insured) =>
          !this.state.insured!.some((i) => i.targetId === insured.targetId)
      );
    }
    return [
      {
        executerId,
        kind: Event.Kind.InsuredAdded,
        insured,
      },
    ];
  }
  private handleRemoveInsured({
    executerId,
    insured,
  }: Command.RemoveInsured): Event[] {
    if (insured.length === 0) throw new InvalidInput("No insured to remove");
    if (!this.state.insured)
      throw new InvalidInput("No insured in this insurance");
    insured.forEach((insured) => {
      if (!this.state.insured!.find((i) => i.targetId === insured.targetId)) {
        // #NOTE use log here instead of throw because some old broken data may miss some insured
        console.log("Insured not found");
      }
    });
    return [
      {
        executerId,
        kind: Event.Kind.InsuredRemoved,
        insured,
      },
    ];
  }
}
