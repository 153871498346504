import Decimal from "decimal.js";
import { DocumentReference, Transaction } from "../../coreFirebase";
import { AggregateBase, AggregateRoot } from "./aggregate";
import { Event as ArtEvent, ArtStyle } from "./arts";
import {
  Amount,
  IAggregateData,
  MultiCurrencyAmount,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { AssetType } from "./enums";
import { EventEnvelope } from "./event";
import {
  AssetNumber,
  buildUpdaterMap,
  ItemNumber,
  MultiCurrencyValue,
  SubCategoryItem,
  SummaryUtils,
  SummaryTag,
} from "./summary";
import {
  ArtSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";
import { calculatePercentage, OmitKeys } from "../utils";

export type Event = EventEnvelope<ArtEvent>;
export interface ArtSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.ArtSummary, 3>;
  id: AssetType.Art;
  //#NOTE we're not using map with label as key because label could contain special characters
  // only minimal info is updated for each tag
  [SummaryTag.Art.Subtype]: SubCategoryItem[]; // value, itemNumber, assetNumber
  [SummaryTag.Art.ArtStyle]: SubCategoryItem[]; // value, itemNumber
}

export interface SubtypeItem {
  label: string;
  assetNumber: number;
  itemNumber: number;
  value: Amount;
  percentage: number;
}
export interface BreakdownItem<T> {
  label: ArtStyle | "-";
  data: T;
  percentage: number;
}

export namespace ArtSummary {
  export function assureVersion(
    input: ArtSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      ArtSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    typesNumber: number;
    totalNumber: number;
    subtypeItems: SubtypeItem[];
    //breakdown by style
    itemBreakdown: BreakdownItem<number>[];
    valueBreakdown: BreakdownItem<Amount>[];
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<ArtSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new ArtSummaryAggregate(summary));
  }

  export function defaultValue(): ArtSummary {
    return {
      "@type": ArtSummaryTypeVersion,
      id: AssetType.Art,
      [SummaryTag.Art.Subtype]: [],
      [SummaryTag.Art.ArtStyle]: [],
      version: 0,
    };
  }

  export function toDisplay(
    input: ArtSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let totalNumber = 0;
    let totalAssets = new Decimal(0);
    let totalLiabilities = new Decimal(0);
    const subtypes: SubtypeItem[] = [];
    const styles: {
      label: ArtStyle | "-";
      item: number;
      value: number;
    }[] = [];

    input[SummaryTag.Art.Subtype].forEach((item) => {
      const value = MultiCurrencyAmount.calculateTargetCurrencyAmount(
        item.multiCurrencyValue,
        exchangeRate
      );
      subtypes.push({
        label: item.label,
        assetNumber: item.assetNumber,
        itemNumber: item.itemNumber,
        value,
        percentage: 0,
      });
      // compute total
      totalAssets = totalAssets.add(value.value);
      totalNumber += item.assetNumber;
    });
    input[SummaryTag.Art.ArtStyle].forEach((item) => {
      const value = MultiCurrencyAmount.calculateTargetCurrencyAmount(
        item.multiCurrencyValue,
        exchangeRate
      );
      styles.push({
        label: item.label as ArtStyle | "-",
        item: item.itemNumber,
        value: value.value,
      });
    });

    const result: OmitKeys<
      Display,
      "typesNumber" | "subtypeItems" | "itemBreakdown" | "valueBreakdown"
    > = {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: { currency, value: totalLiabilities.toNumber() },
      netValue: {
        currency,
        value: totalAssets.sub(totalLiabilities).toNumber(),
      },
      totalNumber,
    };
    const subtypeItems: SubtypeItem[] = subtypes.map((item) => {
      item.percentage = calculatePercentage(
        item.value.value,
        result.assets.value
      );
      return item;
    });
    subtypeItems.sort((a, b) => b.value.value - a.value.value);
    const itemBreakdown: BreakdownItem<number>[] = styles.map((data) => {
      return {
        label: data.label,
        data: data.item,
        percentage: calculatePercentage(data.value, result.assets.value),
      };
    });
    itemBreakdown.sort((a, b) => b.data - a.data);
    const valueBreakdown: BreakdownItem<Amount>[] = styles
      .map((data) => {
        return {
          label: data.label,
          data: { currency, value: data.value },
          percentage: calculatePercentage(data.value, result.assets.value),
        };
      })
      .filter((item) => item.data.value > 0);
    valueBreakdown.sort((a, b) => b.data.value - a.data.value);
    return {
      ...result,
      typesNumber: subtypeItems.length,
      subtypeItems,
      itemBreakdown,
      valueBreakdown,
    };
  }

  export type RelatedUpdates = never;
}

export class ArtSummaryAggregate extends AggregateBase<
  ArtSummary,
  never,
  ArtEvent
> {
  state: ArtSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: ArtSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    const itemNumber = new ItemNumber();
    const assetNumber = new AssetNumber();
    const multiCurrencyValue = new MultiCurrencyValue();
    SummaryUtils.applySummaryData(
      event,
      this.state,
      {
        [SummaryTag.Art.Subtype]: [itemNumber, assetNumber, multiCurrencyValue],
        [SummaryTag.Art.ArtStyle]: [itemNumber, multiCurrencyValue],
      },
      buildUpdaterMap(itemNumber, assetNumber, multiCurrencyValue)
    );
    return this;
  }
}
