import {
  Amount,
  AssetType,
  AssetV2,
  Attachment,
  Beneficiary,
  Owner,
  Ownership,
  PathsOfAmountField,
  PathsOfDateField,
  compareGroupUpdate,
  Period,
  MultiCurrencyAmount,
} from "./common";
import {
  EncryptedType,
  EncryptionField,
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  RequireEncryptionFields,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
  removeEncryptionFields,
  IVSaltFieldKey,
  IVSaltFieldDefaultValue,
} from "../encryption/utils";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  applyUpdateToObject,
  buildObjectUpdate,
  calculateOwnedValue,
  deepCopy,
  optionalDateEqual,
  validateStringNotEmpty,
  validateValueInEnum,
} from "../utils";
import {
  AggregateBase,
  AggregateRoot,
  RepoAndAggregates,
  setObjectDeleted,
} from "./aggregate";
import { EventBase, EventWithTime, preSealEvent, SharedEvent } from "./event";
import { SharedCommand } from "./command";
import { Encryption } from "../database/encryption";
import { CoreFirestore, WithFieldValue } from "../../coreFirebase";
import { ErrorDataOutDated, InvalidInput } from "./error";
import {
  OtherInvestmentTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";

export enum InvestmentType {
  PercentOfCompany = "PercentOfCompany",
  Share = "Share",
  Warrant = "Warrant",
  Option = "Option",
  Loan = "Loan",
  Other = "Other",
}

export enum InvestmentStatus {
  Active = "Active",
  Dormant = "Dormant",
  Suspended = "Suspended",
}

export interface OtherInvestmentBase<T extends InvestmentType = InvestmentType>
  extends AssetV2 {
  "@type": VersionedTypeString<VersionedType.OtherInvestment, 2>;
  assetType: AssetType.OtherInvestment;
  subtype: T;

  personalRefNo: string;
  // - currentValuation/outstandValue in `Value`
  // - groups #REMOVE

  interestRate: number;
  maturityDate?: Date;
  contactIds: string[];
  attributeNotes?: string; // #ENCRYPT
  status: InvestmentStatus;
  website?: string;
  estimatedYield: number;
  estimatedReturn: Amount;

  ownership?: Ownership;
  beneficiary?: Beneficiary;
}

export type PercentOfCompany =
  OtherInvestmentBase<InvestmentType.PercentOfCompany> &
    PercentOfCompany.Fields;
export namespace PercentOfCompany {
  const amountPaths: readonly PathsOfAmountField<Fields>[] = [
    "investmentAmount",
  ] as const;
  export interface Fields {
    units: number;
    percentage: number;
    investmentDate: Date;
    investmentAmount: Amount;
  }
  export const keys: readonly (keyof Fields)[] = [
    "units",
    "percentage",
    "investmentDate",
    "investmentAmount",
  ];
  export function buildUpdate(
    current: Fields,
    update: Fields
  ): UpdateObject<Fields> {
    const result: UpdateObject<Fields> = {};
    if (current.units !== update.units) result.units = update.units;
    if (current.percentage !== update.percentage)
      result.percentage = update.percentage;
    if (current.investmentDate.getTime() !== update.investmentDate.getTime())
      result.investmentDate = update.investmentDate;
    if (!Amount.equal(current.investmentAmount, update.investmentAmount))
      result.investmentAmount = update.investmentAmount;
    return result;
  }
  export function validate(data: UpdateObject<Fields>, isCreate: boolean) {
    for (const key of amountPaths)
      if (data[key]) Amount.validate(key, data[key]!);
    if ((isCreate || data.units) && data.units! < 0) {
      throw new InvalidInput("Units cannot be negative");
    }
    if (
      (isCreate || data.percentage) &&
      (data.percentage! < 0 || data.percentage! > 100)
    ) {
      throw new InvalidInput("Percentage must be between 0 and 100");
    }
    if (
      (isCreate || data.investmentAmount) &&
      data.investmentAmount!.value < 0
    ) {
      throw new InvalidInput("Investment amount cannot be negative");
    }
  }
}

export type Share = OtherInvestmentBase<InvestmentType.Share> & Share.Fields;
export namespace Share {
  const amountPaths: readonly PathsOfAmountField<Fields>[] = [
    "investmentAmount",
    "pricePerShare",
  ] as const;
  export interface Fields {
    shareType?: string;
    pricePerShare: Amount;
    shares: number;
    investmentDate: Date;
    investmentAmount: Amount;
  }
  export const keys: readonly (keyof Fields)[] = [
    "shareType",
    "pricePerShare",
    "shares",
    "investmentDate",
    "investmentAmount",
  ];
  export function buildUpdate(
    current: Fields,
    update: Fields
  ): UpdateObject<Fields> {
    const result: UpdateObject<Fields> = {};
    if (current.shareType !== update.shareType) {
      if (update.shareType) result.shareType = update.shareType;
      else result.shareType = null;
    }
    if (!Amount.equal(current.pricePerShare, update.pricePerShare))
      result.pricePerShare = update.pricePerShare;
    if (current.shares !== update.shares) result.shares = update.shares;
    if (current.investmentDate.getTime() !== update.investmentDate.getTime())
      result.investmentDate = update.investmentDate;
    if (!Amount.equal(current.investmentAmount, update.investmentAmount))
      result.investmentAmount = update.investmentAmount;
    return result;
  }
  export function validate(data: Fields, isCreate: boolean) {
    for (const key of amountPaths)
      if (data[key]) Amount.validate(key, data[key]!);
    if ((isCreate || data.pricePerShare) && data.pricePerShare!.value < 0) {
      throw new InvalidInput("Price per share cannot be negative");
    }
    if ((isCreate || data.shares) && data.shares! < 0) {
      throw new InvalidInput("Shares cannot be negative");
    }
    if (
      (isCreate || data.investmentAmount) &&
      data.investmentAmount!.value < 0
    ) {
      throw new InvalidInput("Investment amount cannot be negative");
    }
  }
}

export type Warrant = OtherInvestmentBase<InvestmentType.Warrant> &
  Warrant.Fields;
export namespace Warrant {
  const amountPaths: readonly PathsOfAmountField<Fields>[] = [
    "investmentAmount",
    "strikePrice",
  ] as const;
  export interface Fields {
    shareType: string;
    strikePrice: Amount;
    shares: number;
    expirationDate: Date;
    investmentAmount: Amount;
    investmentDate?: Date;
  }
  export const keys: readonly (keyof Fields)[] = [
    "shareType",
    "strikePrice",
    "shares",
    "expirationDate",
    "investmentAmount",
    "investmentDate",
  ];
  export function buildUpdate(
    current: Fields,
    update: Fields
  ): UpdateObject<Fields> {
    const result: UpdateObject<Fields> = {};
    if (current.shareType !== update.shareType)
      result.shareType = update.shareType;
    if (!Amount.equal(current.strikePrice, update.strikePrice))
      result.strikePrice = update.strikePrice;
    if (current.shares !== update.shares) result.shares = update.shares;
    if (current.expirationDate.getTime() !== update.expirationDate.getTime())
      result.expirationDate = update.expirationDate;
    if (!Amount.equal(current.investmentAmount, update.investmentAmount))
      result.investmentAmount = update.investmentAmount;
    if (optionalDateEqual(current.investmentDate, update.investmentDate))
      if (update.investmentDate) result.investmentDate = update.investmentDate;
      else result.investmentDate = null;
    return result;
  }
  export function validate(data: Fields, isCreate: boolean) {
    for (const key of amountPaths)
      if (data[key]) Amount.validate(key, data[key]!);
    if (
      (isCreate || data.shareType) &&
      !validateStringNotEmpty(data.shareType)
    ) {
      throw new InvalidInput("Share type cannot be empty");
    }
    if ((isCreate || data.strikePrice) && data.strikePrice!.value < 0) {
      throw new InvalidInput("Strike price cannot be negative");
    }
    if ((isCreate || data.shares) && data.shares! < 0) {
      throw new InvalidInput("Shares cannot be negative");
    }
    if (
      (isCreate || data.investmentAmount) &&
      data.investmentAmount!.value < 0
    ) {
      throw new InvalidInput("Investment amount cannot be negative");
    }
  }
}

export type Option = OtherInvestmentBase<InvestmentType.Option> & Option.Fields;
export namespace Option {
  const amountPaths: readonly PathsOfAmountField<Fields>[] = [
    "investmentAmount",
    "strikePrice",
  ] as const;
  export interface Fields {
    shareType?: string;
    strikePrice: Amount;
    shares: number;
    grantDate?: Date;
    vestingDate: Date;
    investmentAmount: Amount;
    expirationDate?: Date;
    investmentDate?: Date;
  }
  export const keys: readonly (keyof Fields)[] = [
    "shareType",
    "strikePrice",
    "shares",
    "grantDate",
    "vestingDate",
    "expirationDate",
    "investmentAmount",
    "investmentDate",
  ];
  export function buildUpdate(
    current: Fields,
    update: Fields
  ): UpdateObject<Fields> {
    const result: UpdateObject<Fields> = {};
    if (current.shareType !== update.shareType) {
      if (update.shareType) result.shareType = update.shareType;
      else result.shareType = null;
    }
    if (!Amount.equal(current.strikePrice, update.strikePrice))
      result.strikePrice = update.strikePrice;
    if (current.shares !== update.shares) result.shares = update.shares;
    if (!optionalDateEqual(current.grantDate, update.grantDate)) {
      result.grantDate = update.grantDate ?? null;
    }
    if (current.vestingDate.getTime() !== update.vestingDate.getTime())
      result.vestingDate = update.vestingDate;
    if (!Amount.equal(current.investmentAmount, update.investmentAmount))
      result.investmentAmount = update.investmentAmount;
    if (optionalDateEqual(current.expirationDate, update.expirationDate))
      if (update.expirationDate) result.expirationDate = update.expirationDate;
      else result.expirationDate = null;
    if (optionalDateEqual(current.investmentDate, update.investmentDate))
      if (update.investmentDate) result.investmentDate = update.investmentDate;
      else result.investmentDate = null;
    return result;
  }
  export function validate(data: Fields, isCreate: boolean) {
    for (const key of amountPaths)
      if (data[key]) Amount.validate(key, data[key]!);

    if ((isCreate || data.strikePrice) && data.strikePrice!.value < 0) {
      throw new InvalidInput("Strike price cannot be negative");
    }
    if ((isCreate || data.shares) && data.shares! < 0) {
      throw new InvalidInput("Shares cannot be negative");
    }
    if (
      (isCreate || data.investmentAmount) &&
      data.investmentAmount!.value < 0
    ) {
      throw new InvalidInput("Investment amount cannot be negative");
    }
  }
}

export type Loan = OtherInvestmentBase<InvestmentType.Loan> & Loan.Fields;
export namespace Loan {
  export const amountPaths: readonly PathsOfAmountField<Fields>[] = [
    "initialAmount",
    "outstandingAmount",
  ] as const;
  export interface Fields {
    initialAmount: Amount;
    borrowerId?: string;
    startDate: Date;
    interestPeriod: Period;
    outstandingAmount: Amount;
  }
  export const keys: readonly (keyof Fields)[] = [
    "initialAmount",
    "borrowerId",
    "startDate",
    "interestPeriod",
    "outstandingAmount",
  ];
  export function buildUpdate(
    current: Fields,
    update: Fields
  ): UpdateObject<Fields> {
    const result: UpdateObject<Fields> = {};
    if (!Amount.equal(current.initialAmount, update.initialAmount))
      result.initialAmount = update.initialAmount;
    if (!Amount.equal(current.outstandingAmount, update.outstandingAmount))
      result.outstandingAmount = update.outstandingAmount;
    if (current.borrowerId !== update.borrowerId)
      if (update.borrowerId) result.borrowerId = update.borrowerId;
      else result.borrowerId = null;
    if (current.startDate.getTime() !== update.startDate.getTime())
      result.startDate = update.startDate;
    if (current.interestPeriod !== update.interestPeriod)
      result.interestPeriod = update.interestPeriod;

    return result;
  }
  export function validate(data: Fields, isCreate: boolean) {
    for (const key of amountPaths)
      if (data[key]) Amount.validate(key, data[key]!);
    if ((isCreate || data.initialAmount) && data.initialAmount!.value < 0) {
      throw new InvalidInput("Initial amount cannot be negative");
    }
    if (
      (isCreate || data.interestPeriod) &&
      !validateValueInEnum(data.interestPeriod!, Period)
    ) {
      throw new InvalidInput("Invalid interest period");
    }
  }
}

export type Other = OtherInvestmentBase<InvestmentType.Other> & Other.Fields;
export namespace Other {
  export const amountPaths: readonly PathsOfAmountField<Fields>[] = [
    "investmentAmount",
  ] as const;
  export interface Fields {
    investmentAmount: Amount;
    investmentDate: Date;
  }
  export const keys: readonly (keyof Fields)[] = [
    "investmentAmount",
    "investmentDate",
  ];
  export function buildUpdate(
    current: Fields,
    update: Fields
  ): UpdateObject<Fields> {
    const result: UpdateObject<Fields> = {};
    if (!Amount.equal(current.investmentAmount, update.investmentAmount))
      result.investmentAmount = update.investmentAmount;
    if (current.investmentDate.getTime() !== update.investmentDate.getTime())
      result.investmentDate = update.investmentDate;
    return result;
  }
  export function validate(data: Fields, isCreate: boolean) {
    for (const key of amountPaths)
      if (data[key]) Amount.validate(key, data[key]!);
    if (
      (isCreate || data.investmentAmount) &&
      data.investmentAmount!.value < 0
    ) {
      throw new InvalidInput("Investment amount cannot be negative");
    }
  }
}

export type OtherInvestment =
  | PercentOfCompany
  | Share
  | Warrant
  | Option
  | Loan
  | Other;
export namespace OtherInvestment {
  export function assureVersion(
    input: OtherInvestment | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      OtherInvestmentTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.OtherInvestment);
  }

  export const datePaths: readonly (
    | PathsOfDateField<PercentOfCompany>
    | PathsOfDateField<Share>
    | PathsOfDateField<Warrant>
    | PathsOfDateField<Option>
    | PathsOfDateField<Loan>
    | PathsOfDateField<Other>
  )[] = [
    "createAt",
    "updateAt",
    "maturityDate",
    "investmentDate",
    "expirationDate",
    "vestingDate",
    "startDate",
    "grantDate",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<OtherInvestment>[] = [
    "estimatedReturn",
    "value",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<OtherInvestment> {
    const decrypted = await OtherInvestment.decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, <any>datePaths);
    return decrypted;
  }
  export type ToCreateFields<T extends OtherInvestmentBase> = OmitKeys<
    T,
    "@type" | "ownerId" | "version" | "createAt" | "updateAt" | "valueSourceId"
  >;
  export type CreateFields =
    | ToCreateFields<PercentOfCompany>
    | ToCreateFields<Share>
    | ToCreateFields<Warrant>
    | ToCreateFields<Option>
    | ToCreateFields<Loan>
    | ToCreateFields<Other>;

  export type EncryptedKeys = AssetV2.EncryptedKeys | "attributeNotes";
  export type Encrypted<T extends OtherInvestment = OtherInvestment> =
    RequireEncryptionFields<
      EncryptedType<T, OtherInvestment.EncryptedKeys>,
      {
        attachments?: Attachment.Encrypted[];
      }
    >;
  export type EncryptedPart = Pick<OtherInvestment, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    "notes",
    "attributeNotes",
  ] as const;
  export function fromCreate(
    from: OtherInvestment.CreateFields,
    ownerId: string
  ): OtherInvestment {
    const oi: WithFieldValue<OtherInvestment> = {
      ...from,
      "@type": OtherInvestmentTypeVersion,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
    };
    return oi as OtherInvestment;
  }
  //   // NON-OPTIONAL
  //   // OPTIONAL
  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<OtherInvestmentBase>[] =
    [
      "name",
      "subtype",
      "estimatedYield",
      "personalRefNo",
      "status",
      "interestRate",
    ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<OtherInvestmentBase>[] =
    ["notes", "attributeNotes", "notes", "website"];
  function buildBaseUpdate(
    current: OtherInvestmentBase,
    update: OtherInvestmentBase
  ): {
    updates: UpdateObject<OtherInvestmentBase>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      OptionalSimpleTypeUpdatableKeys
    );

    if (!Amount.equal(current.value, update.value))
      baseUpdateFields.value = update.value;
    if (!Amount.equal(current.estimatedReturn, update.estimatedReturn))
      baseUpdateFields.estimatedReturn = update.estimatedReturn;

    if (!Ownership.optionalEqual(current.ownership, update.ownership))
      if (update.ownership) baseUpdateFields.ownership = update.ownership;
      else baseUpdateFields.ownership = null;
    if (!Owner.optionalEqual(current.beneficiary, update.beneficiary))
      if (update.beneficiary) baseUpdateFields.beneficiary = update.beneficiary;
      else baseUpdateFields.beneficiary = null;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;

    if (!optionalDateEqual(current.maturityDate, update.maturityDate))
      if (update.maturityDate)
        baseUpdateFields.maturityDate = update.maturityDate;
      else baseUpdateFields.maturityDate = null;
    if (update.contactIds) baseUpdateFields.contactIds = update.contactIds;
    const { attachments, newImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;
    return { updates: baseUpdateFields, metadata };
  }

  function processSubtypeChange<T extends object, U extends object>(
    current: T,
    keysOfCurrent: readonly (keyof T)[],
    update: U,
    keysOfUpdate: readonly (keyof U)[]
  ): { keysToRemove: (keyof T)[]; add: UpdateObject<U> } {
    const add: UpdateObject<U> = {};
    const keysToRemove = keysOfCurrent.filter(
      (key) => current[key] !== undefined
    );
    keysOfUpdate.forEach((key) => {
      if (update[key] !== undefined) {
        add[key] = <any>update[key];
      }
    });
    return {
      keysToRemove,
      add,
    };
  }
  const subtypeToKeys = {
    PercentOfCompany: PercentOfCompany.keys,
    Share: Share.keys,
    Warrant: Warrant.keys,
    Option: Option.keys,
    Loan: Loan.keys,
    Other: Other.keys,
  };

  export function intoUpdate(
    current: OtherInvestment,
    update: OtherInvestment
  ): {
    updates: UpdateObject<OtherInvestment>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
    };
  } {
    const { updates: baseUpdateFields, metadata } = buildBaseUpdate(
      current,
      update
    );

    if (baseUpdateFields.subtype) {
      const { keysToRemove, add } = processSubtypeChange(
        current,
        <(keyof OtherInvestment)[]>(<unknown>subtypeToKeys[current.subtype]),
        update,
        <(keyof OtherInvestment)[]>(<unknown>subtypeToKeys[update.subtype])
      );
      keysToRemove.forEach((key) => {
        if ((<any>current)[key] != undefined)
          (<any>baseUpdateFields)[key] = null;
      });
      Object.assign(baseUpdateFields, add);
      return { updates: baseUpdateFields, metadata };
    } else {
      switch (update.subtype) {
        case InvestmentType.PercentOfCompany: {
          const updates: UpdateObject<PercentOfCompany.Fields> = {
            ...baseUpdateFields,
            ...PercentOfCompany.buildUpdate(<PercentOfCompany>current, update),
          };
          return {
            updates,
            metadata,
          };
        }
        case InvestmentType.Share: {
          const updates: UpdateObject<Share.Fields> = {
            ...baseUpdateFields,
            ...Share.buildUpdate(<Share>current, update),
          };
          return {
            updates,
            metadata,
          };
        }
        case InvestmentType.Warrant: {
          const updates: UpdateObject<Warrant.Fields> = {
            ...baseUpdateFields,
            ...Warrant.buildUpdate(<Warrant>current, update),
          };
          return {
            updates,
            metadata,
          };
        }
        case InvestmentType.Option: {
          const updates: UpdateObject<Option.Fields> = {
            ...baseUpdateFields,
            ...Option.buildUpdate(<Option>current, update),
          };
          return {
            updates,
            metadata,
          };
        }
        case InvestmentType.Loan: {
          const updates: UpdateObject<Loan.Fields> = {
            ...baseUpdateFields,
            ...Loan.buildUpdate(<Loan>current, update),
          };
          return {
            updates,
            metadata,
          };
        }
        case InvestmentType.Other: {
          const updates: UpdateObject<Other.Fields> = {
            ...baseUpdateFields,
            ...Other.buildUpdate(<Other>current, update),
          };
          return {
            updates,
            metadata,
          };
        }
      }
      // return { updates: baseUpdateFields, metadata };
    }
  }
  export function removeEncryptedFields<
    T extends OtherInvestment | UpdateObject<OtherInvestment>
  >(data: T): OmitKeys<T, EncryptedKeys | "attachments"> {
    const result = doRemoveEncryptedFields(
      data,
      encryptedKeysArray
    ) as OmitKeys<T, EncryptedKeys | "attachments">;
    delete (<any>result).attachments;
    return result;
  }

  export async function encrypt(
    input: OtherInvestment,
    encryption: Encryption
  ): Promise<Encrypted> {
    const {
      attachments,
      ...rest
    }: EncryptedType<OtherInvestment, EncryptedKeys> =
      await fullObjectEncryption(input, encryptedKeysArray, encryption);
    const result = rest as Encrypted;
    if (attachments) {
      result.attachments = await Attachment.encryptArray(
        attachments,
        encryption
      );
    }
    return result;
  }
  export async function encryptPartial<T extends OtherInvestment.EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function decrypt(
    data: Encrypted,
    encryption: Encryption
  ): Promise<OtherInvestment> {
    const EncryptedPart: EncryptedPart = await encryption.decryptAndStringify(
      data[EncryptionFieldKey]["data"],
      encryption.convertBase64ToIVSalt(data[EncryptionFieldKey][IVSaltFieldKey])
    );
    const { attachments, ...rest } = data;
    const result: OtherInvestment = <OtherInvestment>{
      ...removeEncryptionFields(rest),
      ...EncryptedPart,
      attachments: await Attachment.decryptArray(attachments, encryption),
    };
    return result;
  }
  export const decryptPartial: (
    data: EncryptionField,
    encryption: Encryption
  ) => Promise<OtherInvestment.EncryptedPart> = fullObjectDecryption;

  export function newAggregateRoot(state: OtherInvestment.Encrypted) {
    return new AggregateRoot(new OtherInvestmentAggregate(state));
  }

  export function defaultStateValue(): Encrypted {
    const defaultState: EncryptedType<OtherInvestmentBase, EncryptedKeys> = {
      "@type": OtherInvestmentTypeVersion,
      assetType: AssetType.OtherInvestment,
      subtype: InvestmentType.Other,
      id: "",
      name: "",
      ownerId: "",
      version: 0,
      createAt: new Date(0),
      updateAt: new Date(0),
      personalRefNo: "",
      interestRate: 0,
      contactIds: [],
      status: InvestmentStatus.Active,
      value: Amount.defaultValue(),
      estimatedYield: 0,
      estimatedReturn: Amount.defaultValue(),
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue, //`{name:""}`
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
    return <Encrypted<Other>>defaultState;
  }
  //#NOTE validate encrypted keys have legal value
  export function validateEncryptedPart(
    data: EncryptedPart & {
      attachments?: Attachment.EncryptedPart[];
    },
    _isCreate: boolean = false
  ) {
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }
  //#TODO need checks
  //#NOTE validate data after encrypted
  export function validateEncryptedObj(
    data: UpdateObject<OmitKeys<Encrypted, typeof EncryptionFieldKey>>,
    isCreate: boolean = false
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    //non optional fields, if isCreate, the field must be in the data
    if ((isCreate || data.interestRate) && data.interestRate! < 0) {
      throw new InvalidInput("Interest rate can't be negative");
    }
    if (
      (isCreate || data.status) &&
      !validateValueInEnum(data.status!, InvestmentStatus)
    ) {
      throw new InvalidInput("Invalid status");
    }
    if ((isCreate || data.estimatedYield) && data.estimatedYield! < 0) {
      throw new InvalidInput("Estimated yield can't be negative");
    }
    if ((isCreate || data.estimatedReturn) && data.estimatedReturn!.value < 0) {
      throw new InvalidInput("Estimated return can't be negative");
    }
    if ((isCreate || data.value) && data.value!.value < 0) {
      throw new InvalidInput("Value can't be negative");
    }
    switch (data.subtype) {
      case InvestmentType.PercentOfCompany:
        PercentOfCompany.validate(<PercentOfCompany.Fields>data, isCreate);
        break;
      case InvestmentType.Share:
        Share.validate(<Share.Fields>data, isCreate);
        break;
      case InvestmentType.Warrant:
        Warrant.validate(<Warrant.Fields>data, isCreate);
        break;
      case InvestmentType.Option:
        Option.validate(<Option.Fields>data, isCreate);
        break;
      case InvestmentType.Loan:
        Loan.validate(<Loan.Fields>data, isCreate);
        break;
      case InvestmentType.Other:
        Other.validate(<Other.Fields>data, isCreate);
        break;
      default:
        throw new InvalidInput("Invalid subtype");
    }
    //optional fields
    if (data.ownership) {
      Ownership.validate(data.ownership);
    }
    if (data.beneficiary) {
      Owner.validate(0, data.beneficiary);
    }
    if (data.attachments) {
      Attachment.validateEncryptedObj(data.attachments);
    }
  }
  export type RelatedUpdates = {
    addedGroupIds?: string[];
    removedGroupIds?: string[];
  };

  export interface RelatedAggregates {
    group?: RepoAndAggregates<any, any, any>;
    cashAndBanking?: RepoAndAggregates<any, any, any>;
  }
}

export namespace Command {
  export type Kind = SharedCommand.Kind;
  export const Kind = SharedCommand.Kind;

  export interface CreateAsset
    extends SharedCommand.CreateAsset<OtherInvestment.Encrypted> {}
  export const createAsset =
    SharedCommand.createAsset<OtherInvestment.Encrypted>;
  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<
      UpdateObject<OtherInvestment.Encrypted>
    > {}
  export const updateAsset = SharedCommand.updateAsset<
    UpdateObject<OtherInvestment.Encrypted>
  >;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.DeleteAsset;

export namespace Event {
  export type Kind = SharedEvent.Kind;
  export const Kind = SharedEvent.Kind;

  export interface AssetCreated
    extends SharedEvent.AssetCreated<OtherInvestment.Encrypted> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<OtherInvestment.Encrypted>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}
  export interface ValueUpdated extends EventBase {
    kind: SharedEvent.Kind.ValueUpdated;
    previous?: Amount;
    current: Amount;
  }
  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.ValueUpdated
  | Event.GroupsUpdated;

class OtherInvestmentAggregate extends AggregateBase<
  OtherInvestment.Encrypted,
  Command,
  Event
> {
  state: OtherInvestment.Encrypted;
  kind: string;
  relatedUpdates: OtherInvestment.RelatedUpdates = {};

  constructor(state: OtherInvestment.Encrypted) {
    super();
    this.state = state;
    this.kind = state.assetType;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return this.handleCreateAsset(command).map(preSealEvent);
      case Command.Kind.UpdateAsset:
        return this.handleUpdateAsset(command).map(preSealEvent);
      case Command.Kind.DeleteAsset:
        return this.handleDeleteAsset(command).map(preSealEvent);
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state = event.asset;
        break;
      case Event.Kind.AssetUpdated:
        applyUpdateToObject(
          this.state,
          event.current ? event.current : event.asset
        );
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetDeleted:
        if (this.state.groupIds && this.state.groupIds.length > 0) {
          this.relatedUpdates.removedGroupIds = this.state.groupIds;
        }
        this.state = setObjectDeleted(this.state);
        break;
      case Event.Kind.ValueUpdated:
        this.state.value = event.current;
        break;
      case Event.Kind.GroupsUpdated:
        if (event.addIds.length > 0)
          this.relatedUpdates.addedGroupIds = event.addIds;
        if (event.removedIds.length > 0)
          this.relatedUpdates.removedGroupIds = event.removedIds;
        break;
    }
    return this;
  }

  private handleCreateAsset({
    executerId,
    asset,
  }: Command.CreateAsset): Event[] {
    OtherInvestment.validateEncryptedObj(asset, true);

    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.AssetCreated,
        asset,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: {},
            prevAssetNumber: 0,
            currAssetNumber: 1,
            prevItemNumber: 0,
            currItemNumber: 1,
            currTags: [],
          },
        ],
      },
    ];
    if (asset.value) {
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        current: asset.value,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: MultiCurrencyAmount.fromAmounts(
              calculateOwnedValue(asset.value, asset.ownership?.myOwnership)
            ),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: 1,
            currItemNumber: 1,
            prevTags: [],
            currTags: [],
          },
        ],
      });
    }
    if (asset.groupIds && asset.groupIds.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: asset.groupIds,
        removedIds: [],
      });
    }
    if (asset.ownership) {
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        current: asset.ownership,
      };
      events.push(shareholderUpdated);
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        current: asset.beneficiary,
      });
    }
    return events;
  }
  private handleUpdateAsset({
    executerId,
    asset,
    addedToGroup,
    removedFromGroup,
  }: Command.UpdateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    if (!asset.subtype) asset.subtype = this.state.subtype;
    OtherInvestment.validateEncryptedObj(asset);
    const events: Event[] = [];
    let prevOwnedValue = calculateOwnedValue(
      this.state.value,
      this.state.ownership?.myOwnership
    );
    if (asset.value) {
      const currOwnedValue = calculateOwnedValue(
        asset.value,
        this.state.ownership?.myOwnership
      );
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.value,
        current: asset.value,
        summaryData: [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
            currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: 1,
            currItemNumber: 1,
            prevTags: [],
            currTags: [],
          },
        ],
      });
      prevOwnedValue = { ...currOwnedValue };
    }

    const assetUpdatedEvent: Event.AssetUpdated = {
      executerId,
      kind: Event.Kind.AssetUpdated,
      asset,
      previous: deepCopy(this.state),
      current: asset,
    };
    const currOwnedValue = calculateOwnedValue(
      asset.value || this.state.value,
      asset.ownership?.myOwnership || this.state.ownership?.myOwnership
    );
    if (!Amount.equal(prevOwnedValue, currOwnedValue)) {
      assetUpdatedEvent.summaryData = [
        {
          prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
          currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
          prevAssetNumber: 1,
          currAssetNumber: 1,
          prevItemNumber: 1,
          currItemNumber: 1,
          prevTags: [],
          currTags: [],
        },
      ];
    }
    events.push(assetUpdatedEvent);

    if (addedToGroup || removedFromGroup) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: addedToGroup ?? [],
        removedIds: removedFromGroup ?? [],
      });
    }
    if (asset.ownership) {
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        previous: this.state.ownership,
        current: asset.ownership,
      };
      events.push(shareholderUpdated);
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        previous: this.state.beneficiary,
        current: asset.beneficiary,
      });
    }
    return events;
  }
  private handleDeleteAsset({ executerId }: Command.DeleteAsset): Event[] {
    AssetV2.checkDelete(this.state);
    return [
      {
        executerId,
        kind: Event.Kind.AssetDeleted,
        summaryData: [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(
              calculateOwnedValue(
                this.state.value,
                this.state.ownership?.myOwnership
              )
            ),
            currOwnedValue: {},
            prevAssetNumber: 1,
            currAssetNumber: 0,
            prevItemNumber: 1,
            currItemNumber: 0,
            prevTags: [],
            currTags: [],
          },
        ],
      },
    ];
  }
}
