import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
  convertAllTimestamp,
  getQueriedData,
} from "../../coreFirebase";
import {
  ActionEvent,
  EventEnvelope,
  EventWithTime,
  preSealEvent,
  sealEvent,
  SellEvent,
  SharedEvent,
  TagPair,
  ValuationEvent,
} from "./event";
import { AssetV2, MultiCurrencyAmount, Optional } from "./common";
import { Valuation } from "./actions/valuation";
import {
  ActionCommand,
  SellCommand,
  SharedCommand,
  ValuationCommand,
} from "./command";
import { Action } from "./actions";
import { ActionType, ItemActions } from "./actions/base";
import { DataPoisoned, InvalidInput, NotImplemented } from "./error";
import { checkAndPrefixFinanceName, getAssetUserDataPath } from "../refPaths";
import {
  UpdateObject,
  applyUpdateToObject,
  calculateOwnedValue,
  resolveObject,
} from "../utils";
import { SoldInfo } from "./actions/soldInfo";
import { Offer } from "./actions/offer";
import { Consignment } from "./actions/consignment";
import { Exhibition } from "./actions/exhibition";
import { Literature } from "./actions/literature";
import { TastingNote } from "./actions/tastingNote";
import { AssetType } from "./enums";
import {
  RelationsOfAsset,
  buildArtRelation,
  buildBelongingRelation,
  buildCryptocurrencyRelation,
  buildInsuranceRelation,
  buildOtherInvestmentRelation,
} from "./relations";
import { Art } from "./arts";
import { Belonging } from "./belongings";
import { Cryptocurrency } from "./cryptocurrencies";
import { Insurance, Insured } from "./insurance";
import { OtherInvestment } from "./otherInvestments";
import { RentInfo } from "./actions/rentInfo";
import { Encrypted } from "../database/encryption";

//#NOTE start from 1
export const StartSequence = 1;
export const EventBatchSize = 100;
export interface Sequence {
  sequence: number;
}

enum NonAssetDomain {
  Groups = "Groups",
  Institution = "Institution",
}
export type Domain =
  | Exclude<AssetType, AssetType.WinePurchases>
  | NonAssetDomain;
export const Domain = { ...AssetType, ...NonAssetDomain };

export interface IAggregateData {
  id: string;
  version: number;
}
export function setObjectDeleted<T extends IAggregateData>(obj: T): T {
  return <any>{
    id: obj.id,
    version: obj.version,
    ["@aggregateState"]: "deleted",
  };
}
export function stateIsDeleted<T extends IAggregateData>(obj: T): boolean {
  return (<any>obj)["@aggregateState"] === "deleted";
}

export interface IAggregate<State, Command, Event> {
  state: State;
  kind: string;
  relatedReads?: any;
  relatedUpdates: any;

  id(): string;
  version(): number;
  incrementVersion(): void;
  handle(command: Command): EventWithTime<Event>[];
  apply(event: EventWithTime<Event>): this;
}
export interface IAggregateStateWriter<State, Command, Event> {
  setStateTx(
    transaction: Transaction,
    aggregate: IAggregate<State, Command, Event>
  ): void;
}

export class BaseStateWriter<State extends IAggregateData, Command, Event>
  implements IAggregateStateWriter<State, Command, Event>
{
  transaction!: Transaction;
  collectionRef: CollectionReference<State>;

  constructor(ref: CollectionReference<State>) {
    this.collectionRef = ref;
  }

  setStateTx(
    transaction: Transaction,
    aggregate: IAggregate<State, Command, Event>
  ): void {
    const docRef = CoreFirestore.docFromCollection(
      this.collectionRef,
      aggregate.id()
    );
    if (stateIsDeleted(aggregate.state)) {
      this.deleteStateTx(transaction, docRef);
    } else {
      transaction.set(docRef, aggregate.state);
    }
    aggregate.relatedUpdates = {};
  }

  deleteStateTx(
    transaction: Transaction,
    docRef: DocumentReference<State>
  ): void {
    transaction.delete(docRef);
  }
}

export class AssetRelationStateWriter<
  State extends
    | Art.Encrypted
    | Encrypted<Belonging>
    | Cryptocurrency.Encrypted
    | Insurance.Encrypted
    | OtherInvestment.Encrypted,
  Command,
  Event
> implements IAggregateStateWriter<State, Command, Event>
{
  transaction!: Transaction;
  assetCollectionRef: CollectionReference<State>;
  relationCollectionRef: CollectionReference<RelationsOfAsset>;

  constructor(
    assetRef: CollectionReference<State>,
    relationRef: CollectionReference<RelationsOfAsset>
  ) {
    this.assetCollectionRef = assetRef;
    this.relationCollectionRef = relationRef;
  }

  setStateTx(
    transaction: Transaction,
    aggregate: IAggregate<State, Command, Event>
  ): void {
    const docRef = CoreFirestore.docFromCollection(
      this.assetCollectionRef,
      aggregate.id()
    );
    const relationDocRef = CoreFirestore.docFromCollection(
      this.relationCollectionRef,
      aggregate.id()
    );
    if (stateIsDeleted(aggregate.state)) {
      this.deleteStateTx(transaction, docRef, relationDocRef);
    } else {
      transaction.set(docRef, aggregate.state);
      switch (aggregate.state.assetType) {
        case AssetType.Art:
          transaction.set(relationDocRef, buildArtRelation(aggregate.state));
          break;
        case AssetType.Belonging:
        case AssetType.OtherCollectables:
          transaction.set(
            relationDocRef,
            buildBelongingRelation(aggregate.state)
          );
          break;
        case AssetType.Cryptocurrency:
          transaction.set(
            relationDocRef,
            buildCryptocurrencyRelation(aggregate.state)
          );
          break;
        case AssetType.Insurance:
          transaction.set(
            relationDocRef,
            buildInsuranceRelation(aggregate.state)
          );
          break;
        case AssetType.OtherInvestment:
          transaction.set(
            relationDocRef,
            buildOtherInvestmentRelation(aggregate.state)
          );
          break;
        default:
          throw new InvalidInput("AssetType is not valid");
      }
    }
  }

  deleteStateTx(
    transaction: Transaction,
    assetDocRef: DocumentReference<State>,
    relationDocRef: DocumentReference<RelationsOfAsset>
  ): void {
    transaction.delete(assetDocRef);
    transaction.delete(relationDocRef);
  }
}

export class SummaryBaseStateWriter<
  Summary = any,
  SummaryAggregate extends IAggregate<Summary, never, Event> = any,
  Event = any
> implements IAggregateStateWriter<Summary, never, Event>
{
  transaction!: Transaction;
  docRef: DocumentReference<Summary>;

  constructor(docRef: DocumentReference<Summary>) {
    this.docRef = docRef;
  }

  setStateTx(transaction: Transaction, aggregate: SummaryAggregate): void {
    transaction.set(this.docRef, aggregate.state);
  }

  deleteStateTx(_: Transaction): void {
    throw new Error("cannot delete summary");
  }
}

export class AggregateBase<State extends IAggregateData, Command, Event>
  implements IAggregate<State, Command, Event>
{
  state!: State;
  kind!: string;
  relatedUpdates: any;

  id(): string {
    return this.state.id;
  }
  version(): number {
    return this.state.version;
  }
  incrementVersion(): void {
    this.state.version++;
  }

  handle(_: Command): EventWithTime<Event>[] {
    throw new NotImplemented("overwrite this method");
  }
  apply(_: EventWithTime<Event>): this {
    throw new NotImplemented("overwrite this method");
  }
}

export class AggregateRoot<State, Command, Event> {
  protected aggregate: IAggregate<State, Command, Event>;
  protected changes: EventWithTime<Event>[] = [];

  constructor(aggregate: IAggregate<State, Command, Event>) {
    this.aggregate = aggregate;
  }

  id: () => string = () => {
    if (this.aggregate.id) return this.aggregate.id();
    throw new Error("id() not initialized");
  };

  version: () => number = () => {
    if (this.aggregate.version) return this.aggregate.version();
    throw new Error("version() not initialized");
  };

  state(): IAggregate<State, Command, Event>["state"] {
    return this.aggregate.state;
  }

  getAggregate(): IAggregate<State, Command, Event> {
    return this.aggregate;
  }

  relatedUpdates(): IAggregate<State, Command, Event>["relatedUpdates"] {
    return this.aggregate.relatedUpdates;
  }

  getChanges(): EventWithTime<Event>[] {
    return this.changes;
  }

  handle(command: Command): this {
    const events = this.aggregate.handle(command);
    this.changes = this.changes.concat(events);
    return this;
  }

  takeChanges(): EventWithTime<Event>[] {
    const events = this.changes;
    this.changes = [];
    return events;
  }

  applySingle(event: EventWithTime<Event>): this {
    this.aggregate.apply(event);
    this.aggregate.incrementVersion();
    return this;
  }

  apply(events: EventWithTime<Event>[]): this {
    events.forEach((event) => {
      this.aggregate.apply(event);
      this.aggregate.incrementVersion();
    });
    return this;
  }

  applyAllChanges() {
    const events = this.takeChanges();
    this.apply(events);
    return events;
  }
}

export function getSeqDocPath(userId: string, name: Domain) {
  if (Object.values(AssetType).includes(name as AssetType)) {
    return `${getAssetUserDataPath(
      userId,
      name as AssetType
    )}/${checkAndPrefixFinanceName(name, "EventAndSequence")}`;
  } else {
    return `UserData/${userId}/EventAndSequence/${name}`;
  }
}
export function getAssetEventCollectionPath(userId: string, name: Domain) {
  return `${getSeqDocPath(userId, name)}/events`;
}

export class EventReadRepo<Event> {
  eventCollectionRef: CollectionReference<EventEnvelope<Event>>;

  constructor(eventCollectionRef: CollectionReference<EventEnvelope<Event>>) {
    this.eventCollectionRef = eventCollectionRef;
  }

  async readEventFrom(
    start: number,
    end: number,
    limit?: number
  ): Promise<EventEnvelope<Event>[]> {
    if (limit) {
      end = Math.min(end, start + limit);
    }
    return CoreFirestore.getDocsFromCollection(
      this.eventCollectionRef,
      CoreFirestore.where("sequence", ">=", start),
      CoreFirestore.where("sequence", "<", end),
      CoreFirestore.orderBy("sequence")
    )
      .then(getQueriedData)
      .then((events) => getCheckedEvents(events, start, end));
  }
}
export namespace EventReadRepo {
  export function newWithData(userId: string, domain: Domain) {
    const eventCollectionRef = CoreFirestore.collection(
      getAssetEventCollectionPath(userId, domain)
    ) as CollectionReference<EventEnvelope<Event>>;
    return new EventReadRepo(eventCollectionRef);
  }
}

export async function getLatestSequence(
  transaction: Transaction,
  userId: string,
  domain: Domain
) {
  const sequenceDocRef = CoreFirestore.doc(
    getSeqDocPath(userId, domain)
  ) as DocumentReference<Sequence>;

  const seqSnapshot = await transaction.get(sequenceDocRef);
  const data = seqSnapshot.data();
  return data ? data.sequence : StartSequence - 1;
}

export async function newRepo<State, Command, TEvent>(
  transaction: Transaction,
  userId: string,
  domain: Domain,
  stateWriter: ClientRepo<State, Command, TEvent>["stateWriter"]
) {
  return new ClientRepo<State, Command, TEvent>().newWithTx(
    transaction,
    userId,
    domain,
    stateWriter
  );
}
export interface Repo<State, Command, TEvent> {
  newWithTx(
    transaction: Transaction,
    userId: string,
    domain: Domain,
    stateWriter: IAggregateStateWriter<State, Command, TEvent>
  ): Promise<this>;

  commitWithState(aggregate: AggregateRoot<State, Command, TEvent>): void;

  //#NOTE this can be used to handle change among multiple aggregates
  // - we might apply the events outside the function
  manualCommit(
    aggregate: AggregateRoot<State, Command, TEvent>,
    events: EventWithTime<TEvent>[]
  ): TEvent[];
}

export class ClientRepo<State, Command, TEvent>
  implements Repo<State, Command, TEvent>
{
  transaction!: Transaction;
  eventCollectionRef!: CollectionReference<EventEnvelope<TEvent>>;
  sequenceDocRef!: DocumentReference<Sequence>;
  startSequence!: number; //latest sequence + 1 of given event collection
  sequenceDocExists!: boolean;
  domain!: string;
  stateWriter!: IAggregateStateWriter<State, Command, TEvent>;

  async newWithTx(
    transaction: Transaction,
    userId: string,
    domain: Domain,
    stateWriter: ClientRepo<State, Command, TEvent>["stateWriter"]
  ) {
    this.transaction = transaction;
    this.stateWriter = stateWriter;

    this.eventCollectionRef = CoreFirestore.collection(
      getAssetEventCollectionPath(userId, domain)
    ) as CollectionReference<EventEnvelope<TEvent>>;
    this.sequenceDocRef = CoreFirestore.doc(
      getSeqDocPath(userId, domain)
    ) as DocumentReference<Sequence>;

    const seqSnapshot = await transaction.get(this.sequenceDocRef);

    this.domain = domain;
    this.sequenceDocExists = seqSnapshot.exists();
    const data = seqSnapshot.data();
    this.startSequence = data ? data.sequence + 1 : StartSequence;
    return this;
  }

  commitWithState(aggregate: AggregateRoot<State, Command, TEvent>) {
    const events = aggregate.takeChanges();
    aggregate.apply(events);
    this.manualCommit(aggregate, events);
  }

  manualCommit(
    aggregateRoot: AggregateRoot<State, Command, TEvent>,
    events: EventWithTime<TEvent>[]
  ) {
    if (events.length <= 0)
      throw new Error("cannot have zero changes in aggregate");

    const updatedVersion = aggregateRoot.version();
    const aggregateId = aggregateRoot.id();

    const endSequence = this.startSequence + events.length - 1;

    events.forEach((evt, idx) => {
      this.transaction.set(
        CoreFirestore.docFromCollection(this.eventCollectionRef),
        sealEvent<TEvent>(
          this.startSequence + idx,
          updatedVersion - events.length + idx + 1,
          aggregateId,
          this.domain,
          evt
        )
      );
    });

    if (this.sequenceDocExists) {
      this.transaction.update(this.sequenceDocRef, { sequence: endSequence });
    } else {
      this.transaction.set(this.sequenceDocRef, { sequence: endSequence });
      this.sequenceDocExists = true;
    }
    this.startSequence = endSequence + 1;
    this.stateWriter.setStateTx(this.transaction, aggregateRoot.getAggregate());
    return events.map((v) => v.data);
  }
}

export type RepoAndAggregates<State extends IAggregateData, Command, TEvent> = {
  repo: Repo<State, Command, TEvent>;
  aggregates: AggregateRoot<State, Command, TEvent>[];
};

export function handleAddInsurance(
  currentInsurance: Optional<string[]>,
  { executerId, id }: SharedCommand.AddInsurance
): SharedEvent.InsuranceUpdated[] {
  const event: SharedEvent.InsuranceUpdated = {
    executerId,
    kind: SharedEvent.Kind.InsuranceUpdated,
  };
  if (currentInsurance) {
    if (currentInsurance.find((v) => v === id)) return [];
    event.previous = currentInsurance;
    event.current = [...currentInsurance, id];
  } else {
    event.current = [id];
  }
  return [event];
}

export function handleRemoveInsurance(
  currentInsurance: Optional<string[]>,
  { executerId, id }: SharedCommand.RemoveInsurance
): SharedEvent.InsuranceUpdated[] {
  const event: SharedEvent.InsuranceUpdated = {
    executerId,
    kind: SharedEvent.Kind.InsuranceUpdated,
  };
  if (currentInsurance) {
    const idx = currentInsurance.findIndex((v) => v === id);
    if (idx != -1) {
      event.previous = currentInsurance;
      event.current = currentInsurance.filter((_, i) => i !== idx);
    }
  }
  return [event];
}

type SummaryUpdate = {
  shouldUpdate: boolean;
  detail: {
    myOwnership?: number;
    itemNumber: number;
    tags: TagPair[];
  };
};

export namespace SupportValuationAggregate {
  type RequiredEvent =
    | SharedEvent.ValueUpdated
    | ValuationEvent.ValuationAdded
    | ValuationEvent.ValuationUpdated
    | ValuationEvent.ValuationDeleted;

  type SupportActions = ItemActions.Encrypted | Valuation.Encrypted;
  type SupportedAggregate<State extends AssetV2.Encrypted> = {
    state: State;
    relatedReads?: {
      actions: {
        [id: string]: SupportActions;
      };
    };
    relatedUpdates: {
      setActions?: SupportActions[];
      removedActionIds?: string[];
    };
  };

  export function handleAdd<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    { data, executerId, updateAssetValue }: ValuationCommand.AddValuation,
    summaryUpdate: SummaryUpdate
  ): EventWithTime<RequiredEvent>[] {
    Valuation.validateEncryptedObj(data, true);
    const events: RequiredEvent[] = [
      {
        executerId,
        kind: ValuationEvent.Kind.ValuationAdded,
        data: data,
      },
    ];
    if (updateAssetValue) {
      events.push({
        executerId,
        kind: SharedEvent.Kind.ValueUpdated,
        previous: ar.state.value,
        current: data.value,
        valuationId: data.id,
        summaryData: summaryUpdate.shouldUpdate
          ? [
              {
                prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                  calculateOwnedValue(
                    ar.state.value,
                    summaryUpdate.detail.myOwnership
                  )
                ),
                currOwnedValue: MultiCurrencyAmount.fromAmounts(
                  calculateOwnedValue(
                    data.value,
                    summaryUpdate.detail.myOwnership
                  )
                ),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: summaryUpdate.detail.itemNumber,
                currItemNumber: summaryUpdate.detail.itemNumber,
                prevTags: summaryUpdate.detail.tags,
                currTags: summaryUpdate.detail.tags,
              },
            ]
          : undefined,
      });
    }
    return events.map(preSealEvent);
  }
  export function handleUpdate<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    {
      id,
      update,
      executerId,
      createdAfterValueSource,
      latestValuation,
    }: ValuationCommand.UpdateValuation,
    summaryUpdate: SummaryUpdate
  ): EventWithTime<RequiredEvent>[] {
    Valuation.validateEncryptedObj(update);
    if (ar.relatedReads?.actions[id]) {
      //#TODO no update checks now
    } else {
      throw new Error(`Valuation ${id} not provided in relatedReads`);
    }

    const events: RequiredEvent[] = [
      {
        executerId,
        kind: ValuationEvent.Kind.ValuationUpdated,
        id,
        update,
      },
    ];
    // updating the same valuation source
    if (ar.state.valueSourceId == id) {
      // `updateValue` updated from checked to unchecked
      if (update.updateValue === false) {
        if (latestValuation) {
          events.push({
            executerId,
            kind: SharedEvent.Kind.ValueUpdated,
            previous: ar.state.value,
            current: latestValuation.value,
            valuationId: latestValuation.id,
            summaryData: summaryUpdate.shouldUpdate
              ? [
                  {
                    prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                      calculateOwnedValue(
                        ar.state.value,
                        summaryUpdate.detail.myOwnership
                      )
                    ),
                    currOwnedValue: MultiCurrencyAmount.fromAmounts(
                      calculateOwnedValue(
                        latestValuation.value,
                        summaryUpdate.detail.myOwnership
                      )
                    ),
                    prevAssetNumber: 1,
                    currAssetNumber: 1,
                    prevItemNumber: summaryUpdate.detail.itemNumber,
                    currItemNumber: summaryUpdate.detail.itemNumber,
                    prevTags: summaryUpdate.detail.tags,
                    currTags: summaryUpdate.detail.tags,
                  },
                ]
              : undefined,
          });
        }
      }
      // `updateValue` is checked and value is updated
      else if (update.value) {
        events.push({
          executerId,
          kind: SharedEvent.Kind.ValueUpdated,
          previous: ar.state.value,
          current: update.value,
          valuationId: id,
          summaryData: summaryUpdate.shouldUpdate
            ? [
                {
                  prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                    calculateOwnedValue(
                      ar.state.value,
                      summaryUpdate.detail.myOwnership
                    )
                  ),
                  currOwnedValue: MultiCurrencyAmount.fromAmounts(
                    calculateOwnedValue(
                      update.value,
                      summaryUpdate.detail.myOwnership
                    )
                  ),
                  prevAssetNumber: 1,
                  currAssetNumber: 1,
                  prevItemNumber: summaryUpdate.detail.itemNumber,
                  currItemNumber: summaryUpdate.detail.itemNumber,
                  prevTags: summaryUpdate.detail.tags,
                  currTags: summaryUpdate.detail.tags,
                },
              ]
            : undefined,
        });
      }
    }
    // A newer valuation `updateValue` is updated from unchecked to checked
    else if (createdAfterValueSource && update.updateValue && update.value) {
      events.push({
        executerId,
        kind: SharedEvent.Kind.ValueUpdated,
        previous: ar.state.value,
        current: update.value,
        valuationId: id,
        summaryData: summaryUpdate.shouldUpdate
          ? [
              {
                prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                  calculateOwnedValue(
                    ar.state.value,
                    summaryUpdate.detail.myOwnership
                  )
                ),
                currOwnedValue: MultiCurrencyAmount.fromAmounts(
                  calculateOwnedValue(
                    update.value,
                    summaryUpdate.detail.myOwnership
                  )
                ),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: summaryUpdate.detail.itemNumber,
                currItemNumber: summaryUpdate.detail.itemNumber,
                prevTags: summaryUpdate.detail.tags,
                currTags: summaryUpdate.detail.tags,
              },
            ]
          : undefined,
      });
    }
    return events.map(preSealEvent);
  }
  export function handleDelete<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    {
      id,
      executerId,
      linkedToId,
      previousValue,
      currentValue,
      previousValuationName,
    }: ValuationCommand.DeleteValuation,
    summaryUpdate: SummaryUpdate
  ): EventWithTime<RequiredEvent>[] {
    const events: RequiredEvent[] = [
      {
        executerId,
        kind: ValuationEvent.Kind.ValuationDeleted,
        id,
      },
    ];
    if (
      id == ar.state.valueSourceId &&
      linkedToId &&
      previousValue &&
      currentValue
    ) {
      events.push({
        executerId,
        kind: SharedEvent.Kind.ValueUpdated,
        previous: previousValue,
        current: currentValue,
        valuationId: linkedToId,
        valuationName: previousValuationName,
        revertedFromId: id,
        summaryData: summaryUpdate.shouldUpdate
          ? [
              {
                prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                  calculateOwnedValue(
                    previousValue,
                    summaryUpdate.detail.myOwnership
                  )
                ),
                currOwnedValue: MultiCurrencyAmount.fromAmounts(
                  calculateOwnedValue(
                    currentValue,
                    summaryUpdate.detail.myOwnership
                  )
                ),
                prevAssetNumber: 1,
                currAssetNumber: 1,
                prevItemNumber: summaryUpdate.detail.itemNumber,
                currItemNumber: summaryUpdate.detail.itemNumber,
                prevTags: summaryUpdate.detail.tags,
                currTags: summaryUpdate.detail.tags,
              },
            ]
          : undefined,
      });
    }
    return events.map(preSealEvent);
  }
  export function applyAdded<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    event: ValuationEvent.ValuationAdded
  ) {
    if (ar.relatedUpdates.setActions)
      ar.relatedUpdates.setActions.push(event.data);
    else ar.relatedUpdates.setActions = [event.data];
  }
  export function applyUpdated<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    event: ValuationEvent.ValuationUpdated
  ) {
    const maybeValuation = ar.relatedReads?.actions[event.id];
    if (!maybeValuation)
      throw new Error(`Valuation ${event.id} not provided in relatedReads`);
    if (maybeValuation.actionType !== ActionType.AddValuation)
      throw new Error(`Action ${event.id} is not ${ActionType.AddValuation}`);
    ar.relatedUpdates.setActions = [
      applyUpdateToObject(maybeValuation, event.update),
    ];
  }
  export function applyDeleted<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    event: ValuationEvent.ValuationDeleted
  ) {
    if (ar.state.valueSourceId === event.id) delete ar.state.valueSourceId;
    if (ar.relatedUpdates.setActions) {
      const maybeIndex = ar.relatedUpdates.setActions.findIndex(
        (v) => v.id === event.id
      );
      if (maybeIndex != -1) {
        ar.relatedUpdates.setActions.splice(maybeIndex, 1);
      }
    }
    if (ar.relatedUpdates.removedActionIds) {
      ar.relatedUpdates.removedActionIds.push(event.id);
    } else {
      ar.relatedUpdates.removedActionIds = [event.id];
    }
  }
}

export namespace SupportSellAggregate {
  type RequiredEvent =
    | SellEvent.SoldInfoAdded
    | SellEvent.SoldInfoUpdated
    | SellEvent.SoldInfoDeleted;

  type SupportActions = ItemActions.Encrypted | Valuation.Encrypted;
  type SupportedAggregate<State extends AssetV2.Encrypted> = {
    state: State;
    relatedReads?: {
      actions: {
        [id: string]: SupportActions;
      };
    };
    relatedUpdates: {
      setActions?: SupportActions[];
      removedActionIds?: string[];
    };
  };

  export function handleAdd<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    { data, executerId }: SellCommand.MarkAsSold,
    summaryUpdate: SummaryUpdate
  ): EventWithTime<RequiredEvent>[] {
    if (ar.state.closedWith !== undefined) {
      throw new InvalidInput(
        `Asset already closed with SoldInfo: ${ar.state.closedWith}`
      );
    }
    SoldInfo.validateEncryptedObj(data, true);
    const event: SellEvent.SoldInfoAdded = {
      executerId,
      kind: SellEvent.Kind.SoldInfoAdded,
      data,
      logInfo: {
        assetName: ar.state.name,
      },
      summaryData: summaryUpdate.shouldUpdate
        ? [
            {
              prevOwnedValue: MultiCurrencyAmount.fromAmounts(
                calculateOwnedValue(
                  ar.state.value,
                  summaryUpdate.detail.myOwnership
                )
              ),
              currOwnedValue: {},
              prevAssetNumber: 1,
              currAssetNumber: 0,
              prevItemNumber: summaryUpdate.detail.itemNumber,
              currItemNumber: 0,
              prevTags: summaryUpdate.detail.tags,
              currTags: summaryUpdate.detail.tags,
            },
          ]
        : undefined,
    };
    return [preSealEvent(event)];
  }
  export function handleUpdate<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    { id, update, executerId }: SellCommand.UpdateSoldInfo
  ): EventWithTime<RequiredEvent>[] {
    if (ar.state.closedWith != id) {
      throw new InvalidInput(
        `SoldInfo id ${id} inconsistent ${ar.state.closedWith}`
      );
    }
    const currentAction = ar.relatedReads?.actions[id];
    if (currentAction) {
      //#TODO no update checks now
    } else {
      throw new InvalidInput(`SoldInfo ${id} not provided in relatedReads`);
    }
    SoldInfo.validateEncryptedObj(update);
    const event: SellEvent.SoldInfoUpdated = {
      executerId,
      kind: SellEvent.Kind.SoldInfoUpdated,
      id,
      update,
      logInfo: {
        currentTitle: (<SoldInfo.Encrypted>currentAction).title,
      },
    };
    return [preSealEvent(event)];
  }
  export function handleDelete<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    { id, executerId }: SellCommand.DeleteSoldInfo,
    summaryUpdate: SummaryUpdate
  ): EventWithTime<RequiredEvent>[] {
    if (ar.state.closedWith != id) {
      throw new InvalidInput(
        `SoldInfo id ${id} inconsistent ${ar.state.closedWith}`
      );
    }
    const currentAction = ar.relatedReads?.actions[id];
    if (!currentAction) {
      throw new InvalidInput(`SoldInfo ${id} not provided in relatedReads`);
    }
    const event: SellEvent.SoldInfoDeleted = {
      executerId,
      kind: SellEvent.Kind.SoldInfoDeleted,
      id,
      logInfo: { title: (<SoldInfo.Encrypted>currentAction).title },
      summaryData: summaryUpdate.shouldUpdate
        ? [
            {
              prevOwnedValue: {},
              currOwnedValue: MultiCurrencyAmount.fromAmounts(
                calculateOwnedValue(
                  ar.state.value,
                  summaryUpdate.detail.myOwnership
                )
              ),
              prevAssetNumber: 0,
              currAssetNumber: 1,
              prevItemNumber: 0,
              currItemNumber: summaryUpdate.detail.itemNumber,
              prevTags: summaryUpdate.detail.tags,
              currTags: summaryUpdate.detail.tags,
            },
          ]
        : undefined,
    };
    return [preSealEvent(event)];
  }
  export function applyAdded<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    event: SellEvent.SoldInfoAdded
  ) {
    ar.state.closedWith = event.data.id;
    if (ar.relatedUpdates.setActions)
      ar.relatedUpdates.setActions.push(event.data);
    else ar.relatedUpdates.setActions = [event.data];
  }
  export function applyUpdated<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    event: SellEvent.SoldInfoUpdated
  ) {
    const maybeValuation = ar.relatedReads?.actions[event.id];
    if (!maybeValuation)
      throw new Error(`SoldInfo ${event.id} not provided in relatedReads`);
    if (maybeValuation.actionType !== ActionType.MarkAsSold)
      throw new Error(`Action ${event.id} is not ${ActionType.MarkAsSold}`);
    ar.relatedUpdates.setActions = [
      applyUpdateToObject(maybeValuation, event.update),
    ];
  }
  export function applyDeleted<State extends AssetV2.Encrypted>(
    ar: SupportedAggregate<State>,
    event: SellEvent.SoldInfoDeleted
  ) {
    if (ar.state.closedWith === event.id) delete ar.state.closedWith;
    else throw new DataPoisoned("closedWith Id not match");
    if (ar.relatedUpdates.setActions) {
      const maybeIndex = ar.relatedUpdates.setActions.findIndex(
        (v) => v.id === event.id
      );
      if (maybeIndex != -1) {
        ar.relatedUpdates.setActions.splice(maybeIndex, 1);
      }
    }
    if (ar.relatedUpdates.removedActionIds) {
      ar.relatedUpdates.removedActionIds.push(event.id);
    } else {
      ar.relatedUpdates.removedActionIds = [event.id];
    }
  }
}

export namespace SupportActionAggregate {
  // type RequiredEvent<T extends Action.Encrypted, U extends Action.Update> =
  //   | ActionEvent.ActionAdded<T>
  //   | ActionEvent.ActionUpdated<U>
  //   | ActionEvent.ActionDeleted;

  type SupportActions = ItemActions.Encrypted | Valuation.Encrypted;
  type SupportedAggregate<State> = {
    state: State;
    relatedReads?: {
      actions: {
        [id: string]: SupportActions;
      };
    };
    relatedUpdates: {
      setActions?: SupportActions[];
      removedActionIds?: string[];
    };
  };

  export function handleAdd<State, T extends Action.Encrypted>(
    ar: SupportedAggregate<State>,
    command: ActionCommand.AddAction<T>
  ): EventWithTime<ActionEvent.ActionAdded<T>>[] {
    const data: Action.Encrypted = command.data;
    switch (data.actionType) {
      case ActionType.AddOffer:
        Offer.validateEncryptedObj(data, true);
        break;
      case ActionType.AddConsignment:
        Consignment.validateEncryptedObj(data, true);
        break;
      case ActionType.AddExhibition:
        Exhibition.validateEncryptedObj(data, true);
        break;
      case ActionType.AddLiterature:
        Literature.validateEncryptedObj(data, true);
        break;
      case ActionType.AddTastingNote:
        TastingNote.validateEncryptedObj(data, true);
        break;
      case ActionType.RentOut:
        RentInfo.validateEncryptedObj(data, true);
        break;
      default:
        throw new InvalidInput(`Unsupported actionType ${data.actionType}`);
    }
    const event: ActionEvent.ActionAdded<T> = {
      executerId: command.executerId,
      kind: ActionEvent.Kind.ActionAdded,
      data: command.data,
    };
    return [preSealEvent(event)];
  }

  export function getLogInfo(currentAction: SupportActions) {
    let logInfo = "";
    switch (currentAction.actionType) {
      case ActionType.AddOffer:
        logInfo = (<Offer.Encrypted>currentAction).offerNumber;
        break;
      case ActionType.AddConsignment:
        logInfo = (<Consignment.Encrypted>currentAction).consignee;
        break;
      case ActionType.AddLiterature:
        logInfo = (<Literature.Encrypted>currentAction).title;
        break;
      case ActionType.AddExhibition:
        logInfo = (<Exhibition.Encrypted>currentAction).title;
        break;
    }
    return logInfo;
  }
  export function handleUpdate<State, T extends Action.Update>(
    ar: SupportedAggregate<State>,
    command: ActionCommand.UpdateAction<T>
  ): EventWithTime<ActionEvent.ActionUpdated<T>>[] {
    const currentAction = ar.relatedReads?.actions[command.id];
    if (currentAction) {
      switch (currentAction.actionType) {
        case ActionType.AddOffer:
          Offer.validateEncryptedObj(<any>command.update);
          break;
        case ActionType.AddConsignment:
          Consignment.validateEncryptedObj(<any>command.update);
          break;
        case ActionType.AddExhibition:
          Exhibition.validateEncryptedObj(<any>command.update);
          break;
        case ActionType.AddLiterature:
          Literature.validateEncryptedObj(<any>command.update);
          break;
        case ActionType.AddTastingNote:
          TastingNote.validateEncryptedObj(<any>command.update);
          break;
        case ActionType.RentOut:
          RentInfo.validateEncryptedObj(<any>command.update);
          break;
        default:
          throw new InvalidInput(
            `Unsupported actionType ${currentAction.actionType}`
          );
      }
    } else {
      throw new InvalidInput(
        `Action ${command.id} not provided in relatedReads`
      );
    }
    const event: ActionEvent.ActionUpdated<T> = {
      executerId: command.executerId,
      kind: ActionEvent.Kind.ActionUpdated,
      id: command.id,
      actionType: currentAction.actionType,
      update: command.update,
      logInfo: getLogInfo(currentAction),
    };
    return [preSealEvent(event)];
  }
  export function handleDelete<State>(
    ar: SupportedAggregate<State>,
    { id, executerId }: ActionCommand.DeleteAction
  ): EventWithTime<ActionEvent.ActionDeleted>[] {
    const currentAction = ar.relatedReads?.actions[id];
    if (!currentAction)
      throw new InvalidInput(`Action ${id} not provided in relatedReads`);
    const event: ActionEvent.ActionDeleted = {
      executerId,
      kind: ActionEvent.Kind.ActionDeleted,
      id,
      actionType: currentAction.actionType,
      logInfo: getLogInfo(currentAction),
    };
    return [preSealEvent(event)];
  }
  export function applyAdded<State, T extends Action.Encrypted>(
    ar: SupportedAggregate<State>,
    event: ActionEvent.ActionAdded<T>
  ) {
    if (ar.relatedUpdates.setActions)
      ar.relatedUpdates.setActions.push(event.data);
    else ar.relatedUpdates.setActions = [event.data];
  }
  export function applyUpdated<State, T extends Action.Update>(
    ar: SupportedAggregate<State>,
    event: ActionEvent.ActionUpdated<T>
  ) {
    const action = ar.relatedReads?.actions[event.id];
    if (!action)
      throw new Error(`Action ${event.id} not provided in relatedReads`);
    ar.relatedUpdates.setActions = [
      applyUpdateToObject(action, <UpdateObject<SupportActions>>event.update),
    ];
  }
  export function applyDeleted<State>(
    ar: SupportedAggregate<State>,
    event: ActionEvent.ActionDeleted
  ) {
    if (ar.relatedUpdates.setActions) {
      const maybeIndex = ar.relatedUpdates.setActions.findIndex(
        (v) => v.id === event.id
      );
      if (maybeIndex != -1) {
        ar.relatedUpdates.setActions.splice(maybeIndex, 1);
      }
    }
    if (ar.relatedUpdates.removedActionIds) {
      ar.relatedUpdates.removedActionIds.push(event.id);
    } else {
      ar.relatedUpdates.removedActionIds = [event.id];
    }
  }
}

export interface EventConsumer<State, Command, TEvent> {
  stateWriter: Optional<IAggregateStateWriter<State, Command, TEvent>>;
  getAggregateRoot: (
    transaction: Transaction
  ) => Promise<AggregateRoot<State, Command, TEvent>>;
  processEvent: (raw: EventEnvelope<TEvent>[]) => EventWithTime<TEvent>[];
}

export function assetProcessEventBase<TEvent>(
  aggregateId: string,
  raw: EventEnvelope<TEvent>[]
): TEvent[] {
  return raw.filter((v) => v.aggregateId === aggregateId).map((v) => v.data);
}

export type SummaryAggregateConstructor<Summary, SummaryAggregate> = new (
  summary: Summary
) => SummaryAggregate;

export class SummaryEventConsumerBase<State, Command, TEvent, StateAggregate>
  implements EventConsumer<State, Command, TEvent>
{
  stateAggregateConstructor: SummaryAggregateConstructor<State, StateAggregate>;
  stateWriter: Optional<IAggregateStateWriter<State, Command, TEvent>>;
  getAggregateRoot: (
    transaction: Transaction
  ) => Promise<AggregateRoot<State, Command, TEvent>>;

  constructor(
    docRef: DocumentReference<State>,
    getAggregateRoot: (
      transaction: Transaction
    ) => Promise<AggregateRoot<State, Command, TEvent>>,
    stateAggregateConstructor: SummaryAggregateConstructor<
      State,
      StateAggregate
    >,
    hasWritePermission: boolean = true
  ) {
    this.stateWriter = hasWritePermission
      ? new SummaryBaseStateWriter(docRef)
      : undefined;
    this.getAggregateRoot = getAggregateRoot;
    this.stateAggregateConstructor = stateAggregateConstructor;
  }

  processEvent(raw: EventEnvelope<TEvent>[]): EventWithTime<TEvent>[] {
    return <EventWithTime<TEvent>[]>convertAllTimestamp(raw);
  }
}

export async function loopCatchupEvents<TEvent>(
  userId: string,
  domain: Domain,
  consumers: Record<string, EventConsumer<any, any, TEvent>>,
  limit: number = EventBatchSize
): Promise<Record<string, AggregateRoot<any, any, TEvent>>> {
  const eventCollectionRef = CoreFirestore.collection(
    getAssetEventCollectionPath(userId, domain)
  ) as CollectionReference<EventEnvelope<TEvent>>;

  for (;;) {
    const result = await CoreFirestore.runTransaction(async (transaction) => {
      const promiseObj: Record<
        string,
        Promise<AggregateRoot<any, any, TEvent>>
      > = {};

      Object.entries(consumers).forEach(([k, v]) => {
        promiseObj[k] = v.getAggregateRoot(transaction);
      });
      const aggregateRoots: Record<
        string,
        AggregateRoot<any, any, TEvent>
      > = await resolveObject(promiseObj);
      const { synced, setStates } = await catchupEventsInTransaction(
        transaction,
        eventCollectionRef,
        userId,
        domain,
        aggregateRoots,
        consumers,
        limit
      );
      setStates.forEach((f) => f());
      return synced ? aggregateRoots : undefined;
    });
    if (result !== undefined) return result;
  }
  throw new Error("loopCatchupEvents failed");
}

/**
 * Check if the event sequences are in range and in order
 * @returns the checked events before the first event that is not in expected sequence
 */
export async function getCheckedEvents<TEvent>(
  rawEvents: EventEnvelope<TEvent>[],
  start: number,
  end: number // if limit is set, this should be the end sequence of batch not the latest sequence
) {
  const checkedRawEvents: EventEnvelope<TEvent>[] = [];
  const checkedSeq = new Set<number>();
  let expectedSeq = start;

  if (
    rawEvents[0].sequence < start ||
    rawEvents[rawEvents.length - 1].sequence >= end
  ) {
    console.log(
      `events out of queried range: ${rawEvents[0].sequence} - ${
        rawEvents[rawEvents.length - 1].sequence
      }, start: ${start}, end: ${end}`
    );
    return checkedRawEvents;
  }

  for (const event of rawEvents) {
    // Handle duplicate event
    if (checkedSeq.has(event.sequence)) {
      continue;
    }
    if (event.sequence !== expectedSeq) {
      console.log(
        `Unexpected event sequence: ${event.sequence}, expected: ${expectedSeq}`
      );
      break;
    }
    checkedRawEvents.push(event);
    checkedSeq.add(event.sequence);
    expectedSeq++;
  }
  return checkedRawEvents;
}

export async function catchupEventsInTransaction<TEvent>(
  transaction: Transaction,
  eventCollectionRef: CollectionReference<EventEnvelope<TEvent>>,
  userId: string,
  domain: Domain,
  aggregateRoots: Record<string, AggregateRoot<any, any, TEvent>>,
  consumers: Record<string, EventConsumer<any, any, TEvent>>,
  limit: Optional<number> = EventBatchSize
) {
  const minVersion = Math.min(
    ...Object.values(aggregateRoots).map((v) => v.version())
  );
  const processing = Object.entries(aggregateRoots).map(([key, v]) => ({
    ar: v,
    count: v.version() - minVersion,
    consumer: consumers[key],
  }));

  const end = (await getLatestSequence(transaction, userId, domain)) + 1;
  const start = minVersion + 1;
  const eventReadRepo = new EventReadRepo(eventCollectionRef);

  if (start == end) return { synced: true, aggregateRoots, setStates: [] };
  if (start > end)
    throw new Error(
      `start: ${start} > end: ${end} in ${domain}, event collection path ${eventCollectionRef.path}`
    );

  const rawEvents = await eventReadRepo.readEventFrom(start, end, limit);

  const setStates = processing
    .map(({ ar, count, consumer }) => {
      consumer.processEvent(rawEvents.slice(count)).forEach((event) => {
        ar.applySingle(event);
      });
      if (consumer.stateWriter)
        return () => {
          consumer.stateWriter!.setStateTx(transaction, ar.getAggregate());
        };
      else return undefined;
    })
    .filter((f) => f !== undefined) as (() => void)[];
  return { synced: end - start <= limit, aggregateRoots, setStates };
}

export function buildUpdateGroupCommand<
  T extends Pick<AssetV2, "groupIds">,
  Command
>(
  ar: AggregateRoot<any, Command, any>,
  buildUpdateAssetCommand: (
    executerId: string,
    asset: UpdateObject<T>,
    addedToGroup?: Optional<string[]>,
    removedFromGroup?: Optional<string[]>,
    newImages?: Optional<string[]>,
    newMainImage?: Optional<string>,
    removedImages?: Optional<string[]>,
    locationPrimaryDetailsUpdated?: Optional<boolean>,
    addedToInsured?: Optional<Insured[]>,
    removedFromInsured?: Optional<Insured[]>
  ) => Command,
  executerId: string,
  currentGroupIds: Optional<string[]>,
  groupId: string,
  isAdd: boolean
) {
  let command: Command;
  if (isAdd) {
    if (currentGroupIds && currentGroupIds.some((id) => id == groupId))
      return undefined;
    else {
      command = buildUpdateAssetCommand(
        executerId,
        <UpdateObject<T>>{
          groupIds: currentGroupIds ? [...currentGroupIds, groupId] : [groupId],
        },
        [groupId]
      );
    }
  } else {
    if (currentGroupIds && currentGroupIds.some((id) => id == groupId)) {
      command = buildUpdateAssetCommand(
        executerId,
        <UpdateObject<T>>{
          groupIds: currentGroupIds?.filter((v) => v !== groupId),
        },
        undefined,
        [groupId]
      );
    } else return undefined;
  }
  try {
    ar.handle(command);
    return ar;
  } catch (e) {
    console.error("CashAndBankingRepo newArAndUpdateGroup", e);
    return undefined;
  }
}
