import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2018%22%3E%3Cpath%20d%3D%22M24%204.64V3.376A2.625%202.625%200%200%200%2021.375.75H2.625A2.625%202.625%200%200%200%200%203.375v1.266c0%20.13.105.234.234.234h23.532A.234.234%200%200%200%2024%204.641ZM0%206.61v8.015a2.625%202.625%200%200%200%202.625%202.625h18.75A2.625%202.625%200%200%200%2024%2014.625V6.609a.234.234%200%200%200-.234-.234H.234A.234.234%200%200%200%200%206.609Zm6%206.14a.75.75%200%200%201-.75.75H4.5a.75.75%200%200%201-.75-.75V12a.75.75%200%200%201%20.75-.75h.75A.75.75%200%200%201%206%2012v.75Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CreditCardIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 24 18'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M24 4.64V3.376A2.625 2.625 0 0 0 21.375.75H2.625A2.625 2.625 0 0 0 0 3.375v1.266c0 .13.105.234.234.234h23.532c.129 0 .234-.105.234-.234ZM0 6.61v8.015a2.625 2.625 0 0 0 2.625 2.625h18.75A2.625 2.625 0 0 0 24 14.625V6.609a.234.234 0 0 0-.234-.234H.234A.234.234 0 0 0 0 6.609Zm6 6.14a.75.75 0 0 1-.75.75H4.5a.75.75 0 0 1-.75-.75V12a.75.75 0 0 1 .75-.75h.75A.75.75 0 0 1 6 12v.75Z'
        }
      />
    </svg>
  )
}
