import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M7.172%2011.012c.107%200%20.212.02.31.062l3.838%201.597a.78.78%200%200%201%20.21%201.31.95.95%200%200%201-.675.173l-4.698-.765a.316.316%200%201%200-.102.624l4.738.772a1.417%201.417%200%200%200%201.109-.29%201.417%201.417%200%200%200%20.331-1.83l3.369-1.527a1.432%201.432%200%200%201%201.343.088.597.597%200%200%201%20.004%201.021L9.478%2016.79a2.65%202.65%200%200%201-2.058.38l-5.343-1.213v.26a.463.463%200%200%201-.463.462H.464A.463.463%200%200%201%200%2016.215v-4.548c0-.256.207-.463.463-.463h1.15a.463.463%200%200%201%20.464.463v.132l.81-.62a.807.807%200%200%201%20.49-.167Zm3.745-6.257a3.447%203.447%200%201%201%202.567%206.398%203.447%203.447%200%200%201-2.567-6.398ZM2.863%202.602a3.447%203.447%200%201%201%206.683%201.691%203.447%203.447%200%200%201-6.683-1.691Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function HandAndBallsIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 18 18'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M7.172 11.012c.107 0 .212.02.31.062l3.838 1.597a.78.78 0 0 1 .21 1.31.95.95 0 0 1-.675.173l-4.698-.765a.316.316 0 1 0-.102.624l4.738.772a1.417 1.417 0 0 0 1.109-.29 1.417 1.417 0 0 0 .331-1.83l3.369-1.527a1.432 1.432 0 0 1 1.343.088.597.597 0 0 1 .004 1.021L9.478 16.79a2.65 2.65 0 0 1-2.058.38l-5.343-1.213v.26a.463.463 0 0 1-.463.462H.464A.463.463 0 0 1 0 16.215v-4.548c0-.256.207-.463.463-.463h1.15a.463.463 0 0 1 .464.463v.132l.81-.62a.807.807 0 0 1 .49-.167Zm3.745-6.257a3.447 3.447 0 1 1 2.567 6.398 3.447 3.447 0 0 1-2.567-6.398ZM2.863 2.602a3.447 3.447 0 1 1 6.683 1.691 3.447 3.447 0 0 1-6.683-1.691Z'
        }
      />
    </svg>
  )
}
