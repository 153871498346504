import { OtherInvestment, Command, Event } from "../types/otherInvestments";
import {
  AggregateRoot,
  AssetRelationStateWriter,
  buildUpdateGroupCommand,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import { AssetType, Attachment, Currency, Optional } from "../types/common";
import { EncryptionFieldKey } from "../encryption/utils";
import { UpdateObject } from "../utils";
import { OtherInvestmentSummary } from "../types/otherInvestmentSummary";
import { ExchangeRate } from "./exchangeRate";
import { FullRefs, Refs, getAssetsByIds } from "../refs";
import { GroupUpdater } from "./groups";
import { EncryptionManager } from "./encryption";
import { RoleToAsset } from "../types/relations";
import { DataPoisoned } from "../types/error";
import { SummaryManager } from "../types/summaryManager";
import { DbSharedFields } from "../types/database";
import {
  CoreFirestore,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";
import { CashAndBankingRepo } from "./cashAndBanking";

export class OtherInvestmentRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }

  async getSyncedSummary(currency?: Currency) {
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return OtherInvestmentSummary.toDisplay(
      (
        await this.summaryManager
          .get(AssetType.OtherInvestment)
          .syncAndGetData()
      ).summary,
      exRate
    );
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    ar: AggregateRoot<OtherInvestment.Encrypted, Command, Event>,
    isDelete?: boolean
  ) {
    const asset = ar.state();
    const data = ar.relatedUpdates() as OtherInvestment.RelatedUpdates;
    const repoAndAggregates: OtherInvestment.RelatedAggregates = {};
    let groupUpdater: Optional<GroupUpdater> = undefined;
    if (data.addedGroupIds || data.removedGroupIds) {
      groupUpdater = new GroupUpdater(
        this.refs.currentRefs,
        (data.addedGroupIds || []).concat(data.removedGroupIds || [])
      );
      await groupUpdater.read(transaction);
    }
    if (isDelete) {
      repoAndAggregates.cashAndBanking = {
        repo: await CashAndBankingRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: await CashAndBankingRepo.newArAndUpdateAllocatedLiability(
          this.refs.currentRefs,
          transaction,
          this.refs.selfRefs.userId,
          asset.id
        ),
      };
    }
    // * writes
    if (data.addedGroupIds && groupUpdater) {
      for (const groupId of data.addedGroupIds) {
        groupUpdater.addOneItemToGroup(
          transaction,
          groupId,
          asset.id,
          AssetType.OtherInvestment,
          asset.subtype
        );
      }
    }
    if (data.removedGroupIds && groupUpdater) {
      for (const groupId of data.removedGroupIds) {
        groupUpdater.deleteOneItemFromGroup(transaction, groupId, asset.id);
      }
    }
    if (repoAndAggregates.cashAndBanking) {
      const accountData = repoAndAggregates.cashAndBanking;
      accountData.aggregates.forEach((aggregate) => {
        accountData.repo.commitWithState(aggregate);
      });
    }
  }

  async getAll(): Promise<OtherInvestment[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetCollectionRef<OtherInvestment.Encrypted>(
        AssetType.OtherInvestment
      );
    const assets = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) =>
        OtherInvestment.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
    return assets.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getByIds(ids: string[]): Promise<OtherInvestment[]> {
    const result = await getAssetsByIds<OtherInvestment.Encrypted>(
      this.refs.currentRefs,
      AssetType.OtherInvestment,
      ids
    );
    return Promise.all(
      result.map((v) =>
        OtherInvestment.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async getById(id: string): Promise<OtherInvestment> {
    const docRef =
      this.refs.currentRefs.getAssetDocRef<OtherInvestment.Encrypted>(
        AssetType.OtherInvestment,
        id
      );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) =>
        OtherInvestment.decryptAndConvertDate(v, this.Encryption.current)
      );
  }

  async add(req: OtherInvestment.CreateFields) {
    const newDocRef =
      this.refs.currentRefs.getAssetDocRef<OtherInvestment.Encrypted>(
        AssetType.OtherInvestment,
        req.id
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);

      const repo = await OtherInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const asset = OtherInvestment.fromCreate(
        req,
        this.refs.currentRefs.userId
      );
      OtherInvestment.validateEncryptedPart(asset, true);
      const encrypted = await OtherInvestment.encrypt(
        asset,
        this.Encryption.current
      );

      const ar = OtherInvestment.newAggregateRoot(
        OtherInvestment.defaultStateValue()
      );
      ar.handle(Command.createAsset(this.refs.selfRefs.userId, encrypted));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async update(req: OtherInvestment) {
    const docRef =
      this.refs.currentRefs.getAssetDocRef<OtherInvestment.Encrypted>(
        AssetType.OtherInvestment,
        req.id
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = OtherInvestment.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) OtherInvestment.handleOutDated();

      const currentData = await OtherInvestment.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const repo = await OtherInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const {
        updates,
        metadata: { addedToGroup, removedFromGroup },
      } = OtherInvestment.intoUpdate(currentData, req);
      const encryptedFieldsUpdated = OtherInvestment.encryptedKeysArray.reduce(
        (result, key) => result || Object.keys(updates).includes(key),
        false
      );

      const encryptedUpdate: UpdateObject<OtherInvestment.Encrypted> =
        OtherInvestment.removeEncryptedFields(updates);
      if (encryptedFieldsUpdated) {
        const encryptionPart: OtherInvestment.EncryptedPart = {};
        if (updates.notes !== null) {
          encryptionPart.notes = updates.notes || currentData.notes;
        }
        if (updates.attributeNotes !== null) {
          encryptionPart.attributeNotes =
            updates.attributeNotes || currentData.attributeNotes;
        }
        const newEncrypted = await OtherInvestment.encryptPartial(
          encryptionPart,
          this.Encryption.current
        );
        if (newEncrypted[EncryptionFieldKey]) {
          encryptedUpdate[EncryptionFieldKey] =
            newEncrypted[EncryptionFieldKey];
        }
      }
      if (updates.attachments) {
        encryptedUpdate.attachments = await Attachment.encryptArray(
          updates.attachments,
          this.Encryption.current
        );
      }

      const ar = OtherInvestment.newAggregateRoot(currentUndecrypted);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup
        )
      );
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async delete(id: string) {
    const docRef =
      this.refs.currentRefs.getAssetDocRef<OtherInvestment.Encrypted>(
        AssetType.OtherInvestment,
        id
      );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = OtherInvestment.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) OtherInvestment.handleOutDated();
      const repo = await OtherInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = OtherInvestment.newAggregateRoot(currentData);
      ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar, true);
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

export namespace OtherInvestmentRepo {
  export async function removeGroup(
    refs: FullRefs,
    id: string,
    groupId: string
  ) {
    const docRef = refs.currentRefs.getAssetDocRef<OtherInvestment.Encrypted>(
      AssetType.OtherInvestment,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = OtherInvestment.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) OtherInvestment.handleOutDated();

      const repo = await newRepo(refs.currentRefs, transaction);
      const ar = OtherInvestment.newAggregateRoot(currentUndecrypted);
      const currentGroupIds = ar.state().groupIds;
      const arToCommit = buildUpdateGroupCommand(
        ar,
        Command.updateAsset,
        refs.selfRefs.userId,
        currentGroupIds,
        groupId,
        false
      );

      const groupUpdater = new GroupUpdater(refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      //commit
      if (arToCommit) {
        const events = arToCommit.applyAllChanges();
        repo.manualCommit(arToCommit, events);
      }

      // delete from group
      groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<OtherInvestment.Encrypted>(
      AssetType.OtherInvestment,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = OtherInvestment.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) OtherInvestment.handleOutDated();
      const encryptedUpdate: UpdateObject<OtherInvestment.Encrypted> = {};
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Contact: {
            const updateContactIds = currentUndecrypted.contactIds.filter(
              (v) => v !== contactId
            );
            if (
              updateContactIds.length === currentUndecrypted.contactIds.length
            )
              throw new DataPoisoned("contactId not found");
            encryptedUpdate.contactIds = updateContactIds;
            break;
          }
          case RoleToAsset.Shareholder: {
            if (!currentUndecrypted.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder =
              currentUndecrypted.ownership.shareholder.filter(
                (s) => s.contactId !== contactId
              );
            if (
              updateShareholder.length ===
              currentUndecrypted.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            encryptedUpdate.ownership = {
              myOwnership: currentUndecrypted.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!currentUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = currentUndecrypted.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateBeneficiary.length === currentUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            encryptedUpdate.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const repo = await newRepo(refs, transaction);
      const ar = OtherInvestment.newAggregateRoot(currentUndecrypted);
      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function newRepo(refs: Refs, transaction: Transaction) {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.OtherInvestment,
      new AssetRelationStateWriter(
        refs.getAssetCollectionRef<OtherInvestment.Encrypted>(
          AssetType.OtherInvestment
        ),
        refs.Relations
      )
    );
  }

  export async function newArAndUpdateGroup(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string,
    groupId: string,
    isAdd: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<OtherInvestment.Encrypted>(
      AssetType.OtherInvestment,
      assetId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = OtherInvestment.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) OtherInvestment.handleOutDated();
    const ar = OtherInvestment.newAggregateRoot(currentData);
    const currentGroupIds = ar.state().groupIds;
    return buildUpdateGroupCommand(
      ar,
      Command.updateAsset,
      executerId,
      currentGroupIds,
      groupId,
      isAdd
    );
  }
}
