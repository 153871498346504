'use client'

import Link from 'next/link'

import { useToast } from '@/hooks/useToast'
import { Toast, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/base'
import { CheckCircleIcon, CircleAlert } from '@/components/icon'

export function Toaster() {
  const { toasts } = useToast()
  const hasLearnMoreLink = toasts.some((toast) => toast.learnMoreLink)

  return (
    <ToastProvider>
      {toasts.map(function ({ variant, id, title, description, learnMoreLink, action, ...props }) {
        return (
          <Toast key={id} variant={variant} {...props}>
            <div className={'flex items-center gap-2'}>
              {variant === 'error' && <CircleAlert size={20} className={'shrink-0'} />}
              {variant === 'success' && <CheckCircleIcon size={20} className={'shrink-0'} />}
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription className={'whitespace-pre-line'}>{description}</ToastDescription>}
              {learnMoreLink && (
                <Link
                  href={learnMoreLink}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                  className={'ml-2 whitespace-nowrap text-sm text-gray-700 underline hover:text-gray-900'}
                >
                  {'Learn More'}
                </Link>
              )}
            </div>
            {action}
            {/* <ToastClose /> */}
          </Toast>
        )
      })}
      <ToastViewport className={hasLearnMoreLink ? 'md:max-w-md' : ''} />
    </ToastProvider>
  )
}
