import { Amount, Currency, Optional } from "../types/common";

export type StockSymbolExchange = {
  symbol: string;
  exchange: string;
};
export type StockPrice = {
  [exchange: string]: {
    [symbol: string]: Amount;
  };
};

export interface CryptoPriceSource {
  getCryptoPrice: (coinCodes: string[]) => Promise<Record<string, Amount>>;
}

export interface StockPriceSource {
  getStockPrice: (stocks: StockSymbolExchange[]) => Promise<StockPrice>;
}

export interface PriceSource extends CryptoPriceSource, StockPriceSource {}

export class MockPriceSource implements PriceSource {
  stockPriceSource: Optional<StockPriceSource>;
  cryptoPriceSource: Optional<CryptoPriceSource>;
  constructor(
    stockPriceSource?: StockPriceSource,
    cryptoPriceSource?: CryptoPriceSource
  ) {
    this.stockPriceSource = stockPriceSource;
    this.cryptoPriceSource = cryptoPriceSource;
  }
  async getStockPrice(stocks: StockSymbolExchange[]) {
    if (this.stockPriceSource) {
      return this.stockPriceSource.getStockPrice(stocks);
    }
    // //#TODO get real stock price
    // const stockPriceMap: Record<string, Amount> = {};
    // stocks.map((stock) => {
    //   if (!stockPriceMap[stock])
    //     stockPriceMap[stock] = { currency: Currency.USD, value: 1 };
    // });
    // return stockPriceMap;
    let result: StockPrice = {};
    for (let stock of stocks) {
      if (!result[stock.exchange]) {
        result[stock.exchange] = {};
      }
      result[stock.exchange] = {
        [stock.symbol]: {
          currency: Currency.USD,
          value: 1,
        },
      };
    }
    return result;
  }

  async getCryptoPrice(coinCodes: string[]) {
    if (this.cryptoPriceSource) {
      return this.cryptoPriceSource.getCryptoPrice(coinCodes);
    }
    //#TODO get real crypto price
    const cryptoPriceMap: Record<string, Amount> = {};
    coinCodes.map((coinName) => {
      if (!cryptoPriceMap[coinName])
        cryptoPriceMap[coinName] = {
          currency: Currency.USD,
          value: 1,
        };
    });
    return cryptoPriceMap;
  }
}

export interface MaybePriceSource {
  getCryptoPrice?: (coinCodes: string[]) => Promise<Record<string, Amount>>;
  getStockPrice?: (stocks: StockSymbolExchange[]) => Promise<StockPrice>;
}

export class PriceSourceWrapper {
  priceSource?: MaybePriceSource;
  constructor(priceSource?: MaybePriceSource) {
    this.priceSource = priceSource;
  }
  async getStockPrice(stocks: StockSymbolExchange[]) {
    if (!this.priceSource?.getStockPrice) {
      throw new Error("getStockPrice is not defined");
    }
    return this.priceSource.getStockPrice(stocks);
  }
  async getCryptoPrice(coinCodes: string[]) {
    if (!this.priceSource?.getCryptoPrice) {
      throw new Error("getCryptoPrice is not defined");
    }
    return this.priceSource.getCryptoPrice(coinCodes);
  }
}
