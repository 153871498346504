import { mutate } from 'swr'

import { type AssetTypeWithSubtype, type Database } from 'core/remodel/database'
import { ExportRowV1, ExportRowV2 } from 'core/remodel/database/exportHandler'
import { SoldInfo } from 'core/remodel/types/actions/soldInfo'
import { type Art } from 'core/remodel/types/arts'
import { OtherCollectableType } from 'core/remodel/types/belongings/type'
import { Account } from 'core/remodel/types/cashAndBanking'
import {
  AssetV2,
  AttachmentCategory,
  AttachmentKind,
  IVAttachmentFileSaltFieldKey,
  LocationType,
  type Amount,
  type Attachment,
  type BalanceSheet,
  type Currency
} from 'core/remodel/types/common'
import type { Asset } from 'core/remodel/types/common/asset'
import { AssetType, CustomizedType } from 'core/remodel/types/enums'
import type { GlobalDashboard } from 'core/remodel/types/globalDashboard'
import { type Property } from 'core/remodel/types/properties'
import { type LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { groupQuery } from '@/api/GroupService'
import { isMatchedEnv } from '@/constants/site'
import type { AssetItem, DocumentFile, Folder, GroupableAsset } from '@/types/common'
import { delay } from '@/utils/delay'
import i18n from '@/utils/i18n'
import { ImageSizes } from '@/utils/imageTools'
import { isFulfilled, isOwnerDetail, isRentalDetail } from '@/utils/predicate'
import { BeneficiaryValues, InsuranceAssetValues } from '@/components/form'
import { SearchItemProps } from '@/components/GlobalSearch'

export const commonQuery = {
  attachmentUrl: 'attachment-url',
  thumbnailUrl: 'thumbnail-url',
  attachmentIV: 'attachment-iv',
  associatedFiles: 'associated-files',
  locationInfo: 'location-info',
  assetList: 'asset-list',
  liabilityAssetList: 'liability-asset-list',
  nameAndMainImage: 'name-and-main-image',
  global: 'global-summary',
  oneSchemaToken: 'one-schema-token',
  getDocumentByPath: 'get-document-by-path',
  assetNames: 'assetNames',
  exchangeRates: 'exchange-rates',
  targetExchangeRates: 'target-exchange-rates',
  globalSearch: 'global-search',
  mainImageUrlIv: 'main-image-url-iv',
  storageLimit: 'storage-limit',
  metadata: 'attachment-metadata',
  soldInfo: 'sold-info',
  beneficiary: 'beneficiary',
  groupBySubType: 'group-by-sub-type'
} as const

export function fetchGlobalSearchResult(database: Database) {
  return async ([_key, keyword]: [typeof commonQuery.globalSearch, string]) => {
    const resp = await database.getAssetsByGlobalSearch(keyword)
    // temporarily filter out WineAndSpirits from global search Todo: remove this after WineAndSpirits is finished
    const filteredResp = isMatchedEnv(['prod'])
      ? resp.filter((item) => !item.category.includes('WineAndSpirits'))
      : resp
    return filteredResp.map(
      (item): SearchItemProps => ({
        id: item.fireId,
        assetType: item.category as AssetType,
        subType: item.subType,
        name: item.name ?? ''
      })
    )
  }
}

export function fetchExchangeRates(database: Database) {
  return async ([_key, from, to]: [typeof commonQuery.exchangeRates, Currency, Currency]) => {
    return await database.ExRate.getExchangeRate(from, to)
  }
}

export function fetchTargetExchangeRates(database: Database) {
  return async ([_key, currency]: [typeof commonQuery.targetExchangeRates, Currency]) => {
    return await database.ExRate.getToTargetExchangeRates(currency)
  }
}

export function fetchOneSchemaToken(database: Database) {
  return async ([_key]: [typeof commonQuery.oneSchemaToken]) => {
    return await database.getOneSchemaToken()
  }
}

export function fetchAttachmentUrl(database: Database) {
  return async ([_key, ...params]: [typeof commonQuery.attachmentUrl, string, string]) => {
    return await database.Attachments.getUrl(...params)
  }
}

export function fetchThumbnailUrl(database: Database) {
  return async ([_key, assetId, attachmentKey, imageSize]: [
    typeof commonQuery.thumbnailUrl,
    string,
    string,
    ImageSizes
  ]) => {
    try {
      const key = `${attachmentKey}_${imageSize}`
      const url = await database.Attachments.getUrl(assetId, key)
      return url
    } catch (e) {
      console.log('Failed to get thumbnail', e)
      return await database.Attachments.getUrl(assetId, attachmentKey)
    }
  }
}

export function fetchAttachmentIv(database: Database) {
  return async ([_key, assetType, assetId, attachmentKey]: [
    typeof commonQuery.attachmentIV,
    AssetType,
    string,
    string
  ]) => {
    const asset = (await database.getRawAssetById(assetType, assetId)) as AssetV2
    return asset.attachments?.find((attachment) => attachment.key === attachmentKey)?.[IVAttachmentFileSaltFieldKey]
  }
}

export function fetchMainImageUrlAndIv(database: Database) {
  return async ([_key, assetType, assetId]: [typeof commonQuery.mainImageUrlIv, AssetType, string]) => {
    let url
    const { attachments, mainImage } = (await database.getRawAssetById(assetType, assetId)) as AssetV2
    if (mainImage) {
      try {
        url = await database.Attachments.getUrl(assetId, `${mainImage}_${ImageSizes.Small}`)
      } catch (e) {
        url = await database.Attachments.getUrl(assetId, mainImage)
      }
    }
    const iv = attachments?.find((attachment) => attachment.key === mainImage)?.[IVAttachmentFileSaltFieldKey]
    return { url, iv }
  }
}

export interface AssociatedFile extends Attachment {
  type: AttachmentKind
  url: string
  thumbnails?: { small: string; large: string }
}

export function fetchAssociatedFiles(database: Database) {
  return async ([_key, id, attachments]: [typeof commonQuery.associatedFiles, string, Attachment[]]) => {
    const files = await Promise.all(
      attachments.map(async (attach): Promise<AssociatedFile> => {
        const url = await database.Attachments.getUrl(id, attach.key)
        let thumbnails = { small: url, large: url }
        if (attach.type === AttachmentKind.AssetImage) {
          try {
            thumbnails.small = await database.Attachments.getUrl(id, `${attach.key}_${ImageSizes.Small}`)
            thumbnails.large = await database.Attachments.getUrl(id, `${attach.key}_${ImageSizes.Large}`)
          } catch (e) {
            console.log('Failed to get image thumbnail', e)
          }
        }

        return { ...attach, url, thumbnails }
      })
    )
    const assetImages = files.filter((file) => file.type === AttachmentKind.AssetImage)
    const primaryDetails = files.filter((file) => file.type === AttachmentKind.PrimaryDetails)
    const acquisition = files.filter((file) => file.type === AttachmentKind.Acquisition)
    const attributes = files.filter((file) => file.type === AttachmentKind.Attributes)
    const location = files.filter((file) => file.type === AttachmentKind.Location)
    const ownership = files.filter((file) => file.type === AttachmentKind.Ownership)
    const beneficiary = files.filter((file) => file.type === AttachmentKind.Beneficiaries)
    return { assetImages, primaryDetails, acquisition, attributes, location, ownership, beneficiary }
  }
}

export const typeQuery = {
  artistName: 'art-artist-name',
  artMedium: 'art-medium',
  belongingType: 'belonging-type',
  belongingBrand: 'belonging-brand',
  institution: 'bank-or-institution',
  insuranceCompany: 'insurance-company',
  insurancePolicyName: 'insurance-policy-name',
  otherType: 'other-collectables-type',
  otherBrand: 'other-collectables-brand',
  otherModel: 'other-collectables-model',
  wineProducer: 'wine-and-spirits-producer',
  contactType: 'contact-type'
} as const

/* Custom Type */
export function fetchCustomTypes(database: Database) {
  return async ([_key, typeName]: [(typeof typeQuery)[keyof typeof typeQuery], CustomizedType]) => {
    return await database.listCustomizedType(typeName)
  }
}

export async function addCustomType(database: Database, type: CustomizedType, name: string) {
  const key = {
    [CustomizedType.ArtArtistName]: typeQuery.artistName,
    [CustomizedType.ArtMedium]: typeQuery.artMedium,
    [CustomizedType.BelongingType]: typeQuery.belongingType,
    [CustomizedType.BelongingBrand]: typeQuery.belongingBrand,
    [CustomizedType.BankOrInstitution]: typeQuery.institution,
    [CustomizedType.InsuranceCompany]: typeQuery.insuranceCompany,
    [CustomizedType.InsurancePolicyName]: typeQuery.insurancePolicyName,
    [CustomizedType.OtherCollectablesType]: typeQuery.otherType,
    [CustomizedType.OtherCollectablesBrand]: typeQuery.otherBrand,
    [CustomizedType.OtherCollectablesModel]: typeQuery.otherModel,
    [CustomizedType.WineAndSpiritsProducer]: typeQuery.wineProducer,
    [CustomizedType.ContactType]: typeQuery.contactType
  }[type]
  await database.addCustomizedType(type, name)
  await delay()
  mutate([key, type])
  return name
}

/* Location */
export function fetchLocation(database: Database) {
  return async ([_key, type, id]: [typeof commonQuery.locationInfo, LocationType, string]) => {
    switch (type) {
      case LocationType.MyProperty:
        return await database.property.getById(id)
      case LocationType.Address:
        return await database.Account.getContact(id)
    }
  }
}

export function fetchLocationName(database: Database) {
  return async ([_key, type, ids]: [typeof commonQuery.locationInfo, LocationType, string[]]) => {
    switch (type) {
      case LocationType.MyProperty: {
        const properties = await Promise.all(ids.map(async (id) => await database.property.getById(id)))
        return properties.reduce(
          (acc, property) => ({
            ...acc,
            [property.id]: [property.name]
          }),
          {} as Record<string, string[]>
        )
      }
      case LocationType.Address: {
        const contacts = await Promise.all(ids.map(async (id) => await database.Account.getContact(id)))
        return contacts.reduce(
          (acc, contact) => ({
            ...acc,
            [contact.id]: [`${contact.firstName} ${contact.lastName}`]
          }),
          {} as Record<string, string[]>
        )
      }
    }
  }
}

/* Asset */
export async function relocateDraft(
  database: Database,
  groupId: string,
  selected: GroupableAsset[],
  newLocation: LocationInfo,
  callback?: (percentage: number) => void
) {
  const assetType = selected[0].assetType
  const ids = selected.map(({ assetId }) => assetId)
  const NUM_PER_LOOP = 1
  let progress: Record<'current' | 'total', number> = { current: 0, total: selected.length }

  while (progress.current < progress.total) {
    switch (assetType) {
      case AssetType.Art: {
        await database.art.addFromDrafts(ids.slice(progress.current, progress.current + NUM_PER_LOOP), newLocation)
        break
      }
      case AssetType.OtherCollectables: {
        await database.otherCollectable.addFromDrafts(
          ids.slice(progress.current, progress.current + NUM_PER_LOOP),
          newLocation
        )
        break
      }
      case AssetType.Belonging: {
        await database.belonging.addFromDrafts(
          ids.slice(progress.current, progress.current + NUM_PER_LOOP),
          newLocation
        )
        break
      }
    }
    progress = {
      current: progress.current + NUM_PER_LOOP > progress.total ? progress.total : progress.current + NUM_PER_LOOP,
      total: progress.total
    }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutate([groupQuery.info, groupId])
}

export async function deleteDraft(
  database: Database,
  groupId: string,
  selected: GroupableAsset[],
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: selected.length }
  const assetType = selected[0].assetType

  switch (assetType) {
    case AssetType.Art: {
      for (const { assetId } of selected) {
        await database.art.deleteDraft(assetId)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
      }
      break
    }
    case AssetType.OtherCollectables: {
      for (const { assetId } of selected) {
        await database.otherCollectable.deleteDraft(assetId)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
      }
      break
    }
    case AssetType.Belonging: {
      for (const { assetId } of selected) {
        await database.belonging.deleteDraft(assetId)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
      }
      break
    }
  }

  await delay()
  mutate([groupQuery.info, groupId])
}

export function fetchSupportLiabilityAssets(database: Database) {
  return async ([_key]: [typeof commonQuery.liabilityAssetList]) => {
    const isLoanableProperty = ({ archived, closedWith }: Property) => !archived && !closedWith
    const isLoanableArt = ({ closedWith }: Art) => !closedWith

    const [wine, otherCollectable, belonging, properties, arts, otherInvestment] = await Promise.all([
      database.wine.getAllWines(),
      database.otherCollectable.getAll(),
      database.belonging.getAll(),
      database.property.getAll(),
      database.art.getAll(),
      database.otherInvestment.getAll()
    ])

    return {
      loanableAssets: [
        ...properties.filter(isLoanableProperty),
        ...arts.filter(isLoanableArt),
        ...wine,
        ...otherCollectable,
        ...belonging,
        ...otherInvestment
      ],
      nonLoanableAssets: [
        ...properties.filter((property) => !isLoanableProperty(property)),
        ...arts.filter((art) => !isLoanableArt(art))
      ]
    }
  }
}

export function fetchAllAssetsOptions(database: Database) {
  return async ([_key]: [typeof commonQuery.assetList]) => {
    const allAssetsMap = {
      [AssetType.Art]: database.art.getAll(),
      [AssetType.WineAndSpirits]: database.wine.getAllWines(),
      [AssetType.OtherCollectables]: database.otherCollectable.getAll(),
      [AssetType.Belonging]: database.belonging.getAll(),
      [AssetType.Property]: database.property.getAll(),
      [AssetType.Insurance]: database.insurance.getAll(),
      [AssetType.Cryptocurrency]: database.cryptocurrency.getAll(),
      [AssetType.TraditionalInvestments]: database.traditionalInvestment.getAllPortfolio(),
      [AssetType.OtherInvestment]: database.otherInvestment.getAll(),
      [AssetType.CashAndBanking]: database.cashAndBanking.getAllInstitution()
    }

    const assets: { label: string; value: { assetType: AssetType; assetId: string } }[][] = await Promise.all(
      Object.keys(allAssetsMap).map(async (assetType): Promise<any> => {
        switch (assetType) {
          case AssetType.Art:
          case AssetType.WineAndSpirits:
          case AssetType.OtherCollectables:
          case AssetType.Belonging:
          case AssetType.Property:
          case AssetType.Insurance:
          case AssetType.Cryptocurrency:
          case AssetType.TraditionalInvestments:
          case AssetType.OtherInvestment: {
            const items = await allAssetsMap[assetType]
            return items
              .map((asset) => ({ label: asset.name, value: { assetType: assetType, assetId: asset.id } }))
              .flat()
          }
          case AssetType.CashAndBanking: {
            // HACK: [todo] return institution_account instead of institution
            const items = await allAssetsMap[assetType]
            return items
              .map((asset) => ({ label: asset.name, value: { assetType: assetType, assetId: asset.id } }))
              .flat()
          }
        }
      })
    )
    return assets.flat()
  }
}

export function fetchContactAsset(database: Database) {
  return async ([_key, contactId, AssetId, assetType, subType]: [
    typeof commonQuery.nameAndMainImage,
    string,
    string,
    AssetType,
    Account.Type | undefined
  ]) => {
    const contactTag: string[] = []
    switch (assetType) {
      case AssetType.CashAndBanking: {
        if (subType === undefined) return undefined
        const { name, mainImage } = await database.cashAndBanking.getAccountById(AssetId, subType)
        return { name, mainImage, contactTag }
      }
      case AssetType.TraditionalInvestments: {
        const { name, mainImage, ownership, beneficiary } =
          await database.traditionalInvestment.getPortfolioById(AssetId)
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.OtherInvestment: {
        const { name, mainImage, contactIds, ownership, beneficiary } = await database.otherInvestment.getById(AssetId)
        contactIds?.find((item) => item === contactId) && contactTag.push('Contact')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.Cryptocurrency: {
        const { name, mainImage, ownership, beneficiary } = await database.cryptocurrency.getById(AssetId)
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.Insurance: {
        const { name, mainImage, beneficiary, insured, brokerId, specialistId } =
          await database.insurance.getById(AssetId)
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        insured?.find((item) => item.targetId === contactId) && contactTag.push('Insured')
        brokerId === contactId && contactTag.push('Broker')
        specialistId === contactId && contactTag.push('Specialist')
        return { name, mainImage, contactTag }
      }
      case AssetType.Property: {
        const { name, mainImage, detail } = await database.property.getById(AssetId)
        if (isOwnerDetail(detail)) {
          detail.acquisition?.sellerId === contactId && contactTag.push('Seller')
          detail.ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
          detail.beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        }
        if (isRentalDetail(detail)) {
          detail.landlordId === contactId && contactTag.push('Landlord')
        }
        return { name, mainImage, contactTag }
      }
      case AssetType.Art: {
        const { name, mainImage, location, acquisition, ownership, beneficiary } = await database.art.getById(AssetId)
        location.locationId === contactId && contactTag.push('Location')
        acquisition?.sellerId === contactId && contactTag.push('Seller')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.OtherCollectables: {
        const { name, mainImage, location, acquisition, ownership, beneficiary } =
          await database.otherCollectable.getById(AssetId)
        location.locationId === contactId && contactTag.push('Location')
        acquisition?.sellerId === contactId && contactTag.push('Seller')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.Belonging: {
        const { name, mainImage, location, acquisition, ownership, beneficiary } =
          await database.belonging.getById(AssetId)
        location.locationId === contactId && contactTag.push('Location')
        acquisition?.sellerId === contactId && contactTag.push('Seller')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.WineAndSpirits: {
        const { vintage, name, mainImage } = await database.wine.getWineById(AssetId)
        //TODO wine unit is type or purchase for display contact id is seller ..etc
        return { name: `${vintage} ${name}`, mainImage, contactTag }
      }
      case AssetType.WinePurchases:
      case AssetType.BankOrInstitution:
        return undefined
    }
  }
}

export function fetchBeneficiaryValue(database: Database) {
  return async ([_key, contactId, assetId, assetType]: [typeof commonQuery.beneficiary, string, string, AssetType]) => {
    switch (assetType) {
      case AssetType.TraditionalInvestments: {
        const { beneficiary } = await database.traditionalInvestment.getPortfolioById(assetId)
        const beneficiaryValue = beneficiary?.find((item) => item.contactId === contactId)?.percent
        return { beneficiaryValue }
      }
      case AssetType.OtherInvestment: {
        const { beneficiary } = await database.otherInvestment.getById(assetId)
        const beneficiaryValue = beneficiary?.find((item) => item.contactId === contactId)?.percent
        return { beneficiaryValue }
      }
      case AssetType.Cryptocurrency: {
        const { beneficiary } = await database.cryptocurrency.getById(assetId)
        const beneficiaryValue = beneficiary?.find((item) => item.contactId === contactId)?.percent
        return { beneficiaryValue }
      }
      case AssetType.Insurance: {
        const { beneficiary } = await database.insurance.getById(assetId)
        const beneficiaryValue = beneficiary?.find((item) => item.contactId === contactId)?.percent
        return { beneficiaryValue }
      }
      case AssetType.Property: {
        const { detail } = await database.property.getById(assetId)
        if (isOwnerDetail(detail)) {
          const beneficiaryValue = detail.beneficiary?.find((item) => item.contactId === contactId)?.percent
          return { beneficiaryValue }
        }
        if (isRentalDetail(detail)) {
          return undefined
        }
      }
      case AssetType.Art: {
        const { beneficiary } = await database.art.getById(assetId)
        const beneficiaryValue = beneficiary?.find((item) => item.contactId === contactId)?.percent
        return { beneficiaryValue }
      }
      case AssetType.OtherCollectables: {
        const { beneficiary } = await database.otherCollectable.getById(assetId)
        const beneficiaryValue = beneficiary?.find((item) => item.contactId === contactId)?.percent
        return { beneficiaryValue }
      }
      case AssetType.Belonging: {
        const { beneficiary } = await database.belonging.getById(assetId)
        const beneficiaryValue = beneficiary?.find((item) => item.contactId === contactId)?.percent
        return { beneficiaryValue }
      }
      case AssetType.CashAndBanking:
      case AssetType.WineAndSpirits:
      case AssetType.WinePurchases:
      case AssetType.BankOrInstitution:
        return undefined
    }
  }
}

export function fetchNames(database: Database) {
  return async ([_key, items]: [typeof commonQuery.assetNames, AssetItem[]]) => {
    const itemsName = Promise.all(
      items.map(async (item) => {
        switch (item.assetType) {
          case AssetType.CashAndBanking: {
            if (item.subType === undefined) return { name: 'cash & banking', link: `/finances/accounts` } //TODO, no subType
            const { name } = await database.cashAndBanking.getAccountById(item.assetId, item.subType)
            return { name: name, link: `/finances/accounts/info/?id=${item.assetId}&type=${item.subType}` }
          }
          case AssetType.TraditionalInvestments: {
            const { name } = await database.traditionalInvestment.getPortfolioById(item.assetId)
            return { name: name, link: `/finances/tradit-invest/info/?id=${item.assetId}` }
          }
          case AssetType.OtherInvestment: {
            const { name } = await database.otherInvestment.getById(item.assetId)
            return { name: name, link: `/finances/other-invest/info/?id=${item.assetId}` }
          }
          case AssetType.Cryptocurrency: {
            const { name } = await database.cryptocurrency.getById(item.assetId)
            return { name: name, link: `/finances/crypto/` }
          }
          case AssetType.Insurance: {
            const { name } = await database.insurance.getById(item.assetId)
            return { name: name, link: `/finances/insurance/info/?id=${item.assetId}` }
          }
          case AssetType.Property: {
            const { name } = await database.property.getById(item.assetId)
            return { name: name, link: `/properties/summary/info/?id=${item.assetId}` }
          }
          case AssetType.Art: {
            const { name } = await database.art.getById(item.assetId)
            return { name: name, link: `/collectables/art/info/?id=${item.assetId}` }
          }
          case AssetType.OtherCollectables: {
            const { name } = await database.otherCollectable.getById(item.assetId)
            return { name: name, link: `/collectables/other/info/?id=${item.assetId}` }
          }
          case AssetType.Belonging: {
            const { name } = await database.belonging.getById(item.assetId)
            return { name: name, link: `/belongings/summary/info/?id=${item.assetId}` }
          }
          case AssetType.WineAndSpirits: {
            const { vintage, name } = await database.wine.getWineById(item.assetId)
            return { name: `${vintage} ${name}`, link: `/collectables/wine/info/?id=${item.assetId}` }
          }
          case AssetType.WinePurchases:
            return { name: 'Wine Purchase', link: '/' } //TODO, no Purchases
          case AssetType.BankOrInstitution:
            return { name: 'Bank Or Institution', link: '/' } //TODO, no BankOrInstitution
        }
      })
    )
    return itemsName
  }
}

/* Global Dashboard */
export function fetchGlobalDashboard(database: Database) {
  return async ([_key, currency = database.ExRate.BaseCurrency]: [typeof commonQuery.global, Currency?]) => {
    if (!currency) throw Error('No base currency')

    const isProd = isMatchedEnv(['prod'])

    const calculateNetValue = <T extends BalanceSheet>(sheet: T) => {
      const { assets, liabilities } = sheet
      return { ...sheet, netValue: { currency: assets.currency, value: assets.value - liabilities.value } }
    }

    const global = await database.getGlobalDashboard(currency)
    const subtypeItems = await Promise.all(
      global.myCollectables.subtypeItems.map(async (item) => {
        const query = { limit: 1, offset: 0 } as any
        const list: Asset[] =
          item.assetType === AssetType.OtherCollectables
            ? (
                await database.getOtherCollectablesByLabels<OtherCollectableType[], Asset[]>(
                  [item.label as OtherCollectableType],
                  query
                )
              ).list
            : (await database.getAssetsByTypes<AssetType[], Asset[]>([item.assetType], query)).list
        const [{ id: assetId, mainImage: imageId }] = list
        return { ...item, mainImage: { assetId, imageId } }
      })
    )

    // finances
    const { assets: financesAssets = [], liabilities: financesLiabilities = [] } = global.myFinances.distribution
    const excludeCashAndBanking = financesAssets.filter((item) => item.label !== 'CashAndBanking')
    const excludeCashAndBankingAssets = excludeCashAndBanking.reduce((sum, item) => sum + item.value.value, 0)
    const myFinances: GlobalDashboard['myFinances'] = {
      ...global.myFinances,
      assets: { ...global.myFinances.assets, value: global.myFinances.assets.value - excludeCashAndBankingAssets },
      netValue: {
        ...global.myFinances.netValue,
        value: global.myFinances.assets.value - excludeCashAndBankingAssets - global.myFinances.liabilities.value
      },
      distribution: {
        assets: financesAssets.filter((item) => item.label === 'CashAndBanking'),
        liabilities: financesLiabilities.filter((item) => item.label === 'CashAndBanking')
      }
    }

    // collectables
    const wineAndSpiritsAssets = subtypeItems.find((item) => item.label === 'WineAndSpirits')?.value.value ?? 0
    const myCollectables: GlobalDashboard['myCollectables'] = {
      ...global.myCollectables,
      subtypeItems,
      assets: {
        currency,
        value: global.myCollectables.assets.value - wineAndSpiritsAssets
      },
      netValue: {
        currency,
        value: global.myCollectables.assets.value - wineAndSpiritsAssets - global.myCollectables.liabilities.value
      }
    }
    const globalMyCollectables = { ...global.myCollectables, subtypeItems }

    // valueDistribution for prod
    const distributionAssets = global.valueDistribution.assets
    const valueDistribution: GlobalDashboard['valueDistribution'] = {
      ...global.valueDistribution,
      assets: distributionAssets.map((item) => {
        switch (item.label) {
          case 'MyFinances':
            return { ...item, value: { currency, value: item.value.value - excludeCashAndBankingAssets } }
          case 'MyCollectables':
            return { ...item, value: { currency, value: item.value.value - wineAndSpiritsAssets } }
          default:
            return item
        }
      })
    }

    // assets, value for prod
    const assets: Amount = { currency, value: global.assets.value - excludeCashAndBankingAssets - wineAndSpiritsAssets }
    const netValue: Amount = { currency, value: assets.value - global.liabilities.value }

    return {
      myFinances: calculateNetValue(isProd ? myFinances : global.myFinances),
      myProperties: calculateNetValue(global.myProperties),
      myCollectables: calculateNetValue(isProd ? myCollectables : globalMyCollectables),
      myBelongings: calculateNetValue(global.myBelongings),
      liabilities: global.liabilities,
      assets: isProd ? assets : global.assets,
      netValue: isProd ? netValue : { currency, value: global.assets.value - global.liabilities.value },
      valueDistribution: isProd ? valueDistribution : global.valueDistribution
    } satisfies GlobalDashboard
  }
}

/* Attachment */
export function fetchStorageLimit(database: Database) {
  return async ([_key]: [typeof commonQuery.storageLimit]) => {
    const resp = await database.Attachments.getStorageLimit()
    return resp
  }
}

export function getMetadata(database: Database) {
  return async ([_key, assetId, attachmentKey]: [typeof commonQuery.metadata, string, string]) => {
    const promises = await Promise.allSettled([
      database.Attachments.getMeta(assetId, attachmentKey),
      ...Object.values(ImageSizes).map((size) => database.Attachments.getMeta(assetId, `${attachmentKey}_${size}`))
    ])
    const [metadata, ...thumbnails] = promises.filter(isFulfilled).map(({ value }) => value)
    const size = [metadata, ...thumbnails].reduce((sum, value) => sum + value.size, 0)
    return { ...metadata, size }
  }
}

export async function uploadFile(
  database: Database,
  assetId: string,
  attachmentKey: string,
  data: File,
  iv: Uint8Array
) {
  const result = await database.Attachments.upload(assetId, attachmentKey, data, data.type, iv)
  await delay()
  mutate([commonQuery.storageLimit])
  return result
}

export async function deleteFile(
  database: Database,
  assetId: string,
  assetType: AssetType | undefined,
  attachmentKey: string
) {
  // delete file
  await database.Attachments.delete(assetId, attachmentKey)
  // try remove thumbnails
  await Promise.allSettled(
    Object.values(ImageSizes).map((size) => database.Attachments.delete(assetId, `${attachmentKey}_${size}`))
  )

  switch (assetType) {
    // finance
    case AssetType.CashAndBanking: {
      const [asset] = await database.cashAndBanking.getAccountsByIds([assetId])
      await database.cashAndBanking.updateAccount({
        ...asset,
        attachments: asset.attachments?.filter((attach) => attach.key !== attachmentKey)
      })
      break
    }
    case AssetType.TraditionalInvestments: {
      const asset = await database.traditionalInvestment.getPortfolioById(assetId)
      await database.traditionalInvestment.update({
        ...asset,
        attachments: asset.attachments?.filter((attach) => attach.key !== attachmentKey)
      })
      break
    }
    case AssetType.OtherInvestment: {
      const asset = await database.otherInvestment.getById(assetId)
      await database.otherInvestment.update({
        ...asset,
        attachments: asset.attachments?.filter((attach) => attach.key !== attachmentKey)
      })
      break
    }
    case AssetType.Cryptocurrency: {
      const asset = await database.cryptocurrency.getById(assetId)
      await database.cryptocurrency.update({
        ...asset,
        attachments: asset.attachments?.filter((attach) => attach.key !== attachmentKey)
      })
      break
    }
    case AssetType.Insurance: {
      const asset = await database.insurance.getById(assetId)
      await database.insurance.update({
        ...asset,
        attachments: asset.attachments?.filter((attach) => attach.key !== attachmentKey)
      })
      break
    }
    // properties
    case AssetType.Property: {
      const asset = await database.property.getById(assetId)
      const attachments = asset.attachments?.filter((attach) => attach.key !== attachmentKey) ?? []
      const assetImages = attachments.filter(({ type }) => type === AttachmentKind.AssetImage)
      const mainImage = asset.mainImage !== attachmentKey ? asset.mainImage : assetImages[0]?.key
      await database.property.update({ ...asset, attachments, mainImage })
      break
    }
    // collectables
    case AssetType.Art: {
      const asset = await database.art.getById(assetId)
      const attachments = asset.attachments?.filter((attach) => attach.key !== attachmentKey) ?? []
      const assetImages = attachments.filter(({ type }) => type === AttachmentKind.AssetImage)
      const mainImage = asset.mainImage !== attachmentKey ? asset.mainImage : assetImages[0]?.key
      await database.art.update({ ...asset, attachments, mainImage })
      break
    }
    case AssetType.WineAndSpirits: {
      const asset = await database.wine.getWineById(assetId)
      const firstPurchase = asset.purchases[0]
      const attachments = asset.attachments?.filter((attach) => attach.key !== attachmentKey) ?? []
      const assetImages = attachments.filter(({ type }) => type === AttachmentKind.AssetImage)
      const mainImage = asset.mainImage !== attachmentKey ? asset.mainImage : assetImages[0]?.key
      await database.wine.update(asset.id, firstPurchase.id, firstPurchase, asset.personalRefNo, attachments, mainImage)
      break
    }
    case AssetType.OtherCollectables: {
      const asset = await database.otherCollectable.getById(assetId)
      const attachments = asset.attachments?.filter((attach) => attach.key !== attachmentKey) ?? []
      const assetImages = attachments.filter(({ type }) => type === AttachmentKind.AssetImage)
      const mainImage = asset.mainImage !== attachmentKey ? asset.mainImage : assetImages[0]?.key
      await database.otherCollectable.update({ ...asset, attachments, mainImage })
      break
    }
    // belongings
    case AssetType.Belonging: {
      const asset = await database.belonging.getById(assetId)
      const attachments = asset.attachments?.filter((attach) => attach.key !== attachmentKey) ?? []
      const assetImages = attachments.filter(({ type }) => type === AttachmentKind.AssetImage)
      const mainImage = asset.mainImage !== attachmentKey ? asset.mainImage : assetImages[0]?.key
      await database.belonging.update({ ...asset, attachments, mainImage })
      break
    }
    // uncategorized
    case undefined: {
      await database.Attachments.removeFromCategory(AttachmentCategory.Uncategorized, attachmentKey)
      break
    }
  }

  await delay()
  mutate((key) => Array.isArray(key) && key[0] === commonQuery.getDocumentByPath)
}

export async function updateBeneficiary(
  database: Database,
  assetIds: string[],
  data: BeneficiaryValues,
  assetType: AssetType
) {
  let successCount = 0
  switch (assetType) {
    case AssetType.Property: {
      for (const id of assetIds) {
        const asset = await database.property.getById(id)
        await database.property.update({ ...asset, detail: { ...asset.detail, beneficiary: data.beneficiary } })
        successCount++
      }
      return successCount
    }
    case AssetType.Art: {
      for (const id of assetIds) {
        const asset = await database.art.getById(id)
        await database.art.update({ ...asset, beneficiary: data.beneficiary })
        successCount++
      }
      return successCount
    }
    case AssetType.Belonging: {
      for (const id of assetIds) {
        const asset = await database.belonging.getById(id)
        await database.belonging.update({ ...asset, beneficiary: data.beneficiary })
        successCount++
      }
      return successCount
    }
    case AssetType.OtherCollectables: {
      for (const id of assetIds) {
        const asset = await database.otherCollectable.getById(id)
        await database.otherCollectable.update({ ...asset, beneficiary: data.beneficiary })
        successCount++
      }
      return successCount
    }
  }
}
export async function updateAssetInsurance(
  database: Database,
  assetIds: string[],
  data: InsuranceAssetValues,
  assetType: AssetType
) {
  let successCount = 0
  switch (assetType) {
    case AssetType.Property: {
      for (const id of assetIds) {
        const asset = await database.property.getById(id)
        if (!isOwnerDetail(asset.detail)) continue
        await database.property.update({
          ...asset,
          detail: {
            ...asset.detail,
            acquisition: {
              ...asset.detail.acquisition,
              insuranceIds: data.insuranceIds
            }
          }
        })
        successCount++
      }
      return successCount
    }
    case AssetType.Art: {
      for (const id of assetIds) {
        const asset = await database.art.getById(id)
        await database.art.update({
          ...asset,
          acquisition: asset.acquisition && {
            ...asset.acquisition,
            insuranceIds: data.insuranceIds
          }
        })
        successCount++
      }
      return successCount
    }
    case AssetType.OtherCollectables: {
      for (const id of assetIds) {
        const asset = await database.otherCollectable.getById(id)
        await database.otherCollectable.update({
          ...asset,
          acquisition: asset.acquisition && { ...asset.acquisition, insuranceIds: data.insuranceIds }
        })
        successCount++
      }
      return successCount
    }
    case AssetType.Belonging: {
      for (const id of assetIds) {
        const asset = await database.belonging.getById(id)
        await database.belonging.update({
          ...asset,
          acquisition: asset.acquisition && { ...asset.acquisition, insuranceIds: data.insuranceIds }
        })
        successCount++
      }
      return successCount
    }
  }
}

export async function renameFile(
  database: Database,
  assetId: string,
  assetType: AssetType | undefined,
  attachmentKey: string,
  newName: string
) {
  switch (assetType) {
    // finance
    case AssetType.CashAndBanking: {
      const [asset] = await database.cashAndBanking.getAccountsByIds([assetId])
      await database.cashAndBanking.updateAccount({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    case AssetType.TraditionalInvestments: {
      const asset = await database.traditionalInvestment.getPortfolioById(assetId)
      await database.traditionalInvestment.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    case AssetType.OtherInvestment: {
      const asset = await database.otherInvestment.getById(assetId)
      await database.otherInvestment.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    case AssetType.Cryptocurrency: {
      const asset = await database.cryptocurrency.getById(assetId)
      await database.cryptocurrency.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    case AssetType.Insurance: {
      const asset = await database.insurance.getById(assetId)
      await database.insurance.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    // properties
    case AssetType.Property: {
      const asset = await database.property.getById(assetId)
      await database.property.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    // collectables
    case AssetType.Art: {
      const asset = await database.art.getById(assetId)
      await database.art.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    case AssetType.WineAndSpirits: {
      const asset = await database.wine.getWineById(assetId)
      const firstPurchase = asset.purchases[0]
      const attachments =
        asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        })) ?? []
      await database.wine.update(
        asset.id,
        firstPurchase.id,
        firstPurchase,
        asset.personalRefNo,
        attachments,
        asset.mainImage
      )
      break
    }
    case AssetType.OtherCollectables: {
      const asset = await database.otherCollectable.getById(assetId)
      await database.otherCollectable.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    // belongings
    case AssetType.Belonging: {
      const asset = await database.belonging.getById(assetId)
      await database.belonging.update({
        ...asset,
        attachments: asset.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    }
    // uncategorized
    case undefined: {
      const attachments = await database.Attachments.getCategoryFiles(AttachmentCategory.Uncategorized)
      const attachment = attachments.find((attach) => attach.key === attachmentKey)!
      const newValue = { ...attachment, name: newName }
      await database.Attachments.updateCategoryFileInfo(AttachmentCategory.Uncategorized, newValue)
      break
    }
  }

  await delay()
  mutate((key) => Array.isArray(key) && key[0] === commonQuery.getDocumentByPath)
}

export async function uploadFromDocument(
  database: Database,
  assetId: string,
  assetType: AssetType | undefined,
  mainImage: string | undefined,
  attachments: Attachment[]
) {
  switch (assetType) {
    // finance
    case AssetType.CashAndBanking: {
      const [asset] = await database.cashAndBanking.getAccountsByIds([assetId])
      await database.cashAndBanking.updateAccount({ ...asset, attachments })
      break
    }
    case AssetType.TraditionalInvestments: {
      const asset = await database.traditionalInvestment.getPortfolioById(assetId)
      await database.traditionalInvestment.update({ ...asset, attachments })
      break
    }
    case AssetType.OtherInvestment: {
      const asset = await database.otherInvestment.getById(assetId)
      await database.otherInvestment.update({ ...asset, attachments })
      break
    }
    case AssetType.Cryptocurrency: {
      const asset = await database.cryptocurrency.getById(assetId)
      await database.cryptocurrency.update({ ...asset, attachments })
      break
    }
    case AssetType.Insurance: {
      const asset = await database.insurance.getById(assetId)
      await database.insurance.update({ ...asset, attachments })
      break
    }
    // properties
    case AssetType.Property: {
      const asset = await database.property.getById(assetId)
      await database.property.update({ ...asset, attachments, mainImage })
      break
    }
    // collectables
    case AssetType.Art: {
      const asset = await database.art.getById(assetId)
      await database.art.update({ ...asset, attachments, mainImage })
      break
    }
    case AssetType.WineAndSpirits: {
      const asset = await database.wine.getWineById(assetId)
      const firstPurchase = asset.purchases[0]
      await database.wine.update(asset.id, firstPurchase.id, firstPurchase, asset.personalRefNo, attachments, mainImage)
      break
    }
    case AssetType.OtherCollectables: {
      const asset = await database.otherCollectable.getById(assetId)
      await database.otherCollectable.update({ ...asset, attachments, mainImage })
      break
    }
    // belongings
    case AssetType.Belonging: {
      const asset = await database.belonging.getById(assetId)
      await database.belonging.update({ ...asset, attachments, mainImage })
      break
    }
    // uncategorized
    case undefined: {
      await Promise.all(
        attachments.map((attachment) =>
          database!.Attachments.addToCategory(AttachmentCategory.Uncategorized, attachment)
        )
      )
      break
    }
  }
}

type DocumentsParams = {
  path: string[]
  limit: number
  page: number
}

type DocumentsResult = {
  totalCount: number
  list: Folder[] | DocumentFile[]
  depth: number
}

export function getDocumentByPath(database: Database) {
  return async ([_key, params]: [typeof commonQuery.getDocumentByPath, DocumentsParams]): Promise<DocumentsResult> => {
    const t = i18n?.t
    const { path, limit, page } = params

    const getMetadata = async (
      assetId: string | AttachmentCategory,
      attachment: Attachment,
      assetType?: AssetType,
      assetName?: string,
      subtype?: Account.Type
    ): Promise<DocumentFile> => {
      try {
        const meta = await database.Attachments.getMeta(assetId, attachment.key)
        return {
          key: attachment.key,
          label: attachment.name,
          mimeType: meta.contentType!,
          tabInfo: t(`AttachmentKind.${attachment.type}`),
          uploadAt: new Date(meta.updated),
          assetId,
          assetType,
          assetName,
          subtype,
          [IVAttachmentFileSaltFieldKey]: attachment._ivSaltAttachmentFile
        }
      } catch {
        return {
          key: attachment.key,
          label: attachment.name,
          mimeType: 'corrupted file',
          tabInfo: t(`AttachmentKind.${attachment.type}`),
          assetId,
          assetType,
          assetName,
          subtype,
          [IVAttachmentFileSaltFieldKey]: attachment._ivSaltAttachmentFile
        }
      }
    }

    switch (path[0]) {
      case 'myFinances': {
        switch (path[1]) {
          case 'cashAndBanking': {
            const assetId = path?.[2]
            if (assetId) {
              const [{ name, subtype, attachments = [] }] = await database.cashAndBanking.getAccountsByIds([assetId])
              const list = await Promise.all(
                attachments.map((attachment) =>
                  getMetadata(assetId, attachment, AssetType.CashAndBanking, name, subtype)
                )
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.CashAndBanking],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case 'traditionalInvestments': {
            const assetId = path?.[2]
            if (assetId) {
              const { name, attachments = [] } = await database.traditionalInvestment.getPortfolioById(assetId)
              const list = await Promise.all(
                attachments.map((attachment) =>
                  getMetadata(assetId, attachment, AssetType.TraditionalInvestments, name)
                )
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.TraditionalInvestments],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case 'otherInvestments': {
            const assetId = path?.[2]
            if (assetId) {
              const { name, attachments = [] } = await database.otherInvestment.getById(assetId)
              const list = await Promise.all(
                attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.OtherInvestment, name))
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.OtherInvestment],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case 'cryptocurrencies': {
            const assetId = path?.[2]
            if (assetId) {
              const { name, attachments = [] } = await database.cryptocurrency.getById(assetId)
              const list = await Promise.all(
                attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.Cryptocurrency, name))
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.Cryptocurrency],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case 'insurances': {
            const assetId = path?.[2]
            if (assetId) {
              const { name, attachments = [] } = await database.insurance.getById(assetId)
              const list = await Promise.all(
                attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.Insurance, name))
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.Insurance],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case undefined: {
            const [cashAndBanking, traditionalInvestments, otherInvestment, cryptocurrency, insurance] =
              await Promise.all([
                database.checkFilesExist({ category: [AssetType.CashAndBanking] }),
                database.checkFilesExist({ category: [AssetType.TraditionalInvestments] }),
                database.checkFilesExist({ category: [AssetType.OtherInvestment] }),
                database.checkFilesExist({ category: [AssetType.Cryptocurrency] }),
                database.checkFilesExist({ category: [AssetType.Insurance] })
              ])
            const list = [
              {
                key: 'cashAndBanking',
                label: t('AssetTypeOptions.CashAndBanking'),
                isEmpty: !cashAndBanking
              },
              {
                key: 'traditionalInvestments',
                label: t('AssetTypeOptions.TraditionalInvestments'),
                isEmpty: !traditionalInvestments
              },
              {
                key: 'otherInvestment',
                label: t('AssetTypeOptions.OtherInvestment'),
                isEmpty: !otherInvestment
              },
              {
                key: 'cryptoAccount',
                label: t('AssetTypeOptions.Cryptocurrency'),
                isEmpty: !cryptocurrency
              },
              {
                key: 'insurance',
                label: t('AssetTypeOptions.Insurance'),
                isEmpty: !insurance
              }
            ]
            return {
              list: list.slice((page - 1) * limit, page * limit),
              totalCount: list.length,
              depth: path.length
            }
          }
        }
        break
      }
      case 'myProperties': {
        const assetId = path?.[1]
        if (assetId) {
          const { name, attachments = [] } = await database.property.getById(assetId)
          const list = await Promise.all(
            attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.Property, name))
          )
          return {
            list: list.slice((page - 1) * limit, page * limit),
            totalCount: list.length,
            depth: path.length
          }
        } else {
          const { list, totalCount } = await database.getDocumentVaultFolders({
            category: [AssetType.Property],
            offset: (page - 1) * limit,
            limit
          })
          return {
            list: list.map(({ fireId, name, attachmentCount }) => ({
              key: fireId,
              label: name,
              isEmpty: attachmentCount === 0
            })),
            totalCount,
            depth: path.length
          }
        }
      }
      case 'myCollectables': {
        switch (path[1]) {
          case 'arts': {
            const assetId = path?.[2]
            if (assetId) {
              const { name, attachments = [] } = await database.art.getById(assetId)
              const list = await Promise.all(
                attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.Art, name))
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.Art],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case 'wineAndSpirits': {
            const assetId = path?.[2]
            if (assetId) {
              const { name, attachments = [] } = await database.wine.getWineById(assetId)
              const list = await Promise.all(
                attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.WineAndSpirits, name))
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.WineAndSpirits],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case 'otherCollectables': {
            const assetId = path?.[2]
            if (assetId) {
              const { name, attachments = [] } = await database.otherCollectable.getById(assetId)
              const list = await Promise.all(
                attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.OtherCollectables, name))
              )
              return {
                list: list.slice((page - 1) * limit, page * limit),
                totalCount: list.length,
                depth: path.length
              }
            } else {
              const { list, totalCount } = await database.getDocumentVaultFolders({
                category: [AssetType.OtherCollectables],
                offset: (page - 1) * limit,
                limit
              })
              return {
                list: list.map(({ fireId, name, attachmentCount }) => ({
                  key: fireId,
                  label: name,
                  isEmpty: attachmentCount === 0
                })),
                totalCount,
                depth: path.length
              }
            }
          }
          case undefined: {
            const [arts, wineAndSpirits, otherCollectables] = await Promise.all([
              database.checkFilesExist({ category: [AssetType.Art] }),
              database.checkFilesExist({ category: [AssetType.WineAndSpirits] }),
              database.checkFilesExist({ category: [AssetType.OtherCollectables] })
            ])
            const list = [
              { key: 'arts', label: t('AssetTypeOptions.Art'), isEmpty: !arts },
              { key: 'wineAndSpirits', label: t('AssetTypeOptions.WineAndSpirits'), isEmpty: !wineAndSpirits },
              { key: 'otherCollectables', label: t('AssetTypeOptions.OtherCollectables'), isEmpty: !otherCollectables }
            ]
            return {
              list: list.slice((page - 1) * limit, page * limit),
              totalCount: list.length,
              depth: path.length
            }
          }
        }
        break
      }
      case 'myBelongings': {
        const assetId = path?.[1]
        if (assetId) {
          const { name, attachments = [] } = await database.belonging.getById(assetId)
          const list = await Promise.all(
            attachments.map((attachment) => getMetadata(assetId, attachment, AssetType.Belonging, name))
          )
          return {
            list: list.slice((page - 1) * limit, page * limit),
            totalCount: list.length,
            depth: path.length
          }
        } else {
          const { list, totalCount } = await database.getDocumentVaultFolders({
            category: [AssetType.Belonging],
            offset: (page - 1) * limit,
            limit
          })
          return {
            list: list.map(({ fireId, name, attachmentCount }) => ({
              key: fireId,
              label: name,
              isEmpty: attachmentCount === 0
            })),
            totalCount,
            depth: path.length
          }
        }
      }
      case 'uncategorized': {
        const attachments = await database.Attachments.getCategoryFiles(AttachmentCategory.Uncategorized)
        const list = await Promise.all(
          attachments.map((attachment) => getMetadata(AttachmentCategory.Uncategorized, attachment))
        )
        return {
          list: list.slice((page - 1) * limit, page * limit),
          totalCount: list.length,
          depth: path.length
        }
      }
      case undefined: {
        const finances = [
          AssetType.CashAndBanking,
          AssetType.TraditionalInvestments,
          AssetType.OtherInvestment,
          AssetType.Cryptocurrency,
          AssetType.Insurance
        ]
        const collectables = [AssetType.Art, AssetType.WineAndSpirits, AssetType.OtherCollectables]
        const checkUncategorized = async () => {
          const list = await database.Attachments.getCategoryFiles(AttachmentCategory.Uncategorized)
          return list.length > 0
        }
        const [myFinances, myProperties, myCollectables, myBelongings, uncategorized] = await Promise.all([
          database.checkFilesExist({ category: finances }),
          database.checkFilesExist({ category: [AssetType.Property] }),
          database.checkFilesExist({ category: collectables }),
          database.checkFilesExist({ category: [AssetType.Belonging] }),
          checkUncategorized()
        ])
        const list = [
          { key: 'myFinances', label: t('MyFinances'), isEmpty: !myFinances },
          { key: 'myProperties', label: t('MyProperties'), isEmpty: !myProperties },
          { key: 'myCollectables', label: t('MyCollectables'), isEmpty: !myCollectables },
          { key: 'myBelongings', label: t('MyBelongings'), isEmpty: !myBelongings },
          { key: 'uncategorized', label: t('documents:Uncategorized'), isEmpty: !uncategorized }
        ]
        return {
          list: list.slice((page - 1) * limit, page * limit),
          totalCount: list.length,
          depth: path.length
        }
      }
    }

    throw new Error('Not implemented')
  }
}

/* Export */
export async function exportDataV1(
  database: Database,
  taskId: string,
  assetType: AssetType,
  dataRows: ExportRowV1[],
  callback?: (percentage: number) => void
) {
  const handler = await database.getExportHandlerV1(assetType, taskId)
  let progress = handler.getProgress()

  while (progress.total > dataRows.length) {
    const rows = await handler.getNextBatch()
    dataRows.push(...rows)
    const endId = rows.at(-1)?.id ?? ''
    await handler.markEndId(endId)
    callback && callback(Math.round((dataRows.length / progress.total) * 100))
    await delay(500)
  }
  await handler.deleteExportTask(taskId)
}

export async function exportDataV2(
  database: Database,
  taskId: string,
  assetType: AssetType,
  dataRows: ExportRowV2[],
  callback?: (percentage: number) => void
) {
  const handler = await database.getExportHandlerV2(assetType, taskId)
  let progress = handler.getProgress()

  while (progress.total > dataRows.length) {
    const rows = await handler.getNextBatch()
    dataRows.push(...rows)
    const endId = rows.at(-1)?.id ?? ''
    await handler.markEndId(endId)
    callback && callback(Math.round((dataRows.length / progress.total) * 100))
    await delay(500)
  }
  await handler.deleteExportTask(taskId)
}

export async function exportSummary(database: Database, callback?: (percentage: number) => void) {
  const report = await database.getComparativeNetWorthReport(
    isMatchedEnv(['prod'])
      ? [
          AssetType.TraditionalInvestments,
          AssetType.OtherInvestment,
          AssetType.Cryptocurrency,
          AssetType.Insurance,
          AssetType.WineAndSpirits
        ]
      : []
  )
  callback && callback(50)
  await delay(500)
  callback && callback(100)
  await delay(500)
  return report
}

export function fetchSoldInfo(database: Database) {
  return async ([_key, assetId, assetType]: [
    typeof commonQuery.soldInfo,
    string,
    AssetType.Art | AssetType.Property
  ]) => {
    switch (assetType) {
      case AssetType.Art: {
        const { closedWith } = await database.art.getById(assetId)
        if (!closedWith) throw new Error('No closedWith')
        const info = await database.art.getActionById(assetId, closedWith)
        return info as SoldInfo
      }
      case AssetType.Property: {
        const { closedWith } = await database.property.getById(assetId)
        if (!closedWith) throw new Error('No closedWith')
        const info = await database.property.getActionById(assetId, closedWith)
        return info as SoldInfo
      }
    }
  }
}

export function fetchGroupBySubType(database: Database) {
  return async ([_key, assetType]: [typeof commonQuery.groupBySubType, AssetTypeWithSubtype]) => {
    return await database.getAssetsSubTypeGroup(assetType)
  }
}
