import Link from 'next/link'
import { AuthErrorCodes } from 'firebase/auth'
import { Trans } from 'react-i18next'

export const getFirebaseAuthErrorMessage = (errorCode: string): { message: React.ReactNode; kbArticleId?: string } => {
  switch (errorCode) {
    case AuthErrorCodes.USER_DELETED:
      return {
        message: (
          <>
            <p className={'font-bold'}>{'Email Not Found.'}</p>
            <p className={'text-gray-700'}>
              {
                'This email has not been registered in our database. Please check your log in details or register for a new account.'
              }
            </p>
          </>
        ),
        kbArticleId: 'c18e79c8-cbd9-4c85-a5b2-2d94bed6ef37'
      }
    case AuthErrorCodes.USER_DISABLED:
      return {
        message: (
          <Trans
            i18nKey={'auth:AccountBlocked'}
            components={[
              <p key={0} className={'font-bold'} />,
              <span key={1} className={'text-gray-700'} />,
              <Link
                key={0}
                target={'_blank'}
                href={'https://myassets.com/contact-us/'}
                className={'text-black underline hover:text-gray-700'}
              />
            ]}
          />
        ),
        kbArticleId: '25fd6a46-2443-4f2f-8fb8-095dcef5af31'
      }
    case AuthErrorCodes.USER_MISMATCH:
      return {
        message: 'Incorrect Email or Password. Please check your log in details and try again.',
        kbArticleId: '268f9bc2-05f8-48d0-96c1-80a5e9c0b43a'
      }
    case AuthErrorCodes.USER_SIGNED_OUT:
      return { message: 'Session Expired. Please log in again.', kbArticleId: 'dd212a3a-628f-433a-ac69-1d48a815e053' }
    case AuthErrorCodes.INVALID_PASSWORD:
      return {
        message: 'Incorrect Email or Password. Please check your log in details and try again.',
        kbArticleId: '268f9bc2-05f8-48d0-96c1-80a5e9c0b43a'
      }
    case AuthErrorCodes.INVALID_EMAIL:
      return { message: 'Please enter a valid email address.', kbArticleId: 'ea712dd2-9241-4ad6-bbe4-f59fafd0de21' }
    case AuthErrorCodes.EMAIL_EXISTS:
      return {
        message: (
          <Trans
            i18nKey={'auth:EmailExists'}
            components={[
              <p key={0} className={'font-bold'} />,
              <span key={1} className={'text-gray-700'} />,
              <Link key={0} href={'/auth/forgot'} className={'text-black underline hover:text-gray-800'} />
            ]}
          />
        ),
        kbArticleId: 'ea712dd2-9241-4ad6-bbe4-f59fafd0de21'
      }
    case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
      return { message: 'Please check your details and try again.' }
    case AuthErrorCodes.CREDENTIAL_MISMATCH:
      return {
        message: 'Incorrect Email or Password. Please check your log in details and try again.',
        kbArticleId: '268f9bc2-05f8-48d0-96c1-80a5e9c0b43a'
      }
    case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
      return {
        message: 'Incorrect Email or Password. Please check your log in details and try again.',
        kbArticleId: '268f9bc2-05f8-48d0-96c1-80a5e9c0b43a'
      }
    case AuthErrorCodes.NETWORK_REQUEST_FAILED:
      return {
        message: 'Unstable Connection. Please check your internet connection and try again.',
        kbArticleId: '4431e607-36dd-47cb-ae00-ba00aa9aab59'
      }
    case AuthErrorCodes.TIMEOUT:
      return { message: 'Server Timeout. Please try again later.', kbArticleId: '3d4c817d-01bd-48cf-90d4-32ffadcc5e37' }
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return { message: 'Too Many Recovery Attempts', kbArticleId: 'ad36855f-4f7d-4ff6-b0bc-65234ab34dae' }
    case AuthErrorCodes.INVALID_API_KEY:
      return { message: 'Invalid API key. Verify your app configuration.' }
    case AuthErrorCodes.INVALID_APP_CREDENTIAL:
      return { message: 'The credential provided does not have permission to complete this action.' }
    case AuthErrorCodes.INVALID_APP_ID:
      return { message: 'Invalid app ID. Please check your app settings.' }
    case AuthErrorCodes.MFA_REQUIRED:
      return {
        message: 'Please set up 2FA (Two-Factor Authentication)',
        kbArticleId: '740ec302-26bc-4710-a81f-a4eb80157991'
      }
    case AuthErrorCodes.INVALID_MFA_SESSION:
      return {
        message: 'Please set up 2FA (Two-Factor Authentication)',
        kbArticleId: '740ec302-26bc-4710-a81f-a4eb80157991'
      }
    case AuthErrorCodes.MISSING_MFA_INFO:
      return {
        message: 'Invalid 2FA code. Please try again or request a new code.',
        kbArticleId: 'd2043144-f549-4fd2-bf22-b402e84efec6'
      }
    case AuthErrorCodes.INVALID_OAUTH_CLIENT_ID:
      return { message: 'Invalid OAuth client ID. Check your setup and try again.' }
    case AuthErrorCodes.INVALID_OAUTH_PROVIDER:
      return { message: 'Invalid OAuth provider. Please verify your settings.' }
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      return { message: 'Invalid phone number format. Please enter a valid phone number.' }
    case AuthErrorCodes.MISSING_PHONE_NUMBER:
      return { message: 'Phone number is missing. Please enter a phone number.' }
    case AuthErrorCodes.INVALID_CODE:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.MISSING_CODE:
      return {
        message: 'Please add the 2FA (Two-Factor Authentication) code',
        kbArticleId: 'c549b8ab-1e47-4078-9e53-9c02f6e03730'
      }
    case AuthErrorCodes.INVALID_PERSISTENCE:
      return { message: 'Invalid session persistence type. Please check your settings.' }
    case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED:
      return {
        message: 'Please use one of the supported browsers',
        kbArticleId: 'c549b8ab-1e47-4078-9e53-9c02f6e03730'
      }
    case AuthErrorCodes.INVALID_TENANT_ID:
      return { message: 'Invalid tenant ID. Please check your configuration.' }
    case AuthErrorCodes.TENANT_ID_MISMATCH:
      return { message: "Tenant ID does not match the user's account. Please verify and try again." }
    case AuthErrorCodes.MISSING_RECAPTCHA_TOKEN:
      return { message: 'Our anti-spam system has triggered, please try again later.' }
    case AuthErrorCodes.INVALID_RECAPTCHA_TOKEN:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.ADMIN_ONLY_OPERATION:
      return { message: 'This action can only be performed by an administrator.' }
    case AuthErrorCodes.ARGUMENT_ERROR:
      return { message: 'One of the inputs provided is incorrect. Please check and try again.' }
    case AuthErrorCodes.APP_NOT_AUTHORIZED:
      return { message: 'The app is not authorized to use Firebase authentication. Check your setup.' }
    case AuthErrorCodes.APP_NOT_INSTALLED:
      return { message: 'The app must be installed to perform this action.' }
    case AuthErrorCodes.CAPTCHA_CHECK_FAILED:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.CODE_EXPIRED:
      return {
        message: 'Invalid 2FA code. Please try again or request a new code.',
        kbArticleId: 'd2043144-f549-4fd2-bf22-b402e84efec6'
      }
    case AuthErrorCodes.CORDOVA_NOT_READY:
      return { message: "Cordova isn't set up to handle this action." }
    case AuthErrorCodes.CORS_UNSUPPORTED:
      return { message: 'This environment does not support the required resource sharing.' }
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      return {
        message: 'You have been logged out due to changes in your account settings. Please log in again.',
        kbArticleId: '28d89c2d-d8bd-42d5-9c54-e27cc037244a'
      }
    case AuthErrorCodes.DEPENDENT_SDK_INIT_BEFORE_AUTH:
      return { message: 'A required SDK must be initialized after Firebase Authentication.' }
    case AuthErrorCodes.DYNAMIC_LINK_NOT_ACTIVATED:
      return { message: "Firebase Dynamic Links aren't enabled for this project." }
    case AuthErrorCodes.EMAIL_CHANGE_NEEDS_VERIFICATION:
      return {
        message: 'Please check your inbox and verify your email address',
        kbArticleId: 'dea02c20-6756-4251-ba5b-fd30279fa6f7'
      }
    case AuthErrorCodes.EMULATOR_CONFIG_FAILED:
      return { message: 'Firebase Emulator setup failed. Please review the configuration.' }
    case AuthErrorCodes.EXPIRED_OOB_CODE:
      return { message: 'This action code has expired. Please request a new one.' }
    case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.INTERNAL_ERROR:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.INVALID_AUTH_EVENT:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.INVALID_CERT_HASH:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.INVALID_CONTINUE_URI:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.INVALID_CORDOVA_CONFIGURATION:
      return {
        message: 'Cordova configuration is invalid. Please review and update the settings.',
        kbArticleId: '268f9bc2-05f8-48d0-96c1-80a5e9c0b43a'
      }
    case AuthErrorCodes.INVALID_CUSTOM_TOKEN:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.INVALID_DYNAMIC_LINK_DOMAIN:
      return { message: 'The dynamic link domain is not set up for this project.' }
    case AuthErrorCodes.INVALID_EMULATOR_SCHEME:
      return { message: 'The scheme for the Firebase Emulator is invalid. Check your setup.' }
    case AuthErrorCodes.INVALID_IDP_RESPONSE:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.INVALID_MESSAGE_PAYLOAD:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.MISSING_ANDROID_PACKAGE_NAME:
      return { message: 'Please provide an Android package name to proceed with the installation.' }
    case AuthErrorCodes.MISSING_AUTH_DOMAIN:
      return { message: 'An authentication domain is needed to complete this operation.' }
    case AuthErrorCodes.MISSING_CONTINUE_URI:
      return { message: 'Please provide a valid continue URL to proceed.' }
    case AuthErrorCodes.MISSING_IFRAME_START:
      return { message: 'The iframe start URL is missing. Please check your configuration.' }
    case AuthErrorCodes.MISSING_IOS_BUNDLE_ID:
      return { message: 'The request is missing a required Bundle ID.' }
    case AuthErrorCodes.MISSING_OR_INVALID_NONCE:
      return { message: 'The nonce is either missing or invalid. Please try again.' }
    case AuthErrorCodes.MODULE_DESTROYED:
      return { message: 'The Firebase app has been deleted. Please check your project settings.' }
    case AuthErrorCodes.NEED_CONFIRMATION:
      return {
        message: 'Incorrect Email or Password. Please check your log in details and try again.',
        kbArticleId: '268f9bc2-05f8-48d0-96c1-80a5e9c0b43a'
      }
    case AuthErrorCodes.NO_AUTH_EVENT:
      return { message: 'Please log in to continue' }
    case AuthErrorCodes.NO_SUCH_PROVIDER:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.OPERATION_NOT_ALLOWED:
      return { message: 'The sign-in provider is disabled. Enable it in your Firebase console.' }
    case AuthErrorCodes.OPERATION_NOT_SUPPORTED:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.POPUP_BLOCKED:
      return { message: 'The browser has blocked the popup. Please allow popups and try again.' }
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.PROVIDER_ALREADY_LINKED:
      return { message: 'Please check your details and try again.' }
    case AuthErrorCodes.QUOTA_EXCEEDED:
      return {
        message: 'Too Many Requests. Please try again later.',
        kbArticleId: 'dd212a3a-628f-433a-ac69-1d48a815e053'
      }
    case AuthErrorCodes.REDIRECT_CANCELLED_BY_USER:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.REDIRECT_OPERATION_PENDING:
      return { message: 'Your request is being processed, please wait or refresh the page to cancel.' }
    case AuthErrorCodes.REJECTED_CREDENTIAL:
      return {
        message: 'Incorrect Email or Password. Please check your log in details and try again.',
        kbArticleId: '268f9bc2-05f8-48d0-96c1-80a5e9c0b43a'
      }
    case AuthErrorCodes.SECOND_FACTOR_ALREADY_ENROLLED:
      return { message: '2FA is already active on your account.' }
    case AuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED:
      return { message: '2FA is already active on your account, please use your existing 2FA.' }
    case AuthErrorCodes.UNAUTHORIZED_DOMAIN:
      return { message: 'The domain for the continue URL is not allowed. Add it to your Firebase whitelist.' }
    case AuthErrorCodes.UNSUPPORTED_FIRST_FACTOR:
      return { message: 'Please check your details and try again.' }
    case AuthErrorCodes.UNSUPPORTED_PERSISTENCE:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.UNSUPPORTED_TENANT_OPERATION:
      return { message: 'Tenant operation is not supported for this setup.' }
    case AuthErrorCodes.UNVERIFIED_EMAIL:
      return {
        message: 'Please check your inbox and verify your email address',
        kbArticleId: 'dea02c20-6756-4251-ba5b-fd30279fa6f7'
      }
    case AuthErrorCodes.USER_CANCELLED:
      return { message: 'Something went wrong please try again' }
    case AuthErrorCodes.ALREADY_INITIALIZED:
      return { message: 'Firebase Authentication is already set up. No further action is needed.' }
    case AuthErrorCodes.MISSING_CLIENT_TYPE:
      return {
        message: 'There is a problem with your information, please verify it and try again.',
        kbArticleId: '4d8c01c4-7945-4ef6-a0d0-765e5a9367da'
      }
    default:
      return { message: 'An unexpected error occurred. Please try again later or contact support.' }
  }
}
