import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2017%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M15.965%200H2.035A2.038%202.038%200%200%200%200%202.037v12.802c0%201.123.913%202.034%202.036%202.034h13.93A2.036%202.036%200%200%200%2018%2014.84V2.037A2.037%202.037%200%200%200%2015.965%200zM11.64%203.03a1.967%201.967%200%201%201%200%203.933%201.967%201.967%200%200%201%200-3.934zm3.687%2011.961H2.955c-.543%200-.785-.392-.54-.877L5.789%207.43c.245-.485.71-.528%201.04-.097l3.394%204.434a.832.832%200%200%200%201.289.082l.83-.84c.382-.387.944-.34%201.255.106l2.15%203.07c.31.446.123.806-.42.806z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ImageIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 18 17'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M15.965 0H2.035A2.038 2.038 0 0 0 0 2.037v12.802c0 1.123.913 2.034 2.036 2.034h13.93A2.036 2.036 0 0 0 18 14.84V2.037A2.037 2.037 0 0 0 15.965 0zM11.64 3.03a1.967 1.967 0 1 1 0 3.933 1.967 1.967 0 0 1 0-3.934zm3.687 11.961H2.955c-.543 0-.785-.392-.54-.877L5.789 7.43c.245-.485.71-.528 1.04-.097l3.394 4.434a.832.832 0 0 0 1.289.082l.83-.84c.382-.387.944-.34 1.255.106l2.15 3.07c.31.446.123.806-.42.806z'
        }
      />
    </svg>
  )
}
