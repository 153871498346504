import Decimal from "decimal.js";
import { CoreFirestore, getQueriedData } from "../../coreFirebase";
import { Refs } from "../refs";
import { Account } from "../types/cashAndBanking";
import { Loan } from "../types/cashAndBanking/loan";
import { Mortgage } from "../types/cashAndBanking/mortgage";
import { Amount, Currency } from "../types/common";
import { AssetType } from "../types/enums";
import { DataPoisoned } from "../types/error";
import {
  ComparativeNetWorthReport,
  NetWorthReportDetails,
  SubtypeDetails,
  assetTypeWithSubtype,
  defaultComparativeNetWorthReport,
  defaultNetWorthReportDetails,
} from "../types/reports";
import {
  Wine,
  WinePricingMethod,
  WinePurchase,
  WineType,
} from "../types/wineAndSprits";
import {
  AllowedDecimalPlaces,
  addDecimal,
  mulAmount,
  subDecimal,
} from "../utils";
import { ExchangeRate } from "./exchangeRate";
import { PriceSource } from "./priceSource";
import { HoldingItem, HoldingType } from "../types/traditionalInvestments";
import { OwnerDetail, OwnershipType } from "../types/properties";
import { LifeInsuranceType } from "../types/insurance";
import { Database } from "../database";
import {
  OtherInvestment,
  PercentOfCompany,
  Loan as OtherInvestmentLoan,
} from "../types/otherInvestments";

type SharedItems = {
  [id: string]: {
    name?: string; // for property
    subtype?: string;
    purchasePrice: Amount;
    value: Amount;
    ownedPercentage: number;
    sold?: boolean;
    archived?: boolean;
  };
};

export class GlobalDashboardReportExporter {
  readonly database: Database;
  readonly refs: Refs;
  readonly exRate: ExchangeRate;
  readonly priceSource: PriceSource;
  readonly currency: Currency;

  constructor(
    database: Database,
    refs: Refs,
    exRate: ExchangeRate,
    priceSource: PriceSource
  ) {
    this.database = database;
    this.refs = refs;
    this.exRate = exRate;
    this.priceSource = priceSource;
    this.currency = exRate.BaseCurrency as Currency;
  }

  private toDecimalPlacesNumber(value: Decimal) {
    return value.toDecimalPlaces(AllowedDecimalPlaces).toNumber();
  }

  private computePriceAndAssets<T extends SharedItems>(
    items: T,
    details: NetWorthReportDetails & SubtypeDetails,
    computeSubtypes: boolean
  ) {
    const result = Object.values(items).reduce(
      (acc, item) => {
        // #NOTE sold and archived items are not included
        if (item.sold || item.archived) return acc;
        const purchasePrice = new Decimal(item.purchasePrice.value)
          .mul(
            this.exRate.getToBaseExchangeRate(item.purchasePrice.currency).rate
          )
          .mul(item.ownedPercentage)
          .div(100);
        const assets = new Decimal(item.value.value)
          .mul(this.exRate.getToBaseExchangeRate(item.value.currency).rate)
          .mul(item.ownedPercentage)
          .div(100);

        if (!computeSubtypes)
          return {
            totalPurchasePrice: purchasePrice.add(acc.totalPurchasePrice),
            totalAssets: assets.add(acc.totalAssets),
            subtypes: acc.subtypes,
          };

        // subtypes
        if (!item.subtype) throw new Error("Subtype not found");
        if (!acc.subtypes[item.subtype]) {
          acc.subtypes[item.subtype] = {
            totalPurchasePrice: purchasePrice,
            totalAssets: assets,
          };
        } else {
          acc.subtypes[item.subtype].totalPurchasePrice =
            acc.subtypes[item.subtype].totalPurchasePrice.add(purchasePrice);
          acc.subtypes[item.subtype].totalAssets =
            acc.subtypes[item.subtype].totalAssets.add(assets);
        }
        return {
          totalPurchasePrice: purchasePrice.add(acc.totalPurchasePrice),
          totalAssets: assets.add(acc.totalAssets),
          subtypes: acc.subtypes,
        };
      },
      {
        totalPurchasePrice: new Decimal(0),
        totalAssets: new Decimal(0),
        subtypes: {} as {
          [subtype: string]: {
            totalPurchasePrice: Decimal;
            totalAssets: Decimal;
          };
        },
      }
    );
    details.original.purchasePrice.value = this.toDecimalPlacesNumber(
      result.totalPurchasePrice
    );
    details.current.assets.value = this.toDecimalPlacesNumber(
      result.totalAssets
    );

    if (!computeSubtypes) return;
    // subtypes
    for (const subtype in result.subtypes) {
      if (!details.subtype[subtype])
        details.subtype[subtype] = defaultNetWorthReportDetails(this.currency);
      details.subtype[subtype]!.original.purchasePrice.value =
        this.toDecimalPlacesNumber(result.subtypes[subtype].totalPurchasePrice);
      details.subtype[subtype]!.current.assets.value =
        this.toDecimalPlacesNumber(result.subtypes[subtype].totalAssets);
    }
  }

  private sumSubtypeDetailsToTarget(
    target: NetWorthReportDetails,
    subtypes: NetWorthReportDetails[]
  ) {
    const totalResult = subtypes.reduce(
      (acc, v) => {
        const totalPurchasePrice = acc.totalPurchasePrice.add(
          v.original.purchasePrice.value
        );
        const totalOriginalLiabilities = acc.totalOriginalLiabilities.add(
          v.original.liabilities.value
        );
        const totalAssets = acc.totalAssets.add(v.current.assets.value);
        const totalLiabilities = acc.totalLiabilities.add(
          v.current.liabilities.value
        );
        return {
          totalPurchasePrice,
          totalOriginalLiabilities,
          totalAssets,
          totalLiabilities,
        };
      },
      {
        totalPurchasePrice: new Decimal(0),
        totalOriginalLiabilities: new Decimal(0),
        totalAssets: new Decimal(0),
        totalLiabilities: new Decimal(0),
      }
    );
    target.original.purchasePrice.value = this.toDecimalPlacesNumber(
      totalResult.totalPurchasePrice
    );
    target.original.liabilities.value = this.toDecimalPlacesNumber(
      totalResult.totalOriginalLiabilities
    );
    target.current.assets.value = this.toDecimalPlacesNumber(
      totalResult.totalAssets
    );
    target.current.liabilities.value = this.toDecimalPlacesNumber(
      totalResult.totalLiabilities
    );
  }

  private computePercentageChange(original: number, current: number): number {
    if (original == 0 && current == 0) return 0;
    if (original == 0) return NaN;
    const percentageChange = new Decimal(current)
      .div(original)
      .sub(1)
      .mul(100)
      .toDecimalPlaces(AllowedDecimalPlaces)
      .toNumber();
    // if (original < 0) return -percentageChange
    return percentageChange;
  }

  private computeNetValueAndPercentageChange({
    original,
    current,
    netChange,
  }: NetWorthReportDetails) {
    original.netInvestment.value = addDecimal(
      original.purchasePrice.value,
      original.liabilities.value
    );
    current.netValue.value = addDecimal(
      current.assets.value,
      current.liabilities.value
    );

    netChange.amountChange.value = subDecimal(
      current.netValue.value,
      original.netInvestment.value
    );
    netChange.percentageChange = this.computePercentageChange(
      original.netInvestment.value,
      current.netValue.value
    );
  }

  private async processCashAndBanking(result: ComparativeNetWorthReport) {
    // #NOTE: cash and banking does not have purchase price
    const institutions = await this.database.cashAndBanking.getAllInstitution();
    const accounts = institutions.flatMap((v) => Object.values(v.accounts));
    const cashAndBankingResult = accounts.reduce(
      (acc, account) => {
        if (
          account.closed ||
          // needs allocation, compute later
          account.subtype === Account.Type.LoanAccount ||
          account.subtype === Account.Type.MortgageAccount
        ) {
          return acc;
        }

        const value = account.subAccounts
          .reduce((innerAcc, subAccount) => {
            return new Decimal(subAccount.balance.value)
              .mul(
                this.exRate.getToBaseExchangeRate(subAccount.balance.currency)
                  .rate
              )
              .add(innerAcc);
          }, new Decimal(0))
          .mul(account.ownedPercentage)
          .div(100);

        return account.subtype === Account.Type.CreditCardAccount
          ? {
              creditCardAccountLiabilities: value.add(
                acc.creditCardAccountLiabilities
              ),
              totalAssets: acc.totalAssets,
            }
          : {
              creditCardAccountLiabilities: acc.creditCardAccountLiabilities,
              totalAssets: value.add(acc.totalAssets),
            };
      },
      {
        creditCardAccountLiabilities: new Decimal(0),
        totalAssets: new Decimal(0),
      }
    );
    result.myFinances.subtype[AssetType.CashAndBanking].current.assets.value =
      this.toDecimalPlacesNumber(cashAndBankingResult.totalAssets);
    return cashAndBankingResult;
  }

  private async processTraditionalInvestments(
    result: ComparativeNetWorthReport
  ) {
    const portfolios =
      await this.database.traditionalInvestment.getAllPortfolio();
    const stockPriceMap = await this.priceSource.getStockPrice(
      Array.from(
        new Set(
          portfolios.flatMap((portfolio) => {
            return Object.values(portfolio.holdings)
              .filter((holding) => holding.holdingType == HoldingType.Holding)
              .map((h) => {
                let holding = h as HoldingItem.Holding;
                return { symbol: holding.symbol, exchange: holding.exchange };
              });
          })
        )
      )
    );
    const TIResult = portfolios.reduce(
      (acc, item) => {
        const { purchasePrice, assets } = item.holdings.reduce(
          (innerAcc, holding) => {
            const purchasePrice = new Decimal(holding.investedValue.value).mul(
              this.exRate.getToBaseExchangeRate(holding.investedValue.currency)
                .rate
            );
            let assets: Decimal;
            if (holding.holdingType === HoldingType.Holding) {
              let price = stockPriceMap[holding.exchange]?.[holding.symbol];
              if (!price) {
                console.error(
                  `Price not found for ${holding.exchange} ${holding.symbol}`
                );
                price = Amount.zero(this.exRate.BaseCurrency as Currency);
              }
              assets = new Decimal(holding.unit)
                .mul(price.value)
                .mul(this.exRate.getToBaseExchangeRate(price.currency).rate);
            } else {
              assets = new Decimal(holding.unit).mul(
                this.exRate.getToBaseExchangeRate(
                  holding.investedValue.currency
                ).rate
              );
            }
            return {
              purchasePrice: purchasePrice.add(innerAcc.purchasePrice),
              assets: assets.add(innerAcc.assets),
            };
          },
          {
            purchasePrice: new Decimal(0),
            assets: new Decimal(0),
          }
        );
        return {
          totalPurchasePrice: purchasePrice
            .mul(item.ownership?.myOwnership || 100)
            .div(100)
            .add(acc.totalPurchasePrice),
          totalAssets: assets
            .mul(item.ownership?.myOwnership || 100)
            .div(100)
            .add(acc.totalAssets),
        };
      },
      {
        totalPurchasePrice: new Decimal(0),
        totalAssets: new Decimal(0),
      }
    );
    result.myFinances.subtype[
      AssetType.TraditionalInvestments
    ].original.purchasePrice.value = this.toDecimalPlacesNumber(
      TIResult.totalPurchasePrice
    );
    result.myFinances.subtype[
      AssetType.TraditionalInvestments
    ].current.assets.value = this.toDecimalPlacesNumber(TIResult.totalAssets);
  }

  private async processOtherInvestments(result: ComparativeNetWorthReport) {
    const otherInvestments = await this.database.otherInvestment.getAll();
    const items: SharedItems = {};
    otherInvestments.forEach((item) => {
      items[item.id] = {
        purchasePrice:
          (<OtherInvestment.Encrypted<OtherInvestmentLoan>>item)
            .initialAmount ||
          (<OtherInvestment.Encrypted<PercentOfCompany>>item)
            .investmentAmount ||
          Amount.defaultValue(),
        value: item.value,
        ownedPercentage: item.ownership?.myOwnership || 100,
      };
    });
    this.computePriceAndAssets(
      items,
      {
        ...result.myFinances.subtype[AssetType.OtherInvestment],
        subtype: {},
      },
      false
    );
    return items;
  }

  private async processCryptocurrency(result: ComparativeNetWorthReport) {
    const cryptoAccounts = await this.database.cryptocurrency.getAll();
    const cryptoPriceMap = await this.priceSource.getCryptoPrice(
      cryptoAccounts.flatMap((account) =>
        account.coins.map((coin) => coin.coinName)
      )
    );
    const cryptoResult = cryptoAccounts.reduce(
      (acc, account) => {
        const { purchasePrice, assets } = account.coins.reduce(
          (innerAcc, coin) => {
            const purchasePrice = new Decimal(coin.investedValue.value).mul(
              this.exRate.getToBaseExchangeRate(coin.investedValue.currency)
                .rate
            );
            const assets = new Decimal(coin.unit)
              .mul(cryptoPriceMap[coin.coinName].value)
              .mul(
                this.exRate.getToBaseExchangeRate(
                  cryptoPriceMap[coin.coinName].currency
                ).rate
              );
            return {
              purchasePrice: purchasePrice.add(innerAcc.purchasePrice),
              assets: assets.add(innerAcc.assets),
            };
          },
          {
            purchasePrice: new Decimal(0),
            assets: new Decimal(0),
          }
        );
        return {
          totalPurchasePrice: purchasePrice
            .mul(account.ownership?.myOwnership || 100)
            .div(100)
            .add(acc.totalPurchasePrice),
          totalAssets: assets
            .mul(account.ownership?.myOwnership || 100)
            .div(100)
            .add(acc.totalAssets),
        };
      },
      {
        totalPurchasePrice: new Decimal(0),
        totalAssets: new Decimal(0),
      }
    );
    result.myFinances.subtype[
      AssetType.Cryptocurrency
    ].original.purchasePrice.value = this.toDecimalPlacesNumber(
      cryptoResult.totalPurchasePrice
    );
    result.myFinances.subtype[AssetType.Cryptocurrency].current.assets.value =
      this.toDecimalPlacesNumber(cryptoResult.totalAssets);
  }

  private async processInsurance(result: ComparativeNetWorthReport) {
    const insurances = await this.database.insurance.getAll();
    const insuranceItems: SharedItems = {};
    insurances.forEach((item) => {
      // #NOTE: only `whole of life` has surrender value, which means it is includes in `assets`
      if (item.insuranceType !== LifeInsuranceType.WholeOfLife) return;
      insuranceItems[item.id] = {
        purchasePrice: item.premium,
        value: item.value,
        ownedPercentage: 100,
      };
    });
    this.computePriceAndAssets(
      insuranceItems,
      { ...result.myFinances.subtype[AssetType.Insurance], subtype: {} },
      false
    );
  }

  private async processArt(result: ComparativeNetWorthReport) {
    const arts = await this.database.art.getAll();
    const artItems: SharedItems = {};
    arts.forEach((item) => {
      artItems[item.id] = {
        subtype: item.subtype,
        purchasePrice: item.purchasePrice,
        value: item.value,
        ownedPercentage: item.ownership?.myOwnership || 100,
        sold: item.closedWith !== undefined,
      };
    });
    this.computePriceAndAssets(
      artItems,
      result.myCollectables.subtype[AssetType.Art],
      true
    );
    return artItems;
  }

  private async processWine(result: ComparativeNetWorthReport) {
    // Need purchase price. Purchase.Min does not have purchase price.
    const wines = await this.database.wine.getAllWines();
    const processedWines: {
      [wineId: string]: {
        subtype: WineType | "-";
        purchases: WinePurchase[];
      };
    } = {};
    for (const wine of wines) {
      // prevent if we have some old wineIds were in the format of {userId}_{wineId}
      let wineId = wine.wineId;
      const parts = wineId.split("_");
      if (parts.length > 1) {
        wineId = parts[parts.length - 1];
        if (isNaN(Number(wineId))) {
          throw new Error("invalid wineId");
        }
      }
      const purchases = await this.database.wine.getWinePurchasesByIds(
        wine.purchases.map((p) => p.id)
      );
      processedWines[wine.id] = {
        subtype: wine.subtype,
        purchases,
      };
    }

    const wineItems: SharedItems = {};
    Object.entries(processedWines).forEach(([id, wine]) => {
      const { purchasePrice, assets } = wine.purchases.reduce(
        (innerAcc, purchase) => {
          const price =
            purchase.pricingMethod === WinePricingMethod.Lot
              ? purchase.price
              : {
                  currency: purchase.price.currency,
                  value: mulAmount(
                    purchase.price.value,
                    purchase.bottleCount.bottles + purchase.bottleCount.consumed
                  ),
                };
          const value: Amount = {
            currency: purchase.valuePerBottle.currency,
            value: mulAmount(
              purchase.valuePerBottle.value,
              purchase.bottleCount.bottles
            ),
          };
          const purchasePrice = new Decimal(price.value)
            .mul(this.exRate.getToBaseExchangeRate(price.currency).rate)
            .mul(purchase.ownership?.myOwnership || 100)
            .div(100)
            .add(innerAcc.purchasePrice);
          const assets = new Decimal(value.value)
            .mul(this.exRate.getToBaseExchangeRate(value.currency).rate)
            .mul(purchase.ownership?.myOwnership || 100)
            .div(100)
            .add(innerAcc.assets);
          return { purchasePrice, assets };
        },
        { purchasePrice: new Decimal(0), assets: new Decimal(0) }
      );

      wineItems[id] = {
        subtype: wine.subtype,
        purchasePrice: {
          currency: this.currency,
          value: this.toDecimalPlacesNumber(purchasePrice),
        },
        value: {
          currency: this.currency,
          value: this.toDecimalPlacesNumber(assets),
        },
        ownedPercentage: 100,
      };
    });
    this.computePriceAndAssets(
      wineItems,
      result.myCollectables.subtype[AssetType.WineAndSpirits],
      true
    );
    return wineItems;
  }

  private async processOtherCollectables(result: ComparativeNetWorthReport) {
    const otherCollectables = await this.database.otherCollectable.getAll();
    const otherCollectableItems: SharedItems = {};
    otherCollectables.forEach((item) => {
      otherCollectableItems[item.id] = {
        subtype: item.subtype,
        purchasePrice: item.purchasePrice,
        value: item.value,
        ownedPercentage: item.ownership?.myOwnership || 100,
      };
    });
    this.computePriceAndAssets(
      otherCollectableItems,
      result.myCollectables.subtype[AssetType.OtherCollectables],
      true
    );
    return otherCollectableItems;
  }

  private async processProperty() {
    const properties = await this.database.property.getAll();
    const ownedPropertyItems: SharedItems = {};
    properties.forEach((item) => {
      // #NOTE: rent property should not be counted
      if (item.ownershipType === OwnershipType.Rent) return;
      ownedPropertyItems[item.id] = {
        name: item.name,
        // NOTE: property wants to list all properties, so use `id` as subtype for processing
        subtype: item.id,
        purchasePrice: item.price,
        value: item.value,
        ownedPercentage:
          (<OwnerDetail>item.detail).ownership?.myOwnership || 100,
        sold: item.closedWith !== undefined,
        archived: item.archived,
      };
    });
    const propertyResultMap: NetWorthReportDetails & SubtypeDetails = {
      ...defaultNetWorthReportDetails(this.currency),
      subtype: {},
    };
    this.computePriceAndAssets(ownedPropertyItems, propertyResultMap, true);
    return { ownedPropertyItems, propertyResultMap };
  }

  private async processBelonging(result: ComparativeNetWorthReport) {
    const belongings = await this.database.belonging.getAll();
    const belongingItems: SharedItems = {};
    belongings.forEach((item) => {
      belongingItems[item.id] = {
        subtype: item.subtype,
        purchasePrice: item.purchasePrice,
        value: item.value,
        ownedPercentage: item.ownership?.myOwnership || 100,
      };
    });
    this.computePriceAndAssets(belongingItems, result.myBelongings, true);
    return belongingItems;
  }

  /**
   * According to the requirements, the report is computed as follows:
   * - CashAndBanking: includes all kinds of accounts, `liabilities` is the leftover after allocating to assets
   * - Insurance: only include `whole of life` insurance
   * - Property: only include `owned` property
   * sold / archived assets are ignored
   */
  async computeComparativeNetWorthReport(
    // for removing coming soon assetTypes computation
    excludeAssetTypes: (
      | AssetType.TraditionalInvestments
      | AssetType.OtherInvestment
      | AssetType.Cryptocurrency
      | AssetType.Insurance
      | AssetType.WineAndSpirits
    )[]
  ): Promise<ComparativeNetWorthReport> {
    const currency = this.exRate.BaseCurrency as Currency;
    const result: ComparativeNetWorthReport =
      defaultComparativeNetWorthReport(currency);

    // *** Loop summary to get purchasePrice and assets ***

    const cashAndBankingResult = await this.processCashAndBanking(result);
    if (!excludeAssetTypes.includes(AssetType.TraditionalInvestments)) {
      await this.processTraditionalInvestments(result);
    }
    let otherInvestmentItems: SharedItems = {};
    if (!excludeAssetTypes.includes(AssetType.OtherInvestment)) {
      otherInvestmentItems = await this.processOtherInvestments(result);
    }
    if (!excludeAssetTypes.includes(AssetType.Cryptocurrency)) {
      await this.processCryptocurrency(result);
    }
    if (!excludeAssetTypes.includes(AssetType.Insurance)) {
      await this.processInsurance(result);
    }
    const artItems = await this.processArt(result);
    let wineItems: SharedItems = {};
    if (!excludeAssetTypes.includes(AssetType.WineAndSpirits)) {
      wineItems = await this.processWine(result);
    }
    const otherCollectableItems = await this.processOtherCollectables(result);
    const { ownedPropertyItems, propertyResultMap } =
      await this.processProperty();
    const belongingItems = await this.processBelonging(result);

    // *** Query all loan / mortgage. Get liabilities and allocation ***
    const liabilityAccounts = await CoreFirestore.getDocsFromCollection<
      Loan | Mortgage
    >(
      this.refs.getAssetCollectionRef(AssetType.CashAndBanking),
      CoreFirestore.where("ownerId", "==", this.refs.userId),
      CoreFirestore.where("subtype", "in", [
        Account.Type.LoanAccount,
        Account.Type.MortgageAccount,
      ])
    ).then(getQueriedData);

    const assetTypeWithSubtypeItems: { [assetType: string]: SharedItems } = {
      [AssetType.Art]: artItems,
      [AssetType.WineAndSpirits]: wineItems,
      [AssetType.OtherCollectables]: otherCollectableItems,
      [AssetType.Property]: ownedPropertyItems,
      [AssetType.Belonging]: belongingItems,
      [AssetType.OtherInvestment]: otherInvestmentItems,
    };
    const liabilities = liabilityAccounts.reduce(
      (acc, account) => {
        if (account.closedWith) return acc;
        const accountInitialAmount = new Decimal(account.initialAmount.value)
          .mul(
            this.exRate.getToBaseExchangeRate(account.initialAmount.currency)
              .rate
          )
          .mul(-1);
        const accountValue = new Decimal(account.value.value).mul(
          this.exRate.getToBaseExchangeRate(account.value.currency).rate
        );
        if (account.subtype == Account.Type.LoanAccount) {
          // allocated liabilities
          let leftPercentage = 100;
          account.allocations.forEach((alloc) => {
            const assetId =
              alloc.assetType === AssetType.WineAndSpirits
                ? Wine.checkAndBuildWineId(this.refs.userId, alloc.assetId)
                : alloc.assetId;
            // #NOTE: ignore archived / sold / rent
            if (
              !assetTypeWithSubtypeItems[alloc.assetType][assetId] || // no such asset (or the property is rent)
              assetTypeWithSubtypeItems[alloc.assetType][assetId].sold ||
              assetTypeWithSubtypeItems[alloc.assetType][assetId].archived
            ) {
              return;
            }
            const initialLiability = accountInitialAmount
              .mul(alloc.percent)
              .div(100);
            const currentLiability = accountValue.mul(alloc.percent).div(100);
            acc[alloc.assetType].totalInitialLiability =
              acc[alloc.assetType].totalInitialLiability.add(initialLiability);
            acc[alloc.assetType].totalCurrentLiability =
              acc[alloc.assetType].totalCurrentLiability.add(currentLiability);
            leftPercentage -= alloc.percent;
            if (assetTypeWithSubtype.includes(alloc.assetType)) {
              const subtype =
                assetTypeWithSubtypeItems[alloc.assetType][assetId].subtype!;
              if (!acc[alloc.assetType].subtypes![subtype]) {
                acc[alloc.assetType].subtypes![subtype] = {
                  totalInitialLiability: initialLiability,
                  totalCurrentLiability: currentLiability,
                };
              } else {
                acc[alloc.assetType].subtypes![subtype].totalInitialLiability =
                  acc[alloc.assetType].subtypes![
                    subtype
                  ].totalInitialLiability.add(initialLiability);
                acc[alloc.assetType].subtypes![subtype].totalCurrentLiability =
                  acc[alloc.assetType].subtypes![
                    subtype
                  ].totalCurrentLiability.add(currentLiability);
              }
            }
          });
          // Assign left percentage to cash and banking
          if (leftPercentage < 0)
            throw new DataPoisoned("Left percentage is negative");
          else if (leftPercentage > 0) {
            acc[AssetType.CashAndBanking].totalInitialLiability = acc[
              AssetType.CashAndBanking
            ].totalInitialLiability.add(
              accountInitialAmount.mul(leftPercentage).div(100)
            );
            acc[AssetType.CashAndBanking].totalCurrentLiability = acc[
              AssetType.CashAndBanking
            ].totalCurrentLiability.add(
              accountValue.mul(leftPercentage).div(100)
            );
          }
        } else if (account.subtype == Account.Type.MortgageAccount) {
          // #NOTE: ignore rent / sold / archived properties
          if (
            account.linkToPropertyId &&
            ownedPropertyItems[account.linkToPropertyId] &&
            !ownedPropertyItems[account.linkToPropertyId].sold &&
            !ownedPropertyItems[account.linkToPropertyId].archived
          ) {
            acc[AssetType.Property].totalInitialLiability =
              acc[AssetType.Property].totalInitialLiability.add(
                accountInitialAmount
              );
            acc[AssetType.Property].totalCurrentLiability =
              acc[AssetType.Property].totalCurrentLiability.add(accountValue);
            // subtypes
            const subtype =
              ownedPropertyItems[account.linkToPropertyId].subtype!;
            if (!acc[AssetType.Property].subtypes![subtype]) {
              acc[AssetType.Property].subtypes![subtype] = {
                totalInitialLiability: accountInitialAmount,
                totalCurrentLiability: accountValue,
              };
            } else {
              acc[AssetType.Property].subtypes![subtype].totalInitialLiability =
                acc[AssetType.Property].subtypes![
                  subtype
                ].totalInitialLiability.add(accountInitialAmount);
              acc[AssetType.Property].subtypes![subtype].totalCurrentLiability =
                acc[AssetType.Property].subtypes![
                  subtype
                ].totalCurrentLiability.add(accountValue);
            }
          } else {
            acc[AssetType.CashAndBanking].totalInitialLiability =
              acc[AssetType.CashAndBanking].totalInitialLiability.add(
                accountInitialAmount
              );
            acc[AssetType.CashAndBanking].totalCurrentLiability =
              acc[AssetType.CashAndBanking].totalCurrentLiability.add(
                accountValue
              );
          }
        }
        return acc;
      },
      {
        [AssetType.CashAndBanking]: {
          totalInitialLiability: new Decimal(0),
          totalCurrentLiability:
            cashAndBankingResult.creditCardAccountLiabilities,
        },
        [AssetType.Art]: {
          totalInitialLiability: new Decimal(0),
          totalCurrentLiability: new Decimal(0),
          subtypes: {},
        },
        [AssetType.WineAndSpirits]: {
          totalInitialLiability: new Decimal(0),
          totalCurrentLiability: new Decimal(0),
          subtypes: {},
        },
        [AssetType.Property]: {
          totalInitialLiability: new Decimal(0),
          totalCurrentLiability: new Decimal(0),
          subtypes: {},
        },
        [AssetType.Belonging]: {
          totalInitialLiability: new Decimal(0),
          totalCurrentLiability: new Decimal(0),
          subtypes: {},
        },
        [AssetType.OtherCollectables]: {
          totalInitialLiability: new Decimal(0),
          totalCurrentLiability: new Decimal(0),
          subtypes: {},
        },
        [AssetType.OtherInvestment]: {
          totalInitialLiability: new Decimal(0),
          totalCurrentLiability: new Decimal(0),
          subtypes: {},
        },
      } as {
        [key: string]: {
          totalInitialLiability: Decimal;
          totalCurrentLiability: Decimal;
          subtypes?: {
            [key: string]: {
              totalInitialLiability: Decimal;
              totalCurrentLiability: Decimal;
            };
          };
        };
      }
    );

    // *** Assign liabilities to result ***
    Object.entries(liabilities).forEach(([assetType, v]) => {
      const totalInitialLiability = this.toDecimalPlacesNumber(
        v.totalInitialLiability
      );
      const totalCurrentLiability = this.toDecimalPlacesNumber(
        v.totalCurrentLiability
      );

      let target: NetWorthReportDetails;
      switch (assetType) {
        case AssetType.CashAndBanking:
        case AssetType.OtherInvestment:
          target = result.myFinances.subtype[assetType];
          break;
        case AssetType.Art:
        case AssetType.WineAndSpirits:
        case AssetType.OtherCollectables:
          target = result.myCollectables.subtype[assetType];
          break;
        case AssetType.Property:
          target = propertyResultMap;
          break;
        case AssetType.Belonging:
          target = result.myBelongings;
          break;
        default:
          throw new Error(`Unknown asset type ${assetType}`);
      }
      target.original.liabilities.value = totalInitialLiability;
      target.current.liabilities.value = totalCurrentLiability;

      // *** Assign liabilities to subtype ***
      if (v.subtypes) {
        const targetWithSubtype = target as NetWorthReportDetails &
          SubtypeDetails;
        Object.keys(v.subtypes).forEach((subtype) => {
          if (!targetWithSubtype.subtype[subtype])
            targetWithSubtype.subtype[subtype] =
              defaultNetWorthReportDetails(currency);
          targetWithSubtype.subtype[subtype]!.original.liabilities.value =
            this.toDecimalPlacesNumber(
              v.subtypes![subtype].totalInitialLiability
            );
          targetWithSubtype.subtype[subtype]!.current.liabilities.value =
            this.toDecimalPlacesNumber(
              v.subtypes![subtype].totalCurrentLiability
            );
        });
      }
    });

    // transfer properties from map to array
    result.myProperties = {
      original: propertyResultMap.original,
      current: propertyResultMap.current,
      netChange: propertyResultMap.netChange,
      properties: Object.entries(propertyResultMap.subtype).map(([id, v]) => ({
        name: ownedPropertyItems[id].name!,
        original: v.original,
        current: v.current,
        netChange: v.netChange,
      })),
    };

    // myFinances
    this.sumSubtypeDetailsToTarget(
      result.myFinances,
      Object.values(result.myFinances.subtype)
    );
    // myCollectables
    this.sumSubtypeDetailsToTarget(
      result.myCollectables,
      Object.values(result.myCollectables.subtype)
    );
    // total
    this.sumSubtypeDetailsToTarget(result.total, [
      result.myFinances,
      result.myCollectables,
      result.myProperties,
      result.myBelongings,
    ]);

    // *** Compute net value and change ***
    [
      // myFinances
      result.myFinances,
      ...Object.values(result.myFinances.subtype),
      // myCollectables
      result.myCollectables,
      ...Object.values(result.myCollectables.subtype),
      ...Object.values(result.myCollectables.subtype[AssetType.Art].subtype),
      ...Object.values(
        result.myCollectables.subtype[AssetType.WineAndSpirits].subtype
      ),
      ...Object.values(
        result.myCollectables.subtype[AssetType.OtherCollectables].subtype
      ),
      // myProperties
      result.myProperties,
      ...result.myProperties.properties,
      // myBelongings
      result.myBelongings,
      ...Object.values(result.myBelongings.subtype),
      // total
      result.total,
    ].map((v) => this.computeNetValueAndPercentageChange(v));

    return result;
  }
}
