import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M14.457%2010.843c.665%200%201.205.54%201.205%201.204%200%20.3%201.281%201.205%203.614%201.205v2.41c-1.387%200-2.618-.268-3.614-.706v4.32h-2.41v-4.32c-.996.438-2.227.706-3.614.706s-2.618-.268-3.614-.706v4.32h-2.41v-4.32c-.996.438-2.227.706-3.614.706v-2.41c2.332%200%203.614-.905%203.614-1.205a1.205%201.205%200%200%201%202.41%200c0%20.3%201.282%201.205%203.614%201.205s3.614-.905%203.614-1.205c0-.665.54-1.204%201.205-1.204Zm0-10.843c.665%200%201.205.54%201.205%201.205v7.228c0%20.666-.54%201.205-1.205%201.205H4.819c-.665%200-1.205-.54-1.205-1.205V1.205C3.614.539%204.154%200%204.82%200Zm-1.205%202.41H6.024v4.818h2.41l2.409-2.41%202.41%202.41V2.409Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ExhibitionIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 20 20'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M14.457 10.843c.665 0 1.205.54 1.205 1.204 0 .3 1.281 1.205 3.614 1.205v2.41c-1.387 0-2.618-.268-3.614-.706v4.32h-2.41v-4.32c-.996.438-2.227.706-3.614.706s-2.618-.268-3.614-.706v4.32h-2.41v-4.32c-.996.438-2.227.706-3.614.706v-2.41c2.332 0 3.614-.905 3.614-1.205a1.205 1.205 0 0 1 2.41 0c0 .3 1.282 1.205 3.614 1.205s3.614-.905 3.614-1.205c0-.665.54-1.204 1.205-1.204Zm0-10.843c.665 0 1.205.54 1.205 1.205v7.228c0 .666-.54 1.205-1.205 1.205H4.819c-.665 0-1.205-.54-1.205-1.205V1.205C3.614.539 4.154 0 4.82 0Zm-1.205 2.41H6.024v4.818h2.41l2.409-2.41 2.41 2.41V2.409Z'
        }
      />
    </svg>
  )
}
