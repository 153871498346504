import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2017%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M0%2012.78v3.42h3.42l9.9-9.99L9.9%202.79%200%2012.78zM15.93%203.6a.87.87%200%200%200%200-1.26L13.86.27a.87.87%200%200%200-1.26%200l-1.62%201.62%203.42%203.42%201.53-1.71zM8.1%2014.4l-1.8%201.8H18v-1.8H8.1z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function EditIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 18 17'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M0 12.78v3.42h3.42l9.9-9.99L9.9 2.79 0 12.78zM15.93 3.6a.87.87 0 0 0 0-1.26L13.86.27a.87.87 0 0 0-1.26 0l-1.62 1.62 3.42 3.42 1.53-1.71zM8.1 14.4l-1.8 1.8H18v-1.8H8.1z'
        }
      />
    </svg>
  )
}
