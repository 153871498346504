import '@/utils/i18n'
import '@/constants/globals.css'

import { Fragment } from 'react'
import type { NextComponentType } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Roboto } from 'next/font/google'
import Head from 'next/head'
import { LazyMotion } from 'framer-motion'
import { combineProviders } from 'react-combine-provider'
import TagManager from 'react-gtm-module'
import { SWRConfig } from 'swr'

import { buildId, endpoint, environment, isMatchedEnv } from '@/constants/site'
import { usePageInfo } from '@/hooks/usePageInfo'
import { MaintenanceProvider } from '@/store/maintenance'
import { MapProvider } from '@/store/map'
import { MaskProvider } from '@/store/mask'
import { TooltipProvider } from '@/components/base'
import LanguageSync from '@/components/LanguageSync'
import Layout from '@/components/Layout'
import { ReCaptcha } from '@/components/ReCaptcha'
import { Toaster } from '@/components/Toaster'
import { RouterTracking, Tracking } from '@/components/Tracking'

// FIXME: complex import cycle, it must be the last import
import { trackSWRMiddleware } from '../hooks/useTracking'

console.log(JSON.stringify({ environment, endpoint, buildId }, null, 2))

if (isMatchedEnv(['staging', 'prod']) && typeof window !== 'undefined') {
  TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID! })
}

const roboto = Roboto({
  subsets: ['latin'],
  weight: ['100', '300', '400', '500', '700']
})

const CombineProvider = combineProviders([MaskProvider, MapProvider, TooltipProvider, ReCaptcha, MaintenanceProvider])

const ProtectedPage = dynamic(() => import('@/components/ProtectedPage').then(({ ProtectedPage }) => ProtectedPage), {
  ssr: false
})

const loadFeatures = () => import('@/constants/features').then((res) => res.default)

export default function App({ Component, pageProps }: AppProps) {
  const { title, content } = usePageInfo()
  const { isAuthRoute } = Component as NextComponentType & { isAuthRoute: boolean }
  const Protector = isAuthRoute ? Fragment : ProtectedPage

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name={'description'} content={content} />
        <meta name={'viewport'} content={'width=device-width, initial-scale=1'} />
      </Head>

      {/** @see https://nextjs.org/docs/pages/building-your-application/optimizing/fonts#apply-the-font-in-head */}
      <div className={'hidden'}>
        <style jsx={true} global={true}>{`
          :root {
            --roboto: ${roboto.style.fontFamily};
          }
        `}</style>
      </div>

      <LazyMotion features={loadFeatures}>
        <CombineProvider>
          <Protector>
            <Layout>
              <SWRConfig value={{ use: [trackSWRMiddleware], keepPreviousData: true }}>
                <Tracking name={'App'} trackChildren={false}>
                  <Component {...pageProps} />
                </Tracking>
              </SWRConfig>
            </Layout>
          </Protector>
          <LanguageSync />
        </CombineProvider>
      </LazyMotion>

      <Toaster />
      <RouterTracking />
    </>
  )
}
