import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2039%2031%22%3E%3Cpath%20d%3D%22M9.648%2022.232V30.8H1.115v-8.567h8.533Zm30.027%200V30.8H12.359v-8.567h27.316ZM9.648%2011.116v8.567H1.115v-8.567h8.533Zm30.027%200v8.567H12.359v-8.567h27.316ZM9.648%200v8.567H1.115V0h8.533Zm30.027%200v8.567H12.359V0h27.316Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ListViewIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 39 31'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M9.648 22.232V30.8H1.115v-8.567h8.533Zm30.027 0V30.8H12.359v-8.567h27.316ZM9.648 11.116v8.567H1.115v-8.567h8.533Zm30.027 0v8.567H12.359v-8.567h27.316ZM9.648 0v8.567H1.115V0h8.533Zm30.027 0v8.567H12.359V0h27.316Z'
        }
      />
    </svg>
  )
}
