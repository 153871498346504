import { Sequence, getSeqDocPath } from "./aggregate";
import { Refs } from "../refs";
import {
  buildAssociatedLiability,
  CashAndBankingSummary,
  CashAndBankingSummaryAggregate,
  CategoriesAssociatedLiability,
  SupportLiabilityType,
} from "./cashAndBankingSummary";
import {
  OtherInvestmentSummary,
  OtherInvestmentSummaryAggregate,
} from "./otherInvestmentSummary";
import {
  InsuranceSummary,
  InsuranceSummaryAggregate,
} from "./insuranceSummary";
import { PropertySummary, PropertySummaryAggregate } from "./propertySummary";
import { RelationsOfAsset } from "./relations";
import { ArtSummary, ArtSummaryAggregate } from "./artSummary";
import {
  BelongingSummary,
  BelongingSummaryAggregate,
} from "./belongingSummary";
import { WineSummary, WineSummaryAggregate } from "./wineSummary";
import { AssetType } from "./enums";
import { resolveObject } from "../utils";
import { Optional, TargetCurrencyExchangeRateDataMap } from "./common";
import { GlobalDashboard } from "./globalDashboard";
import { PriceSource } from "../database/priceSource";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
} from "../../coreFirebase";
import { Delegate, PermissionLevel } from "./delegates";
import { PermissionCategory } from "../refPaths";
import { SummaryLoader } from "./summaryLoader";
import {
  getAssociatedLiabilityAssetsFromAccounts,
  getLiabilityAssetsMap,
} from "../database/cashAndBanking";
import {
  calculatePortfolioBaseOwnedValue,
  getAllRawPortfolio,
} from "../database/traditionalInvestments";
import {
  calculateCryptocurrencyBaseOwnedValue,
  getAllRawCryptocurrency,
} from "../database/cryptocurrencies";

export type SyncedAssetTypes =
  | AssetType.CashAndBanking
  | AssetType.OtherInvestment
  | AssetType.Insurance
  | AssetType.Property
  | AssetType.Art
  | AssetType.OtherCollectables
  | AssetType.Belonging
  | AssetType.WineAndSpirits;
const SyncedAssetTypesArray: SyncedAssetTypes[] = [
  AssetType.CashAndBanking,
  AssetType.OtherInvestment,
  AssetType.Insurance,
  AssetType.Property,
  AssetType.Art,
  AssetType.OtherCollectables,
  AssetType.Belonging,
  AssetType.WineAndSpirits,
];

function checkPermission(
  permissions: Optional<Delegate.EncryptedDelegator>,
  category: PermissionCategory
): { read: boolean; write: boolean } {
  if (!permissions) {
    return { read: true, write: true };
  } else {
    const permission = permissions[category];
    return {
      read: permission >= PermissionLevel.View,
      write: permission > PermissionLevel.View,
    };
  }
}

export type SummaryPermissions = {
  [key in PermissionCategory]: {
    read: boolean;
    write: boolean;
  };
};

function checkReadSummaryPermissionChanged(
  a: SummaryPermissions,
  b: SummaryPermissions
) {
  return Object.keys(a).some((key) => {
    const typedKey = key as PermissionCategory;
    return a[typedKey].read !== b[typedKey].read;
  });
}

export class SummaryManager {
  currentUserId: Optional<string>;
  isDelegate: boolean = false;
  permissions!: SummaryPermissions;
  relationCollectionRef: Optional<CollectionReference<RelationsOfAsset>>;

  [AssetType.CashAndBanking]: Optional<
    SummaryLoader<CashAndBankingSummary, CashAndBankingSummaryAggregate>
  >;
  [AssetType.OtherInvestment]: Optional<
    SummaryLoader<OtherInvestmentSummary, OtherInvestmentSummaryAggregate>
  >;
  [AssetType.Insurance]: Optional<
    SummaryLoader<InsuranceSummary, InsuranceSummaryAggregate>
  >;
  [AssetType.Property]: Optional<
    SummaryLoader<PropertySummary, PropertySummaryAggregate>
  >;

  [AssetType.Art]: Optional<SummaryLoader<ArtSummary, ArtSummaryAggregate>>;
  [AssetType.OtherCollectables]: Optional<
    SummaryLoader<BelongingSummary, BelongingSummaryAggregate>
  >;
  [AssetType.Belonging]: Optional<
    SummaryLoader<BelongingSummary, BelongingSummaryAggregate>
  >;
  [AssetType.WineAndSpirits]: Optional<
    SummaryLoader<WineSummary, WineSummaryAggregate>
  >;

  get<T extends SyncedAssetTypes>(assetType: T) {
    if (!this[assetType]) {
      throw new Error(
        `SummaryManager does not have permission of ${assetType}`
      );
    }
    return this[assetType] as NonNullable<SummaryManager[T]>;
  }

  setSummaryLoader(
    refs: Refs,
    permissions: Optional<Delegate.EncryptedDelegator> = undefined
  ): void {
    const convertedPermission = Object.fromEntries(
      permissions
        ? Object.values(PermissionCategory).map((category) => [
            category as PermissionCategory,
            checkPermission(permissions, category),
          ])
        : Object.values(PermissionCategory).map((category) => [
            category as PermissionCategory,
            { read: true, write: true },
          ])
    ) as SummaryPermissions;
    this.isDelegate = permissions !== undefined;

    this.doSetSummaryLoader(refs, convertedPermission);
  }

  resetSummaryLoader(refs: Refs): void {
    const convertedPermission = this.permissions
      ? this.permissions
      : (Object.fromEntries(
          Object.values(PermissionCategory).map((category) => [
            category as PermissionCategory,
            { read: true, write: true },
          ])
        ) as SummaryPermissions);
    this.doSetSummaryLoader(refs, convertedPermission, true);
  }

  private doSetSummaryLoader(
    refs: Refs,
    convertedPermission: SummaryPermissions,
    force: boolean = false
  ) {
    //#NOTE don't fetch if input user is current user and the permission is not changed
    if (
      !force &&
      this.currentUserId == refs.userId &&
      !checkReadSummaryPermissionChanged(this.permissions, convertedPermission)
    ) {
      console.log("Skip setSummaryLoader. No user and permission changes.");
      return;
    }
    this.unsubscribe();

    const maxReadPermission = Object.values(convertedPermission).every(
      (permission) => permission.read === true
    );

    // * wipe out all previous data
    this[AssetType.CashAndBanking] = undefined;
    this[AssetType.OtherInvestment] = undefined;
    this[AssetType.Insurance] = undefined;
    this[AssetType.Property] = undefined;
    this[AssetType.Art] = undefined;
    this[AssetType.OtherCollectables] = undefined;
    this[AssetType.Belonging] = undefined;
    this[AssetType.WineAndSpirits] = undefined;

    this.currentUserId = refs.userId;
    this.permissions = convertedPermission;
    this.relationCollectionRef = refs.Relations;

    if (
      maxReadPermission ||
      this.permissions[PermissionCategory.Finance].read
    ) {
      this[AssetType.CashAndBanking] = new SummaryLoader(
        refs,
        AssetType.CashAndBanking,
        this.permissions[PermissionCategory.Finance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.CashAndBanking)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.CashAndBankingSummary,
          aggregateConstructor: CashAndBankingSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return CashAndBankingSummary.newAggregateRoot(
              transaction,
              refs.CashAndBankingSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Finance].read) {
      this[AssetType.OtherInvestment] = new SummaryLoader(
        refs,
        AssetType.OtherInvestment,
        this.permissions[PermissionCategory.Finance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.OtherInvestment)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.OtherInvestmentSummary,
          aggregateConstructor: OtherInvestmentSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return OtherInvestmentSummary.newAggregateRoot(
              transaction,
              refs.OtherInvestmentSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Insurance].read) {
      this[AssetType.Insurance] = new SummaryLoader(
        refs,
        AssetType.Insurance,
        this.permissions[PermissionCategory.Insurance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Insurance)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.InsuranceSummary,
          aggregateConstructor: InsuranceSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return InsuranceSummary.newAggregateRoot(
              transaction,
              refs.InsuranceSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Property].read) {
      this[AssetType.Property] = new SummaryLoader(
        refs,
        AssetType.Property,
        this.permissions[PermissionCategory.Property].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Property)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.PropertySummary,
          aggregateConstructor: PropertySummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return PropertySummary.newAggregateRoot(
              transaction,
              refs.PropertySummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Art].read) {
      this[AssetType.Art] = new SummaryLoader(
        refs,
        AssetType.Art,
        this.permissions[PermissionCategory.Art].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Art)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.ArtSummary,
          aggregateConstructor: ArtSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return ArtSummary.newAggregateRoot(transaction, refs.ArtSummary);
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.OtherCollectables].read) {
      this[AssetType.OtherCollectables] = new SummaryLoader(
        refs,
        AssetType.OtherCollectables,
        this.permissions[PermissionCategory.OtherCollectables].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.OtherCollectables)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.OtherCollectableSummary,
          aggregateConstructor: BelongingSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return BelongingSummary.newAggregateRoot(
              transaction,
              refs.OtherCollectableSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Belonging].read) {
      this[AssetType.Belonging] = new SummaryLoader(
        refs,
        AssetType.Belonging,
        this.permissions[PermissionCategory.Belonging].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Belonging)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.BelongingSummary,
          aggregateConstructor: BelongingSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return BelongingSummary.newAggregateRoot(
              transaction,
              refs.BelongingSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Wine].read) {
      this[AssetType.WineAndSpirits] = new SummaryLoader(
        refs,
        AssetType.WineAndSpirits,
        this.permissions[PermissionCategory.Wine].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.WineAndSpirits)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.WineSummary,
          aggregateConstructor: WineSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return WineSummary.newAggregateRoot(transaction, refs.WineSummary);
          },
        }
      );
    }
  }

  unsubscribe() {
    SyncedAssetTypesArray.forEach((assetType) => {
      const summaryLoader = this[assetType];
      if (summaryLoader !== undefined) summaryLoader.unsubscribe();
    });
  }

  async load(assetTypes: SyncedAssetTypes[]) {
    const deDuped = Array.from(new Set(assetTypes));
    return CoreFirestore.runTransaction(async (transaction) => {
      const promiseObj = Object.fromEntries(
        deDuped
          .filter((assetType) => this[assetType] !== undefined)
          .map((assetType) => [assetType, this[assetType]!.load(transaction)])
      );
      return await resolveObject(promiseObj);
    });
  }

  async syncAndGetData(assetTypes: SyncedAssetTypes[]) {
    const deDuped = Array.from(new Set(assetTypes));
    const promiseObj = Object.fromEntries(
      deDuped
        .filter((assetType) => this[assetType] !== undefined)
        .map((assetType) => [assetType, this[assetType]!.syncAndGetData()])
    );
    return await resolveObject(promiseObj);
  }

  private async tryGetAssociatedLiability(
    refs: Refs,
    exchangeRates: TargetCurrencyExchangeRateDataMap
  ): Promise<CategoriesAssociatedLiability> {
    const liabilityAssets = await getAssociatedLiabilityAssetsFromAccounts(
      refs
    );
    const assetTypes: SupportLiabilityType[] = [];
    if (this.permissions[PermissionCategory.Property].read) {
      assetTypes.push(AssetType.Property);
    }
    if (this.permissions[PermissionCategory.Art].read) {
      assetTypes.push(AssetType.Art);
    }
    if (this.permissions[PermissionCategory.Wine].read) {
      assetTypes.push(AssetType.WineAndSpirits);
    }
    if (this.permissions[PermissionCategory.OtherCollectables].read) {
      assetTypes.push(AssetType.OtherCollectables);
    }
    if (this.permissions[PermissionCategory.Belonging].read) {
      assetTypes.push(AssetType.Belonging);
    }
    const filteredLiabilityAssets = liabilityAssets.filter((asset) =>
      assetTypes.includes(asset.assetType)
    );
    const liabilityAssetsMap = await getLiabilityAssetsMap(
      refs,
      filteredLiabilityAssets,
      {
        type: "keepAssetTypes",
        data: assetTypes,
      }
    );

    return buildAssociatedLiability(
      exchangeRates,
      Object.values(liabilityAssetsMap).flat()
    );
  }

  /*
   * The liabilities under myProperties, myCollectables, myBelongings
   * are associated liabilities from loan or mortgage accounts.
   * Their netValue do not include the associated liabilities.
   */
  async getGlobalDashboard(
    refs: Refs,
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    priceSource: PriceSource
  ) {
    const summary = await this.syncAndGetData(SyncedAssetTypesArray);
    const financeRead = this.permissions[PermissionCategory.Finance].read;
    const traditionalInvestmentBaseOwnedValue = financeRead
      ? await getAllRawPortfolio(refs).then((v) =>
          calculatePortfolioBaseOwnedValue(v, exchangeRate, priceSource)
        )
      : undefined;
    const cryptocurrencyBaseOwnedValue = financeRead
      ? await getAllRawCryptocurrency(refs).then((v) =>
          calculateCryptocurrencyBaseOwnedValue(v, exchangeRate, priceSource)
        )
      : undefined;

    return await GlobalDashboard.fromSummaries(
      exchangeRate,
      summary[AssetType.CashAndBanking]
        ?.summary as Optional<CashAndBankingSummary>,
      traditionalInvestmentBaseOwnedValue,
      summary[AssetType.OtherInvestment]
        ?.summary as Optional<OtherInvestmentSummary>,
      cryptocurrencyBaseOwnedValue,
      summary[AssetType.Insurance]?.summary as Optional<InsuranceSummary>,
      summary[AssetType.Property]?.summary as Optional<PropertySummary>,
      summary[AssetType.Art]?.summary as Optional<ArtSummary>,
      summary[AssetType.WineAndSpirits]?.summary as Optional<WineSummary>,
      summary[AssetType.OtherCollectables]
        ?.summary as Optional<BelongingSummary>,
      summary[AssetType.Belonging]?.summary as Optional<BelongingSummary>,
      this[AssetType.CashAndBanking]
        ? await this.tryGetAssociatedLiability(refs, exchangeRate)
        : undefined,
      this.permissions
    );
  }
}

export interface ItemRef {
  assetId: string;
  assetType: SyncedAssetTypes;
  subtype?: string;
  subId?: string;
}
export function itemRefToId(input: ItemRef) {
  return input.subId ? `${input.assetId}_${input.subId}` : input.assetId;
}
