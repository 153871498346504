import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2038%2031%22%3E%3Cpath%20d%3D%22M12.042%2022.232V30.8H.96v-8.567h11.083Zm12.667%200V30.8H13.626v-8.567h11.083Zm12.667%200V30.8H26.292v-8.567h11.084ZM12.042%2011.116v8.567H.96v-8.567h11.083Zm12.667%200v8.567H13.626v-8.567h11.083Zm12.667%200v8.567H26.292v-8.567h11.084ZM12.042%200v8.567H.96V0h11.083ZM24.71%200v8.567H13.626V0h11.083Zm12.667%200v8.567H26.292V0h11.084Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CardViewIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 38 31'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M12.042 22.232V30.8H.96v-8.567h11.083Zm12.667 0V30.8H13.626v-8.567h11.083Zm12.667 0V30.8H26.292v-8.567h11.084ZM12.042 11.116v8.567H.96v-8.567h11.083Zm12.667 0v8.567H13.626v-8.567h11.083Zm12.667 0v8.567H26.292v-8.567h11.084ZM12.042 0v8.567H.96V0h11.083ZM24.71 0v8.567H13.626V0h11.083Zm12.667 0v8.567H26.292V0h11.084Z'
        }
      />
    </svg>
  )
}
