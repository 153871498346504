import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%209%2015%22%3E%3Cpath%20d%3D%22M0%207v4.571C0%2013.286%201.688%2015%203.375%2015h2.25C7.313%2015%209%2013.286%209%2011.571V7H0Zm3.375%202.857c0-.32.248-.571.563-.571h1.124a.56.56%200%200%201%20.563.571v2.286a.56.56%200%200%201-.563.571H3.938a.561.561%200%200%201-.562-.571V9.857ZM1%203.6V6h1.167V4.08c0-1.596%201.05-2.88%202.333-2.88%201.283%200%202.333%201.284%202.333%202.88V6H8V3.6C8%201.608%206.437%200%204.5%200S1%201.608%201%203.6Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function SecurityIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 9 15'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M0 7v4.571C0 13.286 1.688 15 3.375 15h2.25C7.313 15 9 13.286 9 11.571V7H0Zm3.375 2.857c0-.32.248-.571.563-.571h1.124a.56.56 0 0 1 .563.571v2.286a.56.56 0 0 1-.563.571H3.938a.561.561 0 0 1-.562-.571V9.857ZM1 3.6V6h1.167V4.08c0-1.596 1.05-2.88 2.333-2.88 1.283 0 2.333 1.284 2.333 2.88V6H8V3.6C8 1.608 6.437 0 4.5 0S1 1.608 1 3.6Z'
        }
      />
    </svg>
  )
}
