import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2%204.364%2012%200l10%204.364v6.545C22%2016.964%2017.733%2022.625%2012%2024%206.267%2022.625%202%2016.964%202%2010.91zm10.002%201.09c.806%200%201.46.646%201.46%201.433v3.683h3.746c.806%200%201.459.646%201.459%201.432%200%20.791-.658%201.432-1.459%201.432h-3.747v3.68c0%20.79-.658%201.431-1.459%201.431-.805%200-1.458-.645-1.458-1.432v-3.678H6.792c-.8%200-1.459-.646-1.459-1.433%200-.79.658-1.432%201.459-1.432h3.752V6.887c-.005-.787.653-1.432%201.458-1.432%22%20fill%3D%22%23BCBEC9%22%2F%3E%3C%2Fsvg%3E)
 */
export default function InsuranceAssetIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg width={size} height={size} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M2 4.364 12 0l10 4.364v6.545C22 16.964 17.733 22.625 12 24 6.267 22.625 2 16.964 2 10.91zm10.002 1.09c.806 0 1.46.646 1.46 1.433v3.683h3.746c.806 0 1.459.646 1.459 1.432 0 .791-.658 1.432-1.459 1.432h-3.747v3.68c0 .79-.658 1.431-1.459 1.431-.805 0-1.458-.645-1.458-1.432v-3.678H6.792c-.8 0-1.459-.646-1.459-1.433 0-.79.658-1.432 1.459-1.432h3.752V6.887c-.005-.787.653-1.432 1.458-1.432'
        }
        fill={'#BCBEC9'}
      />
    </svg>
  )
}
