import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2012%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5.175%208.977%208%206.152l-.725-.725-2.11%202.115-1.05-1.05-.715.71m-.15%203.275q-1.14%200-1.945-.785A2.6%202.6%200%200%201%20.5%207.767q0-.975.585-1.74.59-.765%201.54-.975a3.4%203.4%200%200%201%201.25-1.86A3.42%203.42%200%200%201%206%202.477q1.465%200%202.48%201.02Q9.5%204.51%209.5%205.977a2.16%202.16%200%200%201%201.43.75q.57.639.57%201.5a2.17%202.17%200%200%201-.655%201.595%202.17%202.17%200%200%201-1.595.655z%22%20fill%3D%22%23ddd%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CloudCheckIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 12 13'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M5.175 8.977 8 6.152l-.725-.725-2.11 2.115-1.05-1.05-.715.71m-.15 3.275q-1.14 0-1.945-.785A2.6 2.6 0 0 1 .5 7.767q0-.975.585-1.74.59-.765 1.54-.975a3.4 3.4 0 0 1 1.25-1.86A3.42 3.42 0 0 1 6 2.477q1.465 0 2.48 1.02Q9.5 4.51 9.5 5.977a2.16 2.16 0 0 1 1.43.75q.57.639.57 1.5a2.17 2.17 0 0 1-.655 1.595 2.17 2.17 0 0 1-1.595.655z'
        }
      />
    </svg>
  )
}
