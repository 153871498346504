import { DocumentReference, Transaction } from "../../coreFirebase";
import { Encrypted } from "../database/encryption";
import {
  EncryptableObject,
  IsAmount,
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
  IsShortString,
  NotEncrypted,
} from "../decorators";
import { subDecimal } from "../utils";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import { BelongingType, OtherCollectableType } from "./belongings";
import {
  Amount,
  AssetType,
  Currency,
  MultiCurrencyAmount,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { EventEnvelope } from "./event";
import * as Property from "./properties";
import { Event as PropertyEvent } from "./properties/command";
import { Configuration } from "./properties/configuration";
import { ContactLocation } from "./properties/contactLocation";
import {
  PropertySummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<PropertyEvent>;
export interface PropertySummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.PropertySummary, 3>;
  id: AssetType.Property;
  multiCurrencyValue: MultiCurrencyAmount;
}
//#TODO: Need further check if the decorators are used properly
export class SummaryLocationItem {
  @NotEncrypted
  @IsShortString()
  name!: string;
  @NotEncrypted
  @IsEnum(Property.Type)
  subtype!: Property.Type;
  @NotEncrypted
  @IsEnum(Property.OwnershipType)
  ownershipType!: Property.OwnershipType;
  @NotEncrypted
  @IsAmount()
  value!: Amount;
  @NotEncrypted
  @IsNumber()
  ownedPercentage!: number;
  @NotEncrypted
  @IsOptional()
  mainImage?: string;
  @EncryptableObject(ContactLocation)
  location!: ContactLocation;
  @NotEncrypted
  @IsOptional()
  configuration?: ConfigurationSummary;
  @NotEncrypted
  @IsBoolean()
  sold!: boolean;
  @NotEncrypted
  @IsBoolean()
  archived!: boolean;
  @NotEncrypted
  @IsAmount()
  purchasePrice!: Amount;
}

export interface ConfigurationSummary {
  bedroom: number;
  bathroom: number;
  otherRoom: number;
  carPark: number;
}
namespace ConfigurationSummary {
  export function fromEncryptedConfiguration(
    input: Encrypted<Configuration>
  ): ConfigurationSummary {
    return {
      bedroom: input.bedroom!.length,
      bathroom: input.bathroom!.length,
      otherRoom: input.otherRoom!.length,
      carPark: input.carPark!.length,
    };
  }
}

// TODO move to property type after property refactor branch is merged
export interface BreakdownItem {
  label: AssetType | OtherCollectableType | BelongingType | string;
  value: Amount;
  percentage: number;
}

// TODO move to property type after property refactor branch is merged
export interface PropertyAssetsBreakdown {
  assets: BreakdownItem[];
  liabilities: BreakdownItem[];
}

export namespace PropertySummary {
  export function assureVersion(
    input: PropertySummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      PropertySummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<PropertySummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new PropertySummaryAggregate(summary));
  }

  export function defaultValue(): PropertySummary {
    const defaultSummary: PropertySummary = {
      "@type": PropertySummaryTypeVersion,
      id: AssetType.Property,
      multiCurrencyValue: {},
      version: 0,
    };
    return defaultSummary;
  }

  export function toDisplay(
    input: PropertySummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    const totalAssets = MultiCurrencyAmount.calculateTargetCurrencyAmount(
      input.multiCurrencyValue,
      exchangeRate
    );

    const result: Display = {
      assets: { ...totalAssets },
      liabilities: {
        currency,
        value: 0,
      },
      netValue: { ...totalAssets },
    };
    return result;
  }

  export type RelatedUpdates = never;
}

export class PropertySummaryAggregate extends AggregateBase<
  PropertySummary,
  never,
  PropertyEvent
> {
  state: PropertySummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: PropertySummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    if (event.data.summaryData === undefined) return this;

    //find out data changes
    for (const summaryData of event.data.summaryData) {
      let dataChanged = false;

      const valueChange: MultiCurrencyAmount = {
        ...summaryData.currOwnedValue,
      };
      const allCurrencies = new Set([
        ...Object.keys(summaryData.prevOwnedValue),
        ...Object.keys(summaryData.currOwnedValue),
      ]);

      for (const currency of allCurrencies) {
        const prevValue = summaryData.prevOwnedValue[currency as Currency] || 0;
        const currValue = summaryData.currOwnedValue[currency as Currency] || 0;
        valueChange[currency as Currency] = subDecimal(currValue, prevValue);
        dataChanged = dataChanged || valueChange[currency as Currency] != 0;
        // remove currency if value is 0 to keep changes clean
        if (valueChange[currency as Currency] == 0)
          delete valueChange[currency as Currency];
      }

      if (dataChanged) {
        MultiCurrencyAmount.add(
          this.state.multiCurrencyValue,
          ...MultiCurrencyAmount.toAmounts(valueChange)
        );
      }
    }

    return this;
  }
}
