import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M17.972%203.054%205.606%208H2.25C1.01%208%200%209.01%200%2010.25V13c0%201.178.925%202%202.25%202h.85l.908%206.356A.75.75%200%200%200%204.75%2022h3a.753.753%200%200%200%20.746-.834l-.59-5.246%2010.065%204.026a.756.756%200%200%200%20.7-.075.75.75%200%200%200%20.329-.621V3.75a.75.75%200%200%200-1.028-.696zm3.247%206.282%202.5-2a.751.751%200%200%200-.938-1.172l-2.5%202a.751.751%200%200%200%20.938%201.172zm0%205.328a.751.751%200%200%200-.938%201.172l2.5%202a.753.753%200%200%200%201.055-.117.751.751%200%200%200-.117-1.055zm2.031-3.414h-2.5a.75.75%200%200%200%200%201.5h2.5a.75.75%200%200%200%200-1.5z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function MegaphoneIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 24 24'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M17.972 3.054 5.606 8H2.25C1.01 8 0 9.01 0 10.25V13c0 1.178.925 2 2.25 2h.85l.908 6.356A.75.75 0 0 0 4.75 22h3a.753.753 0 0 0 .746-.834l-.59-5.246 10.065 4.026a.756.756 0 0 0 .7-.075.75.75 0 0 0 .329-.621V3.75a.75.75 0 0 0-1.028-.696zM21.219 9.336l2.5-2a.751.751 0 0 0-.938-1.172l-2.5 2a.751.751 0 0 0 .938 1.172zM21.219 14.664a.751.751 0 0 0-.938 1.172l2.5 2a.753.753 0 0 0 1.055-.117.751.751 0 0 0-.117-1.055zM23.25 11.25h-2.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5z'
        }
      />
    </svg>
  )
}
