import { subDecimal } from "../utils";
import { Currency, MultiCurrencyAmount } from "./common";
import { Asset } from "./common/asset";
import { EventBase, EventEnvelope, SummaryData, TagPair } from "./event";

export type SubCategoryItem = {
  label: string;
  assetNumber: number;
  itemNumber: number;
  multiCurrencyValue: MultiCurrencyAmount;
};
export type SubCategoryItemInfoKeys = Exclude<keyof SubCategoryItem, "label">;

export namespace SubCategoryItem {
  export function newWithLabel(label: string): SubCategoryItem {
    return {
      label,
      assetNumber: 0,
      itemNumber: 0,
      multiCurrencyValue: {},
    };
  }

  export function isEmpty(item: SubCategoryItem): boolean {
    let empty = item.assetNumber == 0 && item.itemNumber == 0;
    let currencyEntry = Object.entries(item.multiCurrencyValue);
    if (currencyEntry.length == 0) return empty;
    else {
      return empty && currencyEntry.every(([_, value]) => value == 0);
    }
  }
}

interface FieldUpdater {
  key: SubCategoryItemInfoKeys;
  getChange: (summaryData: SummaryData) => {
    change: SubCategoryItem[SubCategoryItemInfoKeys];
    dataChanged: boolean;
  };
  applyAdd: (data: SubCategoryItem, itemChange: SubCategoryItem) => void;
  applySubtract: (data: SubCategoryItem, itemChange: SubCategoryItem) => void;
}

export class ItemNumber implements FieldUpdater {
  key: "itemNumber" = "itemNumber";
  getChange(summaryData: SummaryData): {
    change: SubCategoryItem[SubCategoryItemInfoKeys];
    dataChanged: boolean;
  } {
    const change = summaryData.currItemNumber - summaryData.prevItemNumber;
    return { change, dataChanged: change != 0 };
  }
  applyAdd(data: SubCategoryItem, itemChange: SubCategoryItem): void {
    data.itemNumber += itemChange.itemNumber;
  }
  applySubtract(data: SubCategoryItem, itemChange: SubCategoryItem): void {
    data.itemNumber -= itemChange.itemNumber;
  }
}
export class AssetNumber implements FieldUpdater {
  key: "assetNumber" = "assetNumber";
  getChange(summaryData: SummaryData): {
    change: SubCategoryItem[SubCategoryItemInfoKeys];
    dataChanged: boolean;
  } {
    const change = summaryData.currAssetNumber - summaryData.prevAssetNumber;
    return { change, dataChanged: change != 0 };
  }
  applyAdd(data: SubCategoryItem, itemChange: SubCategoryItem): void {
    data.assetNumber += itemChange.assetNumber;
  }
  applySubtract(data: SubCategoryItem, itemChange: SubCategoryItem): void {
    data.assetNumber -= itemChange.assetNumber;
  }
}

export class MultiCurrencyValue implements FieldUpdater {
  key: "multiCurrencyValue" = "multiCurrencyValue";
  getChange(summaryData: SummaryData): {
    change: SubCategoryItem[SubCategoryItemInfoKeys];
    dataChanged: boolean;
  } {
    let dataChanged = false;
    const valueChange: MultiCurrencyAmount = {
      ...summaryData.currOwnedValue,
    };
    const allCurrencies = new Set([
      ...Object.keys(summaryData.prevOwnedValue),
      ...Object.keys(summaryData.currOwnedValue),
    ]);

    for (const currency of allCurrencies) {
      const prevValue = summaryData.prevOwnedValue[currency as Currency] || 0;
      const currValue = summaryData.currOwnedValue[currency as Currency] || 0;
      valueChange[currency as Currency] = subDecimal(currValue, prevValue);
      dataChanged = dataChanged || valueChange[currency as Currency] != 0;
      // remove currency if value is 0 to keep changes clean
      if (valueChange[currency as Currency] == 0)
        delete valueChange[currency as Currency];
    }
    return { change: valueChange, dataChanged };
  }
  applyAdd(data: SubCategoryItem, itemChange: SubCategoryItem): void {
    MultiCurrencyAmount.add(
      data.multiCurrencyValue,
      ...MultiCurrencyAmount.toAmounts(itemChange.multiCurrencyValue)
    );
  }
  applySubtract(data: SubCategoryItem, itemChange: SubCategoryItem): void {
    MultiCurrencyAmount.subtract(
      data.multiCurrencyValue,
      ...MultiCurrencyAmount.toAmounts(itemChange.multiCurrencyValue)
    );
  }
}

export function buildUpdaterMap(...updaters: FieldUpdater[]): UpdaterMap {
  const map: UpdaterMap = {};
  updaters.forEach((updater) => {
    map[updater.key] = updater;
  });
  return map;
}
type TagFieldUpdaterMap<Tag extends string> = {
  [key in Tag]: FieldUpdater[];
};

type UpdaterMap = {
  [key in SubCategoryItemInfoKeys]?: FieldUpdater;
};
type Change = {
  [key in SubCategoryItemInfoKeys]?: SubCategoryItem[SubCategoryItemInfoKeys];
};

export namespace SummaryUtils {
  export function applySummaryData<
    Event extends EventEnvelope<EventBase>,
    Tag extends string, // summary tag enum
    SharedSummary extends { [label in Tag]: SubCategoryItem[] }
  >(
    event: Event,
    summary: SharedSummary,
    tagUpdaterMap: TagFieldUpdaterMap<Tag>, //map of updaters required for each tag
    allUpdater: UpdaterMap //union of updaters used in tagUpdaterMap
  ) {
    if (event.data.summaryData === undefined) return;

    //find out data changes
    for (const summaryData of event.data.summaryData) {
      const {
        changes,
        dataChanged,
      }: {
        changes: Change;
        dataChanged: boolean;
      } = Object.entries(allUpdater).reduce(
        (acc, [key, updater]) => {
          if (!updater) return acc;
          const { change, dataChanged } = updater.getChange(summaryData);
          acc.changes[key as SubCategoryItemInfoKeys] = change;
          acc.dataChanged = acc.dataChanged || dataChanged;
          return acc;
        },
        { changes: {}, dataChanged: false } as {
          changes: Change;
          dataChanged: boolean;
        }
      );

      const removeTags: TagPair<Tag>[] = [];
      const addTags: TagPair<Tag>[] = [];
      //common tags between prev and curr, need update if data changed
      const updateTags: TagPair<Tag>[] = [];

      if (summaryData.prevTags) {
        summaryData.prevTags.forEach((t) => {
          const tag = t as TagPair<Tag>;
          const found = summaryData.currTags.find(
            (t) => t.key == tag.key && t.val == tag.val
          );
          if (!found) removeTags.push(tag);
          else updateTags.push(tag);
        });
      }
      summaryData.currTags.forEach((t) => {
        const tag = t as TagPair<Tag>;
        if (
          !summaryData.prevTags?.find(
            (t) => t.key == tag.key && t.val == tag.val
          )
        )
          addTags.push(tag);
      });

      //remove old data from old tags
      if (removeTags.length > 0) {
        let itemToRemove = {
          label: "",
          assetNumber: summaryData.prevAssetNumber,
          itemNumber: summaryData.prevItemNumber,
          multiCurrencyValue: { ...summaryData.prevOwnedValue },
        };
        subtractSubCategoryItem(
          summary,
          removeTags,
          tagUpdaterMap,
          itemToRemove
        );
      }
      //add new data to new tags
      if (addTags.length > 0) {
        let itemToAdd = {
          label: "",
          assetNumber: summaryData.currAssetNumber,
          itemNumber: summaryData.currItemNumber,
          multiCurrencyValue: { ...summaryData.currOwnedValue },
        };
        addSubCategoryItem(summary, addTags, tagUpdaterMap, itemToAdd);
      }
      //update if data changed
      if (updateTags.length > 0 && dataChanged) {
        let itemToUpdate: SubCategoryItem = {
          label: "",
          assetNumber: (changes.assetNumber as number) || 0,
          itemNumber: (changes.itemNumber as number) || 0,
          multiCurrencyValue:
            (changes.multiCurrencyValue as MultiCurrencyAmount) || {},
        };
        addSubCategoryItem(summary, updateTags, tagUpdaterMap, itemToUpdate);
      }
    }
  }

  export function addSubCategoryItem<
    Tag extends string,
    SharedSummary extends { [label in Tag]: SubCategoryItem[] }
  >(
    summary: SharedSummary,
    tags: TagPair<Tag>[],
    tagUpdaterMap: TagFieldUpdaterMap<Tag>,
    itemChange: SubCategoryItem
  ) {
    for (const tag of tags) {
      let currentTag = findOrInitTag(summary, tag);
      if (currentTag) {
        const updaters = tagUpdaterMap[tag.key];
        updaters.forEach((updater) => {
          updater.applyAdd(currentTag.item, itemChange);
        });
        if (SubCategoryItem.isEmpty(currentTag.item))
          removeTag(summary, tag, currentTag.index);
      }
    }
  }

  export function subtractSubCategoryItem<
    Tag extends string,
    SharedSummary extends { [label in Tag]: SubCategoryItem[] }
  >(
    summary: SharedSummary,
    tags: TagPair<Tag>[],
    tagUpdaterMap: TagFieldUpdaterMap<Tag>,
    itemChange: SubCategoryItem
  ) {
    for (const tag of tags) {
      let currentTag = findOrInitTag(summary, tag);
      if (currentTag) {
        const updaters = tagUpdaterMap[tag.key];
        updaters.forEach((updater) => {
          updater.applySubtract(currentTag.item, itemChange);
        });
        if (SubCategoryItem.isEmpty(currentTag.item))
          removeTag(summary, tag, currentTag.index);
      }
    }
  }

  function findOrInitTag<
    Tag extends string,
    SharedSummary extends { [label in Tag]: SubCategoryItem[] }
  >(
    summary: SharedSummary,
    tag: TagPair
  ): { index: number; item: SubCategoryItem } {
    const summaryTag = tag.key as Tag;
    let index = summary[summaryTag].findIndex((item) => item.label == tag.val);
    if (index == -1) {
      let newItem = SubCategoryItem.newWithLabel(tag.val);
      summary[summaryTag].push(newItem);
      return {
        index: summary[summaryTag].length - 1,
        item: newItem,
      };
    } else {
      return {
        index,
        item: summary[summaryTag][index],
      };
    }
  }

  function removeTag<
    Tag extends string,
    SharedSummary extends { [label in Tag]: SubCategoryItem[] }
  >(summary: SharedSummary, tag: TagPair, index?: number) {
    const summaryTag = tag.key as Tag;
    let idx = index
      ? index
      : summary[summaryTag].findIndex((item) => item.label == tag.val);
    if (idx !== -1) summary[summaryTag].splice(idx, 1);
  }

  // check if summary should be updated
  export function shouldUpdateSummary<
    State extends Pick<Asset, "closedWith" | "archived">
  >(state: State, exclude?: ExcludeStatus) {
    if (exclude === ExcludeStatus.Sold) return !state.archived;
    if (exclude === ExcludeStatus.Archived)
      return state.closedWith === undefined;
    return state.closedWith === undefined && !state.archived;
  }
}

export enum ExcludeStatus {
  Sold = "sold",
  Archived = "archived",
}

export namespace SummaryTag {
  export enum Art {
    Subtype = "subtype",
    ArtStyle = "artStyle",
  }

  export enum Belonging {
    Subtype = "subtype",
  }

  export enum Wine {
    Subtype = "subtype",
    BottleSize = "bottleSize",
    Origin = "origin",
    Vintage = "vintage",
    Varietal = "varietal",
  }

  export enum CashAndBanking {
    InstitutionAssets = "institutionAssets",
    InstitutionLiabilities = "institutionLiabilities",
  }
}
