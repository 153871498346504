import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23536C88%22%2F%3E%3Cpath%20d%3D%22M4.92%2027.047v2.642H3.686v-7.11h2.72c.794%200%201.425.207%201.892.62.467.414.7.96.7%201.641%200%20.697-.228%201.239-.685%201.626-.458.387-1.098.581-1.922.581h-1.47Zm0-.991h1.486c.44%200%20.775-.104%201.006-.31.23-.207.346-.506.346-.896%200-.384-.117-.691-.351-.92-.235-.23-.557-.348-.967-.355H4.92v2.48Zm6.392.991v2.642h-1.235v-7.11h2.72c.794%200%201.424.207%201.892.62.467.414.7.96.7%201.641%200%20.697-.228%201.239-.686%201.626-.457.387-1.098.581-1.921.581h-1.47Zm0-.991h1.484c.44%200%20.775-.104%201.006-.31.231-.207.347-.506.347-.896%200-.384-.117-.691-.352-.92-.234-.23-.556-.348-.966-.355h-1.519v2.48Zm10.293-2.48h-2.217v6.113h-1.225v-6.114h-2.198v-.996h5.64v.996Zm3.379%201.572%201.494-2.569h1.426l-2.149%203.526%202.198%203.584h-1.44l-1.529-2.608-1.533%202.608h-1.436l2.202-3.584-2.153-3.526h1.426l1.494%202.569Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23253E59%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FilePptxIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#536C88'}
      />
      <path
        d={
          'M4.92 27.047v2.642H3.686v-7.11h2.72c.794 0 1.425.207 1.892.62.467.414.7.96.7 1.641 0 .697-.228 1.239-.685 1.626-.458.387-1.098.581-1.922.581h-1.47Zm0-.991h1.486c.44 0 .775-.104 1.006-.31.23-.207.346-.506.346-.896 0-.384-.117-.691-.351-.92-.235-.23-.557-.348-.967-.355H4.92v2.48Zm6.392.991v2.642h-1.235v-7.11h2.72c.794 0 1.424.207 1.892.62.467.414.7.96.7 1.641 0 .697-.228 1.239-.686 1.626-.457.387-1.098.581-1.921.581h-1.47Zm0-.991h1.484c.44 0 .775-.104 1.006-.31.231-.207.347-.506.347-.896 0-.384-.117-.691-.352-.92-.234-.23-.556-.348-.966-.355h-1.519v2.48Zm10.293-2.48h-2.217v6.113h-1.225v-6.114h-2.198v-.996h5.64v.996Zm3.379 1.572 1.494-2.569h1.426l-2.149 3.526 2.198 3.584h-1.44l-1.529-2.608-1.533 2.608h-1.436l2.202-3.584-2.153-3.526h1.426l1.494 2.569Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#253E59'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}
