import {
  Amount,
  AssetType,
  AssetV2,
  Attachment,
  Beneficiary,
  Deletable,
  Optional,
  Owner,
  Ownership,
  PathsOfDateField,
  compareGroupUpdate,
  Currency,
  WithId,
} from "./common";
import {
  EncryptedType,
  EncryptionField,
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  IVSaltFieldDefaultValue,
  IVSaltFieldKey,
  RequireEncryptionFields,
  doRemoveEncryptedFields,
  fullObjectEncryption,
  removeEncryptionFields,
} from "../encryption/utils";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  applyUpdateToObject,
  buildObjectUpdate,
  addDecimal,
  mulAmount,
  validateStringNotEmpty,
  deepCopy,
} from "../utils";
import {
  AggregateRoot,
  IAggregate,
  IAggregateStateWriter,
  RepoAndAggregates,
  setObjectDeleted,
  stateIsDeleted,
} from "./aggregate";
import { ErrorDataOutDated, DataPoisoned, InvalidInput } from "./error";
import {
  EventBase,
  EventWithTime,
  preSealEvent,
  SharedEvent,
  TxEvent,
} from "./event";
import { CommandBase, SharedCommand, TxCommand } from "./command";
import { Encryption } from "../database/encryption";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
  WithFieldValue,
} from "../../coreFirebase";
import {
  PortfolioTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";
import { RelationsOfAsset, buildPortfolioRelation } from "./relations";

export type HoldingUnits = { holdingName: string; units: number }[];

export enum HoldingType {
  Cash = "Cash",
  Holding = "Holding",
}

export type HoldingItem = HoldingItem.Cash | HoldingItem.Holding;
export interface HoldingUnit {
  name: string;
  unit: number;
}
export namespace HoldingItem {
  interface Base {
    id: string;
    name: string; //name of the holding, should come from search result
    holdingType: HoldingType;

    unit: number;
    contribution: Amount;
    withdrawal: Amount;
    investedValue: Amount;

    createDate: Date; //not from system, user set date
  }
  export interface Cash extends Base {
    holdingType: HoldingType.Cash;
  }

  export interface Holding extends Base {
    holdingType: HoldingType.Holding;

    isin: string | null;
    symbol: string;
    exchange: string;
  }

  export interface Create {
    name: string;
    holdingType: HoldingType;
    isin?: string;
    symbol?: string;
    exchange?: string;
    price: Amount;
    unit: number;
    date: Date;
  }
  export function fromCreate(from: Create, id: string): HoldingItem {
    const defaultValue: Amount = {
      currency: from.price.currency,
      value: 0,
    };
    if (from.holdingType === HoldingType.Cash) {
      const cash: Cash = {
        id,
        name: from.name,
        holdingType: HoldingType.Cash,
        unit: 0,
        contribution: { ...defaultValue },
        withdrawal: { ...defaultValue },
        investedValue: { ...defaultValue },
        createDate: from.date,
      };
      return cash;
    } else {
      const holding: Holding = {
        id,
        name: from.name,
        holdingType: HoldingType.Holding,
        isin: from.isin ?? "",
        symbol: from.symbol ?? "",
        exchange: from.exchange ?? "",
        contribution: { ...defaultValue },
        withdrawal: { ...defaultValue },
        createDate: from.date,
        investedValue: { ...defaultValue },
        unit: 0,
      };
      return holding;
    }
  }
  export function validateCreate(data: Create) {
    if (!validateStringNotEmpty(data.name))
      throw new InvalidInput("Name is required");
    if (data.holdingType === HoldingType.Holding) {
      // if (!validateStringNotEmpty(data.isin))
      //   throw new InvalidInput("ISIN is required");
      // if (!validateStringNotEmpty(data.symbol))
      //   throw new InvalidInput("Symbol is required");
      // if (!validateStringNotEmpty(data.exchange))
      //   throw new InvalidInput("Exchange is required");
    }
    if (data.unit <= 0) throw new InvalidInput("Unit must be positive");
    Amount.validate("price", data.price);
    if (data.price.value < 0) throw new InvalidInput("Price must be positive");
  }
}

export interface Portfolio extends OmitKeys<AssetV2, "value"> {
  "@type": VersionedTypeString<VersionedType.Portfolio, 2>;
  assetType: AssetType.TraditionalInvestments;
  subtype: "-";

  portfolioCurrency: Currency;
  value: HoldingUnits;

  institution?: string;
  holdings: HoldingItem[];

  // @Encrypted
  accountNumber?: string;
  productName?: string;

  ownership?: Ownership;
  beneficiary?: Beneficiary;
}
export namespace Portfolio {
  export function assureVersion(
    input: Portfolio | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      PortfolioTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Portfolio);
  }

  export const datePaths: readonly PathsOfDateField<Portfolio>[] = [
    "createAt",
    "updateAt",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Portfolio> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    decrypted.holdings.forEach((holding) => {
      CoreFirestore.convertDateFieldsFromFirestore(holding, ["createDate"]);
    });
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Portfolio,
    | "@type"
    | "ownerId"
    | "version"
    | "createAt"
    | "updateAt"
    | "value"
    | "valueSourceId"
    | "notes"
    | "holdings"
  > & {
    holdings: HoldingItem.Create[];
  };

  export type EncryptedKeys = "accountNumber";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Portfolio, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<Portfolio, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    "accountNumber",
  ] as const;

  //#NOTEholding should be handle separately
  export function fromCreate(from: CreateFields, ownerId: string): Portfolio {
    const holdings = from.holdings.map((v) =>
      HoldingItem.fromCreate(v, CoreFirestore.genAssetId())
    );
    const portfolio: WithFieldValue<Portfolio> = {
      ...from,
      value: [],
      version: 0,
      ownerId,
      holdings,
      "@type": PortfolioTypeVersion,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
    };
    return portfolio as Portfolio;
  }

  // OPTIONAL "groupIds"
  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Updatable>[] = [
    "name",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Updatable>[] =
    ["institution", "accountNumber", "productName"];
  type Updatable = Pick<
    Portfolio,
    | "name"
    | "institution"
    | "accountNumber"
    | "productName"
    | "groupIds"
    | "ownership"
    | "beneficiary"
    | "attachments"
  >;
  export type EncryptedUpdate = RequireEncryptionFields<
    EncryptedType<Updatable, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export function intoUpdate(
    current: Updatable,
    update: Updatable
  ): {
    updates: UpdateObject<Updatable>;
    metadata: {
      addedToGroup?: string[];
      removedFromGroup?: string[];
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      OptionalSimpleTypeUpdatableKeys
    );

    if (!Ownership.optionalEqual(current.ownership, update.ownership))
      if (update.ownership) baseUpdateFields.ownership = update.ownership;
      else baseUpdateFields.ownership = null;
    if (!Owner.optionalEqual(current.beneficiary, update.beneficiary))
      if (update.beneficiary) baseUpdateFields.beneficiary = update.beneficiary;
      else baseUpdateFields.beneficiary = null;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;
    const { attachments, newImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;

    return { updates: baseUpdateFields, metadata };
  }

  export function removeEncryptedFields<
    T extends Portfolio | UpdateObject<Portfolio>
  >(data: T): OmitKeys<T, EncryptedKeys | "attachments"> {
    const result = doRemoveEncryptedFields(
      data,
      encryptedKeysArray
    ) as OmitKeys<T, EncryptedKeys | "attachments">;
    delete (<any>result).attachments;
    return result;
  }
  export async function encrypt(
    input: Portfolio,
    encryption: Encryption
  ): Promise<Encrypted> {
    const { attachments, ...rest }: EncryptedType<Portfolio, EncryptedKeys> =
      await fullObjectEncryption(input, encryptedKeysArray, encryption);
    const result = rest as Encrypted;
    if (attachments) {
      result.attachments = await Attachment.encryptArray(
        attachments,
        encryption
      );
    }
    return result;
  }
  export async function encryptPartial<T extends Portfolio.EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function decrypt(
    data: Encrypted,
    encryption: Encryption
  ): Promise<Portfolio> {
    const EncryptedPart: EncryptedPart = await encryption.decryptAndStringify(
      data[EncryptionFieldKey]["data"],
      encryption.convertBase64ToIVSalt(data[EncryptionFieldKey][IVSaltFieldKey])
    );
    const { attachments, ...rest } = data;
    const result: Portfolio = {
      ...removeEncryptionFields(rest),
      ...EncryptedPart,
      attachments: await Attachment.decryptArray(attachments, encryption),
    };
    return result;
  }

  export function newAggregateRoot(state: PortfolioState) {
    return new AggregateRoot(new PortfolioAggregate(state));
  }

  // export function defaultValue(): TraditionalInvestment {
  //   return <TraditionalInvestment>{};
  // }

  export function defaultStateValue(): Encrypted {
    return {
      "@type": PortfolioTypeVersion,
      assetType: AssetType.TraditionalInvestments,
      subtype: "-",
      name: "",
      id: "",
      ownerId: "",
      version: 0,
      value: [],
      createAt: new Date(0),
      updateAt: new Date(0),
      portfolioCurrency: Currency.USD,
      holdings: [],
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue, //`{name:""}`
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
  }

  //#NOTE validate encrypted keys have legal value
  export function validateEncryptedPart(
    data: EncryptedPart & {
      attachments?: Attachment.EncryptedPart[];
    },
    _isCreate: boolean = false
  ) {
    if (data.attachments) {
      data.attachments.forEach((attachment) => {
        Attachment.validateEncryptedPart(attachment);
      });
    }
  }

  //#TODO need checks
  //#NOTE validate data after encrypted
  export function validateEncryptedObj(
    data: UpdateObject<OmitKeys<Encrypted, typeof EncryptionFieldKey>>,
    isCreate: boolean = false
  ) {
    //non optional fields, if isCreate, the field must be in the data
    if (isCreate && !data.portfolioCurrency) {
      throw new InvalidInput("portfolioCurrency is required");
    }
    if (isCreate || data.value) {
      data.value!.forEach((value) => {
        if (!validateStringNotEmpty(value.holdingName))
          throw new InvalidInput("Holding Name is required");
        if (value.units <= 0)
          throw new InvalidInput("Holding Units must be positive");
      });
    }
    // optional fields
    if (data.attachments) {
      Attachment.validateEncryptedObj(data.attachments);
    }
    if (data.ownership) {
      Ownership.validate(data.ownership);
    }
    if (data.beneficiary) {
      Owner.validate(0, data.beneficiary);
    }
  }

  export type RelatedUpdates = {
    newHoldingIds?: string[];
    addedGroupIds?: string[];
    removedGroupIds?: string[];
  };

  export interface RelatedAggregates {
    group?: RepoAndAggregates<any, any, any>;
  }
}

export type PortfolioTransaction =
  | PortfolioTransaction.CashTx
  | PortfolioTransaction.HoldingTx;
export namespace PortfolioTransaction {
  export type Create = CashTxCreate | HoldingTxCreate;
  export type Update = CashTxUpdate | HoldingTxUpdate;
  export function validateCreate(data: Create) {
    if (data.holdingType === HoldingType.Cash) {
      validateCashTxCreate(data);
    } else {
      validateHoldingTxCreate(data);
    }
  }
  function validateCashTxCreate(data: CashTxCreate) {
    if (!validateStringNotEmpty(data.holdingId))
      throw new InvalidInput("holdingId is required");
    if (!data.totalAmount || data.totalAmount.value <= 0)
      throw new InvalidInput("totalAmount must be positive");
    if (!data.date) throw new InvalidInput("date is required");
  }
  function validateHoldingTxCreate(data: HoldingTxCreate) {
    if (!validateStringNotEmpty(data.holdingId))
      throw new InvalidInput("holdingId is required");
    if (!data.unit || data.unit <= 0)
      throw new InvalidInput("unit must be positive");
    if (!data.purchasePrice || data.purchasePrice.value <= 0)
      throw new InvalidInput("purchasePrice must be positive");
    if (!data.date) throw new InvalidInput("date is required");
  }

  export function convertDate(tx: PortfolioTransaction) {
    CoreFirestore.convertDateFieldsFromFirestore(tx, ["date"]);
  }

  export function fromCreate(
    from: Create,
    id: string,
    ownerId: string
  ): PortfolioTransaction {
    if (from.holdingType === HoldingType.Cash) {
      const tx: CashTx = {
        id,
        ownerId,
        holdingType: HoldingType.Cash,
        holdingId: from.holdingId,
        transactionType: from.transactionType,
        totalAmount: from.totalAmount,
        date: from.date,
      };
      return tx;
    } else {
      const tx: HoldingTx = {
        id,
        ownerId,
        holdingType: HoldingType.Holding,
        holdingId: from.holdingId,
        transactionType: from.transactionType,
        unit: from.unit,
        purchasePrice: from.purchasePrice,
        totalAmount: {
          currency: from.purchasePrice.currency,
          value: mulAmount(from.purchasePrice.value, from.unit),
        },
        date: from.date,
      };
      return tx;
    }
  }
  export function txTypeWillAdd(type: CashTxType | HoldingTxType) {
    return (
      cashTxTypeWillAdd(type as CashTxType) ||
      holdingTxTypeWillAdd(type as HoldingTxType)
    );
  }

  export function txTypeIsContribution(type: CashTxType | HoldingTxType) {
    return (
      (type as CashTxType) === CashTxType.Contribution ||
      (type as HoldingTxType) === HoldingTxType.TransferIn
    );
  }

  export enum CashTxType {
    Contribution = "Contribution",
    Withdrawal = "Withdrawal",
    AssetPurchase = "AssetPurchase",
    AssetSell = "AssetSell",
    FeesAndCharges = "FeesAndCharges",
    Income = "Income",
    FXPurchase = "FXPurchase",
    FXSale = "FXSale",
  }

  // export function cashTxTypeIsContribution(type: CashTxType) {
  //   return type === CashTxType.Contribution;
  // }
  // export function cashTxTypeIsWithdrawal(type: CashTxType) {
  //   return type === CashTxType.Withdrawal;
  // }
  export function cashTxTypeWillAdd(type: CashTxType) {
    return (
      type === CashTxType.Contribution ||
      type === CashTxType.AssetPurchase ||
      type === CashTxType.FeesAndCharges ||
      type === CashTxType.FXSale
    );
  }
  export interface CashTx {
    id: string;
    ownerId: string;
    holdingType: HoldingType.Cash;
    holdingId: string;
    transactionType: CashTxType;
    //Holding currency
    totalAmount: Amount;
    date: Date;
  }
  export type CashTxCreate = CashTx;
  export type CashTxUpdate = UpdateObject<
    Pick<CashTx, "transactionType" | "totalAmount" | "date">
  >;

  export enum HoldingTxType {
    Buy = "Buy",
    Sell = "Sell",
    DividendReinvestment = "DividendReinvestment",
    TransferIn = "TransferIn",
    TransferOut = "TransferOut",
  }

  // export function holdingTxTypeIsContribution(type: HoldingTxType) {
  //   return type === HoldingTxType.TransferIn;
  // }
  // export function holdingTxTypeIsWithdrawal(type: HoldingTxType) {
  //   return type === HoldingTxType.TransferOut;
  // }
  export function holdingTxTypeWillAdd(type: HoldingTxType) {
    return (
      type === HoldingTxType.TransferIn ||
      type === HoldingTxType.DividendReinvestment ||
      type === HoldingTxType.Buy
    );
  }
  export interface HoldingTx {
    id: string;
    ownerId: string;
    holdingType: HoldingType.Holding;
    holdingId: string;

    transactionType: HoldingTxType;
    unit: number;
    purchasePrice: Amount;
    totalAmount: Amount;
    date: Date;
  }
  export type HoldingTxCreate = OmitKeys<HoldingTx, "totalAmount">;
  export type HoldingTxUpdate = UpdateObject<
    Pick<HoldingTx, "transactionType" | "unit" | "purchasePrice" | "date">
  >;
  export function holdingTxFromCreate(from: HoldingTxCreate): HoldingTx {
    return {
      ...from,
      totalAmount: {
        currency: from.purchasePrice.currency,
        value: mulAmount(from.purchasePrice.value, from.unit),
      },
    };
  }
  export function holdingTxFromHoldingCreate(
    from: HoldingItem.Create,
    holdingId: string,
    ownerId: string
  ): PortfolioTransaction {
    const totalAmount: Amount = {
      currency: from.price.currency,
      value: mulAmount(from.price.value, from.unit),
    };
    if (from.holdingType === HoldingType.Cash) {
      const tx: CashTx = {
        id: holdingId,
        ownerId,
        holdingType: HoldingType.Cash,
        holdingId: holdingId,
        transactionType: CashTxType.Contribution,
        totalAmount,
        date: from.date,
      };
      return tx;
    } else {
      const tx: HoldingTx = {
        id: holdingId,
        ownerId,
        holdingType: HoldingType.Holding,
        holdingId: holdingId,
        transactionType: HoldingTxType.Buy,
        unit: from.unit,
        purchasePrice: from.price,
        totalAmount,
        date: from.date,
      };
      return tx;
    }
  }
  export function getTotalValue<
    T extends
      | Pick<CashTx, "holdingType" | "totalAmount">
      | Pick<HoldingTx, "holdingType" | "unit" | "purchasePrice">
  >(tx: T): Amount {
    if (tx.holdingType === HoldingType.Cash) {
      return { ...tx.totalAmount };
    } else {
      return {
        currency: tx.purchasePrice.currency,
        value: mulAmount(tx.purchasePrice.value, tx.unit),
      };
    }
  }
  export function getNumericSignedUnit(tx: PortfolioTransaction): number {
    if (tx.holdingType === HoldingType.Cash) {
      return cashTxTypeWillAdd(tx.transactionType)
        ? tx.totalAmount.value
        : -tx.totalAmount.value;
    } else {
      return holdingTxTypeWillAdd(tx.transactionType) ? tx.unit : -tx.unit;
    }
  }
  export function getNumericSignedValueData<
    T extends
      | Pick<CashTx, "totalAmount" | "holdingType" | "transactionType">
      | Pick<
          HoldingTx,
          "totalAmount" | "holdingType" | "transactionType" | "unit"
        >
  >(
    tx: T
  ): {
    value: Amount;
    unitChange: number;
    contribution?: Amount;
    withdrawal?: Amount;
  } {
    const result: {
      value: Amount;
      unitChange: number;
      contribution?: Amount;
      withdrawal?: Amount;
    } = {
      value: {
        currency: tx.totalAmount.currency,
        value: 0,
      },
      unitChange: 0,
    };
    if (tx.holdingType === HoldingType.Cash) {
      if (cashTxTypeWillAdd(tx.transactionType)) {
        result.value.value = tx.totalAmount.value;
        result.unitChange = tx.totalAmount.value;
      } else {
        result.value.value = -tx.totalAmount.value;
        result.unitChange = -tx.totalAmount.value;
      }

      if (tx.transactionType === CashTxType.Contribution)
        result.contribution = tx.totalAmount;
      else if (tx.transactionType === CashTxType.Withdrawal)
        result.withdrawal = tx.totalAmount;
    } else {
      if (holdingTxTypeWillAdd(tx.transactionType)) {
        result.value.value = tx.totalAmount.value;
        result.unitChange = tx.unit;
      } else {
        result.value.value = -tx.totalAmount.value;
        result.unitChange = -tx.unit;
      }
      if (tx.transactionType === HoldingTxType.TransferIn)
        result.contribution = tx.totalAmount;
      else if (tx.transactionType === HoldingTxType.TransferOut)
        result.withdrawal = tx.totalAmount;
    }
    return result;
  }

  export interface Root {
    id: string;
    ownerId: string;
  }

  export function sorter(tx1: PortfolioTransaction, tx2: PortfolioTransaction) {
    const date1 = tx1.date;
    const date2 = tx2.date;
    if (date1 == date2) {
      return tx2.id > tx1.id ? 1 : -1;
    } else return date2 > date1 ? 1 : -1;
  }
}

export namespace Command {
  enum CustomKind {
    AddHolding = "AddHolding",
  }
  export type Kind = SharedCommand.Kind | TxCommand.Kind | CustomKind;
  export const Kind = {
    ...SharedCommand.Kind,
    ...TxCommand.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends CommandBase {
    kind: Kind;
  }

  export interface CreateAsset
    extends SharedCommand.CreateAsset<Portfolio.Encrypted> {
    newHoldings: WithId<HoldingItem.Create>[];
  }
  export function createAsset(
    executerId: string,
    asset: Portfolio.Encrypted,
    newHoldings: WithId<HoldingItem.Create>[]
  ): CreateAsset {
    return {
      kind: Kind.CreateAsset,
      executerId,
      asset,
      newHoldings,
    };
  }

  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<UpdateObject<Portfolio.Encrypted>> {}
  export const updateAsset = SharedCommand.updateAsset<
    UpdateObject<Portfolio.EncryptedUpdate>
  >;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;

  export interface AddTransaction
    extends TxCommand.AddTransaction<PortfolioTransaction> {}
  export const addTransaction = TxCommand.addTransaction;
  export interface UpdateTransaction
    extends TxCommand.UpdateTransaction<PortfolioTransaction.Update> {}
  export const updateTransaction = TxCommand.updateTransaction;
  export interface DeleteTransaction extends TxCommand.DeleteTransaction {}
  export const deleteTransaction = TxCommand.deleteTransaction;

  export interface AddHolding extends BaseExtended {
    kind: CustomKind.AddHolding;
    holding: WithId<HoldingItem.Create>;
  }
  export function addHolding(
    executerId: string,
    holding: WithId<HoldingItem.Create>
  ): AddHolding {
    return {
      kind: CustomKind.AddHolding,
      executerId,
      holding,
    };
  }
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.DeleteAsset
  | Command.AddHolding
  | Command.AddTransaction
  | Command.UpdateTransaction
  | Command.DeleteTransaction;
export namespace Event {
  enum CustomKind {
    HoldingAdded = "HoldingAdded",
  }
  export type Kind = SharedEvent.Kind | TxEvent.Kind | CustomKind;
  export const Kind = {
    ...SharedEvent.Kind,
    ...TxEvent.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends EventBase {
    kind: Kind;
  }

  export interface AssetCreated
    extends SharedEvent.AssetCreated<Portfolio.Encrypted> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<Portfolio.EncryptedUpdate>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}

  export interface TransactionAdded
    extends TxEvent.TransactionAdded<PortfolioTransaction> {
    unitChange: number;
    investedValueChange: Amount;
    withdrawalChange?: Amount;
    contributionChange?: Amount;
  }
  export interface TransactionUpdated
    extends TxEvent.TransactionUpdated<PortfolioTransaction.Update> {
    unitChange: number;
    investedValueChange: Amount;
    withdrawalChange?: Amount;
    contributionChange?: Amount;
  }
  export interface TransactionDeleted extends TxEvent.TransactionDeleted {
    unitChange: number;
    investedValueChange: Amount;
    withdrawalChange?: Amount;
    contributionChange?: Amount;
  }

  export interface ValueUpdated extends EventBase {
    kind: SharedEvent.Kind.ValueUpdated;
    previous?: HoldingUnits;
    current: HoldingUnits;
  }
  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}

  export interface HoldingAdded extends BaseExtended {
    kind: CustomKind.HoldingAdded;
    holding: HoldingItem;
  }
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.ValueUpdated
  | Event.GroupsUpdated
  | Event.HoldingAdded
  | Event.TransactionAdded
  | Event.TransactionUpdated
  | Event.TransactionDeleted;

export interface PortfolioState {
  portfolio: Portfolio.Encrypted;
  transactions: {
    [holdingId: string]: {
      [id: string]: Deletable<Optional<PortfolioTransaction>>;
    };
  };
}

class PortfolioAggregate implements IAggregate<PortfolioState, Command, Event> {
  state: PortfolioState;
  kind: string;
  relatedUpdates: Portfolio.RelatedUpdates = {};

  constructor(state: PortfolioState) {
    this.state = state;
    this.kind = state.portfolio.assetType;
  }

  id(): string {
    return this.state.portfolio.id;
  }

  version(): number {
    return this.state.portfolio.version;
  }

  incrementVersion(): void {
    this.state.portfolio.version++;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return this.handleCreateAsset(command).map(preSealEvent);
      case Command.Kind.UpdateAsset:
        return this.handleUpdateAsset(command).map(preSealEvent);
      case Command.Kind.DeleteAsset:
        return this.handleDeleteAsset(command).map(preSealEvent);
      case Command.Kind.AddHolding:
        return this.handleAddHolding(command).map(preSealEvent);
      case Command.Kind.AddTransaction:
        return this.handleAddTransaction(command).map(preSealEvent);
      case Command.Kind.UpdateTransaction:
        return this.handleUpdateTransaction(command).map(preSealEvent);
      case Command.Kind.DeleteTransaction:
        return this.handleDeleteTransaction(command).map(preSealEvent);
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state.portfolio = event.asset;
        this.relatedUpdates.newHoldingIds = this.state.portfolio.holdings.map(
          (i) => i.id
        );
        break;
      case Event.Kind.AssetUpdated:
        applyUpdateToObject(
          this.state.portfolio,
          event.current ? event.current : event.asset
        );
        this.state.portfolio.updateAt = time;
        break;
      case Event.Kind.AssetDeleted:
        if (
          this.state.portfolio.groupIds &&
          this.state.portfolio.groupIds.length > 0
        )
          this.relatedUpdates.removedGroupIds = this.state.portfolio.groupIds;
        this.state.portfolio = setObjectDeleted(this.state.portfolio);
        break;
      case Event.Kind.HoldingAdded:
        if (this.state.portfolio.holdings)
          this.state.portfolio.holdings.push(event.holding);
        else this.state.portfolio.holdings = [event.holding];
        if (this.relatedUpdates.newHoldingIds)
          this.relatedUpdates.newHoldingIds.push(event.holding.id);
        else this.relatedUpdates.newHoldingIds = [event.holding.id];
        break;
      case Event.Kind.TransactionAdded:
        if (!this.state.transactions[event.parentId])
          this.state.transactions[event.parentId] = {};
        this.state.transactions[event.parentId][event.id] = event.data;
        this.applyTransactionValueChanged(event);
        break;
      case Event.Kind.TransactionUpdated:
        applyUpdateToObject(
          this.state.transactions[event.parentId][event.id]!,
          event.update
        );
        this.applyTransactionValueChanged(event);
        break;
      case Event.Kind.TransactionDeleted:
        this.state.transactions[event.parentId][event.id] = null;
        this.applyTransactionValueChanged(event);
        break;
      case Event.Kind.ValueUpdated:
        this.state.portfolio.value = event.current;
        break;
      case Event.Kind.GroupsUpdated:
        if (event.addIds.length > 0)
          this.relatedUpdates.addedGroupIds = event.addIds;
        if (event.removedIds.length > 0)
          this.relatedUpdates.removedGroupIds = event.removedIds;
        break;
    }
    return this;
  }

  private handleCreateAsset({
    executerId,
    asset,
    newHoldings,
  }: Command.CreateAsset): Event[] {
    Portfolio.validateEncryptedObj(asset, true);
    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.AssetCreated,
        asset,
      },
    ];

    const unitMap: { [name: string]: number } = {};
    newHoldings.forEach((holding) => {
      unitMap[holding.name] = holding.unit;
      const newTx = PortfolioTransaction.holdingTxFromHoldingCreate(
        holding,
        holding.id,
        executerId
      );
      events.push({
        executerId,
        kind: Event.Kind.HoldingAdded,
        holding: HoldingItem.fromCreate(holding, holding.id),
      });
      const transactionAdded: Event.TransactionAdded = {
        executerId,
        kind: Event.Kind.TransactionAdded,
        parentId: holding.id,
        id: holding.id,
        data: newTx,
        unitChange: holding.unit,
        investedValueChange: newTx.totalAmount,
      };
      if (newTx.holdingType === HoldingType.Cash)
        transactionAdded.contributionChange = newTx.totalAmount;
      events.push(transactionAdded);
    });

    events.push({
      executerId,
      kind: Event.Kind.ValueUpdated,
      current: Object.entries(unitMap).map(([name, unit]) => ({
        holdingName: name,
        units: unit,
      })),
    });

    if (asset.groupIds && asset.groupIds.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: asset.groupIds,
        removedIds: [],
      });
    }
    if (asset.ownership) {
      events.push({
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        previous: this.state.portfolio.ownership,
        current: asset.ownership,
      });
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        current: asset.beneficiary,
      });
    }
    return events;
  }
  private handleUpdateAsset({
    executerId,
    asset,
    addedToGroup,
    removedFromGroup,
  }: Command.UpdateAsset): Event[] {
    AssetV2.checkUpdate(this.state.portfolio);
    Portfolio.validateEncryptedObj(asset);
    const events: Event[] = [];
    events.push({
      executerId,
      kind: Event.Kind.AssetUpdated,
      asset,
      previous: deepCopy(this.state.portfolio),
      current: asset,
    });

    if (addedToGroup || removedFromGroup) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: addedToGroup ?? [],
        removedIds: removedFromGroup ?? [],
      });
    }

    if (asset.ownership) {
      events.push({
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        previous: this.state.portfolio.ownership,
        current: asset.ownership,
      });
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        previous: this.state.portfolio.beneficiary,
        current: asset.beneficiary,
      });
    }
    return events;
  }
  private handleDeleteAsset({ executerId }: Command.DeleteAsset): Event[] {
    AssetV2.checkDelete(this.state.portfolio);
    return [{ executerId, kind: Event.Kind.AssetDeleted }];
  }
  private handleAddHolding({
    executerId,
    holding,
  }: Command.AddHolding): Event[] {
    HoldingItem.validateCreate(holding);

    if (
      this.state.portfolio.holdings &&
      this.state.portfolio.holdings.some((i) => i.id === holding.id)
    )
      throw new InvalidInput("Holding already exists");
    const newValue = [...this.state.portfolio.value];
    const holdingUnit = newValue.find((i) => i.holdingName === holding.name);
    if (holdingUnit) {
      holdingUnit.units = addDecimal(holdingUnit.units, holding.unit);
    } else {
      newValue.push({
        holdingName: holding.name,
        units: holding.unit,
      });
    }

    const newTx = PortfolioTransaction.holdingTxFromHoldingCreate(
      holding,
      holding.id,
      executerId
    );
    const transactionAdded: Event.TransactionAdded = {
      executerId,
      kind: Event.Kind.TransactionAdded,
      parentId: holding.id,
      id: holding.id,
      data: newTx,
      unitChange: holding.unit,
      investedValueChange: newTx.totalAmount,
    };
    if (newTx.holdingType === HoldingType.Cash)
      transactionAdded.contributionChange = newTx.totalAmount;
    return [
      {
        executerId,
        kind: Event.Kind.HoldingAdded,
        holding: HoldingItem.fromCreate(holding, holding.id),
      },
      transactionAdded,
      {
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.portfolio.value,
        current: newValue,
      },
    ];
  }

  private handleAddTransaction({
    executerId,
    parentId,
    id,
    data,
  }: Command.AddTransaction): Event[] {
    PortfolioTransaction.validateCreate(data);

    const holding = this.state.portfolio.holdings.find(
      (i) => i.id === parentId
    );
    if (!holding) throw new InvalidInput("Holding does not exist");

    const tx = <any>{
      holdingType: data.holdingType,
      transactionType: data.transactionType,
      totalAmount: data.totalAmount,
    };
    if (data.holdingType === HoldingType.Holding) tx.unit = data.unit;
    const { value, contribution, withdrawal, unitChange } =
      PortfolioTransaction.getNumericSignedValueData(tx);

    const newValue = [...this.state.portfolio.value];
    const currencyUnit = newValue.find((i) => i.holdingName === holding?.name);
    if (currencyUnit) {
      currencyUnit.units = addDecimal(currencyUnit.units, unitChange);
    } else {
      newValue.push({
        holdingName: holding.name,
        units: unitChange,
      });
    }

    const transactionAdded: Event.TransactionAdded = {
      executerId,
      parentId,
      id,
      kind: Event.Kind.TransactionAdded,
      data,
      unitChange,
      investedValueChange: value,
    };
    if (withdrawal) transactionAdded.withdrawalChange = withdrawal;
    if (contribution) transactionAdded.contributionChange = contribution;

    return [
      transactionAdded,
      {
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.portfolio.value,
        current: newValue,
      },
    ];
  }

  private handleUpdateTransaction({
    executerId,
    parentId,
    id,
    update,
  }: Command.UpdateTransaction): Event[] {
    const holding = this.state.portfolio.holdings.find(
      (i) => i.id === parentId
    );
    if (!holding) throw new InvalidInput("Holding does not exist");
    const prevTx = this.state.transactions[parentId][id];
    if (!prevTx) throw new InvalidInput("Transaction does not exist");

    const transactionUpdated: Event.TransactionUpdated = {
      executerId,
      kind: Event.Kind.TransactionUpdated,
      parentId,
      id,
      update,
      unitChange: 0,
      investedValueChange: {
        currency: prevTx.totalAmount.currency,
        value: 0,
      },
    };
    const events: Event[] = [transactionUpdated];

    let typeChangeValue = false;
    if (update.transactionType) {
      typeChangeValue =
        PortfolioTransaction.txTypeWillAdd(update.transactionType) !==
          PortfolioTransaction.txTypeWillAdd(prevTx.transactionType) ||
        PortfolioTransaction.txTypeIsContribution(update.transactionType) !==
          PortfolioTransaction.txTypeIsContribution(prevTx.transactionType);
    }
    const valueChanged =
      typeChangeValue ||
      "unit" in update ||
      "purchasePrice" in update ||
      "totalAmount" in update;

    if (valueChanged) {
      const totalAmount = PortfolioTransaction.getTotalValue(
        prevTx.holdingType == HoldingType.Cash
          ? {
              holdingType: HoldingType.Cash,
              totalAmount:
                (<PortfolioTransaction.CashTxUpdate>update).totalAmount ||
                prevTx.totalAmount,
            }
          : {
              holdingType: HoldingType.Holding,
              unit:
                (<PortfolioTransaction.HoldingTxUpdate>update).unit ||
                prevTx.unit,
              purchasePrice:
                (<PortfolioTransaction.HoldingTxUpdate>update).purchasePrice ||
                prevTx.purchasePrice,
            }
      );
      (<any>update).totalAmount = totalAmount;
      const tx = <any>{
        holdingType: prevTx.holdingType,
        transactionType: update.transactionType || prevTx.transactionType,
        totalAmount,
      };
      if (prevTx.holdingType === HoldingType.Holding)
        tx.unit =
          (<PortfolioTransaction.HoldingTxUpdate>update).unit || prevTx.unit;
      const prevChanges =
        PortfolioTransaction.getNumericSignedValueData(prevTx);
      const { value, contribution, withdrawal, unitChange } =
        PortfolioTransaction.getNumericSignedValueData(tx);
      transactionUpdated.unitChange = addDecimal(
        -prevChanges.unitChange,
        unitChange
      );
      transactionUpdated.investedValueChange = {
        currency: value.currency,
        value: addDecimal(-prevChanges.value.value, value.value),
      };
      if (withdrawal || prevChanges.withdrawal)
        transactionUpdated.withdrawalChange = {
          currency: value.currency,
          value: addDecimal(
            -(prevChanges.withdrawal?.value || 0),
            withdrawal?.value || 0
          ),
        };
      if (contribution || prevChanges.contribution)
        transactionUpdated.contributionChange = {
          currency: value.currency,
          value: addDecimal(
            -(prevChanges.contribution?.value || 0),
            contribution?.value || 0
          ),
        };

      const newValue = [...this.state.portfolio.value];
      const currencyUnit = newValue.find(
        (i) => i.holdingName === holding?.name
      );
      if (!currencyUnit) throw new DataPoisoned("Holding not found");
      currencyUnit.units = addDecimal(
        currencyUnit.units,
        transactionUpdated.unitChange
      );
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.portfolio.value,
        current: newValue,
      });
    }

    return events;
  }

  private handleDeleteTransaction({
    executerId,
    parentId,
    id,
  }: Command.DeleteTransaction): Event[] {
    const holding = this.state.portfolio.holdings.find(
      (i) => i.id === parentId
    );
    if (!holding) throw new InvalidInput("Holding does not exist");
    const tx = this.state.transactions[parentId][id];
    if (!tx) throw new InvalidInput("Transaction does not exist");

    const { value, contribution, withdrawal, unitChange } =
      PortfolioTransaction.getNumericSignedValueData(tx);

    const transactionDeleted: Event.TransactionDeleted = {
      executerId,
      kind: Event.Kind.TransactionDeleted,
      parentId,
      id,
      unitChange: -unitChange,
      investedValueChange: Amount.toNegative(value),
    };
    if (withdrawal)
      transactionDeleted.withdrawalChange = Amount.toNegative(withdrawal);
    if (contribution)
      transactionDeleted.contributionChange = Amount.toNegative(contribution);

    const newValue = [...this.state.portfolio.value];
    const currencyUnit = newValue.find((i) => i.holdingName === holding?.name);
    if (!currencyUnit) throw new DataPoisoned("Holding not found");
    currencyUnit.units = addDecimal(currencyUnit.units, -unitChange);
    return [
      transactionDeleted,
      {
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.portfolio.value,
        current: newValue,
      },
    ];
  }

  private applyTransactionValueChanged(
    event:
      | Event.TransactionAdded
      | Event.TransactionUpdated
      | Event.TransactionDeleted
  ) {
    const {
      parentId: holdingId,
      investedValueChange,
      withdrawalChange,
      contributionChange,
      unitChange,
    } = event;
    const holding = this.state.portfolio.holdings.find(
      (i) => i.id === holdingId
    );
    if (!holding) throw new DataPoisoned("Holding not found");
    if (unitChange) holding.unit = addDecimal(holding.unit, unitChange);
    if (investedValueChange)
      holding.investedValue.value = addDecimal(
        holding.investedValue.value,
        investedValueChange.value
      );
    if (withdrawalChange)
      holding.withdrawal.value = addDecimal(
        holding.withdrawal.value,
        withdrawalChange.value
      );
    if (contributionChange)
      holding.contribution.value = addDecimal(
        holding.contribution.value,
        contributionChange.value
      );
  }
}

export class PortfolioStateWriter
  implements IAggregateStateWriter<PortfolioState, Command, Event>
{
  transaction!: Transaction;
  portfolioCollectionRef: CollectionReference<Portfolio.Encrypted>;
  txCollectionRootCollectionRef: CollectionReference<PortfolioTransaction.Root>;
  relationCollectionRef: CollectionReference<RelationsOfAsset>;
  ownerId: string;

  constructor(
    portfolioRef: CollectionReference<Portfolio.Encrypted>,
    txCollectionRootCollectionRef: CollectionReference<PortfolioTransaction.Root>,
    relationRef: CollectionReference<RelationsOfAsset>,
    ownerId: string
  ) {
    this.portfolioCollectionRef = portfolioRef;
    this.txCollectionRootCollectionRef = txCollectionRootCollectionRef;
    this.relationCollectionRef = relationRef;
    this.ownerId = ownerId;
  }

  setStateTx(transaction: Transaction, aggregate: PortfolioAggregate): void {
    const portfolioDocRef = CoreFirestore.docFromCollection(
      this.portfolioCollectionRef,
      aggregate.state.portfolio.id
    );
    const relationDocRef = CoreFirestore.docFromCollection(
      this.relationCollectionRef,
      aggregate.state.portfolio.id
    );
    if (aggregate.relatedUpdates.newHoldingIds) {
      aggregate.relatedUpdates.newHoldingIds.forEach((id) => {
        const root: PortfolioTransaction.Root = {
          id,
          ownerId: this.ownerId,
        };
        transaction.set(
          CoreFirestore.docFromCollection(
            this.txCollectionRootCollectionRef,
            id
          ),
          root
        );
      });
    }
    if (stateIsDeleted(aggregate.state.portfolio)) {
      this.deleteStateTx(transaction, portfolioDocRef, relationDocRef);
    } else {
      transaction.set(portfolioDocRef, aggregate.state.portfolio);
      transaction.set(
        relationDocRef,
        buildPortfolioRelation(aggregate.state.portfolio)
      );
      Object.entries(aggregate.state.transactions).forEach(
        ([holdingId, holdingTxs]) => {
          const txCollectionRef = CoreFirestore.collection(
            //#HACK need to put this in Refs
            `${this.txCollectionRootCollectionRef.path}/${holdingId}/Transaction`
          );
          Object.entries(holdingTxs).forEach(([id, tx]) => {
            if (tx) {
              transaction.set(
                CoreFirestore.docFromCollection(txCollectionRef, id),
                tx
              );
            } else if (tx === null)
              transaction.delete(
                CoreFirestore.docFromCollection(txCollectionRef, id)
              );
          });
        }
      );
    }
    aggregate.relatedUpdates = {};
  }

  deleteStateTx(
    transaction: Transaction,
    portfolioDocRef: DocumentReference<Portfolio.Encrypted>,
    relationDocRef: DocumentReference<RelationsOfAsset>
  ): void {
    transaction.delete(portfolioDocRef);
    transaction.delete(relationDocRef);
  }
}
