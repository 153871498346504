import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2020%2021%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%2013.393a2.917%202.917%200%201%201%200-5.834%202.917%202.917%200%200%201%200%205.834m6.192-2.108c.033-.267.058-.533.058-.808s-.025-.55-.058-.834l1.758-1.358a.42.42%200%200%200%20.1-.533l-1.666-2.884a.41.41%200%200%200-.509-.183l-2.075.833a6%206%200%200%200-1.408-.816l-.308-2.209a.42.42%200%200%200-.417-.35H8.334a.42.42%200%200%200-.417.35l-.308%202.209a6%206%200%200%200-1.409.816l-2.075-.833a.41.41%200%200%200-.508.183L1.95%207.752a.41.41%200%200%200%20.1.533l1.76%201.358a7%207%200%200%200-.059.834c0%20.275.025.541.059.808l-1.76%201.383a.41.41%200%200%200-.1.534l1.667%202.883c.1.183.325.25.508.183l2.075-.841a5.8%205.8%200%200%200%201.409.825l.308%202.208c.033.2.208.35.417.35h3.333c.208%200%20.383-.15.417-.35l.308-2.208a6%206%200%200%200%201.408-.825l2.075.841a.42.42%200%200%200%20.509-.183l1.666-2.883a.42.42%200%200%200-.1-.534z%22%20fill%3D%22%23ddd%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CogIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 20 21'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M10 13.393a2.917 2.917 0 1 1 0-5.834 2.917 2.917 0 0 1 0 5.834m6.192-2.108c.033-.267.058-.533.058-.808s-.025-.55-.058-.834l1.758-1.358a.42.42 0 0 0 .1-.533l-1.666-2.884a.41.41 0 0 0-.509-.183l-2.075.833a6 6 0 0 0-1.408-.816l-.308-2.209a.42.42 0 0 0-.417-.35H8.334a.42.42 0 0 0-.417.35l-.308 2.209a6 6 0 0 0-1.409.816l-2.075-.833a.41.41 0 0 0-.508.183L1.95 7.752a.41.41 0 0 0 .1.533l1.76 1.358a7 7 0 0 0-.059.834c0 .275.025.541.059.808l-1.76 1.383a.41.41 0 0 0-.1.534l1.667 2.883c.1.183.325.25.508.183l2.075-.841a5.8 5.8 0 0 0 1.409.825l.308 2.208c.033.2.208.35.417.35h3.333c.208 0 .383-.15.417-.35l.308-2.208a6 6 0 0 0 1.408-.825l2.075.841a.42.42 0 0 0 .509-.183l1.666-2.883a.42.42 0 0 0-.1-.534z'
        }
      />
    </svg>
  )
}
