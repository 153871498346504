import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2019%2014%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.064%206.353c1.8%200%203.177-1.377%203.177-3.177S13.864%200%2012.064%200c-.318%200-.635.106-.953.106.635.953.953%201.906.953%203.07%200%201.165-.318%202.118-.953%203.071.318%200%20.635.106.953.106zm-5.294%200c1.8%200%203.176-1.377%203.176-3.177S8.57%200%206.77%200c-1.8%200-3.177%201.376-3.177%203.176%200%201.8%201.377%203.177%203.177%203.177zm6.988%202.33c.847.74%201.483%201.8%201.483%202.964v2.118h3.176v-2.118c0-1.587-2.541-2.647-4.659-2.965zM6.77%208.47C4.652%208.47.417%209.529.417%2011.647v2.118h12.706v-2.118c0-2.118-4.235-3.176-6.353-3.176z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function OwnershipIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 19 14'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M12.064 6.353c1.8 0 3.177-1.377 3.177-3.177S13.864 0 12.064 0c-.318 0-.635.106-.953.106.635.953.953 1.906.953 3.07 0 1.165-.318 2.118-.953 3.071.318 0 .635.106.953.106zm-5.294 0c1.8 0 3.176-1.377 3.176-3.177S8.57 0 6.77 0c-1.8 0-3.177 1.376-3.177 3.176 0 1.8 1.377 3.177 3.177 3.177zm6.988 2.33c.847.74 1.483 1.8 1.483 2.964v2.118h3.176v-2.118c0-1.587-2.541-2.647-4.659-2.965zM6.77 8.47C4.652 8.47.417 9.529.417 11.647v2.118h12.706v-2.118c0-2.118-4.235-3.176-6.353-3.176z'
        }
      />
    </svg>
  )
}
