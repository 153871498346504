import { Optional, PathsOfDateField, WithId } from "./remodel/types/common"; //#MARK CYCLE
import { AlreadyExist, DocNotExist } from "./remodel/types/error";

export type DocumentData = { [field: string]: any };
export type Primitive = string | number | boolean | undefined | null;
export type WhereFilterOp =
  | "<"
  | "<="
  | "=="
  | "!="
  | ">="
  | ">"
  | "array-contains"
  | "in"
  | "array-contains-any"
  | "not-in";

export interface FieldValue {
  isEqual(other: FieldValue): boolean;
}

export namespace QueryConstraint {
  export type Kind =
    | "limit"
    | "orderBy"
    | "where"
    | "startAfter"
    | "startAfterFieldValues"
    | "startAt";

  export interface Base {
    kind: Kind;
  }
  export interface Limit extends Base {
    kind: "limit";
    limit: number;
  }
  export interface OrderBy extends Base {
    kind: "orderBy";
    fieldPath: string;
    directionStr?: "desc" | "asc";
  }
  export interface Where extends Base {
    kind: "where";
    fieldPath: string;
    opStr: WhereFilterOp;
    value: unknown;
  }
  export interface StartAfter<AppModelType, DbModelType extends DocumentData>
    extends Base {
    kind: "startAfter";
    snapshot: DocumentSnapshot<AppModelType, DbModelType>;
  }
  export interface StartAfterFieldValues extends Base {
    kind: "startAfterFieldValues";
    fieldValues: any[];
  }

  export interface StartAt<AppModelType, DbModelType extends DocumentData>
    extends Base {
    kind: "startAt";
    snapshot: DocumentSnapshot<AppModelType, DbModelType>;
  }
}
export type QueryConstraint =
  | QueryConstraint.Limit
  | QueryConstraint.OrderBy
  | QueryConstraint.Where
  | QueryConstraint.StartAfter<any, any>
  | QueryConstraint.StartAfterFieldValues
  | QueryConstraint.StartAt<any, any>;

export interface SnapshotOptions {
  readonly serverTimestamps?: "estimate" | "previous" | "none";
}
export interface DocumentSnapshot<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData
> {
  exists(): boolean;
  data(options?: SnapshotOptions): AppModelType | undefined;
  get id(): string;
  get ref(): DocumentReference<AppModelType, DbModelType>;
}

export interface FirestoreError {
  code: string;
  message: string;
  name: string;
  stack?: string;
}

export interface DocumentReference<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData
> {
  readonly type: "document";
  get id(): string;
  get path(): string;
  get parent(): CollectionReference<AppModelType, DbModelType>;
}
export interface CollectionReference<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData
> {
  readonly type: "collection";
  get id(): string;
  get path(): string;
  get parent(): DocumentReference<DocumentData, DocumentData> | null;
}

export declare interface StorageReference {
  toString(): string;
  root: StorageReference;
  bucket: string;
  fullPath: string;
  name: string;
  // storage: FirebaseStorage;
  parent: StorageReference | null;
}

export type SetOptions = {
  merge?: boolean;
  mergeFields?: string[];
};
export type WithFieldValue<T> =
  | T
  | (T extends Primitive
      ? T
      : T extends {}
      ? {
          [K in keyof T]: WithFieldValue<T[K]> | FieldValue;
        }
      : never);
export type PartialWithFieldValue<T> =
  | Partial<T>
  | (T extends Primitive
      ? T
      : T extends {}
      ? {
          [K in keyof T]?: PartialWithFieldValue<T[K]> | FieldValue;
        }
      : never);

export interface Unsubscribe {
  /** Removes the listener when invoked. */
  (): void;
}

export type UnionToIntersection<U> = (
  U extends unknown ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;
export type AddPrefixToKeys<
  Prefix extends string,
  T extends Record<string, unknown>
> = {
  [K in keyof T & string as `${Prefix}.${K}`]+?: string extends K ? any : T[K];
};
export type ChildUpdateFields<K extends string, V> = V extends Record<
  string,
  unknown
>
  ? AddPrefixToKeys<K, UpdateData<V>>
  : never;

export type NestedUpdateFields<T extends Record<string, unknown>> =
  UnionToIntersection<
    {
      [K in keyof T & string]: ChildUpdateFields<K, T[K]>;
    }[keyof T & string]
  >;
export type UpdateData<T> = T extends Primitive
  ? T
  : T extends {}
  ? {
      [K in keyof T]?: UpdateData<T[K]> | FieldValue;
    } & NestedUpdateFields<T>
  : Partial<T>;

export interface Transaction {
  get<AppModelType, DbModelType extends DocumentData>(
    documentRef: DocumentReference<AppModelType, DbModelType>
  ): Promise<DocumentSnapshot<AppModelType, DbModelType>>;
  set<AppModelType, DbModelType extends DocumentData>(
    documentRef: DocumentReference<AppModelType, DbModelType>,
    data: WithFieldValue<AppModelType>
  ): Transaction;
  //   set<AppModelType, DbModelType extends DocumentData>(
  //     documentRef: DocumentReference<AppModelType, DbModelType>,
  //     data: PartialWithFieldValue<AppModelType>
  //     // options: SetOptions
  //   ): this;
  update<AppModelType, DbModelType extends DocumentData>(
    documentRef: DocumentReference<AppModelType, DbModelType>,
    data: UpdateData<DbModelType>
  ): Transaction;
  //   update<AppModelType, DbModelType extends DocumentData>(
  //     documentRef: DocumentReference<AppModelType, DbModelType>,
  //     field: string, // | FieldPath,
  //     value: unknown,
  //     ...moreFieldsAndValues: unknown[]
  //   ): this;
  delete<AppModelType, DbModelType extends DocumentData>(
    documentRef: DocumentReference<AppModelType, DbModelType>
  ): Transaction;
}
export interface TransactionOptions {
  readonly maxAttempts?: number;
}

export interface SnapshotMetadata {
  readonly hasPendingWrites: boolean;
  readonly fromCache: boolean;
  isEqual(other: SnapshotMetadata): boolean;
}
export interface QueryDocumentSnapshot<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData
> extends DocumentSnapshot<AppModelType, DbModelType> {
  data(options?: SnapshotOptions): AppModelType;
}
export interface SnapshotListenOptions {
  /**
   * Include a change even if only the metadata of the query or of a document
   * changed. Default is false.
   */
  readonly includeMetadataChanges?: boolean;
}
export declare type DocumentChangeType = "added" | "removed" | "modified";
export declare interface DocumentChange<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData
> {
  /** The type of change ('added', 'modified', or 'removed'). */
  readonly type: DocumentChangeType;
  /** The document affected by this change. */
  readonly doc: QueryDocumentSnapshot<AppModelType, DbModelType>;
  /**
   * The index of the changed document in the result set immediately prior to
   * this `DocumentChange` (i.e. supposing that all prior `DocumentChange` objects
   * have been applied). Is `-1` for 'added' events.
   */
  readonly oldIndex: number;
  /**
   * The index of the changed document in the result set immediately after
   * this `DocumentChange` (i.e. supposing that all prior `DocumentChange`
   * objects and the current `DocumentChange` object have been applied).
   * Is -1 for 'removed' events.
   */
  readonly newIndex: number;
}
export interface QuerySnapshot<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData
> {
  // readonly metadata: SnapshotMetadata;
  //   readonly query: Query<AppModelType, DbModelType>;

  get docs(): Array<QueryDocumentSnapshot<AppModelType, DbModelType>>;
  get size(): number;
  get empty(): boolean;
  forEach(
    callback: (
      result: QueryDocumentSnapshot<AppModelType, DbModelType>
    ) => void,
    thisArg?: unknown
  ): void;
  docChanges(
    options?: SnapshotListenOptions
  ): Array<DocumentChange<AppModelType, DbModelType>>;
}

export declare type AggregateType = "count" | "avg" | "sum";
export declare class AggregateField<T> {
  /** A type string to uniquely identify instances of this class. */
  readonly type = "AggregateField";
  /** Indicates the aggregation operation of this AggregateField. */
  readonly aggregateType: AggregateType;
}
export declare function sum(field: string): AggregateField<number>;
export declare function average(field: string): AggregateField<number | null>;
export declare function count(): AggregateField<number>;
export declare type AggregateFieldType =
  | ReturnType<typeof sum>
  | ReturnType<typeof average>
  | ReturnType<typeof count>;
export declare interface AggregateSpec {
  [field: string]: AggregateFieldType;
}
/**
 * A type whose keys are taken from an `AggregateSpec`, and whose values are the
 * result of the aggregation performed by the corresponding `AggregateField`
 * from the input `AggregateSpec`.
 */
export declare type AggregateSpecData<T extends AggregateSpec> = {
  [P in keyof T]: T[P] extends AggregateField<infer U> ? U : never;
};
export interface AggregateQuerySnapshot<
  AggregateSpecType extends AggregateSpec,
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData
> {
  data(): AggregateSpecData<AggregateSpecType>;
}

//#NOTE copied from firebase/storage
interface SettableMetadata {
  /**
   * Served as the 'Cache-Control' header on object download.
   */
  cacheControl?: string | undefined;
  /**
   * Served as the 'Content-Disposition' header on object download.
   */
  contentDisposition?: string | undefined;
  /**
   * Served as the 'Content-Encoding' header on object download.
   */
  contentEncoding?: string | undefined;
  /**
   * Served as the 'Content-Language' header on object download.
   */
  contentLanguage?: string | undefined;
  /**
   * Served as the 'Content-Type' header on object download.
   */
  contentType?: string | undefined;
  /**
   * Additional user-defined custom metadata.
   */
  customMetadata?:
    | {
        [key: string]: string;
      }
    | undefined;
}
//#NOTE copied from firebase/storage
interface UploadMetadata extends SettableMetadata {
  /**
   * A Base64-encoded MD5 hash of the object being uploaded.
   */
  md5Hash?: string | undefined;
}
//#NOTE copied from firebase/storage
export interface FullMetadata extends UploadMetadata {
  /**
   * The bucket this object is contained in.
   */
  bucket: string;
  /**
   * The full path of this object.
   */
  fullPath: string;
  /**
   * The object's generation.
   * {@link https://cloud.google.com/storage/docs/metadata#generation-number}
   */
  generation: string;
  /**
   * The object's metageneration.
   * {@link https://cloud.google.com/storage/docs/metadata#generation-number}
   */
  metageneration: string;
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  name: string;
  /**
   * The size of this object, in bytes.
   */
  size: number;
  /**
   * A date string representing when this object was created.
   */
  timeCreated: string;
  /**
   * A date string representing when this object was last updated.
   */
  updated: string;
  /**
   * Tokens to allow access to the downloatd URL.
   */
  downloadTokens: string[] | undefined;
  /**
   * `StorageReference` associated with this upload.
   */
  ref?: StorageReference | undefined;
}
//#NOTE copied from firebase/storage
export interface UploadResult {
  /**
   * Contains the metadata sent back from the server.
   */
  readonly metadata: FullMetadata;
  /**
   * The reference that spawned this upload.
   */
  readonly ref: StorageReference;
}

export interface ICoreFirestore {
  isAdmin(): boolean;
  collection<T>(path: string): CollectionReference<T>;
  doc<T>(path: string): DocumentReference<T>;
  docFromCollection<T>(
    collectionReference: CollectionReference<T>,
    id?: string
  ): DocumentReference<T>;
  ref(url: string): StorageReference;
  refExtend(root: StorageReference, url: string): StorageReference;

  onSnapshot<AppModelType, DbModelType extends DocumentData = DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    onNext: (snapshot: DocumentSnapshot<AppModelType, DbModelType>) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe;
  onSnapshotCollection<AppModelType, DbModelType extends DocumentData>(
    observer: {
      next?: (snapshot: QuerySnapshot<AppModelType, DbModelType>) => void;
      error?: (error: FirestoreError) => void;
      complete?: () => void;
    },
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Unsubscribe;

  getDoc<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    reference: DocumentReference<AppModelType, DbModelType>
  ): Promise<DocumentSnapshot<AppModelType, DbModelType>>;
  updateDoc<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: UpdateData<DbModelType>
  ): Promise<void>;
  setDoc<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: WithFieldValue<AppModelType>
  ): Promise<void>;
  setDocWithOption<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: PartialWithFieldValue<AppModelType>,
    options: SetOptions
  ): Promise<void>;
  deleteDoc<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>
  ): Promise<void>;
  getDocsFromCollection<
    AppModelType,
    DbModelType extends DocumentData = DocumentData
  >(
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Promise<QuerySnapshot<AppModelType, DbModelType>>;
  getCountFromServer<
    AppModelType,
    DbModelType extends DocumentData = DocumentData
  >(
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Promise<
    AggregateQuerySnapshot<
      {
        count: AggregateField<number>;
      },
      AppModelType,
      DbModelType
    >
  >;

  genAssetId(): string;

  getDocsByIdsPure<T>(
    collectionRef: CollectionReference<T>,
    ids: string[],
    option?: {
      batchSize?: number;
      ignoreNotFound?: boolean;
    }
  ): Promise<T[]>;

  runTransaction<T>(
    updateFunction: (transaction: Transaction) => Promise<T>,
    options?: TransactionOptions
  ): Promise<T>;

  getDownloadURL(ref: StorageReference): Promise<string>;
  getMetadata(ref: StorageReference): Promise<FullMetadata>;
  uploadBytes(
    ref: StorageReference,
    data: Blob | Uint8Array | ArrayBuffer,
    metadata?: unknown
  ): Promise<UploadResult>;
  deleteObject(ref: StorageReference): Promise<void>;

  checkAndConvertTimestampToDate(input: any): Optional<Date>;
  convertTimestampToDate<T extends object>(input: any, key: keyof T): void;
  //#NOTE this will change the input object
  convertDateFieldsFromFirestore<T extends object>(
    input: T,
    paths: readonly PathsOfDateField<T>[]
  ): void;
  convertDateFieldsFromFirestoreNotStrict<T extends object>(
    input: T,
    paths: readonly string[]
  ): void;

  serverTimestamp(): FieldValue;
  isTimestamp(obj: object): boolean;
  isServerTimestamp(obj: object): boolean;
  deleteField(): FieldValue;
  increment(n: number): FieldValue;

  limit(n: number): QueryConstraint;
  startAfter<AppModelType, DbModelType extends DocumentData>(
    snapshot: DocumentSnapshot<AppModelType, DbModelType>
  ): QueryConstraint;
  startAfterFieldValues(fieldValues: any[]): QueryConstraint;
  startAt<AppModelType, DbModelType extends DocumentData>(
    snapshot: DocumentSnapshot<AppModelType, DbModelType>
  ): QueryConstraint;
  orderBy(fieldPath: string, directionStr?: "desc" | "asc"): QueryConstraint;
  where(
    fieldPath: string,
    opStr: WhereFilterOp,
    value: unknown
  ): QueryConstraint;
}

export class FirestoreManager implements ICoreFirestore {
  private inner!: ICoreFirestore;

  constructor() {}

  setup(inner: ICoreFirestore) {
    this.inner = inner;
  }

  checkInitialized() {
    if (!this.inner) {
      throw new Error("Firestore not initialized");
    }
  }

  isAdmin(): boolean {
    this.checkInitialized();
    return this.inner.isAdmin();
  }

  collection<T>(path: string): CollectionReference<T> {
    this.checkInitialized();
    return this.inner.collection(path);
  }

  doc<T>(path: string): DocumentReference<T> {
    this.checkInitialized();
    return this.inner.doc(path);
  }
  docFromCollection<T>(
    collectionReference: CollectionReference<T>,
    id?: string
  ): DocumentReference<T> {
    this.checkInitialized();
    return this.inner.docFromCollection(collectionReference, id);
  }
  ref(url: string): StorageReference {
    this.checkInitialized();
    return this.inner.ref(url);
  }
  refExtend(root: StorageReference, ext: string): StorageReference {
    this.checkInitialized();
    return this.inner.refExtend(root, ext);
  }

  onSnapshot<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    reference: DocumentReference<AppModelType, DbModelType>,
    onNext: (snapshot: DocumentSnapshot<AppModelType, DbModelType>) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    this.checkInitialized();
    return this.inner.onSnapshot(reference, onNext, onError, onCompletion);
  }
  onSnapshotCollection<AppModelType, DbModelType extends DocumentData>(
    observer: {
      next?: (snapshot: QuerySnapshot<AppModelType, DbModelType>) => void;
      error?: (error: FirestoreError) => void;
      complete?: () => void;
    },
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Unsubscribe {
    this.checkInitialized();
    return this.inner.onSnapshotCollection(
      observer,
      collectionReference,
      ...queryConstraints
    );
  }

  getDoc<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    reference: DocumentReference<AppModelType, DbModelType>
  ): Promise<DocumentSnapshot<AppModelType, DbModelType>> {
    this.checkInitialized();
    return this.inner.getDoc(reference);
  }
  updateDoc<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: UpdateData<DbModelType>
  ): Promise<void> {
    this.checkInitialized();
    return this.inner.updateDoc(reference, data);
  }
  setDoc<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: WithFieldValue<AppModelType>
  ): Promise<void> {
    this.checkInitialized();
    return this.inner.setDoc(reference, data);
  }
  setDocWithOption<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: PartialWithFieldValue<AppModelType>,
    options: SetOptions
  ): Promise<void> {
    this.checkInitialized();
    return this.inner.setDocWithOption(reference, data, options);
  }
  deleteDoc<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>
  ): Promise<void> {
    this.checkInitialized();
    return this.inner.deleteDoc(reference);
  }
  getDocsFromCollection<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Promise<QuerySnapshot<AppModelType, DbModelType>> {
    this.checkInitialized();
    return this.inner.getDocsFromCollection(
      collectionReference,
      ...queryConstraints
    );
  }
  getCountFromServer<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Promise<
    AggregateQuerySnapshot<
      {
        count: AggregateField<number>;
      },
      AppModelType,
      DbModelType
    >
  > {
    this.checkInitialized();
    return this.inner.getCountFromServer(
      collectionReference,
      ...queryConstraints
    );
  }

  genAssetId(): string {
    this.checkInitialized();
    return this.inner.genAssetId();
  }

  getDocsByIdsPure<T>(
    collectionRef: CollectionReference<T>,
    ids: string[],
    options?: {
      batchSize?: number;
      ignoreNotFound?: boolean;
    }
  ): Promise<T[]> {
    this.checkInitialized();
    const { batchSize, ignoreNotFound } = options || {};
    return this.inner.getDocsByIdsPure(collectionRef, ids, {
      batchSize: batchSize || 10,
      ignoreNotFound: ignoreNotFound || false,
    });
  }

  async runTransaction<T>(
    updateFunction: (transaction: Transaction) => Promise<T>,
    options?: TransactionOptions
  ): Promise<T> {
    this.checkInitialized();
    return await this.inner.runTransaction(updateFunction, options);
  }

  getDownloadURL(ref: StorageReference): Promise<string> {
    this.checkInitialized();
    return this.inner.getDownloadURL(ref);
  }
  getMetadata(ref: StorageReference): Promise<FullMetadata> {
    this.checkInitialized();
    return this.inner.getMetadata(ref);
  }
  uploadBytes(
    ref: StorageReference,
    data: Blob | Uint8Array | ArrayBuffer,
    metadata?: unknown
  ): Promise<UploadResult> {
    this.checkInitialized();
    return this.inner.uploadBytes(ref, data, metadata);
  }
  deleteObject(ref: StorageReference): Promise<void> {
    this.checkInitialized();
    return this.inner.deleteObject(ref);
  }

  checkAndConvertTimestampToDate(input: any): Optional<Date> {
    this.checkInitialized();
    return this.inner.checkAndConvertTimestampToDate(input);
  }
  convertTimestampToDate<T extends object>(input: any, key: keyof T): void {
    this.checkInitialized();
    this.inner.convertTimestampToDate(input, key);
  }
  //#NOTE this will change the input object
  convertDateFieldsFromFirestore<T extends object>(
    input: T,
    paths: readonly PathsOfDateField<T>[]
  ): void {
    this.checkInitialized();
    this.inner.convertDateFieldsFromFirestore(input, paths);
  }
  convertDateFieldsFromFirestoreNotStrict<T extends object>(
    input: T,
    paths: readonly string[]
  ): void {
    this.checkInitialized();
    this.inner.convertDateFieldsFromFirestoreNotStrict(input, paths);
  }

  serverTimestamp(): FieldValue {
    this.checkInitialized();
    return this.inner.serverTimestamp();
  }

  isTimestamp(obj: object): boolean {
    this.checkInitialized();
    return this.inner.isTimestamp(obj);
  }

  isServerTimestamp(obj: object): boolean {
    this.checkInitialized();
    return this.inner.isServerTimestamp(obj);
  }

  deleteField(): FieldValue {
    this.checkInitialized();
    return this.inner.deleteField();
  }

  increment(n: number): FieldValue {
    this.checkInitialized();
    return this.inner.increment(n);
  }

  limit(n: number): QueryConstraint {
    return {
      kind: "limit",
      limit: n,
    };
  }

  startAfter<AppModelType, DbModelType extends DocumentData>(
    snapshot: DocumentSnapshot<AppModelType, DbModelType>
  ): QueryConstraint {
    return {
      kind: "startAfter",
      snapshot,
    };
  }

  startAfterFieldValues(...fieldValues: any[]): QueryConstraint {
    return {
      kind: "startAfterFieldValues",
      fieldValues,
    };
  }

  startAt<AppModelType, DbModelType extends DocumentData>(
    snapshot: DocumentSnapshot<AppModelType, DbModelType>
  ): QueryConstraint {
    return {
      kind: "startAt",
      snapshot,
    };
  }

  orderBy(fieldPath: string, directionStr?: "desc" | "asc"): QueryConstraint {
    return {
      kind: "orderBy",
      fieldPath,
      directionStr,
    };
  }

  where(
    fieldPath: string,
    opStr: WhereFilterOp,
    value: unknown
  ): QueryConstraint {
    return {
      kind: "where",
      fieldPath,
      opStr,
      value,
    };
  }
}

export const CoreFirestore = new FirestoreManager();

export function checkAndGetData<T, U extends DocumentData = DocumentData>(
  snapshot: DocumentSnapshot<T, U>
): T {
  const data = snapshot.data();
  if (snapshot.exists() && data) return data;
  throw new DocNotExist(`${snapshot.ref.path} not exist`);
}

export function checkAndTryGetData<T, U extends DocumentData = DocumentData>(
  snapshot: DocumentSnapshot<T, U>,
  ignoreNotFound = false
): Optional<T> {
  try {
    return checkAndGetData(snapshot);
  } catch (e) {
    if (ignoreNotFound && e instanceof DocNotExist) {
      return undefined;
    }
    throw e;
  }
}

export function getQueriedData<T, U extends DocumentData = DocumentData>(
  snapshot: QuerySnapshot<T, U>
) {
  return snapshot.docs.map((doc) => doc.data());
}

export function getQueriedDataWithId<T, U extends DocumentData = DocumentData>(
  snapshot: QuerySnapshot<T, U>
): WithId<T>[] {
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export function checkDuplicated(snapshot: DocumentSnapshot) {
  if (snapshot.exists() && snapshot.data())
    throw new AlreadyExist(`${snapshot.ref.path} already exist`);
}

//#NOTE minimum required fields from firebase/auth
export interface Auth {
  readonly currentUser: User | null;
}

//#NOTE minimum required fields from firebase/auth
export interface User {
  readonly uid: string;
  readonly email: string | null;
  getIdToken(forceRefresh?: boolean): Promise<string>;
  //extended from UserInfo
  readonly displayName: string | null;
  readonly phoneNumber: string | null;
  readonly photoURL: string | null;
}
export interface ICoreAuth {
  updateCurrentUser(auth: Auth, user: User | null): Promise<void>;
  updateEmail(user: User, newEmail: string): Promise<void>;
  updateProfile(
    user: User,
    update: {
      displayName?: string | null | undefined;
      photoURL?: string | null | undefined;
    }
  ): Promise<void>;
}

export class AuthManager implements ICoreAuth {
  private inner!: ICoreAuth;

  constructor() {}

  setup(inner: ICoreAuth) {
    this.inner = inner;
  }

  checkInitialized() {
    if (!this.inner) {
      throw new Error("Auth not initialized");
    }
  }

  updateCurrentUser(auth: Auth, user: User | null): Promise<void> {
    this.checkInitialized();
    return this.inner.updateCurrentUser(auth, user);
  }
  updateEmail(user: User, newEmail: string): Promise<void> {
    this.checkInitialized();
    return this.inner.updateEmail(user, newEmail);
  }
  updateProfile(
    user: User,
    update: {
      displayName?: string | null | undefined;
      photoURL?: string | null | undefined;
    }
  ): Promise<void> {
    this.checkInitialized();
    return this.inner.updateProfile(user, update);
  }
}

export const CoreAuth = new AuthManager();

export function convertAllTimestamp(item: any) {
  const maybeConverted = CoreFirestore.checkAndConvertTimestampToDate(item);
  if (maybeConverted) {
    return maybeConverted;
  } else if (!item) {
    // undefined or null
    return item;
  } else if (item instanceof Array) {
    (item as Array<any>).forEach((value, index) => {
      item[index] = convertAllTimestamp(value);
    });
  } else if (typeof item === "object") {
    Object.entries(item).forEach(([key, value]) => {
      item[key] = convertAllTimestamp(value);
    });
  }
  return item;
}
