import { Command, Event } from "./command";
import {
  AggregateBase,
  SupportValuationAggregate,
  handleAddInsurance,
  handleRemoveInsurance,
  setObjectDeleted,
} from "../aggregate";
import { Belonging } from "./belonging";
import { Encrypted } from "../../database/encryption";
import {
  applyUpdateToObject,
  calculateOwnedValue,
  deepCopy,
  UpdateObject,
} from "../../utils";
import { Amount, AssetV2, Attachment, MultiCurrencyAmount } from "../common";
import { InvalidInput } from "../error";
import { EventWithTime, SharedEvent, TagPair, preSealEvent } from "../event";
import { LocationInfo } from "../relations/locationInfo";
import { BelongingsUtils } from "./belongingsUtils";
import { EncryptionFieldKey } from "../../encryption/utils";
import { CollectableAcquisition } from "../common/acquisition";
import { SummaryUtils, SummaryTag } from "../summary";

export function toTagPair<T extends Pick<Belonging, "subtype">>(
  current: UpdateObject<T>,
  previous?: T
): TagPair[] {
  const tags: TagPair[] = [];
  if (current.subtype) {
    tags.push({ key: SummaryTag.Belonging.Subtype, val: current.subtype });
  } else if (previous && current.subtype !== null) {
    tags.push({ key: SummaryTag.Belonging.Subtype, val: previous.subtype });
  }
  return tags;
}

export class BelongingAggregate extends AggregateBase<
  Encrypted<Belonging>,
  Command,
  Event
> {
  state: Encrypted<Belonging>;
  kind: string;
  relatedReads?: BelongingsUtils.RelatedReads;
  relatedUpdates: BelongingsUtils.RelatedUpdates = {};

  constructor(
    state: Encrypted<Belonging>,
    relatedReads?: BelongingsUtils.RelatedReads
  ) {
    super();
    this.state = state;
    this.kind = state.assetType!; //#HACK: using bang(!) here, this is caused by the generic Encrypted type
    if (relatedReads) this.relatedReads = relatedReads;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return this.handleCreateAsset(command).map(preSealEvent);
      case Command.Kind.UpdateAsset:
        return this.handleUpdateAsset(command).map(preSealEvent);
      case Command.Kind.RelocateAsset:
        return this.handleRelocateAsset(command).map(preSealEvent);
      case Command.Kind.DeleteAsset:
        return this.handleDeleteAsset(command).map(preSealEvent);
      case Command.Kind.AddInsurance:
        return handleAddInsurance(
          this.state.acquisition?.insuranceIds,
          command
        ).map(preSealEvent);
      case Command.Kind.RemoveInsurance:
        return handleRemoveInsurance(
          this.state.acquisition?.insuranceIds,
          command
        ).map(preSealEvent);
      case Command.Kind.AddValuation:
        return SupportValuationAggregate.handleAdd<any>(this, command, {
          shouldUpdate: SummaryUtils.shouldUpdateSummary(this.state),
          detail: {
            myOwnership: this.state.ownership?.myOwnership,
            itemNumber: this.state.number!,
            tags: toTagPair(this.state),
          },
        });
      case Command.Kind.UpdateValuation:
        return SupportValuationAggregate.handleUpdate<any>(this, command, {
          shouldUpdate: SummaryUtils.shouldUpdateSummary(this.state),
          detail: {
            myOwnership: this.state.ownership?.myOwnership,
            itemNumber: this.state.number!,
            tags: toTagPair(this.state),
          },
        });
      case Command.Kind.DeleteValuation:
        return SupportValuationAggregate.handleDelete<any>(this, command, {
          shouldUpdate: SummaryUtils.shouldUpdateSummary(this.state),
          detail: {
            myOwnership: this.state.ownership?.myOwnership,
            itemNumber: this.state.number!,
            tags: toTagPair(this.state),
          },
        });
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state = event.asset;
        if (this.state.acquisition && this.state.acquisition.insuranceIds)
          this.relatedUpdates.addedInsuranceIds =
            this.state.acquisition.insuranceIds;
        this.state.createAt = time;
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetUpdated:
        applyUpdateToObject(
          this.state,
          // FIXME: remove `asset` after migration
          event.current ? event.current : event.asset
        );
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetDeleted:
        if (this.state.groupIds && this.state.groupIds.length > 0)
          this.relatedUpdates.removedGroupIds = this.state.groupIds;
        if (this.state.acquisition && this.state.acquisition.insuranceIds)
          this.relatedUpdates.removedInsuranceIds =
            this.state.acquisition.insuranceIds;
        this.state = setObjectDeleted(this.state);
        break;
      case Event.Kind.ValueUpdated:
        this.state.value = event.current;
        if (event.valuationId) this.state.valueSourceId = event.valuationId;
        else delete this.state.valueSourceId;
        this.state.updateAt = time;
        break;
      case Event.Kind.GroupsUpdated:
        if (event.addIds.length > 0)
          this.relatedUpdates.addedGroupIds = event.addIds;
        if (event.removedIds.length > 0)
          this.relatedUpdates.removedGroupIds = event.removedIds;
        break;
      case Event.Kind.LocationUpdated:
        this.state.location = event.current;
        this.state.updateAt = time;
        break;
      case Event.Kind.InsuranceUpdated:
        if (event.previous && event.previous.length > 0) {
          if (event.current) {
            const removedInsuranceIds: string[] = [];
            event.previous.forEach((id) => {
              if (event.current!.includes(id)) return;
              removedInsuranceIds.push(id);
            });
            if (removedInsuranceIds.length > 0) {
              this.relatedUpdates.removedInsuranceIds = removedInsuranceIds;
            }
          } else {
            this.relatedUpdates.removedInsuranceIds = event.previous;
          }
        } else if (event.current) {
          this.relatedUpdates.addedInsuranceIds = event.current;
        }
        if (this.state.acquisition) {
          this.state.acquisition.insuranceIds = event.current;
        } else {
          this.state.acquisition = {
            //#HACK: nothing encrypted, will be replace by asset IV if encrypted filed is added
            ...CollectableAcquisition.defaultValueWithEncryptedField(""),
            insuranceIds: event.current,
          };
        }
        break;

      case Event.Kind.ValuationAdded:
        //#HACK: Using any to replace Encrypted<Belonging>
        SupportValuationAggregate.applyAdded<any>(this, event);
        break;
      case Event.Kind.ValuationUpdated:
        //#HACK: Using any to replace Encrypted<Belonging>
        SupportValuationAggregate.applyUpdated<any>(this, event);
        break;
      case Event.Kind.ValuationDeleted:
        //#HACK: Using any to replace Encrypted<Belonging>
        SupportValuationAggregate.applyDeleted<any>(this, event);
    }
    return this;
  }

  private handleCreateAsset({
    asset,
    executerId,
    valuation,
  }: Command.CreateAsset): Event[] {
    if (!valuation)
      throw new InvalidInput("Valuation is required when creating asset");
    asset.valueSourceId = valuation.id;

    //#HACK: Since some fields are mistakenly typed as Encrypted, we can use type casting here, this is caused by the generic Encrypted type
    const hackCurr = asset as Belonging;
    const currTags = toTagPair(hackCurr);

    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.AssetCreated,
        asset,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: {},
            prevAssetNumber: 0,
            currAssetNumber: 1,
            prevItemNumber: 0,
            currItemNumber: hackCurr.number,
            currTags: deepCopy(currTags),
          },
        ],
      },
      {
        executerId,
        kind: Event.Kind.LocationUpdated,
        //#HACK: using bang(!) and type casting here, this is caused by the generic Encrypted type
        current: asset.location! as LocationInfo.Encrypted,
      },
      {
        executerId,
        kind: Event.Kind.ValuationAdded,
        data: valuation,
      },
      {
        executerId,
        kind: Event.Kind.ValueUpdated,
        current: hackCurr.value,
        valuationId: valuation.id,
        summaryData: [
          {
            prevOwnedValue: {},
            currOwnedValue: MultiCurrencyAmount.fromAmounts(
              calculateOwnedValue(
                hackCurr.value,
                hackCurr.ownership?.myOwnership
              )
            ),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: hackCurr.number,
            currItemNumber: hackCurr.number,
            prevTags: deepCopy(currTags),
            currTags: deepCopy(currTags),
          },
        ],
      },
    ];

    if (asset.groupIds && asset.groupIds.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: asset.groupIds,
        removedIds: [],
      });
    }
    if (asset.ownership) {
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        current: hackCurr.ownership,
      };
      events.push(shareholderUpdated);
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        current: hackCurr.beneficiary,
      });
    }
    if (asset.attachments) {
      events.push(
        ...SharedEvent.attachmentEventOnCreate(
          executerId,
          //#HACK: using type casting here, this is caused by the generic Encrypted type
          asset.attachments as Attachment.Encrypted[],
          asset.mainImage
        )
      );
    }
    return events;
  }

  private handleUpdateAsset({
    executerId,
    asset,
    addedToGroup,
    removedFromGroup,
    newImages,
    newMainImage,
    locationPrimaryDetailsUpdated,
  }: Command.UpdateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    const events: Event[] = [];
    //#HACK: Since some fields are mistakenly typed as Encrypted, we can use type casting here, this is caused by the generic Encrypted type
    const hackPrev = this.state as Belonging;
    const hackCurr = asset as UpdateObject<Belonging>;
    // use this to trace the owned value change after each event
    let prevTags = toTagPair(this.state);
    let prevValue = hackPrev.value;
    let prevOwnedValue = calculateOwnedValue(
      hackPrev.value,
      this.state.ownership?.myOwnership
    );

    if (hackCurr.value) {
      const currOwnedValue = calculateOwnedValue(
        hackCurr.value,
        this.state.ownership?.myOwnership
      );
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: hackPrev.value,
        current: hackCurr.value,
        summaryData: [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
            currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
            prevAssetNumber: 1,
            currAssetNumber: 1,
            prevItemNumber: hackPrev.number,
            currItemNumber: hackPrev.number,
            prevTags: deepCopy(prevTags),
            currTags: deepCopy(prevTags),
          },
        ],
      });
      prevValue = { ...hackCurr.value };
      prevOwnedValue = { ...currOwnedValue };
      delete asset.value;
    }
    const assetUpdatedEvent: Event.AssetUpdated = {
      executerId,
      kind: Event.Kind.AssetUpdated,
      // TODO: remove this
      asset,
      previous: deepCopy(this.state),
      current: asset,
    };

    const currOwnedValue = calculateOwnedValue(
      prevValue,
      hackCurr.ownership?.myOwnership || this.state.ownership?.myOwnership
    );
    const isTagUpdated = hackCurr.subtype;
    if (
      !Amount.equal(prevOwnedValue, currOwnedValue) ||
      hackCurr.number ||
      isTagUpdated
    ) {
      const currTags = toTagPair(hackCurr, hackPrev);
      assetUpdatedEvent.summaryData = [
        {
          prevOwnedValue: MultiCurrencyAmount.fromAmounts(prevOwnedValue),
          currOwnedValue: MultiCurrencyAmount.fromAmounts(currOwnedValue),
          prevAssetNumber: 1,
          currAssetNumber: 1,
          prevItemNumber: hackPrev.number,
          currItemNumber: hackCurr.number || hackPrev.number,
          prevTags: deepCopy(prevTags),
          currTags: deepCopy(currTags),
        },
      ];
      if (isTagUpdated) prevTags = deepCopy(currTags);
    }
    if (locationPrimaryDetailsUpdated)
      assetUpdatedEvent.locationPrimaryDetailsUpdated = true;
    events.push(assetUpdatedEvent);

    if (addedToGroup || removedFromGroup) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: addedToGroup ?? [],
        removedIds: removedFromGroup ?? [],
      });
    }
    if (newImages) {
      events.push({
        executerId,
        kind: Event.Kind.ImageAdded,
        images: newImages,
      });
    }
    if (newMainImage) {
      events.push({
        executerId,
        kind: Event.Kind.MainImageSet,
        previous: this.state.mainImage,
        current: newMainImage,
      });
    }

    //#HACK: using bang(!) and type casting here, this is caused by the generic Encrypted type
    const hackStateLocation = this.state.location! as LocationInfo.Encrypted;
    const hackAssetLocation = asset.location! as LocationInfo.Encrypted;

    if (
      hackAssetLocation &&
      hackAssetLocation.locationId !== hackStateLocation.locationId
    ) {
      const event: Event.LocationUpdated = {
        executerId,
        kind: Event.Kind.LocationUpdated,
        current: hackAssetLocation,
      };
      if (this.state.location) event.previous = hackStateLocation;
      events.push(event);
    }
    if (asset.acquisition) {
      const maybeEvent = SharedEvent.insuranceEventOnUpdate(
        executerId,
        this.state.acquisition?.insuranceIds,
        asset.acquisition.insuranceIds
      );
      if (maybeEvent) events.push(maybeEvent);
    }

    if (hackCurr.ownership) {
      const shareholderUpdated: Event.ShareholderUpdated = {
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        previous: hackPrev.ownership,
        current: hackCurr.ownership,
      };
      events.push(shareholderUpdated);
    }
    if (hackCurr.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        previous: hackPrev.beneficiary,
        current: hackCurr.beneficiary,
      });
    }

    return events;
  }

  private handleRelocateAsset({
    executerId,
    fromLocationId,
    toLocation,
  }: Command.RelocateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    LocationInfo.validateEncryptedObj(toLocation);
    //#HACK: using bang(!) and type casting here, this is caused by the generic Encrypted type
    const location = this.state.location! as LocationInfo.Encrypted;

    if (location.locationId == fromLocationId) {
      return [
        {
          executerId,
          kind: Event.Kind.LocationUpdated,
          previous: location,
          current: {
            ...toLocation,
            [EncryptionFieldKey]: location[EncryptionFieldKey],
          },
        },
      ];
    } else {
      return [];
    }
  }

  private handleDeleteAsset({ executerId }: Command.DeleteAsset): Event[] {
    AssetV2.checkDelete(this.state);
    const hackPrev = this.state as Belonging;

    return [
      {
        executerId,
        kind: Event.Kind.AssetDeleted,
        summaryData: [
          {
            prevOwnedValue: MultiCurrencyAmount.fromAmounts(
              calculateOwnedValue(
                hackPrev.value,
                hackPrev.ownership?.myOwnership
              )
            ),
            currOwnedValue: {},
            prevAssetNumber: 1,
            currAssetNumber: 0,
            prevItemNumber: hackPrev.number,
            currItemNumber: 0,
            prevTags: toTagPair(hackPrev),
            currTags: [],
          },
        ],
      },
    ];
  }
}
