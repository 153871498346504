import { useCallback, useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { PermissionCategory } from 'core/remodel/refPaths'
import { cn } from '@/utils/classnames'
import useIsMobile from '@/hooks/useIsMobile'
import { useAuthStore } from '@/store/authStore'
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import {
  BelongingIcon,
  CollectableIcon,
  DashboardIcon,
  DocumentVaultIcon,
  FinanceIcon,
  GroupIcon,
  MyAssetsLogo,
  PanelIcon,
  PropertyIcon
} from '@/components/icon'

type LinkData = {
  title: string
  icon: JSX.Element
  url: string
  disabled: boolean
  exact?: boolean
}

export default function SideBar({ className }: { className?: string }) {
  const [isExpand, setIsExpand] = useState(false)
  const router = useRouter()
  const { t } = useTranslation()
  const { canView } = useAuthStore((state) => state.permissions)
  const isMobile = useIsMobile()
  const links: LinkData[] = [
    {
      title: t('GlobalDashboard'),
      icon: <DashboardIcon size={32} />,
      url: '/',
      disabled: !canView('GlobalDashboard'),
      exact: true
    },
    {
      title: t('MyFinances'),
      icon: <FinanceIcon size={32} />,
      url: '/finances',
      disabled: !canView(PermissionCategory.Finance) && !canView(PermissionCategory.Insurance)
    },
    {
      title: t('MyProperties'),
      icon: <PropertyIcon size={32} />,
      url: '/properties',
      disabled: !canView(PermissionCategory.Property)
    },
    {
      title: t('MyCollectables'),
      icon: <CollectableIcon size={32} />,
      url: '/collectables',
      disabled: !canView('MyCollectables')
    },
    {
      title: t('MyBelongings'),
      icon: <BelongingIcon size={32} />,
      url: '/belongings',
      disabled: !canView(PermissionCategory.Belonging)
    },
    {
      title: t('Groups'),
      icon: <GroupIcon size={32} />,
      url: '/groups',
      disabled: !canView('Groups')
    },
    {
      title: t('DocumentVault'),
      icon: <DocumentVaultIcon size={32} />,
      url: '/documents',
      disabled: !canView('DocumentVault')
    }
  ]

  // close sidebar after route change is complete
  useEffect(() => {
    const close = () => setIsExpand(false)
    router.events.on('routeChangeComplete', close)
    return () => router.events.off('routeChangeComplete', close)
  }, [router.events])

  return isMobile ? (
    <>
      <Button
        className={'flex h-[52px]  justify-start rounded-none hover:bg-background-hover'}
        onClick={() => setIsExpand((prev) => !prev)}
      >
        <div className={'flex items-center justify-center'}>
          <PanelIcon
            className={cn('transition-transform', isExpand ? '-scale-x-100 text-primary-hover' : 'scale-x-100')}
            size={32}
          />
        </div>
      </Button>
      <aside
        className={cn(
          'fixed left-0 top-[60px] z-30 flex h-screen w-[240px] flex-col overflow-hidden bg-background transition-transform duration-300',
          isExpand ? 'translate-x-0' : '-translate-x-full',
          className
        )}
      >
        {/*logo beta */}
        <div className={'flex items-center justify-center gap-x-2 py-4'}>
          <Link href={'/'} aria-label={'Go to homepage'} data-testid={'myassets-logo-link'}>
            <MyAssetsLogo width={147} height={30} />
          </Link>
          <span className={'rounded-full bg-white px-4 py-1 text-xs font-medium text-text'}>{'BETA'}</span>
        </div>

        {/* links */}
        <ul className={'w-full border-y border-gray-600'}>
          {links.map((link, index) => !link.disabled && <NavLink key={index} link={link} isExpand={true} />)}
        </ul>
      </aside>
    </>
  ) : (
    <aside
      className={cn(
        'fixed left-0 top-0 z-30 mt-[60px] flex h-[calc(100%-60px)] w-[60px] flex-col overflow-hidden bg-background transition-[width]',
        isExpand ? 'w-full md:w-[240px]' : 'w-[60px]',
        className
      )}
    >
      <Button
        className={'flex h-[52px] w-full justify-start rounded-none hover:bg-background-hover'}
        onClick={() => setIsExpand((prev) => !prev)}
      >
        <div className={'flex w-[60px] items-center justify-center'}>
          <PanelIcon
            className={cn('transition-transform', isExpand ? '-scale-x-100 text-primary-hover' : 'scale-x-100')}
            size={32}
          />
        </div>
      </Button>
      <ul>{links.map((link, index) => !link.disabled && <NavLink key={index} link={link} isExpand={isExpand} />)}</ul>
    </aside>
  )
}

interface NavLinkProps {
  link: LinkData
  isExpand: boolean
}

function NavLink({ link, isExpand }: NavLinkProps) {
  const router = useRouter()

  const isActive = useCallback(
    (url: string, exact = false) => {
      if (exact) return router.asPath === url
      return router.asPath.includes(url)
    },
    [router.asPath]
  )

  return isExpand ? (
    <li
      className={cn(
        'text-grey transition-colors',
        isActive(link.url, link.exact) ? 'bg-background-active [&_svg]:text-primary-hover' : 'hover:bg-background-hover'
      )}
    >
      <Link className={'flex items-center py-4'} href={link.url}>
        <div className={'flex w-[60px] shrink-0 items-center justify-center'}>{link.icon}</div>
        <span className={'whitespace-nowrap opacity-100 transition-opacity'}>{link.title}</span>
      </Link>
    </li>
  ) : (
    <Tooltip>
      <TooltipTrigger asChild={true}>
        <li
          className={cn(
            'text-grey transition-colors',
            isActive(link.url, link.exact)
              ? 'bg-background-active [&_svg]:text-primary-hover'
              : 'hover:bg-background-hover'
          )}
        >
          <Link className={'flex items-center py-4'} href={link.url}>
            <div className={'flex w-[60px] shrink-0 items-center justify-center'}>{link.icon}</div>
            <span className={'whitespace-nowrap opacity-0 transition-opacity'}>{link.title}</span>
          </Link>
        </li>
      </TooltipTrigger>
      <TooltipContent side={'right'}>{link.title}</TooltipContent>
    </Tooltip>
  )
}
