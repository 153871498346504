import * as React from 'react'
import { useState } from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'

export const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn('relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full', className)}
    {...props}
  />
))
Avatar.displayName = AvatarPrimitive.Root.displayName

type AvatarImageProps = React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> & {
  base64IV?: string
  placeholder?: string
}

export const AvatarImage = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Image>, AvatarImageProps>(
  ({ className, src, base64IV, placeholder, ...props }, ref) => {
    const [decryptedUrl, setDecryptedUrl] = useState<string | null>(null)

    React.useEffect(() => {
      const decryptImage = async () => {
        try {
          const { database } = useAuthStore.getState()
          const preferences = await database!.Account.getPreferences()
          const base64 = base64IV ?? preferences.ivSalt
          const response = await fetch(src!)
          const encrypted = await response.arrayBuffer()
          const iv = database!.Encryption.current.convertBase64ToIVSalt(base64)
          const decrypted = await database!.Encryption.current.decryptBytes(encrypted, iv)
          const blob = new Blob([decrypted], { type: 'image/jpeg' })
          const url = URL.createObjectURL(blob)
          setDecryptedUrl((prev) => {
            if (prev) URL.revokeObjectURL(prev)
            return url
          })
        } catch (error) {
          console.error(`Failed to fetch and decrypt image: ${error}`)
        }
      }

      if (src) decryptImage()
    }, [base64IV, src])

    if (decryptedUrl) {
      return (
        <AvatarPrimitive.Image
          ref={ref}
          className={cn('aspect-square h-full w-full', className)}
          src={decryptedUrl}
          {...props}
        />
      )
    }

    return (
      <AvatarPrimitive.Image
        ref={ref}
        className={cn('aspect-square h-full w-full', className)}
        src={placeholder}
        fetchPriority={'high'}
        {...props}
      />
    )
  }
)
AvatarImage.displayName = AvatarPrimitive.Image.displayName

export const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn('flex h-full w-full items-center justify-center rounded-full bg-slate-500 text-white', className)}
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName
