import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { PermissionCategory } from 'core/remodel/refPaths'
import { fetchSubscription, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'
import { Button, Modal } from '@/components/base'

import {
  ArrowLeftIcon,
  BelongingIcon,
  CollectableIcon,
  ContactIcon,
  DelegateIcon,
  DocumentVaultIcon,
  FinanceIcon,
  GroupIcon,
  PropertyIcon
} from './icon'

interface AssetCreationOption {
  id: string
  icon: React.ReactNode
  label: string
  disabled: boolean
  route?: string
  subOptions?: AssetCreationOption[]
}

interface AssetsCreationProps {
  isOpen: boolean
  onClose: () => void
}

export function AssetsCreation({ isOpen, onClose }: AssetsCreationProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const database = useAuthStore((state) => state.database)
  const { canCreate } = useAuthStore((state) => state.permissions)
  const [currentView, setCurrentView] = useState<'main' | 'sub'>('main')
  const [selectedOption, setSelectedOption] = useState<AssetCreationOption | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { data: subscription } = useSWR([userQuery.subscription], fetchSubscription(database!))
  const isSeatsFull = subscription?.delegates.seats === subscription?.delegates.consumed
  const onCreateDelegate = () => {
    if (isSeatsFull) {
      setErrorMessage(t('delegates:SeatsFull'))
      return
    }
    router.push('/account/delegates/create')
  }
  useEffect(() => {
    if (!isOpen) {
      setCurrentView('main')
      setSelectedOption(null)
    }
  }, [isOpen])

  const optionsAssets: AssetCreationOption[] = [
    {
      id: 'myFinances',
      icon: <FinanceIcon className={'h-10 w-10'} />,
      label: t('MyFinances'),
      disabled: !canCreate(PermissionCategory.Finance),
      subOptions: [
        {
          id: 'cashAndBank',
          icon: <FinanceIcon className={'h-8 w-8'} />,
          label: t('finances:PageTabs.CashAndBanking'),
          route: '/finances/accounts/create',
          disabled: !canCreate(PermissionCategory.Finance)
        },
        {
          id: 'traditionalInvestments',
          icon: <FinanceIcon className={'h-8 w-8'} />,
          label: t('finances:PageTabs.TraditionalInvestments'),
          route: '/finances/tradit-invest/create',
          disabled: true
        },
        {
          id: 'otherInvestments',
          icon: <FinanceIcon className={'h-8 w-8'} />,
          label: t('finances:PageTabs.OtherInvestment'),
          route: '/finances/other-invest/create',
          disabled: true
        },
        {
          id: 'cryptocurrencies',
          icon: <FinanceIcon className={'h-8 w-8'} />,
          label: t('finances:PageTabs.Cryptocurrencies'),
          route: '/finances/crypto/create',
          disabled: true
        },
        {
          id: 'insurances',
          icon: <FinanceIcon className={'h-8 w-8'} />,
          label: t('finances:PageTabs.Insurances'),
          route: '/finances/insurance/create',
          disabled: true
        }
      ]
    },
    {
      id: 'myProperties',
      icon: <PropertyIcon className={'h-10 w-10'} />,
      label: t('MyProperties'),
      route: '/properties/summary/create',
      disabled: !canCreate(PermissionCategory.Property)
    },
    {
      id: 'myCollectables',
      icon: <CollectableIcon className={'h-10 w-10'} />,
      label: t('MyCollectables'),
      disabled:
        !canCreate(PermissionCategory.Art) &&
        !canCreate(PermissionCategory.Wine) &&
        !canCreate(PermissionCategory.OtherCollectables),
      subOptions: [
        {
          id: 'art',
          icon: <CollectableIcon className={'h-8 w-8'} />,
          label: t('collectables:Art'),
          route: '/collectables/art/create',
          disabled: !canCreate(PermissionCategory.Art)
        },
        {
          id: 'wine',
          icon: <CollectableIcon className={'h-8 w-8'} />,
          label: t('collectables:Wine'),
          route: '/collectables/wine/create',
          disabled: true
        },
        {
          id: 'other',
          icon: <CollectableIcon className={'h-8 w-8'} />,
          label: t('collectables:OtherCollectables'),
          route: '/collectables/other/create',
          disabled: !canCreate(PermissionCategory.OtherCollectables)
        }
      ]
    },
    {
      id: 'myBelongings',
      icon: <BelongingIcon className={'h-10 w-10'} />,
      label: t('MyBelongings'),
      route: '/belongings/summary/create',
      disabled: !canCreate(PermissionCategory.Belonging)
    }
  ]
  const optionsOthers: AssetCreationOption[] = [
    {
      id: 'documentVault',
      icon: <DocumentVaultIcon className={'h-10 w-10'} />,
      label: t('DocumentVault'),
      route: '/documents/summary/create',
      disabled: false
    },
    {
      id: 'groups',
      icon: <GroupIcon className={'h-10 w-10'} />,
      label: t('Groups'),
      route: '/groups/summary/create',
      disabled: false
    },
    {
      id: 'delegate',
      icon: <DelegateIcon className={'h-10 w-10'} />,
      label: t('delegates:Delegates'),
      route: '/account/delegates/create',
      disabled: false
    },
    {
      id: 'contacts',
      icon: <ContactIcon className={'h-10 w-10'} />,
      label: t('Contacts'),
      route: '/account/contacts/create',
      disabled: false
    }
  ]
  const handleOptionClick = (option: AssetCreationOption) => {
    if (option.id === 'delegate') {
      onCreateDelegate()
      return
    }
    if (option.subOptions) {
      setSelectedOption(option)
      setCurrentView('sub')
    } else if (option.route) {
      onClose()
      router.push(option.route)
    }
  }

  const handleBack = () => {
    setCurrentView('main')
    setSelectedOption(null)
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Modal className={'mx-4 w-full max-w-xs sm:max-w-sm'} onBackdropClick={onClose}>
          <Modal.Content className={'gap-y-8'}>
            <div className={'relative flex items-center justify-center'}>
              <label className={'text-lg font-medium text-primary'}>{t('AddAsset')}</label>
              {currentView === 'sub' ? (
                <button onClick={handleBack} className={'absolute left-0 text-primary'}>
                  <ArrowLeftIcon />
                </button>
              ) : null}
              <Modal.CloseButton className={'absolute right-0 text-primary'} onClose={onClose} />
            </div>

            <div style={{ position: 'relative', overflow: 'hidden' }}>
              <AnimatePresence mode={'wait'}>
                {currentView === 'main' ? (
                  <motion.div
                    key={'main'}
                    initial={{ x: '-100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    transition={{ type: 'tween', duration: 0.3 }}
                    className={'grid grid-cols-2 gap-2'}
                  >
                    {optionsAssets.map((option) => (
                      <Button
                        key={option.id}
                        variant={'outline'}
                        className={cn(
                          'flex h-24 flex-col items-center justify-center gap-2 border-[#E5E7EB] bg-[#FBFCFE]',
                          'hover:border-primary hover:bg-[#FBFCFE]'
                        )}
                        onClick={() => handleOptionClick(option)}
                        disabled={option.disabled}
                      >
                        {option.icon}
                        <span className={'text-sm text-black'}>{option.label}</span>
                      </Button>
                    ))}
                    <div className={'col-span-2 w-full text-center text-lg font-medium uppercase text-primary'}>
                      {t('Others')}
                    </div>
                    {optionsOthers.map((option) => (
                      <Button
                        key={option.id}
                        variant={'outline'}
                        className={cn(
                          'flex h-24 flex-col items-center justify-center gap-2 border-[#E5E7EB] bg-[#FBFCFE]',
                          'hover:border-primary hover:bg-[#FBFCFE]'
                        )}
                        onClick={() => handleOptionClick(option)}
                        disabled={option.disabled}
                      >
                        {option.icon}
                        <span className={'text-sm text-black'}>{option.label}</span>
                      </Button>
                    ))}
                  </motion.div>
                ) : (
                  <motion.div
                    key={'sub'}
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '100%' }}
                    transition={{ type: 'tween', duration: 0.3 }}
                    className={'flex flex-col items-center gap-2'}
                  >
                    <div className={'mb-2 flex h-24 w-full flex-col items-center justify-between gap-3 text-primary'}>
                      {selectedOption?.icon}
                      <span className={'text-sm text-black'}>{selectedOption?.label}</span>
                      <div className={'h-px w-full bg-gray-200'} />
                    </div>
                    {selectedOption?.subOptions?.map((subOption) => (
                      <Button
                        key={subOption.id}
                        variant={'outline'}
                        className={cn(
                          'h-12 w-full border-[#E5E7EB] bg-[#FBFCFE]',
                          'hover:border-primary hover:bg-[#FBFCFE]'
                        )}
                        onClick={() => handleOptionClick(subOption)}
                        disabled={subOption.disabled}
                      >
                        <span className={'text-sm text-black'}>{subOption.label}</span>
                      </Button>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </Modal.Content>
        </Modal>
      )}
      {errorMessage && (
        <Modal>
          <Modal.Header className={'bg-primary'}>
            <label className={'text-sm font-medium uppercase text-white'}>{t('delegates:Delegate')}</label>
            <Modal.CloseButton className={'text-white'} onClose={() => setErrorMessage(null)} />
          </Modal.Header>
          <Modal.Content>
            <div className={'flex min-h-[50px] flex-col justify-center gap-4'}>
              <span className={'max-w-[416px] break-words text-sm text-gray-600'}>{errorMessage}</span>
            </div>
          </Modal.Content>
        </Modal>
      )}
    </AnimatePresence>
  )
}
