import Decimal from "decimal.js";
import { DocumentReference, Transaction } from "../../coreFirebase";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import { Event as BelongingEvent } from "./belongings";
import {
  Amount,
  AssetType,
  LocationType,
  MultiCurrencyAmount,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { EventEnvelope } from "./event";
import {
  AssetNumber,
  buildUpdaterMap,
  ItemNumber,
  MultiCurrencyValue,
  SubCategoryItem,
  SummaryUtils,
  SummaryTag,
} from "./summary";
import {
  BelongingSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";
import { calculatePercentage, OmitKeys } from "../utils";

export type Event = EventEnvelope<BelongingEvent>;
export interface BelongingSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.BelongingSummary, 3>;
  id: AssetType.Belonging | AssetType.OtherCollectables;
  // only minimal info is updated for each tag
  [SummaryTag.Belonging.Subtype]: SubCategoryItem[]; // value, itemNumber, assetNumber
}
export interface SubtypeItem {
  label: string;
  assetNumber: number;
  itemNumber: number;
  value: Amount;
  percentage: number;
}

export namespace BelongingSummary {
  export function assureVersion(
    input: BelongingSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      BelongingSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    typesNumber: number;
    totalNumber: number;
    subtypeItems: SubtypeItem[];
  }

  export interface LocationInfo {
    id: string;
    locationType: LocationType;
    itemNumber: number;
    value: Amount;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<BelongingSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(snapshot.data(), assureVersion, () =>
      defaultValue(docRef.id as BelongingSummary["id"])
    );
    return new AggregateRoot(new BelongingSummaryAggregate(summary));
  }

  export function defaultValue(
    assetType: AssetType.OtherCollectables | AssetType.Belonging
  ): BelongingSummary {
    return {
      "@type": BelongingSummaryTypeVersion,
      id: assetType,
      [SummaryTag.Belonging.Subtype]: [],
      version: 0,
    };
  }

  export function toDisplay(
    input: BelongingSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let totalNumber = 0;
    let totalAssets = new Decimal(0);
    let totalLiabilities = new Decimal(0);
    const subtypes: SubtypeItem[] = [];
    input[SummaryTag.Belonging.Subtype].forEach((item) => {
      const value = MultiCurrencyAmount.calculateTargetCurrencyAmount(
        item.multiCurrencyValue,
        exchangeRate
      );
      subtypes.push({
        label: item.label,
        assetNumber: item.assetNumber,
        itemNumber: item.itemNumber,
        value: value,
        percentage: 0,
      });
      // compute total
      totalAssets = totalAssets.add(value.value);
      totalNumber += item.assetNumber;
    });

    const result: OmitKeys<Display, "typesNumber" | "subtypeItems"> = {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: totalLiabilities.toNumber(),
      },
      netValue: {
        currency,
        value: totalAssets.sub(totalLiabilities).toNumber(),
      },
      totalNumber,
    };
    const subtypeItems: SubtypeItem[] = subtypes.map((item) => {
      item.percentage = calculatePercentage(
        item.value.value,
        result.assets.value
      );
      return item;
    });
    subtypeItems.sort((a, b) => b.value.value - a.value.value);
    return { ...result, typesNumber: subtypeItems.length, subtypeItems };
  }

  export type RelatedUpdates = never;
}

export class BelongingSummaryAggregate extends AggregateBase<
  BelongingSummary,
  never,
  BelongingEvent
> {
  state: BelongingSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: BelongingSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    const itemNumber = new ItemNumber();
    const assetNumber = new AssetNumber();
    const multiCurrencyValue = new MultiCurrencyValue();
    SummaryUtils.applySummaryData(
      event,
      this.state,
      {
        [SummaryTag.Belonging.Subtype]: [
          itemNumber,
          assetNumber,
          multiCurrencyValue,
        ],
      },
      buildUpdaterMap(itemNumber, assetNumber, multiCurrencyValue)
    );
    return this;
  }
}
