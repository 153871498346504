import { EncryptedPackage, EncryptionLib } from "../types/encryptionLib";

const crypto = require("crypto").webcrypto;

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function ab2str(buf: ArrayBuffer) {
  return String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));
}

export default class Encryption implements EncryptionLib {
  protected readonly ekasEndpoint: string;
  mockLocalStorage: { [key: string]: string } = {};

  constructor(ekasEndpoint: string) {
    this.ekasEndpoint = ekasEndpoint;
  }

  async encryptWithDEK(data: string, dek: string, iv: Uint8Array): Promise<string> {
    if (typeof crypto === "undefined") return "";

    // console.log("Encrypting with DEK");
    const subtleCrypto = crypto.subtle;
    const dekStr = atob(dek);

    const encryptedData = await subtleCrypto.encrypt(
      { name: "AES-CBC", iv },
      await subtleCrypto.importKey(
        "raw",
        str2ab(dekStr),
        { name: "AES-CBC", length: 256 },
        false,
        ["encrypt"]
      ),
      new TextEncoder().encode(data)
    );
    return Buffer.from(encryptedData).toString("hex");
  }

  async encryptBytesWithDEK(
    data: Blob | Uint8Array | ArrayBuffer,
    dek: string,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    if (typeof crypto === "undefined")
      throw new Error(
        "encryptBytesWithDEK is not supported in this environment"
      );

    let dataArrayBuffer: ArrayBuffer | Uint8Array;
    if (data instanceof Blob) {
      dataArrayBuffer = await data.arrayBuffer();
    } else {
      dataArrayBuffer = data;
    }
    // console.log(dek);
    // console.log("Encrypting Bytes with DEK");
    const subtleCrypto = crypto.subtle;
    const dekStr = atob(dek);

    const encryptedData = await subtleCrypto.encrypt(
      { name: "AES-CBC", iv },
      await subtleCrypto.importKey(
        "raw",
        str2ab(dekStr),
        { name: "AES-CBC", length: 256 },
        false,
        ["encrypt"]
      ),
      dataArrayBuffer
    );
    return encryptedData;
  }

  async decryptWithDEK(encryptedData: string, dek: string, iv: Uint8Array): Promise<string> {
    if (typeof crypto === "undefined") return "";
    // console.log("Decrypting with DEK");
    const subtleCrypto = crypto.subtle;
    const dekStr = atob(dek);

    const decipher = await subtleCrypto.decrypt(
      { name: "AES-CBC", iv },
      await subtleCrypto.importKey(
        "raw",
        str2ab(dekStr),
        { name: "AES-CBC", length: 256 },
        false,
        ["decrypt"]
      ),
      new Uint8Array(Buffer.from(encryptedData, "hex"))
    );
    return new TextDecoder().decode(decipher);
  }

  async decryptBytesWithDEK(
    encryptedData: ArrayBuffer,
    dek: string,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    if (typeof crypto === "undefined")
      throw new Error(
        "decryptBytesWithDEK is not supported in this environment"
      );

    // console.log("Decrypting with DEK");
    const subtleCrypto = crypto.subtle;
    const dekStr = atob(dek);

    const decipher = await subtleCrypto.decrypt(
      { name: "AES-CBC", iv },
      await subtleCrypto.importKey(
        "raw",
        str2ab(dekStr),
        { name: "AES-CBC", length: 256 },
        false,
        ["decrypt"]
      ),
      encryptedData
    );
    return decipher;
  }

  async generateDEK(): Promise<string> {
    const subtleCrypto = crypto.subtle;
    const key = await subtleCrypto.generateKey(
      {
        name: "AES-CBC",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );

    const keyBuffer = await subtleCrypto.exportKey("raw", key);
    const keyString = ab2str(keyBuffer);
    return btoa(keyString);
  }

  async loadDEKFromStorage(userId: string): Promise<string | null> {
    return this.mockLocalStorage[`/deks/${userId}`] || null;
  }

  async storeDEKIntoStorage(dek: string, userId: string): Promise<void> {
    this.mockLocalStorage[`/deks/${userId}`] = dek;
  }

  async generateNCK(): Promise<{ publicKey: string; privateKey: string }> {
    if (typeof crypto === "undefined") return { publicKey: "", privateKey: "" };

    const subtleCrypto = crypto.subtle;
    const keyPair = await subtleCrypto.generateKey(
      { name: 'ECDH', namedCurve: 'P-256' },
      true,
      ['deriveKey', 'deriveBits']
    );

    // Export public key to send to server
    const publicKeyRaw = await subtleCrypto.exportKey('raw', keyPair.publicKey);
    const publicKeyBase64 = btoa(String.fromCharCode(...new Uint8Array(publicKeyRaw)));

    // Export private key for storage/later use
    const privateKeyPkcs8 = await subtleCrypto.exportKey('pkcs8', keyPair.privateKey);
    const privateKeyBase64 = btoa(String.fromCharCode(...new Uint8Array(privateKeyPkcs8)));

    return {
      publicKey: publicKeyBase64,
      privateKey: privateKeyBase64
    };
  }

  async decryptWithNCK(data: EncryptedPackage, privateKey: string): Promise<string> {
    if (typeof crypto === "undefined") return "";

    const subtleCrypto = crypto.subtle;
    const { encryptedData, ephemeralPublicKey, iv } = data;

    const encryptedBuffer = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));

    const ephemeralPublicKeyObj = await subtleCrypto.importKey(
      'raw',
      Uint8Array.from(atob(ephemeralPublicKey), c => c.charCodeAt(0)),
      { name: 'ECDH', namedCurve: 'P-256' },
      false,
      []
    );

    const privateKeyObj = await subtleCrypto.importKey(
      'pkcs8',
      Uint8Array.from(atob(privateKey), c => c.charCodeAt(0)),
      { name: 'ECDH', namedCurve: 'P-256' },
      false,
      ['deriveKey', 'deriveBits']
    );

    const sharedSecret = await subtleCrypto.deriveBits(
      { name: 'ECDH', public: ephemeralPublicKeyObj },
      privateKeyObj,
      256
    );

    const derivedKey = await subtleCrypto.digest('SHA-256', sharedSecret);

    // Then import as AES-GCM key
    const aesKey = await subtleCrypto.importKey(
      'raw',
      derivedKey,
      { name: 'AES-GCM', length: 256 },
      false,
      ['decrypt']
    );

    const decrypted = await subtleCrypto.decrypt(
      {
        name: 'AES-GCM',
        iv: Uint8Array.from(atob(iv), c => c.charCodeAt(0)),
        tagLength: 128
      },
      aesKey,
      encryptedBuffer
    );

    return new TextDecoder().decode(decrypted);
  }

  async encryptWithRSK(data: string, publicKey: string): Promise<string> {
    if (typeof crypto === "undefined") return "";

    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    const pemContents = publicKey.substring(
      pemHeader.length,
      publicKey.length - pemFooter.length - 1
    );

    // base64 decode the string to get the binary data
    const binaryDerString = atob(pemContents);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);
    const publicKeyBuffer = await crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"]
    );

    const subtleCrypto = crypto.subtle;

    const encryptedData = await subtleCrypto.encrypt(
      { name: "RSA-OAEP" },
      publicKeyBuffer,
      new TextEncoder().encode(data)
    );

    return btoa(
      String.fromCharCode.apply(null, Array.from(new Uint8Array(encryptedData)))
    );
  }

  async encryptWithNCK(data: string, publicKey: string): Promise<EncryptedPackage> {
    // if (typeof crypto === 'undefined')
    //   return {
    //     encryptedData: '',
    //     ephemeralPublicKey: '',
    //     iv: ''
    //   }

    // Use Node.js crypto instead of subtle
    const crypto = require('crypto');

    // Generate ephemeral key pair using Node.js crypto
    const ephemeralKeyPair = crypto.createECDH("prime256v1"); // P-256 curve
    ephemeralKeyPair.generateKeys();

    // Compute shared secret
    const sharedSecret = ephemeralKeyPair.computeSecret(
      Buffer.from(publicKey, "base64"),
      "base64",
      "hex"
    );

    // Derive key using SHA-256
    const derivedKey = crypto.createHash("sha256")
      .update(Buffer.from(sharedSecret, "hex"))
      .digest();

    // Generate IV
    const iv = crypto.randomBytes(12);

    // Create cipher
    const cipher = crypto.createCipheriv(
      "aes-256-gcm",
      derivedKey,
      iv
    );

    // Encrypt the data
    const ciphertext = Buffer.concat([
      cipher.update(data, "utf8"),
      cipher.final()
    ]);

    // Get auth tag and combine everything into one buffer
    const authTag = cipher.getAuthTag();
    const combinedBuffer = Buffer.concat([ciphertext, authTag]);

    return {
      encryptedData: combinedBuffer.toString("base64"),
      ephemeralPublicKey: ephemeralKeyPair.getPublicKey("base64"),
      iv: iv.toString("base64"),
    };
  }

  async getPublicKeyForEncrypt(idToken: string): Promise<string> {
    // console.log("getPublicKeyForEncrypt");

    const resp = await fetch(`${this.ekasEndpoint}/gpkfe`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "firebase-jwt": idToken,
      },
    });

    const { publicKey } = await resp.json();

    return publicKey;
  }

  async saveDEKForUser(encryptedDEK: string, idToken: string): Promise<void> {
    // console.log("saveDEKForUser");

    const resp = await fetch(`${this.ekasEndpoint}/sdfu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "firebase-jwt": idToken,
      },
      body: JSON.stringify({ encryptedDEK }),
    });

    if (!resp.ok) {
      throw new Error("Error getting DEK for user");
    }

    return;
  }

  async getDEKForUser(
    pubKey: string,
    idToken: string,
    uid: string
  ): Promise<EncryptedPackage> {
    // console.log("getDEKForUser");

    const resp = await fetch(`${this.ekasEndpoint}/gdfu`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + idToken,
        "firebase-jwt": idToken,
      },
      body: JSON.stringify({ publicKey: pubKey, uid }),
    });

    if (!resp.ok) {
      throw new Error("Error getting DEK for user");
    }

    const encryptedPackage = await resp.json();

    return encryptedPackage;
  }

  generateNewIVSalt(): Uint8Array {
    const iv = new Uint8Array(16)
    return crypto.getRandomValues(iv)
  }

  convertIVSaltToBase64(iv: Uint8Array): string {
    const base64String = Buffer.from(iv).toString('base64');

    return base64String
  }

  convertBase64ToIVSalt(base64: string): Uint8Array {
    const binaryDer = Buffer.from(base64, 'base64')
    return binaryDer as Uint8Array
  }
}

export class MockEncryption extends Encryption {
  private key = "O8bH9Oq8Gz9FR89mFhwY1zkJtD5MwTDcD6sa11UcdKk=";

  async generateDEK(): Promise<string> {
    return this.key;
  }

  async getDEKForUser(
    pubKey: string,
    idToken: string,
    uid: string
  ): Promise<EncryptedPackage> {
    // console.log("getDEKForUser");

    const { publicKey } = await this.generateNCK()
    return {
      encryptedData: this.key,
      ephemeralPublicKey: publicKey,
      iv: '1'
    }
  }
  async getPublicKeyForEncrypt(idToken: string): Promise<string> {
    // console.log("getPublicKeyForEncrypt");
    return "";
  }
  async encryptWithRSK(data: string, publicKey: string): Promise<string> {
    return data;
  }
  async saveDEKForUser(encryptedDEK: string, idToken: string): Promise<void> { }
}

export const NoEncryption: EncryptionLib = {
  async encryptWithDEK(data: string, dek: string, iv: Uint8Array): Promise<string> {
    return data;
  },
  async decryptWithDEK(encryptedData: string, dek: string, iv: Uint8Array): Promise<string> {
    return encryptedData;
  },
  async generateDEK(): Promise<string> {
    return "dek";
  },
  async loadDEKFromStorage(userId: string): Promise<string | null> {
    return "dek";
  },
  async encryptWithRSK(data: string, rsk: string): Promise<string> {
    return "encryptedData";
  },
  async storeDEKIntoStorage(userId: string, dek: string): Promise<void> { },
  async generateNCK(): Promise<{ publicKey: string; privateKey: string }> {
    return { publicKey: "pub", privateKey: "priv" };
  },
  async decryptWithNCK(encryptedData: EncryptedPackage, nck: string): Promise<string> {
    return "decryptedData";
  },
  async getPublicKeyForEncrypt(idToken: string): Promise<string> {
    return "pub";
  },
  async saveDEKForUser(encryptedDek: string, idToken: string): Promise<void> { },
  async getDEKForUser(
    publicKey: string,
    idToken: string,
    uid: string
  ): Promise<EncryptedPackage> {
    return {
      encryptedData: "dek",
      ephemeralPublicKey: "epk",
      iv: "iv"
    };
  },
  encryptBytesWithDEK: function (
    data: Blob | Uint8Array | ArrayBuffer,
    dek: string,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    throw new Error("Function not implemented.");
  },
  decryptBytesWithDEK: function (
    encryptedData: ArrayBuffer,
    dek: string,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    throw new Error("Function not implemented.");
  },
  generateNewIVSalt(): Uint8Array {
    const iv = new Uint8Array(16)
    return iv
  },
  convertIVSaltToBase64(_iv: Uint8Array): string {
    return ''
  },
  convertBase64ToIVSalt(_base64: string): Uint8Array {
    return new Uint8Array(0)
  }
};

