import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23ddd%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m15.93.98%205.433%205.433h-3.576a1.86%201.86%200%200%201-1.857-1.858zm-.55%2020.177a1.86%201.86%200%200%201-1.859%201.857H4.499a1.86%201.86%200%200%201-1.857-1.857V8.118A1.86%201.86%200%200%201%204.498%206.26h4.09v4.138a2.656%202.656%200%200%200%202.654%202.654h4.138z%22%20%2F%3E%3Cpath%20d%3D%22M15.675%2011.399h-3.576a1.86%201.86%200%200%201-1.858-1.858V5.966z%22%20%2F%3E%3Cpath%20d%3D%22M19.211%2018.525h-1.855v-7.537c0-.01-.006-.018-.006-.027a.4.4%200%200%200-.02-.099l-.014-.04a.4.4%200%200%200-.077-.115h-.001l-6.791-6.792a.4.4%200%200%200-.118-.078q-.016-.007-.034-.012a.4.4%200%200%200-.105-.021c-.009-.001-.016-.006-.025-.006H8.329v-.666a1.86%201.86%200%200%201%201.858-1.858h4.09v4.138a2.657%202.657%200%200%200%202.653%202.654h4.138v8.601a1.86%201.86%200%200%201-1.857%201.858%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CopyGroupIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 24 24'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'm15.93.98 5.433 5.433h-3.576a1.86 1.86 0 0 1-1.857-1.858zm-.55 20.177a1.86 1.86 0 0 1-1.859 1.857H4.499a1.86 1.86 0 0 1-1.857-1.857V8.118A1.86 1.86 0 0 1 4.498 6.26h4.09v4.138a2.656 2.656 0 0 0 2.654 2.654h4.138z'
        }
      />
      <path d={'M15.675 11.399h-3.576a1.86 1.86 0 0 1-1.858-1.858V5.966z'} />
      <path
        d={
          'M19.211 18.525h-1.855v-7.537c0-.01-.006-.018-.006-.027a.4.4 0 0 0-.02-.099l-.014-.04a.4.4 0 0 0-.077-.115h-.001l-6.791-6.792a.4.4 0 0 0-.118-.078q-.016-.007-.034-.012a.4.4 0 0 0-.105-.021c-.009-.001-.016-.006-.025-.006H8.329v-.666a1.86 1.86 0 0 1 1.858-1.858h4.09v4.138a2.657 2.657 0 0 0 2.653 2.654h4.138v8.601a1.86 1.86 0 0 1-1.857 1.858'
        }
      />
    </svg>
  )
}
