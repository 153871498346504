import {
  Belonging,
  BelongingDraft,
  BelongingDraftWithState,
  BelongingsUtils,
  Command,
  Event,
} from "../types/belongings";
import {
  AggregateRoot,
  AssetRelationStateWriter,
  Repo,
  buildUpdateGroupCommand,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import { AssetType, Currency, LocationType, Optional } from "../types/common";
import { AllowedDecimalPlaces, UpdateObject, addDecimal } from "../utils";
import { PropertiesRepo } from "./properties";
import { BelongingSummary } from "../types/belongingSummary";
import { ExchangeRate } from "../database/exchangeRate";
import { Valuation } from "../types/actions/valuation";
import { RoleToAsset } from "../types/relations";
import { FullRefs, Refs, getAssetDraftsByIds, getAssetsByIds } from "../refs";
import { InsuranceRepo } from "./insurance";
import { GroupUpdater } from "./groups";
import {
  Encrypted,
  EncryptionManager,
  fillBackEncryptedFields,
} from "./encryption";
import {
  actionRefFromAssetDocRef,
  buildAddValuationCommand,
  buildDeleteValuationCommand,
  buildUpdateValuationCommand,
  doGetAllActions,
} from "./actions";
import Decimal from "decimal.js";
import { Action } from "../types/actions";
import { DataPoisoned } from "../types/error";
import { DbSharedFields } from "../types/database";
import { SummaryManager } from "../types/summaryManager";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";
import { CashAndBankingRepo } from "./cashAndBanking";
import { validateWithGroups, ValidationGroup } from "../decorators";
import { LocationInfo } from "../types/relations/locationInfo";
import { DraftItemData } from "../types/groups";

export class BelongingsRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  // protected readonly userId: string;
  protected readonly assetType:
    | AssetType.Belonging
    | AssetType.OtherCollectables;
  protected readonly summaryRef: DocumentReference<BelongingSummary>;

  readonly Encryption: EncryptionManager;

  constructor(
    shared: DbSharedFields,
    assetType: AssetType.Belonging | AssetType.OtherCollectables
  ) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;

    this.assetType = assetType;
    this.summaryRef =
      assetType == AssetType.Belonging
        ? this.refs.currentRefs.BelongingSummary
        : this.refs.currentRefs.OtherCollectableSummary;
  }

  async getSyncedSummary(currency?: Currency) {
    const summary = (
      await this.summaryManager.get(this.assetType).syncAndGetData()
    ).summary;
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return BelongingSummary.toDisplay(summary, exRate);
  }

  private actionDocRef(id: string, valuationId: string) {
    return CoreFirestore.docFromCollection(
      this.refs.currentRefs.getActionCollectionRef<Valuation.Encrypted>(
        this.assetType,
        id
      ),
      valuationId
    );
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    ar: AggregateRoot<Encrypted<Belonging>, Command, Event>,
    isDelete?: boolean
  ) {
    const asset = ar.state();
    const data = ar.relatedUpdates() as BelongingsUtils.RelatedUpdates;
    const repoAndAggregates: BelongingsUtils.RelatedAggregates = {};
    let groupUpdater: Optional<GroupUpdater> = undefined;
    if (data.addedGroupIds || data.removedGroupIds) {
      groupUpdater = new GroupUpdater(
        this.refs.currentRefs,
        (data.addedGroupIds || []).concat(data.removedGroupIds || [])
      );
      await groupUpdater.read(transaction);
    }
    if (data.addedInsuranceIds || data.removedInsuranceIds) {
      repoAndAggregates.insurance = {
        repo: await InsuranceRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: [],
      };
      if (data.addedInsuranceIds) {
        for (const insuranceId of data.addedInsuranceIds) {
          repoAndAggregates.insurance!.aggregates.push(
            await InsuranceRepo.newArAndUpdateInsured(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              insuranceId,
              asset.id,
              this.assetType
            )
          );
        }
      }
      if (data.removedInsuranceIds) {
        for (const insuranceId of data.removedInsuranceIds) {
          repoAndAggregates.insurance!.aggregates.push(
            await InsuranceRepo.newArAndUpdateInsured(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              insuranceId,
              asset.id,
              this.assetType,
              false
            )
          );
        }
      }
    }
    if (isDelete) {
      repoAndAggregates.cashAndBanking = {
        repo: await CashAndBankingRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: await CashAndBankingRepo.newArAndUpdateAllocatedLiability(
          this.refs.currentRefs,
          transaction,
          this.refs.selfRefs.userId,
          asset.id
        ),
      };
    }
    // * writes
    if (data.addedGroupIds && groupUpdater) {
      for (const groupId of data.addedGroupIds) {
        groupUpdater.addOneItemToGroup(
          transaction,
          groupId,
          asset.id,
          this.assetType,
          asset.subtype!
        );
      }
    }
    if (data.removedGroupIds && groupUpdater) {
      for (const groupId of data.removedGroupIds) {
        groupUpdater.deleteOneItemFromGroup(transaction, groupId, asset.id);
      }
    }
    if (repoAndAggregates.insurance) {
      const insuranceData = repoAndAggregates.insurance;
      insuranceData.aggregates.forEach((aggregate) => {
        insuranceData.repo.commitWithState(aggregate);
      });
    }
    if (repoAndAggregates.cashAndBanking) {
      const accountData = repoAndAggregates.cashAndBanking;
      accountData.aggregates.forEach((aggregate) => {
        accountData.repo.commitWithState(aggregate);
      });
    }

    if (data.setActions) {
      data.setActions.forEach((action) => {
        const docRef = this.actionDocRef(asset.id, action.id);
        action.ownerId = this.refs.currentRefs.userId;
        transaction.set(docRef, action);
      });
    }
    if (data.removedActionIds) {
      data.removedActionIds.forEach((id) => {
        const docRef = this.actionDocRef(asset.id, id);
        transaction.delete(docRef);
      });
    }
  }

  async getAll(): Promise<Belonging[]> {
    const collectionRef = this.refs.currentRefs.getAssetCollectionRef<
      Encrypted<Belonging>
    >(this.assetType);
    const assets = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) =>
        BelongingsUtils.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
    return assets.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getByIds(ids: string[]): Promise<Belonging[]> {
    const result = await getAssetsByIds<Encrypted<Belonging>>(
      this.refs.currentRefs,
      this.assetType,
      ids
    );
    return Promise.all(
      result.map((v) =>
        BelongingsUtils.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async getById(id: string): Promise<Belonging> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      id
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) =>
        BelongingsUtils.decryptAndConvertDate(v, this.Encryption.current)
      );
  }

  async addDraft(req: BelongingDraft["req"], groupId: string) {
    const newDocRef = this.refs.currentRefs.getAssetDraftDocRef<
      Encrypted<BelongingDraftWithState>
    >(this.assetType, req.id);
    const assetWithEmptyLocation: BelongingsUtils.CreateFields = {
      ...req,
      location: LocationInfo.emptyLocation,
    };

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);

      const belonging = BelongingsUtils.fromCreate(
        assetWithEmptyLocation,
        this.refs.currentRefs.userId
      );
      validateWithGroups(belonging, Belonging, [ValidationGroup.OnCreate]);

      const valuation = await Valuation.getSystemCreationEncrypted(
        CoreFirestore.genAssetId(),
        this.refs.currentRefs.userId,
        belonging.value,
        this.Encryption.current
      );
      const groupUpdater = new GroupUpdater(this.refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      const ar = BelongingsUtils.newAggregateRoot(
        BelongingsUtils.defaultStateValue()
      );
      const encrypted = await BelongingsUtils.encrypt(
        belonging,
        this.Encryption.current
      );

      ar.handle(
        Command.createAsset(this.refs.selfRefs.userId, encrypted, valuation)
      );
      const events = ar.applyAllChanges();
      const encryptedDraft = await BelongingsUtils.encryptDraft(
        {
          req,
          ownerId: this.refs.currentRefs.userId,
          groupId,
        },
        this.Encryption.current
      );
      transaction.set(newDocRef, {
        ...encryptedDraft,
        state: ar.state(),
      });
      let maybeEvent = events.find(
        (v) => v.data.kind === Event.Kind.ValueUpdated
      );
      let updateDraftItemData: Optional<DraftItemData> = undefined;
      if (maybeEvent !== undefined) {
        const valueUpdated = maybeEvent.data as Event.ValueUpdated;
        updateDraftItemData = {
          assets: valueUpdated.current,
        };
      }
      groupUpdater.addOneItemToGroup(
        transaction,
        groupId,
        req.id,
        this.assetType,
        req.subtype,
        updateDraftItemData
      );
    });
  }

  async deleteDraft(id: string) {
    const docRef = this.refs.currentRefs.getAssetDraftDocRef<
      Encrypted<BelongingDraftWithState>
    >(this.assetType, id);
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const groupId = currentData.groupId;
      let groupUpdater: Optional<GroupUpdater> = undefined;
      if (groupId !== undefined) {
        groupUpdater = new GroupUpdater(this.refs.currentRefs, [groupId]);
        await groupUpdater.read(transaction);
        await groupUpdater.readItemsOfGroup(transaction, groupId, [id]);
      }

      transaction.delete(docRef);
      if (groupUpdater && groupId) {
        groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
      }
    });
  }

  // Info in encrypted state is enough for display
  async getEncryptedDraftStateById(
    id: string
  ): Promise<BelongingsUtils.DraftDisplay> {
    const docRef = this.refs.currentRefs.getAssetDraftDocRef<
      Encrypted<BelongingDraftWithState>
    >(this.assetType, id);
    const data = await CoreFirestore.getDoc(docRef).then(checkAndGetData);
    CoreFirestore.convertDateFieldsFromFirestoreNotStrict(
      data.state!,
      BelongingsUtils.datePaths
    );
    return data.state as BelongingsUtils.DraftDisplay;
  }

  // Info in encrypted state is enough for display
  async getAllEncryptedDraftStates(): Promise<BelongingsUtils.DraftDisplay[]> {
    const collectionRef = this.refs.currentRefs.getAssetDraftCollectionRef<
      Encrypted<BelongingDraftWithState>
    >(this.assetType);
    const data = await CoreFirestore.getDocsFromCollection(
      collectionRef,
      CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
    ).then(getQueriedData);
    data.map((v) =>
      CoreFirestore.convertDateFieldsFromFirestoreNotStrict(
        v.state!,
        BelongingsUtils.datePaths
      )
    );
    return data
      .map((v) => v.state as BelongingsUtils.DraftDisplay)
      .sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async addFromDrafts(ids: string[], location: LocationInfo) {
    const result = await getAssetDraftsByIds<
      Encrypted<BelongingDraftWithState>
    >(this.refs.currentRefs, this.assetType, ids);
    const requests: BelongingsUtils.CreateFields[] = (
      await Promise.all(
        result.map((v) =>
          BelongingsUtils.decryptDraftWithStateAndConvertDate(
            v,
            this.Encryption.current
          )
        )
      )
    ).map((v) => ({
      ...v.req,
      location,
    }));
    for (const req of requests) {
      const draftDocRef = this.refs.currentRefs.getAssetDraftDocRef<
        Encrypted<BelongingDraftWithState>
      >(this.assetType, req.id);
      const newDocRef = this.refs.currentRefs.getAssetDocRef<
        Encrypted<Belonging>
      >(this.assetType, req.id);
      await CoreFirestore.runTransaction(async (transaction) => {
        const draft = await transaction.get(draftDocRef).then(checkAndGetData);
        const groupId = draft.groupId;
        let groupUpdater: Optional<GroupUpdater> = undefined;
        if (groupId !== undefined) {
          groupUpdater = new GroupUpdater(this.refs.currentRefs, [groupId]);
          await groupUpdater.read(transaction);
          await groupUpdater.readItemsOfGroup(transaction, groupId, [req.id]);
        }

        await this.addInTransaction(transaction, newDocRef, req);
        transaction.delete(draftDocRef);
        if (groupUpdater && groupId) {
          groupUpdater.deleteOneItemFromGroup(transaction, groupId, req.id);
        }
      });
    }
  }

  private async addInTransaction(
    transaction: Transaction,
    newDocRef: DocumentReference<Encrypted<Belonging>>,
    req: BelongingsUtils.CreateFields
  ) {
    await transaction.get(newDocRef).then(checkDuplicated);
    if (req.location.locationType == LocationType.MyProperty)
      await PropertiesRepo.checkActiveAndGet(
        this.refs.currentRefs,
        transaction,
        req.location.locationId
      );
    const repo = await BelongingsRepo.newRepo(
      this.refs.currentRefs,
      transaction,
      this.assetType
    );

    const belonging = BelongingsUtils.fromCreate(
      req,
      this.refs.currentRefs.userId
    );
    validateWithGroups(belonging, Belonging, [ValidationGroup.OnCreate]);

    const valuation = await Valuation.getSystemCreationEncrypted(
      CoreFirestore.genAssetId(),
      this.refs.currentRefs.userId,
      belonging.value,
      this.Encryption.current
    );

    const ar = BelongingsUtils.newAggregateRoot(
      BelongingsUtils.defaultStateValue()
    );
    const encrypted = await BelongingsUtils.encrypt(
      belonging,
      this.Encryption.current
    );

    ar.handle(
      Command.createAsset(this.refs.selfRefs.userId, encrypted, valuation)
    );
    const events = ar.applyAllChanges();
    await this.handleRelatedAggregates(transaction, ar);
    //commit
    repo.manualCommit(ar, events);
  }

  async add(req: BelongingsUtils.CreateFields) {
    const newDocRef = this.refs.currentRefs.getAssetDocRef<
      Encrypted<Belonging>
    >(this.assetType, req.id);
    await CoreFirestore.runTransaction(async (transaction) => {
      await this.addInTransaction(transaction, newDocRef, req);
    });
  }

  async update(req: Belonging) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      req.id
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = BelongingsUtils.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
      if (
        req.location.locationType == LocationType.MyProperty &&
        //#HACK: using bang(!) here, this is caused by the generic Encrypted type
        req.location.locationId !== currentUndecrypted.location!.locationId
      )
        await PropertiesRepo.checkActiveAndGet(
          this.refs.currentRefs,
          transaction,
          req.location.locationId
        );
      const currentData = await BelongingsUtils.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const repo = await BelongingsRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        this.assetType
      );

      /* #NOTE
       ** if there are more than one fields like `notes`, ex: Belonging type has `notes` and `attributeNotes`, then we need to add more logic here to handle them
       **    => for example, if the updateObject only update `notes` field for this time,
       **       we need to manually assign back the `attributeNotes` from currentData to updateObject before calling encryptObject(),
       **       so that the new `_encrypted` data will contains both the lastest `notes` and `attributeNotes`
       */

      //#NOTE: undefined => unchanged , null => delete removable field in firestore

      const {
        updates: belongingUpdate,
        metadata: {
          addedToGroup,
          removedFromGroup,
          newImages,
          newMainImage,
          removedImages,
          locationPrimaryDetailsUpdated,
        },
      } = BelongingsUtils.intoUpdate(currentData, req);

      fillBackEncryptedFields(currentData, belongingUpdate, Belonging);

      validateWithGroups(belongingUpdate, Belonging, [
        ValidationGroup.OnUpdate,
      ]);

      const encryptedUpdate = await this.Encryption.current.encryptObject(
        belongingUpdate,
        Belonging
      );

      const ar = BelongingsUtils.newAggregateRoot(currentUndecrypted);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup,
          newImages,
          newMainImage,
          removedImages,
          locationPrimaryDetailsUpdated
        )
      );
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async delete(id: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = BelongingsUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
      const repo = await BelongingsRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        this.assetType
      );
      const ar = BelongingsUtils.newAggregateRoot(currentData);
      ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar, true);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async getActionById(assetId: string, actionId: string): Promise<Action> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      assetId
    );
    const actionCollectionRef = actionRefFromAssetDocRef(docRef);
    const actionDocRef = CoreFirestore.docFromCollection(
      actionCollectionRef,
      actionId
    );
    return await CoreFirestore.getDoc(actionDocRef)
      .then(checkAndGetData)
      .then((v) => Action.decryptAndConvertDate(v, this.Encryption.current));
  }

  async getAllActions(assetId: string): Promise<Action[]> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      assetId
    );
    return doGetAllActions(
      docRef,
      this.refs.currentRefs.userId,
      this.Encryption.current
    );
  }

  //#NOTE updateValuation is actually an addValuation
  async addValuation(assetId: string, createFields: Valuation.CreateFields) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = BelongingsUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
      const command = await buildAddValuationCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current,
        createFields.updateValue
      );

      const repo = await BelongingsRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        this.assetType
      );

      const ar = BelongingsUtils.newAggregateRoot(currentData);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateValuation(assetId: string, updateFields: Valuation.Update) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = BelongingsUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
      const { command, valuation } = await buildUpdateValuationCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        updateFields.id,
        updateFields,
        this.Encryption.current,
        currentData.valueSourceId
      );

      const repo = await BelongingsRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        this.assetType
      );

      const ar = BelongingsUtils.newAggregateRoot(currentData, {
        actions: {
          [updateFields.id]: valuation,
        },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteValuation(assetId: string, valuationId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      this.assetType,
      assetId
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = BelongingsUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
      const command = await buildDeleteValuationCommand(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        valuationId,
        this.Encryption.current
      );

      const repo = await BelongingsRepo.newRepo(
        this.refs.currentRefs,
        transaction,
        this.assetType
      );

      const ar = BelongingsUtils.newAggregateRoot(currentData);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

export namespace BelongingsRepo {
  export async function relocate(
    refs: Refs,
    executerId: string,
    id: string,
    assetType: AssetType.Belonging | AssetType.OtherCollectables,
    fromLocationId: string,
    toLocation: LocationInfo.Encrypted
  ) {
    const docRef = refs.getAssetDocRef<Encrypted<Belonging>>(assetType, id);
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = BelongingsUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
      const repo = await newRepo(refs, transaction, assetType);
      const ar = BelongingsUtils.newAggregateRoot(currentData);
      ar.handle(Command.relocateAsset(executerId, fromLocationId, toLocation));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function removeGroup(
    refs: FullRefs,
    id: string,
    assetType: AssetType.Belonging | AssetType.OtherCollectables,
    groupId: string
  ) {
    const docRef = refs.currentRefs.getAssetDocRef<Encrypted<Belonging>>(
      assetType,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = BelongingsUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();

      const repo = await newRepo(refs.currentRefs, transaction, assetType);
      const ar = BelongingsUtils.newAggregateRoot(currentData);
      const currentGroupIds = ar.state().groupIds;
      const arToCommit = buildUpdateGroupCommand(
        ar,
        Command.updateAsset,
        refs.selfRefs.userId,
        currentGroupIds,
        groupId,
        false
      );

      const groupUpdater = new GroupUpdater(refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      //commit
      if (arToCommit) {
        const events = arToCommit.applyAllChanges();
        repo.manualCommit(arToCommit, events);
      }

      // delete from group
      groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    assetType: AssetType.Belonging | AssetType.OtherCollectables,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<Encrypted<Belonging>>(assetType, id);
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      //#HACK: Since some fields are mistakenly typed as Encrypted, we can use type casting here, this is caused by the generic Encrypted type
      const hackCurr = currentUndecrypted as Belonging;
      const encryptedUpdate: UpdateObject<Encrypted<Belonging>> = {};
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Seller:
            if (contactId !== currentUndecrypted.acquisition?.sellerId)
              throw new DataPoisoned("sellerId mismatch");
            encryptedUpdate.acquisition = {
              ...currentUndecrypted.acquisition,
              sellerId: null!,
            };
            break;
          case RoleToAsset.Shareholder: {
            if (!hackCurr.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder = hackCurr.ownership.shareholder.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateShareholder.length === hackCurr.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            encryptedUpdate.ownership = {
              myOwnership: hackCurr.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!currentUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = currentUndecrypted.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateBeneficiary.length === currentUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            encryptedUpdate.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const repo = await newRepo(refs, transaction, assetType);
      const ar = BelongingsUtils.newAggregateRoot(currentUndecrypted);
      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function newRepo(
    refs: Refs,
    transaction: Transaction,
    assetType: AssetType.OtherCollectables | AssetType.Belonging
  ): Promise<Repo<Encrypted<Belonging>, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      assetType,
      new AssetRelationStateWriter(
        refs.getAssetCollectionRef(assetType),
        refs.Relations
      )
    );
  }

  export async function newArAndUpdateInsurance(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string,
    assetType: AssetType.OtherCollectables | AssetType.Belonging,
    insuranceId: string,
    isCreate: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Encrypted<Belonging>>(
      assetType,
      assetId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = BelongingsUtils.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
    const ar = BelongingsUtils.newAggregateRoot(currentData);
    ar.handle(
      isCreate
        ? Command.addInsurance(executerId, insuranceId)
        : Command.removeInsurance(executerId, insuranceId)
    );
    return ar;
  }

  export async function newArAndUpdateGroup(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string,
    assetType: AssetType.OtherCollectables | AssetType.Belonging,
    groupId: string,
    isAdd: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Encrypted<Belonging>>(
      assetType,
      assetId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = BelongingsUtils.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) BelongingsUtils.handleOutDated();
    const ar = BelongingsUtils.newAggregateRoot(currentData);
    const currentGroupIds = ar.state().groupIds;
    return buildUpdateGroupCommand(
      ar,
      Command.updateAsset,
      executerId,
      currentGroupIds,
      groupId,
      isAdd
    );
  }
}
