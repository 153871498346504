import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23577BFF%22%2F%3E%3Cpath%20fill%3D%22%232B4FD2%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#577BFF'}
      />
      <path fill={'#2B4FD2'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}
