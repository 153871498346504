import * as React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import { ChevronDown } from 'lucide-react'
import {
  useController,
  useWatch,
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'

import { cn } from '@/utils/classnames'

export const Select = SelectPrimitive.Root

export const SelectGroup = SelectPrimitive.Group

export const SelectValue = SelectPrimitive.Value

export const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'flex h-9 w-full items-center justify-between gap-x-1 rounded bg-white px-2 text-sm text-text placeholder:text-text',
      'focus:outline-none focus:ring-1 focus:ring-primary disabled:cursor-not-allowed disabled:opacity-50',
      className
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDown className={'h-4 w-4 text-primary'} />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

export const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
    // https://github.com/radix-ui/primitives/issues/1658#issuecomment-2333384681
      ref={(instance) => {
        if (typeof ref === 'function') {
          ref(instance)
        } else if (ref) {
          ref.current = instance
        }
        if (!instance) return
        // THIS IS A HACK
        instance.ontouchstart = (e) => {
          e.preventDefault()
        }
      }}
      className={cn(
        'relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-white text-text shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          'py-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

export const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)} {...props} />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & { subText?: string }
>(({ className, children, subText, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex w-full cursor-default select-none items-center px-4 py-2 text-sm outline-none focus:bg-primary focus:text-white',
      'data-[disabled]:pointer-events-none data-[state=checked]:bg-grey/25 data-[disabled]:opacity-50 data-[state=checked]:focus:bg-primary',
      'placeholder:text-grey/80',
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    {subText && <p className={'ml-2 text-xs text-gray-600'}>{subText}</p>}
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

export const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-grey', className)} {...props} />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

interface FormSelectProps<Values extends FieldValues, Path extends FieldPath<Values>>
  extends SelectPrimitive.SelectProps {
  label?: string
  className?: string
  contentClassName?: string
  placeholder?: React.ReactNode
  onSelected?: () => void
  onChanged?: (value: FieldPathValue<Values, Path>) => void
  // controller props
  name: Path
  control: Control<Values>
  rules?: RegisterOptions<Values>
  defaultValue?: FieldPathValue<Values, Path>
}

export function FormSelect<Values extends FieldValues, Path extends FieldPath<Values>>({
  label,
  name,
  control,
  rules,
  className,
  contentClassName,
  defaultValue,
  placeholder,
  children,
  onSelected,
  onChanged,
  ...props
}: FormSelectProps<Values, Path>) {
  const id = React.useId()
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules, defaultValue })
  const value = useWatch({ control, name })
  const isRequired = rules?.required !== undefined

  const handleValueChange = (value: FieldPathValue<Values, Path>) => {
    field.onChange(value)
    onChanged?.(value)
    onSelected?.()
  }
  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setTimeout(() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }
      }, 0)
    }
  }

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn('text-xs text-[#414554]', { 'after:ml-0.5 after:text-error after:content-["*"]': isRequired })}
        >
          {label}
        </label>
      )}
      <div className={cn('rounded border', error !== undefined && 'border-2 border-red-500')}>
        <Select value={value || undefined} onValueChange={handleValueChange} onOpenChange={handleOpenChange} {...props}>
          <SelectTrigger
            id={id}
            className={'bg-grey-input px-3 py-1.5 [&>span]:truncate'}
            data-testid={`${name}-select-trigger`}
            aria-label={`${name}-select-trigger`}
          >
            <SelectValue placeholder={placeholder} className={'text-grey/80'} />
          </SelectTrigger>
          <SelectContent className={cn('-ml-px max-h-[20rem] max-w-min overflow-y-auto', contentClassName)}>
            {children}
          </SelectContent>
        </Select>
      </div>
      {error && <p className={'text-xs text-red-500'}>{error.message}</p>}
    </div>
  )
}
