import { useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { WithId } from 'core/remodel/types/common'
import type { Delegate, DelegateInvite } from 'core/remodel/types/delegates'
import type { Profile } from 'core/remodel/types/user'
import { fetchProfile, userQuery } from '@/api/AccountService'
import { delegateQuery, fetchDelegatesOf, fetchInvitations } from '@/api/DelegateService'
import { cn } from '@/utils/classnames'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import useIsMobile from '@/hooks/useIsMobile'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { useMask } from '@/store/mask'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/base'
import { AssetsCreation } from '@/components/AssetsCreation'
import TruncatedText from '@/components/base/TruncatedText'
import Confirm from '@/components/Confirm'
import CreateAssetButton from '@/components/CreactAssetButton'
import { GlobalSearch } from '@/components/GlobalSearch'
import { HelpCenter, type HelpCenterRef } from '@/components/HelpCenter'
import {
  ArrowRightIcon,
  BillingIcon,
  ContactIcon,
  DelegateIcon,
  EyeOffIcon,
  MyAssetsLogo,
  SignOutIcon,
  SupportIcon,
  UserIcon,
  WishlistIcon
} from '@/components/icon'
import SideBar from '@/components/SideBar'

export default function NavBar({ className }: { className?: string }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { delegatorId, canView } = useAuthStore((state) => state.permissions)
  const { data: profile } = useSWR([userQuery.profile], fetchProfile(database!))
  const { data: invitations } = useSWR([delegateQuery.invitations], fetchInvitations(database!))
  const { data: delegatesOf } = useSWR([delegateQuery.of], fetchDelegatesOf(database!))
  const selectedDelegateName = (delegatesOf ?? []).find(({ id }) => id === delegatorId)?.delegatorName
  const helpCenterRef = useRef<HelpCenterRef>(null)
  const isMobile = useIsMobile()
  const [isAssetsCreationOpen, setIsAssetsCreationOpen] = useState(false)

  const handleAssetsCreationOpen = () => {
    setIsAssetsCreationOpen(true)
  }

  const handleAssetsCreationClose = () => {
    setIsAssetsCreationOpen(false)
  }

  return (
    <>
      <header
        className={cn(
          'fixed left-0 top-0 z-40 w-full bg-background pl-4 pr-[calc(100%-100vw+16px)] shadow-lg',
          className
        )}
      >
        <div className={'flex h-[60px] items-center justify-between'}>
          {/* Mobile SideBar */}
          {isMobile && <SideBar />}
          {/* Desktop Logo */}
          <div className={'hidden items-center gap-x-4 sm:flex'}>
            <Link
              className={'hidden sm:block'}
              href={'/'}
              aria-label={'Go to homepage'}
              data-testid={'myassets-logo-link'}
            >
              <MyAssetsLogo width={147} height={24} />
            </Link>
            {/* Beta */}
            <span className={'rounded-full bg-white px-4 py-1 text-xs font-medium text-text'}>{'BETA'}</span>
            {/* loggedAccount */}
            {selectedDelegateName && (
              <Trans
                t={t}
                i18nKey={'account:LoggedAccount'}
                values={{ name: selectedDelegateName }}
                parent={(props: any) => (
                  <span className={'rounded bg-secondary-hover p-2 px-4 text-xs font-medium text-white'} {...props} />
                )}
                components={[<span key={0} className={'font-bold'} />]}
              />
            )}
          </div>
          <div className={'flex flex-1 items-center justify-end  gap-x-3  sm:gap-x-4'}>
            {/* Global Search */}
            <GlobalSearch />
            {/* Wishlist */}
            {canView('Wishlist') && (
              <Tooltip>
                <TooltipTrigger asChild={true}>
                  <Link href={'/wishlist'} aria-label={'Go to wishlist'} data-testid={'wishlist-link'}>
                    <WishlistIcon className={'text-grey transition-colors hover:text-primary-hover'} />
                  </Link>
                </TooltipTrigger>
                <TooltipContent side={'bottom'} sideOffset={12}>
                  {t('Wishlist')}
                </TooltipContent>
              </Tooltip>
            )}
            {/* Create Asset Button*/}
            <CreateAssetButton
              id={'finances_add_cash_and_banking'}
              label={t('finances:CreateAccount')}
              onClick={handleAssetsCreationOpen}
              aria-label={'add cash and banking'}
              data-testid={'finances-add-cash-and-banking-fixedButton'}
            />
            {/* User Menu */}
            <UserMenu
              profile={profile}
              delegatesOf={delegatesOf}
              invitations={invitations}
              onSupportOpen={() => helpCenterRef.current?.onOpen()}
            />
          </div>
        </div>
      </header>
      <HelpCenter ref={helpCenterRef} />
      <AssetsCreation isOpen={isAssetsCreationOpen} onClose={handleAssetsCreationClose} />
    </>
  )
}

interface UserMenuProps {
  profile: Profile | undefined
  delegatesOf: WithId<Delegate.EncryptedDelegator>[] | undefined
  invitations: WithId<DelegateInvite>[] | undefined
  onSupportOpen: () => void
}

function UserMenu({ profile, delegatesOf, invitations, onSupportOpen }: UserMenuProps) {
  const { t } = useTranslation()
  const { toast } = useToast()
  const router = useRouter()
  const logout = useAuthStore((state) => state.logout)
  const { delegatorId, changeToSelf, changeToDelegate } = useAuthStore((state) => state.permissions)
  const hasDelegates = delegatesOf && delegatesOf.length > 0
  const hasInvitations = invitations && invitations.length > 0
  // null is self, undefined is not selected
  const [selectedIdentityId, setSelectedIdentityId] = useState<undefined | null | string>(undefined)
  const { isMasked, toggleMask } = useMask()
  const isMobile = useIsMobile()
  const [isExpand, setIsExpand] = useState(false)
  const items = [
    {
      label: t('Profile'),
      url: '/account/profile',
      icon: <UserIcon className={'mr-2'} size={isMobile ? 32 : 16} />,
      disabled: delegatorId !== null,
      ariaLabel: 'Go to account settings',
      testId: 'account-settings-link'
    },
    {
      label: t('Delegates'),
      url: '/account/delegates',
      icon: <DelegateIcon className={'mr-2'} size={isMobile ? 32 : 16} />,
      disabled: delegatorId !== null,
      ariaLabel: 'Go to delegates',
      testId: 'delegates-link'
    },
    {
      label: t('Subscription'),
      url: '/account/subscription',
      icon: <BillingIcon className={'mr-2'} size={isMobile ? 32 : 16} />,
      disabled: delegatorId !== null,
      ariaLabel: 'Go to billing and subscription',
      testId: 'billing-and-subscription-link'
    },
    {
      label: t('Contacts'),
      url: '/account/contacts',
      icon: <ContactIcon className={'mr-2'} size={isMobile ? 32 : 16} />,
      // disabled: delegatorId !== null
      ariaLabel: 'Go to address book',
      testId: 'address-book-link'
    }
    // TODO: activity
    // {
    //   label: t('Activity'),
    //   url: '/account/activity',
    //   icon: <ActivityIcon className={'mr-2'} size={16} />,
    //   disabled: delegatorId !== null
    // }
  ]

  const handleLogout = async () => {
    try {
      if (isMasked) toggleMask()
      await logout(false)
      toast({ variant: 'success', description: t('auth:Toast.Success.SignOut') })
      router.replace('/auth/login/')
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = parseErrorMessage(e, 'Unknown error: Sign out')
        toast({ variant: 'error', description: errorMessage })
      }
    }
  }

  const handleChangeSelf = async () => {
    try {
      await changeToSelf()
      router.push('/')
      toast({ variant: 'success', description: 'Now viewing as yourself' })
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Switch to self')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  const handleChangeDelegate = async (id: string) => {
    try {
      const delegatorName = delegatesOf?.find(({ id }) => id === selectedIdentityId)?.delegatorName ?? '-'
      await changeToDelegate(id)
      router.push('/')
      toast({ variant: 'success', description: `Now viewing as ${delegatorName}` })
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Switch to delegate')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  return isMobile ? (
    <>
      <Button onClick={() => setIsExpand((prev) => !prev)}>
        {isExpand ? (
          <Avatar aria-label={'Open user menu'} data-testid={'user-menu-button'} className={'h-8 w-8'}>
            <AvatarImage src={profile?.photo} alt={'avatar'} />
            <AvatarFallback className={'bg-grey/20'}>
              <ArrowRightIcon className={'text-primary'} size={24} />
            </AvatarFallback>
          </Avatar>
        ) : (
          <Avatar aria-label={'Open user menu'} data-testid={'user-menu-button'} className={'h-8 w-8'}>
            <AvatarImage src={profile?.photo} alt={'avatar'} />
            <AvatarFallback className={'bg-[#6B7EA9]'}>
              <UserIcon />
            </AvatarFallback>
          </Avatar>
        )}
      </Button>
      <aside
        className={cn(
          'fixed right-0 top-[60px] z-30 flex h-screen w-[240px] flex-col overflow-hidden bg-background transition-transform duration-300',
          isExpand ? 'translate-x-0' : 'translate-x-full'
        )}
      >
        {/* user info */}
        <div
          className={'flex cursor-pointer gap-x-2 p-2 hover:bg-background-hover'}
          onClick={() => {
            if (delegatorId) {
              setSelectedIdentityId(null)
              setIsExpand(false)
            }
          }}
        >
          <div className={'relative shrink-0'}>
            <Avatar>
              <AvatarImage src={profile?.photo} alt={'avatar'} />
              <AvatarFallback className={'bg-[#6B7EA9]'}>
                <UserIcon />
              </AvatarFallback>
            </Avatar>
          </div>
          <div className={'flex w-full items-center justify-between'}>
            <div className={'flex flex-col overflow-hidden'}>
              <TruncatedText as={'span'} className={'max-w-[160px] text-xl text-white'}>
                {profile?.name || t('Unknown')}
              </TruncatedText>
              <span className={'text-xs font-medium text-grey'}>{t('PersonalAccount')}</span>
            </div>
            <div className={cn('h-3 w-3 rounded-full', delegatorId ? 'border border-[#BDBEC8]' : 'bg-[#79CF1B]')} />
          </div>
        </div>

        {/* delegate list */}
        {hasDelegates && (
          <>
            <p className={'my-1 pl-2 text-xs text-gray-400'}>{t('DelegatesFor')}</p>
            {delegatesOf.map((delegate) => (
              <div
                key={delegate.id}
                className={'flex cursor-pointer gap-x-2 p-4 hover:bg-background-hover'}
                onClick={() => {
                  if (delegatorId !== delegate.id) {
                    setSelectedIdentityId(delegate.id)
                    setIsExpand(false)
                  }
                }}
              >
                <Avatar>
                  <AvatarImage src={undefined} alt={'avatar'} />
                  <AvatarFallback className={'bg-[#6B7EA9]'}>
                    <UserIcon />
                  </AvatarFallback>
                </Avatar>
                <div className={'flex flex-1 items-center justify-between'}>
                  <TruncatedText as={'span'} className={'text-sm text-white'}>
                    {delegate.delegatorName}
                  </TruncatedText>
                  <div
                    className={cn(
                      'h-3 w-3 rounded-full',
                      delegatorId === delegate.id ? 'bg-[#79CF1B]' : 'border border-[#BDBEC8]'
                    )}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        <div className={'my-1 h-[1px] bg-grey/20'} />

        {/* other menu items */}
        {items.map((item, index) => (
          <Link
            key={index}
            href={item.url}
            className={'flex items-center gap-x-2 py-4 pl-3.5  text-gray-300 hover:bg-background-hover'}
            aria-label={item.ariaLabel}
            data-testid={item.testId}
            onClick={() => setIsExpand(false)}
          >
            {item.icon}
            <span className={'text-grey'}>{item.label}</span>
          </Link>
        ))}

        {/* hide sensitive data */}
        <div className={'flex items-center justify-between gap-x-2 p-4 pl-3.5 text-sm text-gray-300'}>
          <div className={'flex items-center gap-x-2'}>
            <EyeOffIcon className={'mr-2'} size={32} />
            <span>{t('HideSensitiveData')}</span>
          </div>
          <Switch
            defaultChecked={isMasked}
            onClick={toggleMask}
            data-testid={'hide-sensitive-data-switch'}
            aria-label={'hide-sensitive-data-switch'}
          />
        </div>

        {/* support and logout */}
        <div
          className={'flex cursor-pointer items-center gap-x-2 py-3 pl-3.5 text-gray-300 hover:bg-background-hover'}
          onClick={() => {
            onSupportOpen()
            setIsExpand(false)
          }}
        >
          <SupportIcon className={'mr-2'} size={32} />
          <span className={'text-grey'}>{t('Support')}</span>
        </div>

        <div className={'my-1 h-[1px] bg-grey/20'} />

        <div
          className={'flex cursor-pointer items-center gap-x-2 p-4 text-gray-300 hover:bg-background-hover'}
          onClick={() => {
            handleLogout()
            setIsExpand(false)
          }}
        >
          <SignOutIcon className={'mr-2'} size={28} />
          <span className={'text-grey'}>{t('SignOut')}</span>
        </div>
      </aside>
    </>
  ) : (
    <div className={'relative'}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild={true}>
          <Avatar aria-label={'Open user menu'} data-testid={'user-menu-button'}>
            <AvatarImage src={profile?.photo} alt={'avatar'} />
            <AvatarFallback className={'bg-[#6B7EA9]'}>
              <UserIcon />
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>

        <DropdownMenuContent className={'mt-4 w-64 bg-background text-grey'} sideOffset={8} align={'end'}>
          <DropdownMenuItem
            className={'gap-x-2 focus:bg-background-hover focus:text-secondary-hover'}
            onSelect={() => delegatorId && setSelectedIdentityId(null)}
          >
            <div className={'relative shrink-0'}>
              <Avatar>
                <AvatarImage src={profile?.photo} alt={'avatar'} />
                <AvatarFallback className={'bg-[#6B7EA9]'}>
                  <UserIcon />
                </AvatarFallback>
              </Avatar>
            </div>
            <div className={'flex w-full items-center justify-between'}>
              <div className={'flex flex-col overflow-hidden'}>
                <TruncatedText as={'span'} className={' max-w-[170px] text-xl text-white'}>
                  {profile?.name || t('Unknown')}
                </TruncatedText>
                <span className={'text-xs font-medium text-grey'}>{t('PersonalAccount')}</span>
              </div>
              <div className={cn('h-3 w-3 rounded-full', delegatorId ? 'border border-[#BDBEC8]' : 'bg-[#79CF1B]')} />
            </div>
          </DropdownMenuItem>
          {hasDelegates && (
            <>
              <p className={'my-1 pl-2 text-xs'}>{t('DelegatesFor')}</p>
              {delegatesOf.map((delegate) => (
                <DropdownMenuItem
                  key={delegate.id}
                  className={'gap-x-2 focus:bg-background-hover focus:text-secondary-hover'}
                  onSelect={() => delegatorId !== delegate.id && setSelectedIdentityId(delegate.id)}
                >
                  {/* FIXME avatar */}
                  <div className={'relative'}>
                    <Avatar>
                      <AvatarImage src={undefined} alt={'avatar'} />
                      <AvatarFallback className={'bg-[#6B7EA9]'}>
                        <UserIcon />
                      </AvatarFallback>
                    </Avatar>
                  </div>
                  <div className={'flex flex-1 items-center justify-between'}>
                    <TruncatedText as={'span'} className={'text-sm text-white'}>
                      {delegate.delegatorName}
                    </TruncatedText>
                    <div
                      className={cn(
                        'h-3 w-3 rounded-full',
                        delegatorId === delegate.id ? 'bg-[#79CF1B]' : 'border border-[#BDBEC8]'
                      )}
                    />
                  </div>
                </DropdownMenuItem>
              ))}
            </>
          )}

          {hasInvitations && (
            <>
              <p className={'my-1 pl-2 text-xs'}>{'Invitation from:'}</p>
              {invitations.map((delegate) => (
                <DropdownMenuItem
                  key={delegate.id}
                  className={'gap-x-2 focus:bg-background-hover focus:text-secondary-hover'}
                  asChild={true}
                >
                  <Link href={{ pathname: '/identity/invitation/', query: { id: delegate.id } }}>
                    {/* FIXME avatar */}
                    <Avatar>
                      <AvatarImage src={undefined} alt={'avatar'} />
                      <AvatarFallback className={'bg-[#6B7EA9]'}>
                        <UserIcon />
                      </AvatarFallback>
                    </Avatar>
                    <TruncatedText as={'span'} className={'text-sm text-white'}>
                      {delegate.permissions.delegatorName}
                    </TruncatedText>
                  </Link>
                </DropdownMenuItem>
              ))}
            </>
          )}
          <DropdownMenuSeparator />
          {items.map((item, index) => (
            <DropdownMenuItem
              key={index}
              className={'focus:bg-background-hover focus:text-secondary-hover'}
              asChild={true}
              disabled={item.disabled}
              aria-label={item.ariaLabel}
              data-testid={item.testId}
            >
              <Link href={item.url}>
                {item.icon}
                <span>{item.label}</span>
              </Link>
            </DropdownMenuItem>
          ))}
          {/* Hide Sensitive Data */}
          <div className={'flex items-center justify-between px-4 py-2 text-sm'}>
            <div className={'flex items-center'}>
              <EyeOffIcon className={'mr-2'} size={16} />
              <span>{t('HideSensitiveData')}</span>
            </div>
            <Switch
              defaultChecked={isMasked}
              onClick={toggleMask}
              data-testid={'hide-sensitive-data-switch'}
              aria-label={'hide-sensitive-data-switch'}
            />
          </div>
          {/* Support */}
          <DropdownMenuItem
            className={'focus:bg-background-hover focus:text-secondary-hover'}
            onSelect={onSupportOpen}
            aria-label={'Open help center'}
            data-testid={'support-button'}
          >
            <SupportIcon className={'mr-2'} size={16} />
            <span>{t('Support')}</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className={'focus:bg-background-hover focus:text-secondary-hover'}
            onSelect={handleLogout}
            aria-label={'Sign out'}
            data-testid={'sign-out-button'}
          >
            <SignOutIcon className={'mr-2'} size={16} />
            <span>{t('SignOut')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedIdentityId && (
        <Confirm
          title={t('account:SwitchDelegate')}
          additionalContent={
            <div className={'grid max-w-[416px] gap-y-4'}>
              <Trans
                t={t}
                i18nKey={'account:SwitchDelegateContent'}
                parent={(props: any) => <span className={'break-words text-sm text-gray-600'} {...props} />}
                components={[<span key={0} className={'font-bold'} />]}
              />
              <Link
                className={'text-sm text-primary'}
                href={'https://myassets.com/knowledge-base/'}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {t('LearnMore')}
              </Link>
            </div>
          }
          onCancel={() => setSelectedIdentityId(undefined)}
          cancelLabel={t('Cancel')}
          onConfirm={() => {
            handleChangeDelegate(selectedIdentityId)
            setSelectedIdentityId(undefined)
          }}
          confirmLabel={t('account:SwitchAccount')}
        />
      )}
      {selectedIdentityId === null && (
        <Confirm
          title={t('account:SwitchPersonal')}
          additionalContent={
            <div className={'grid max-w-[416px] gap-y-4'}>
              <Trans
                t={t}
                i18nKey={'account:SwitchPersonalContent'}
                parent={(props: any) => <span className={'break-words text-sm text-gray-600'} {...props} />}
                components={[<span key={0} className={'font-bold'} />]}
              />
            </div>
          }
          onCancel={() => setSelectedIdentityId(undefined)}
          cancelLabel={t('Cancel')}
          onConfirm={() => {
            handleChangeSelf()
            setSelectedIdentityId(undefined)
          }}
          confirmLabel={t('account:SwitchAccount')}
        />
      )}
    </div>
  )
}
