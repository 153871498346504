import Decimal from "decimal.js";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  addDecimal,
  AllowedDecimalPlaces,
  calculatePercentage,
  subDecimal,
} from "../utils";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import { Event as AccountEventInner } from "./cashAndBanking";
import {
  Amount,
  AssetType,
  Categories,
  categoriesFromAssetType,
  Currency,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { EventEnvelope } from "./event";
import {
  MultiCurrencyValue,
  SubCategoryItem,
  SummaryUtils,
  buildUpdaterMap,
  SummaryTag,
} from "./summary";
import {
  CashAndBankingSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<AccountEventInner>;

export type SupportLiabilityType =
  | AssetType.Property
  | AssetType.Art
  | AssetType.WineAndSpirits
  | AssetType.OtherCollectables
  | AssetType.Belonging
  | AssetType.OtherInvestment;

export const supportLiabilityTypes: SupportLiabilityType[] = [
  AssetType.Property,
  AssetType.Art,
  AssetType.WineAndSpirits,
  AssetType.OtherCollectables,
  AssetType.Belonging,
  AssetType.OtherInvestment,
];

export interface CashAndBankingSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.CashAndBankingSummary, 3>;
  id: AssetType.CashAndBanking;
  // only minimal info is updated for each tag
  [SummaryTag.CashAndBanking.InstitutionAssets]: SubCategoryItem[]; // value
  [SummaryTag.CashAndBanking.InstitutionLiabilities]: SubCategoryItem[]; // value
}

export namespace CashAndBankingSummary {
  export function assureVersion(
    input: CashAndBankingSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      CashAndBankingSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    institutions: {
      assets: DisplayItem[];
      liabilities: DisplayItem[];
    };
  }
  interface DisplayItem {
    name: string;
    subtypes: {
      originValue: Amount;
      value: Amount;
      percentage: number;
    }[];
    value: Amount;
    percentage: number;
  }

  export function toDisplay(
    input: CashAndBankingSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let assets = new Decimal(0);
    let liabilities = new Decimal(0);
    const assetsArr: DisplayItem[] = [];
    const liabilitiesArr: DisplayItem[] = [];

    function toDisplayItem(item: SubCategoryItem): DisplayItem {
      let totalValue = new Decimal(0);
      const subtypes: DisplayItem["subtypes"] = Object.entries(
        item.multiCurrencyValue
      ).map(([originCurrency, originValue]) => {
        const value = new Decimal(originValue)
          .mul(exchangeRate.rates[originCurrency].rate)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .toNumber();
        totalValue = totalValue.add(value);
        return {
          originValue: {
            currency: originCurrency as Currency,
            value: Math.abs(originValue),
          },
          value: {
            currency,
            value: Math.abs(value),
          },
          percentage: 0,
        };
      });
      return {
        name: item.label,
        subtypes,
        value: {
          currency,
          value: totalValue.abs().toNumber(),
        },
        percentage: 0,
      };
    }

    input[SummaryTag.CashAndBanking.InstitutionAssets].forEach((item) => {
      const displayItem = toDisplayItem(item);
      assets = assets.add(displayItem.value.value);
      assetsArr.push(displayItem);
    });
    input[SummaryTag.CashAndBanking.InstitutionLiabilities].forEach((item) => {
      const displayItem = toDisplayItem(item);
      liabilities = liabilities.add(displayItem.value.value);
      liabilitiesArr.push(displayItem);
    });

    const netValue = subDecimal(assets, liabilities);

    const result: Display = {
      assets: {
        currency,
        value: assets.toNumber(),
      },
      liabilities: {
        currency,
        value: liabilities.toNumber(),
      },
      netValue: {
        currency,
        value: netValue,
      },
      institutions: {
        assets: assetsArr,
        liabilities: liabilitiesArr,
      },
    };

    result.institutions.assets.forEach((v) => {
      v.percentage = calculatePercentage(v.value.value, result.assets.value);
      v.subtypes.forEach((subtype) => {
        subtype.percentage = calculatePercentage(
          subtype.value.value,
          v.value.value
        );
      });
    });
    result.institutions.liabilities.forEach((v) => {
      v.percentage = calculatePercentage(
        v.value.value,
        result.liabilities.value
      );
      v.subtypes.forEach((subtype) => {
        subtype.percentage = calculatePercentage(
          subtype.value.value,
          v.value.value
        );
      });
    });

    result.institutions.assets.sort((a, b) => b.value.value - a.value.value);
    result.institutions.liabilities.sort(
      (a, b) => b.value.value - a.value.value
    );
    return result;
  }

  export function defaultValue() {
    const summary: CashAndBankingSummary = {
      "@type": CashAndBankingSummaryTypeVersion,
      id: AssetType.CashAndBanking,
      version: 0,
      [SummaryTag.CashAndBanking.InstitutionAssets]: [],
      [SummaryTag.CashAndBanking.InstitutionLiabilities]: [],
    };
    return summary;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<CashAndBankingSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new CashAndBankingSummaryAggregate(summary));
  }
}

export class CashAndBankingSummaryAggregate extends AggregateBase<
  CashAndBankingSummary,
  never,
  AccountEventInner
> {
  state: CashAndBankingSummary;
  declare relatedUpdates: never;

  constructor(state: CashAndBankingSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    const multiCurrencyValue = new MultiCurrencyValue();
    SummaryUtils.applySummaryData(
      event,
      this.state,
      {
        [SummaryTag.CashAndBanking.InstitutionAssets]: [multiCurrencyValue],
        [SummaryTag.CashAndBanking.InstitutionLiabilities]: [
          multiCurrencyValue,
        ],
      },
      buildUpdaterMap(multiCurrencyValue)
    );
    return this;
  }
}

export type AssociatedLiabilityAsset = {
  accountId: string;
  assetId: string;
  liability: Amount;
  assetType: SupportLiabilityType;
};
export type CategoriesAssociatedLiability = {
  [category in Exclude<Categories, "MyFinances">]: Amount;
};

export function buildAssociatedLiability(
  exchangeRate: TargetCurrencyExchangeRateDataMap,
  liabilityAssets: AssociatedLiabilityAsset[]
): CategoriesAssociatedLiability {
  const result = {
    [Categories.MyProperties]: {
      currency: exchangeRate.targetCurrency,
      value: 0,
    },
    [Categories.MyCollectables]: {
      currency: exchangeRate.targetCurrency,
      value: 0,
    },
    [Categories.MyBelongings]: {
      currency: exchangeRate.targetCurrency,
      value: 0,
    },
  };
  liabilityAssets.forEach((v) => {
    const baseLiability = TargetCurrencyExchangeRateDataMap.amountToCurrency(
      exchangeRate,
      v.liability
    );
    const category = categoriesFromAssetType(v.assetType) as Exclude<
      Categories,
      "MyFinances"
    >;
    result[category].value = addDecimal(
      result[category].value,
      baseLiability.value
    );
  });
  return result;
}
