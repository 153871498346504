import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20viewBox%3D%220%200%2021%2015%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2.102%201.816V13.08H6.59a4.506%204.506%200%200%201%203.188%201.309l.13.129H.653V1.816h1.45Zm18.223%200v12.702H11.07l.13-.129a4.51%204.51%200%200%201%203.187-1.309h4.488V1.816h1.45ZM6.59.006a4.51%204.51%200%200%201%203.188%201.31l.134.132v11.55a5.65%205.65%200%200%200-3.322-1.07H3.255V.007Zm11.132%200v11.922h-3.335a5.65%205.65%200%200%200-3.322%201.07V1.448l.135-.133A4.51%204.51%200%200%201%2014.387.007h3.335Zm-9.81%208.072H5.255v1.153H7.91V8.078Zm7.81%200h-2.656v1.153h2.656V8.078Zm-7.81-2.592H5.255v1.153H7.91V5.486Zm7.81%200h-2.656v1.153h2.656V5.486Zm-7.81-2.592H5.255v1.152H7.91V2.894Zm7.81%200h-2.656v1.152h2.656V2.894Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function OpenedBookIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 21 15'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M2.102 1.816V13.08H6.59a4.506 4.506 0 0 1 3.188 1.309l.13.129H.653V1.816h1.45Zm18.223 0v12.702H11.07l.13-.129a4.51 4.51 0 0 1 3.187-1.309h4.488V1.816h1.45ZM6.59.006a4.51 4.51 0 0 1 3.188 1.31l.134.132v11.55a5.65 5.65 0 0 0-3.322-1.07H3.255V.007Zm11.132 0v11.922h-3.335a5.65 5.65 0 0 0-3.322 1.07V1.448l.135-.133A4.51 4.51 0 0 1 14.387.007h3.335Zm-9.81 8.072H5.255v1.153H7.91V8.078Zm7.81 0h-2.656v1.153h2.656V8.078Zm-7.81-2.592H5.255v1.153H7.91V5.486Zm7.81 0h-2.656v1.153h2.656V5.486Zm-7.81-2.592H5.255v1.152H7.91V2.894Zm7.81 0h-2.656v1.152h2.656V2.894Z'
        }
      />
    </svg>
  )
}
