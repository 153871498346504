import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20460%20460%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M458.27%20297.42c-2.56-8.192-11.264-12.8-18.944-10.24h-.512l-186.88%2061.44c-12.288-19.456-34.816-30.72-57.856-29.184L109.086%2061.388C100.894%2036.3%2078.366%2018.38%2051.742%2015.82l-34.816-3.072C8.222%2011.724.542%2018.38.03%2026.572c-.512%208.192%205.632%2015.872%2013.824%2016.896L48.67%2046.54c14.336%201.536%2026.112%2010.752%2030.72%2024.576l84.992%20258.56c-29.696%2018.432-38.4%2057.344-19.968%2087.04%2018.432%2029.696%2057.344%2038.4%2087.04%2019.968%2019.968-12.288%2031.232-34.816%2029.184-57.856l186.88-61.44c8.704-3.072%2013.312-11.776%2010.752-19.968z%22%2F%3E%3Cpath%20d%3D%22M445.47%20225.74%20385.566%2042.956c-3.072-8.704-12.288-13.824-21.504-10.752l-189.44%2061.952c-8.704%203.072-13.824%2012.288-10.752%2021.504l59.904%20182.784c3.072%208.704%2012.288%2013.824%2021.504%2010.752l189.44-61.952c8.704-3.072%2013.824-12.288%2010.752-21.504z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CartIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 460 460'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M458.27 297.42c-2.56-8.192-11.264-12.8-18.944-10.24h-.512l-186.88 61.44c-12.288-19.456-34.816-30.72-57.856-29.184L109.086 61.388C100.894 36.3 78.366 18.38 51.742 15.82l-34.816-3.072C8.222 11.724.542 18.38.03 26.572c-.512 8.192 5.632 15.872 13.824 16.896L48.67 46.54c14.336 1.536 26.112 10.752 30.72 24.576l84.992 258.56c-29.696 18.432-38.4 57.344-19.968 87.04 18.432 29.696 57.344 38.4 87.04 19.968 19.968-12.288 31.232-34.816 29.184-57.856l186.88-61.44c8.704-3.072 13.312-11.776 10.752-19.968z'
        }
      />
      <path
        d={
          'M445.47 225.74 385.566 42.956c-3.072-8.704-12.288-13.824-21.504-10.752l-189.44 61.952c-8.704 3.072-13.824 12.288-10.752 21.504l59.904 182.784c3.072 8.704 12.288 13.824 21.504 10.752l189.44-61.952c8.704-3.072 13.824-12.288 10.752-21.504z'
        }
      />
    </svg>
  )
}
