import Decimal from "decimal.js";
import { DocumentReference, Transaction } from "../../coreFirebase";
import { calculatePercentage, OmitKeys } from "../utils";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import {
  Amount,
  LocationType,
  MultiCurrencyAmount,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { AssetType } from "./enums";
import { InvalidInput } from "./error";
import { EventEnvelope } from "./event";
import {
  AssetNumber,
  buildUpdaterMap,
  ItemNumber,
  MultiCurrencyValue,
  SubCategoryItem,
  SummaryUtils,
  SummaryTag,
} from "./summary";
import {
  assureSummaryVersion,
  validateTypeUpToDate,
  VersionedType,
  VersionedTypeString,
  WineSummaryTypeVersion,
} from "./typeVersion";
import { Wine, Event as WineEvent, WinePurchase } from "./wineAndSprits";

export type Event = EventEnvelope<WineEvent>;
export type WineInSummary = Pick<
  Wine.Encrypted,
  | "subtype"
  | "vintage"
  | "country"
  | "labeledVariety"
  | "catalogueImage"
  | "updateAt"
> & {
  liability: Amount;
  value: Amount;
  purchases: WinePurchaseInSummary[];
};
type WinePurchaseInSummary = Pick<
  WinePurchase,
  | "id"
  | "valuePerBottle"
  | "purchaseDate"
  | "bottleCount"
  | "bottleSize"
  | "pricingMethod"
  | "price"
> & { ownedPercentage: number };
export interface WineSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.WineSummary, 3>;
  id: AssetType.WineAndSpirits;
  // only minimal info is updated for each tag
  [SummaryTag.Wine.Subtype]: SubCategoryItem[]; // value, assetNumber, itemNumber
  [SummaryTag.Wine.BottleSize]: SubCategoryItem[]; // itemNumber
  [SummaryTag.Wine.Vintage]: SubCategoryItem[]; // value, itemNumber
  [SummaryTag.Wine.Origin]: SubCategoryItem[]; // value, itemNumber
  [SummaryTag.Wine.Varietal]: SubCategoryItem[]; // value, itemNumber
}
export interface SubtypeItem {
  label: string;
  assetNumber: number;
  itemNumber: number;
  value: Amount;
  liability: Amount;
  percentage: number;
}

export interface BottleLocationInfo {
  bottles: number;
  // only in leaf node
  details?: {
    [wineId: string]: number;
  };
  child?: BottleLocationNode;
}
export interface BottleLocationNode {
  located: {
    //id: room, bin or shelf
    [id: string]: BottleLocationInfo;
  };
  notLocated: BottleLocationInfo;
}
export namespace BottlesInLocation {
  export interface DisplayInfo {
    // room, bin or shelf
    location?: string;
    bottles: number;
    // only in leaf node
    details?: {
      wineDocId: string;
      bottles: number;
    }[];
    child?: DisplayNode;
  }
  export interface DisplayNode {
    located?: DisplayInfo[];
    notLocated?: DisplayInfo;
  }
}

export namespace WineSummary {
  export function assureVersion(
    input: WineSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      WineSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;

    drinkingNow: number;
    typesNumber: number;
    totalBottlesNumber: number;
    subtypeItems: SubtypeItem[];

    vintage: {
      range: string;
      number: number;
      value: Amount;
    }[];
    bottleSize: { size: string; bottles: number }[];
    origin: {
      origin: string;
      number: number;
      value: Amount;
    }[];
    varietal: {
      varietal: string;
      number: number;
      value: Amount;
    }[];
  }

  export interface LocationInfo {
    id: string;
    locationType: LocationType;
    itemNumber: number;
    value: Amount;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<WineSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new WineSummaryAggregate(summary));
  }

  export function defaultValue(): WineSummary {
    return {
      "@type": WineSummaryTypeVersion,
      id: AssetType.WineAndSpirits,
      [SummaryTag.Wine.Subtype]: [],
      [SummaryTag.Wine.BottleSize]: [],
      [SummaryTag.Wine.Vintage]: [],
      [SummaryTag.Wine.Origin]: [],
      [SummaryTag.Wine.Varietal]: [],
      version: 0,
    };
  }

  export function toDisplay(
    input: WineSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let totalBottlesNumber = 0;
    let totalAssets = new Decimal(0);
    const subtypesMap: SubtypeItem[] = [];
    let hasUnspecifiedType = false;

    input[SummaryTag.Wine.Subtype].forEach((item) => {
      const value = MultiCurrencyAmount.calculateTargetCurrencyAmount(
        item.multiCurrencyValue,
        exchangeRate
      );
      subtypesMap.push({
        label: item.label,
        assetNumber: item.assetNumber,
        itemNumber: item.itemNumber,
        value,
        liability: { currency, value: 0 },
        percentage: 0,
      });
      totalAssets = totalAssets.add(value.value);
      totalBottlesNumber += item.itemNumber;
      if (item.label == "-") hasUnspecifiedType = true;
    });

    //#TODO we don't know how to do drinkNow
    const result: OmitKeys<Display, "typesNumber" | "subtypeItems"> = {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: 0,
      },
      netValue: {
        currency,
        value: totalAssets.toNumber(),
      },
      drinkingNow: 0,
      totalBottlesNumber,

      vintage: input[SummaryTag.Wine.Vintage].map((item) => ({
        range: item.label,
        number: item.itemNumber,
        value: MultiCurrencyAmount.calculateTargetCurrencyAmount(
          item.multiCurrencyValue,
          exchangeRate
        ),
      })),
      bottleSize: input[SummaryTag.Wine.BottleSize].map((item) => ({
        size: item.label,
        bottles: item.itemNumber,
      })),
      origin: input[SummaryTag.Wine.Origin].map((item) => ({
        origin: item.label,
        number: item.itemNumber,
        value: MultiCurrencyAmount.calculateTargetCurrencyAmount(
          item.multiCurrencyValue,
          exchangeRate
        ),
      })),
      varietal: input[SummaryTag.Wine.Varietal].map((item) => ({
        varietal: item.label,
        number: item.itemNumber,
        value: MultiCurrencyAmount.calculateTargetCurrencyAmount(
          item.multiCurrencyValue,
          exchangeRate
        ),
      })),
    };
    const subtypeItems: SubtypeItem[] = Object.values(subtypesMap).map(
      (item) => {
        item.percentage = calculatePercentage(
          item.value.value,
          result.assets.value
        );
        return item;
      }
    );
    subtypeItems.sort((a, b) => b.value.value - a.value.value);
    return {
      ...result,
      typesNumber: hasUnspecifiedType
        ? subtypeItems.length - 1
        : subtypeItems.length,
      subtypeItems,
    };
  }

  export type RelatedUpdates = never;
}

export class WineSummaryAggregate extends AggregateBase<
  WineSummary,
  never,
  WineEvent
> {
  state: WineSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: WineSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    const itemNumber = new ItemNumber();
    const assetNumber = new AssetNumber();
    const multiCurrencyValue = new MultiCurrencyValue();
    SummaryUtils.applySummaryData(
      event,
      this.state,
      {
        [SummaryTag.Wine.Subtype]: [
          itemNumber,
          assetNumber,
          multiCurrencyValue,
        ],
        [SummaryTag.Wine.BottleSize]: [itemNumber],
        [SummaryTag.Wine.Vintage]: [itemNumber, multiCurrencyValue],
        [SummaryTag.Wine.Origin]: [itemNumber, multiCurrencyValue],
        [SummaryTag.Wine.Varietal]: [itemNumber, multiCurrencyValue],
      },
      buildUpdaterMap(itemNumber, assetNumber, multiCurrencyValue)
    );
    return this;
  }
}
