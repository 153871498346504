import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20d%3D%22M0%20241h106.386c.985-37.555%205.577-72.935%2013.235-105H31.123C14.185%20167.615%202.214%20203.24%200%20241zm106.386%2030H0c2.214%2037.76%2014.185%2073.385%2031.123%20105h88.499c-7.658-32.065-12.251-67.445-13.236-105zM241%20241V136h-90.361c-8.21%2031.776-13.182%2067.478-14.269%20105zm0-135V0c-32.847%209.174-61.943%2051.143-81.145%20106zm0%20406V406h-81.145c19.202%2054.857%2048.298%2096.826%2081.145%20106zM271%200v106h81.145C332.943%2051.143%20303.847%209.174%20271%200zm104.63%20241c-1.088-37.522-6.059-73.224-14.269-105H271v105zM241%20271H136.37c1.088%2037.524%206.059%2073.224%2014.269%20105H241zm143.011-165h77.75c-31.049-42.473-74.76-78.355-125.684-95.257%2019.571%2023.104%2035.94%2057.847%2047.934%2095.257zM127.989%20406h-77.75c31.049%2042.473%2074.76%2078.355%20125.684%2095.257-19.571-23.104-35.94-57.847-47.934-95.257zm0-300c11.993-37.41%2028.363-72.153%2047.933-95.257C124.999%2027.645%2081.288%2063.527%2050.239%20106zm256.022%20300c-11.993%2037.41-28.363%2072.153-47.933%2095.257%2050.923-16.902%2094.634-52.784%20125.684-95.257zM271%20271v105h90.361c8.21-31.776%2013.182-67.476%2014.269-105zm121.379-135c7.657%2032.065%2012.25%2067.445%2013.235%20105H512c-2.214-37.76-14.185-73.385-31.123-105zM271%20406v106c32.847-9.174%2061.943-51.143%2081.145-106zm241-135H405.614c-.985%2037.555-5.577%2072.935-13.235%20105h88.499c16.937-31.615%2028.908-67.24%2031.122-105z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CatalogueIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 512 512'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M0 241h106.386c.985-37.555 5.577-72.935 13.235-105H31.123C14.185 167.615 2.214 203.24 0 241zm106.386 30H0c2.214 37.76 14.185 73.385 31.123 105h88.499c-7.658-32.065-12.251-67.445-13.236-105zM241 241V136h-90.361c-8.21 31.776-13.182 67.478-14.269 105zm0-135V0c-32.847 9.174-61.943 51.143-81.145 106zm0 406V406h-81.145c19.202 54.857 48.298 96.826 81.145 106zM271 0v106h81.145C332.943 51.143 303.847 9.174 271 0zm104.63 241c-1.088-37.522-6.059-73.224-14.269-105H271v105zM241 271H136.37c1.088 37.524 6.059 73.224 14.269 105H241zm143.011-165h77.75c-31.049-42.473-74.76-78.355-125.684-95.257 19.571 23.104 35.94 57.847 47.934 95.257zM127.989 406h-77.75c31.049 42.473 74.76 78.355 125.684 95.257-19.571-23.104-35.94-57.847-47.934-95.257zm0-300c11.993-37.41 28.363-72.153 47.933-95.257C124.999 27.645 81.288 63.527 50.239 106zm256.022 300c-11.993 37.41-28.363 72.153-47.933 95.257 50.923-16.902 94.634-52.784 125.684-95.257zM271 271v105h90.361c8.21-31.776 13.182-67.476 14.269-105zm121.379-135c7.657 32.065 12.25 67.445 13.235 105H512c-2.214-37.76-14.185-73.385-31.123-105zM271 406v106c32.847-9.174 61.943-51.143 81.145-106zm241-135H405.614c-.985 37.555-5.577 72.935-13.235 105h88.499c16.937-31.615 28.908-67.24 31.122-105z'
        }
      />
    </svg>
  )
}
