import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20viewBox%3D%220%200%2016%2016%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M14.694%206.843v7.439c0%20.946-.768%201.714-1.714%201.714h-2.857V9.712a.571.571%200%200%200-.57-.572H6.125a.571.571%200%200%200-.572.572v6.284H2.697a1.714%201.714%200%200%201-1.714-1.714v-7.44a2.807%202.807%200%200%200%203.428%200%202.895%202.895%200%200%200%203.427%200%202.895%202.895%200%200%200%203.428%200%202.807%202.807%200%200%200%203.428%200Zm0-2.273c0%20.947-.768%201.714-1.714%201.714-.494%200-.963-.217-1.284-.593a.59.59%200%200%200-.86%200%201.724%201.724%200%200%201-2.567%200l-.026-.025a.59.59%200%200%200-.835.025%201.724%201.724%200%200%201-2.567%200l-.025-.025a.59.59%200%200%200-.835.025c-.32.376-.79.593-1.284.593A1.714%201.714%200%200%201%20.983%204.57ZM3.839%200v3.428H0L1.012.391A.571.571%200%200%201%201.554%200h2.285Zm3.428%200v3.428H4.982V0h2.285Zm6.855%200a.57.57%200%200%201%20.542.39l1.013%203.038h-3.84V0Zm-3.427%200v3.428H8.41V0h2.285Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ConsignmentIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 16 16'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M14.694 6.843v7.439c0 .946-.768 1.714-1.714 1.714h-2.857V9.712a.571.571 0 0 0-.57-.572H6.125a.571.571 0 0 0-.572.572v6.284H2.697a1.714 1.714 0 0 1-1.714-1.714v-7.44a2.807 2.807 0 0 0 3.428 0 2.895 2.895 0 0 0 3.427 0 2.895 2.895 0 0 0 3.428 0 2.807 2.807 0 0 0 3.428 0Zm0-2.273c0 .947-.768 1.714-1.714 1.714-.494 0-.963-.217-1.284-.593a.59.59 0 0 0-.86 0 1.724 1.724 0 0 1-2.567 0l-.026-.025a.59.59 0 0 0-.835.025 1.724 1.724 0 0 1-2.567 0l-.025-.025a.59.59 0 0 0-.835.025c-.32.376-.79.593-1.284.593A1.714 1.714 0 0 1 .983 4.57ZM3.839 0v3.428H0L1.012.391A.571.571 0 0 1 1.554 0h2.285Zm3.428 0v3.428H4.982V0h2.285Zm6.855 0a.57.57 0 0 1 .542.39l1.013 3.038h-3.84V0Zm-3.427 0v3.428H8.41V0h2.285Z'
        }
      />
    </svg>
  )
}
