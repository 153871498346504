import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2221%22%20viewBox%3D%220%200%2024%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M2.4%2020.1C1.74%2020.1%201.1752%2019.8652%200.7056%2019.3956C0.236%2018.926%200.0008%2018.3608%200%2017.7V3.30002C0%202.64002%200.2352%202.07522%200.7056%201.60562C1.176%201.13602%201.7408%200.900824%202.4%200.900024H9.6L12%203.30002H21.6C22.26%203.30002%2022.8252%203.53522%2023.2956%204.00562C23.766%204.47602%2024.0008%205.04082%2024%205.70002V17.7C24%2018.36%2023.7652%2018.9252%2023.2956%2019.3956C22.826%2019.866%2022.2608%2020.1008%2021.6%2020.1H2.4ZM12.24%2012.9L10.29%2014.85L11.97%2016.53L16.8%2011.7L11.97%206.87002L10.29%208.55002L12.24%2010.5H7.2V12.9H12.24Z%22%20fill%3D%22%23BDBEC8%22%2F%3E%3C%2Fsvg%3E)
 */
export default function MoveGroupIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 24 21'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M2.4 20.1C1.74 20.1 1.1752 19.8652 0.7056 19.3956C0.236 18.926 0.0008 18.3608 0 17.7V3.30002C0 2.64002 0.2352 2.07522 0.7056 1.60562C1.176 1.13602 1.7408 0.900824 2.4 0.900024H9.6L12 3.30002H21.6C22.26 3.30002 22.8252 3.53522 23.2956 4.00562C23.766 4.47602 24.0008 5.04082 24 5.70002V17.7C24 18.36 23.7652 18.9252 23.2956 19.3956C22.826 19.866 22.2608 20.1008 21.6 20.1H2.4ZM12.24 12.9L10.29 14.85L11.97 16.53L16.8 11.7L11.97 6.87002L10.29 8.55002L12.24 10.5H7.2V12.9H12.24Z'
        }
      />
    </svg>
  )
}
