import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20408%20408%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M87.748%20388.784c.461%2011.01%209.521%2019.699%2020.539%2019.699h191.911c11.018%200%2020.078-8.689%2020.539-19.699l13.705-289.316H74.043l13.705%20289.316zm159.907-217.455a8.35%208.35%200%200%201%208.35-8.349h13.355a8.351%208.351%200%200%201%208.35%208.349v165.293a8.35%208.35%200%200%201-8.35%208.349h-13.355a8.35%208.35%200%200%201-8.35-8.349V171.329zm-58.439%200a8.35%208.35%200%200%201%208.349-8.349h13.355a8.35%208.35%200%200%201%208.349%208.349v165.293a8.348%208.348%200%200%201-8.349%208.349h-13.355a8.348%208.348%200%200%201-8.349-8.349V171.329zm-58.441%200a8.35%208.35%200%200%201%208.349-8.349h13.356a8.35%208.35%200%200%201%208.349%208.349v165.293a8.349%208.349%200%200%201-8.349%208.349h-13.356a8.348%208.348%200%200%201-8.349-8.349V171.329zM343.567%2021.043h-88.535V4.305A4.305%204.305%200%200%200%20250.727%200h-92.971a4.305%204.305%200%200%200-4.304%204.305v16.737H64.916c-7.125%200-12.9%205.776-12.9%2012.901V74.47h304.451V33.944c0-7.125-5.775-12.901-12.9-12.901z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function TrashIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 408 408'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M87.748,388.784c0.461,11.01,9.521,19.699,20.539,19.699h191.911c11.018,0,20.078-8.689,20.539-19.699l13.705-289.316 H74.043L87.748,388.784z M247.655,171.329c0-4.61,3.738-8.349,8.35-8.349h13.355c4.609,0,8.35,3.738,8.35,8.349v165.293 c0,4.611-3.738,8.349-8.35,8.349h-13.355c-4.61,0-8.35-3.736-8.35-8.349V171.329z M189.216,171.329 c0-4.61,3.738-8.349,8.349-8.349h13.355c4.609,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.737,8.349-8.349,8.349h-13.355 c-4.61,0-8.349-3.736-8.349-8.349V171.329L189.216,171.329z M130.775,171.329c0-4.61,3.738-8.349,8.349-8.349h13.356 c4.61,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.738,8.349-8.349,8.349h-13.356c-4.61,0-8.349-3.736-8.349-8.349V171.329z'
        }
      />
      <path
        d={
          'M343.567,21.043h-88.535V4.305c0-2.377-1.927-4.305-4.305-4.305h-92.971c-2.377,0-4.304,1.928-4.304,4.305v16.737H64.916 c-7.125,0-12.9,5.776-12.9,12.901V74.47h304.451V33.944C356.467,26.819,350.692,21.043,343.567,21.043z'
        }
      />
    </svg>
  )
}
