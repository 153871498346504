import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M432%20176c0-13.408-1.44-26.464-4.256-38.752-.064-.32-.16-.64-.256-.96L399.584%2012.48C397.984%205.184%20391.456%200%20384%200H128c-7.488%200-13.984%205.184-15.616%2012.48L84.512%20136.224c-.096.32-.192.672-.256%201.024C81.408%20149.536%2080%20162.592%2080%20176c0%2091.648%2070.432%20167.072%20160%20175.2V480h-64c-8.832%200-16%207.168-16%2016s7.168%2016%2016%2016h160c8.832%200%2016-7.168%2016-16s-7.168-16-16-16h-64V351.2c89.568-8.128%20160-83.552%20160-175.2zm-318.304-16c.544-4.928.512-10.08%201.536-14.72.096-.352.192-.704.288-1.088L140.8%2032h230.432l25.28%20112.192c.064.32.16.672.256.992%201.024%204.704.992%209.888%201.536%2014.816H113.696z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function BottleRemovedIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 512 512'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M432 176c0-13.408-1.44-26.464-4.256-38.752-.064-.32-.16-.64-.256-.96L399.584 12.48C397.984 5.184 391.456 0 384 0H128c-7.488 0-13.984 5.184-15.616 12.48L84.512 136.224c-.096.32-.192.672-.256 1.024C81.408 149.536 80 162.592 80 176c0 91.648 70.432 167.072 160 175.2V480h-64c-8.832 0-16 7.168-16 16s7.168 16 16 16h160c8.832 0 16-7.168 16-16s-7.168-16-16-16h-64V351.2c89.568-8.128 160-83.552 160-175.2zm-318.304-16c.544-4.928.512-10.08 1.536-14.72.096-.352.192-.704.288-1.088L140.8 32h230.432l25.28 112.192c.064.32.16.672.256.992 1.024 4.704.992 9.888 1.536 14.816H113.696z'
        }
      />
    </svg>
  )
}
