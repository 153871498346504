import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2030%2024%22%20fill%3D%22%23ddd%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23a)%22%3E%3Cpath%20d%3D%22M9.436%209.787a8.82%208.82%200%200%200%204.627%205.922c0%20.056-.008.111-.008.167a8.72%208.72%200%200%200%202.665%206.273%207.2%207.2%200%200%201-3.472.918C9.252%2023.067%206%2019.84%206%2015.876c0-2.573%201.381-4.819%203.436-6.09m17.128%200C28.617%2011.057%2030%2013.303%2030%2015.876c0%203.965-3.252%207.19-7.248%207.19a7.2%207.2%200%200%201-3.472-.917%208.73%208.73%200%200%200%202.667-6.273c0-.056-.008-.11-.01-.167a8.82%208.82%200%200%200%204.627-5.922m-6.164%206.56a7.07%207.07%200%200%201-2.32%204.8%207.07%207.07%200%200%201-2.32-4.8%208.6%208.6%200%200%200%202.32.325c.804%200%201.58-.119%202.32-.325m-2.4-5.76a7.1%207.1%200%200%201%202.24%204.08c-.708.24-1.453.4-2.24.4a7%207%200%200%201-2.24-.4%207.1%207.1%200%200%201%202.24-4.08m4.748-1.92c.805%200%201.568.162%202.292.404a7.16%207.16%200%200%201-3.31%204.876%208.74%208.74%200%200%200-2.45-4.364%207.2%207.2%200%200%201%203.468-.916m-9.496%200c1.263%200%202.433.35%203.468.916a8.74%208.74%200%200%200-2.45%204.364%207.16%207.16%200%200%201-3.31-4.876%207.2%207.2%200%200%201%202.292-.404m4.828-8c3.792%200%206.873%202.984%207.12%206.734a8.7%208.7%200%200%200-2.341-.329c-1.7%200-3.361.498-4.779%201.435a8.67%208.67%200%200%200-4.779-1.435%208.7%208.7%200%200%200-2.341.33C11.207%203.65%2014.288.667%2018.08.667M4.877.875a.875.875%200%201%200-1.75%200v2.251H.874A.877.877%200%200%200%200%204.001a.88.88%200%200%200%20.875.876h2.251v2.248c0%20.48.392.875.875.875a.877.877%200%200%200%20.876-.875V4.877h2.248C7.605%204.877%208%204.485%208%204a.877.877%200%200%200-.875-.875H4.877z%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22M0%200h30v24H0z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E)
 */
export default function AddGroupIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 30 24'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <g clipPath={'url(#a)'}>
        <path
          d={
            'M9.436 9.787a8.82 8.82 0 0 0 4.627 5.922c0 .056-.008.111-.008.167a8.72 8.72 0 0 0 2.665 6.273 7.2 7.2 0 0 1-3.472.918C9.252 23.067 6 19.84 6 15.876c0-2.573 1.381-4.819 3.436-6.09m17.128 0C28.617 11.057 30 13.303 30 15.876c0 3.965-3.252 7.19-7.248 7.19a7.2 7.2 0 0 1-3.472-.917 8.73 8.73 0 0 0 2.667-6.273c0-.056-.008-.11-.01-.167a8.82 8.82 0 0 0 4.627-5.922m-6.164 6.56a7.07 7.07 0 0 1-2.32 4.8 7.07 7.07 0 0 1-2.32-4.8 8.6 8.6 0 0 0 2.32.325c.804 0 1.58-.119 2.32-.325m-2.4-5.76a7.1 7.1 0 0 1 2.24 4.08c-.708.24-1.453.4-2.24.4a7 7 0 0 1-2.24-.4 7.1 7.1 0 0 1 2.24-4.08m4.748-1.92c.805 0 1.568.162 2.292.404a7.16 7.16 0 0 1-3.31 4.876 8.74 8.74 0 0 0-2.45-4.364 7.2 7.2 0 0 1 3.468-.916m-9.496 0c1.263 0 2.433.35 3.468.916a8.74 8.74 0 0 0-2.45 4.364 7.16 7.16 0 0 1-3.31-4.876 7.2 7.2 0 0 1 2.292-.404m4.828-8c3.792 0 6.873 2.984 7.12 6.734a8.7 8.7 0 0 0-2.341-.329c-1.7 0-3.361.498-4.779 1.435a8.67 8.67 0 0 0-4.779-1.435 8.7 8.7 0 0 0-2.341.33C11.207 3.65 14.288.667 18.08.667M4.877.875a.875.875 0 1 0-1.75 0v2.251H.874A.877.877 0 0 0 0 4.001a.88.88 0 0 0 .875.876h2.251v2.248c0 .48.392.875.875.875a.877.877 0 0 0 .876-.875V4.877h2.248C7.605 4.877 8 4.485 8 4a.877.877 0 0 0-.875-.875H4.877z'
          }
        />
      </g>
      <defs>
        <clipPath id={'a'}>
          <path d={'M0 0h30v24H0z'} />
        </clipPath>
      </defs>
    </svg>
  )
}
