import { useCallback, useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { AssetType } from 'core/remodel/types/enums'
import { commonQuery, fetchGlobalSearchResult, fetchMainImageUrlAndIv } from '@/api/CommonService'
import { infoPathnameMap, placeholderImageMap } from '@/constants/assets'
import { FIELD_MAX_LENGTH } from '@/constants/validation'
import { cn } from '@/utils/classnames'
import { useDebounce } from '@/hooks/useDebounce'
import useIsMobile from '@/hooks/useIsMobile'
import { useAuthStore } from '@/store/authStore'
import { Button, Input } from '@/components/base'
import TruncatedText from '@/components/base/TruncatedText'
import { ArrowLeftIcon, SearchIcon, XIcon } from '@/components/icon'
import { Image } from '@/components/Image'

const newLocal = 'h-8 w-60 rounded-none bg-transparent p-0 text-black focus:ring-0'

const hasCreateButtonRoutes = [
  '/account/contacts',
  '/account/contacts/create',
  '/account/contacts/edit',
  '/account/delegates',
  '/account/delegates/create',
  '/account/delegates/edit',
  '/belongings/summary',
  '/belongings/summary/create',
  '/belongings/summary/list',
  '/collectables/art',
  '/collectables/art/create',
  '/collectables/art/list',
  '/collectables/other',
  '/collectables/other/create',
  '/collectables/other/list',
  '/collectables/wine',
  '/collectables/wine/create',
  '/collectables/wine/list',
  '/documents/summary',
  '/documents/summary/create',
  '/finances/accounts',
  '/finances/accounts/create',
  '/finances/crypto',
  '/finances/crypto/create',
  '/finances/insurance',
  '/finances/insurance/create',
  '/finances/other-invest',
  '/finances/other-invest/create',
  '/finances/tradit-invest',
  '/finances/tradit-invest/create',
  '/groups/summary',
  '/properties/summary',
  '/properties/summary/create',
  '/properties/summary/list',
  '/wishlist'
]

export function GlobalSearch() {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLDivElement>(null)
  const keyword = useDebounce(value, 500)
  const database = useAuthStore((state) => state.database)
  const { data = [] } = useSWR(
    keyword.length > 0 && [commonQuery.globalSearch, keyword],
    fetchGlobalSearchResult(database!)
  )
  const [isMobileSearch, setIsMobileSearch] = useState(false)
  const isMobile = useIsMobile()

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value
    setValue(keyword)
    setIsOpen(keyword.length > 0)
  }, [])

  const toggleSearch = () => {
    setIsMobileSearch(!isMobileSearch)
    setValue('')
  }

  const handleSearchItemClick = () => {
    setIsMobileSearch(false)
    setValue('')
  }

  return isMobile ? (
    <>
      <div ref={searchRef} className={cn('relative mt-1')}>
        <Button onClick={toggleSearch}>
          <SearchIcon className={'hover:text-primary-hover'} strokeWidth={3} size={24} />
        </Button>
      </div>
      {isMobileSearch && (
        <div className={'fixed inset-0 z-50 md:hidden'}>
          <div className={'absolute inset-0 bg-black/40'} onClick={toggleSearch} />

          <div className={'z-51 absolute inset-x-0 top-0 p-4'}>
            <div className={'flex items-center bg-gray-100 px-2'}>
              <ArrowLeftIcon className={'text-gray-400'} size={24} onClick={toggleSearch} />
              <Input
                className={'h-8 w-full border-gray-200 bg-gray-100 focus:border-primary focus:ring-0'}
                value={value}
                onChange={handleInputChange}
                placeholder={t('SearchMyAssets')}
                autoFocus
                maxLength={FIELD_MAX_LENGTH}
              />
              {value ? (
                <Button onClick={toggleSearch}>
                  <XIcon className={'text-text-gray hover:text-text/40'} size={22} />
                </Button>
              ) : (
                <Button onClick={toggleSearch}>
                  <SearchIcon className={'text-primary'} strokeWidth={3} size={20} />
                </Button>
              )}
            </div>

            {isOpen && value && (
              <div className={'inset-x-4 mt-1 h-[calc(100vh-72px)] max-w-full overflow-y-auto bg-white'}>
                {data.length > 0 ? (
                  data.map((item) => (
                    <SearchItem
                      key={item.id}
                      className={cn('border-b border-gray-100 px-4', 'hover:bg-gray-50')}
                      {...item}
                      onClick={handleSearchItemClick}
                    />
                  ))
                ) : (
                  <div className={'p-4 text-center text-gray-500'}>{t('NoData')}</div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  ) : (
    <div ref={searchRef} className={cn('relative')}>
      <div className={'w-60 transition-all duration-300 ease-in-out'}>
        <div
          className={
            'relative flex h-8 w-full items-center self-center rounded-sm bg-white p-2 transition-opacity duration-300'
          }
        >
          <Input
            className={newLocal}
            value={value}
            onChange={handleInputChange}
            placeholder={t('SearchMyAssets')}
            onFocus={() => setIsOpen(true)}
            aria-label={'globalSearch'}
            data-testid={'globalSearch'}
            maxLength={FIELD_MAX_LENGTH}
          />
          <div className={'absolute right-1 flex'}>
            {value && (
              <Button onClick={() => setValue('')}>
                <XIcon className={'text-text-gray hover:text-text/40'} size={22} />
              </Button>
            )}
            <Button>
              <SearchIcon className={'text-primary'} strokeWidth={3} size={20} />
            </Button>
          </div>
        </div>
      </div>
      {isOpen && (
        <menu
          ref={menuRef}
          className={'absolute top-9 w-full overflow-y-auto rounded-sm border-grey bg-gray-100 text-sm shadow-lg'}
          onClick={() => setIsOpen(false)}
        >
          {data.length > 0
            ? data.map((item, index) => (
                <SearchItem key={item.id} className={cn(index > 0 && 'border-t border-grey')} {...item} />
              ))
            : value && <div className={'py-4 text-center font-medium text-text/70'}>{t('NoData')}</div>}
        </menu>
      )}
    </div>
  )
}

export interface SearchItemProps {
  id: string
  assetType: AssetType
  subType: string
  name: string
  className?: string
  onClick?: () => void
}

function SearchItem({ id, name, assetType, className, subType, onClick }: SearchItemProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data } = useSWR([commonQuery.mainImageUrlIv, assetType, id], fetchMainImageUrlAndIv(database!))
  const { url, iv } = data ?? {}

  const query = assetType === AssetType.CashAndBanking ? { id, type: subType } : { id }

  return (
    <Link href={{ pathname: infoPathnameMap[assetType], query: query }} onClick={onClick}>
      <div className={cn('group flex gap-2 bg-transparent py-2 pl-4 hover:bg-grey/30', className)}>
        <div className={'relative h-10 w-10 overflow-hidden rounded-full'}>
          <Image
            className={'object-cover'}
            src={url}
            base64={iv}
            alt={'Thumbnail'}
            placeholder={placeholderImageMap[assetType]}
            fill={true}
          />
        </div>
        <div className={'ml-1 grid justify-center'}>
          <TruncatedText className={'text-sm font-medium text-black'}>{name}</TruncatedText>
          <TruncatedText className={'text-xs font-medium text-text/60'}>
            {t(`AssetTypeOptions.${assetType}`)}
          </TruncatedText>
        </div>
      </div>
    </Link>
  )
}
